import { createEntityAdapter, createSlice, EntityState, isAnyOf } from "@reduxjs/toolkit";
import { LocationModel } from "../../app/types";
import { LoadingStatus, status } from "../../app/shared";
import { loadBuildingsByLocationId } from "../buildingStore/buildingsRequests";
import {
  createLocation,
  deleteLocation,
  requestLocationByQuery,
  updateLocation,
} from "./locationRequests";
import { deleteLocationMap, uploadLocationMapFile } from "../mapStore/mapRequests";

export const locationAdapter = createEntityAdapter<LocationModel>({
  selectId: (location) => location.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

type LocationState = {
  selectedLocationId: number | null;
  loadLocationsStatus: LoadingStatus;
  updateLocationStatus: LoadingStatus;
} & EntityState<LocationModel>;

const initialState: LocationState = {
  selectedLocationId: null,
  loadLocationsStatus: status.default,
  updateLocationStatus: status.default,
  ...locationAdapter.getInitialState(),
}

export const locationSlice = createSlice({
  name: 'locationsList',
  initialState,
  reducers: {
    setCurrentLocationId: (state, action) => {
      state.selectedLocationId = action.payload;
    },
    setCurrentLocationIdByMapId: (state, action) => {
      const mapId = action.payload as number;
      let currentLocation: LocationModel;
      const selectors = locationAdapter.getSelectors();
      const locationList = selectors.selectAll(state);

      (locationList || []).forEach((location) => {
        if (location?.mapId === mapId) {
          currentLocation = location;
        }
      });

      // @ts-ignore
      if (currentLocation) {
        state.selectedLocationId = currentLocation.id;
      }
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(requestLocationByQuery.pending, (state) => {
        state.loadLocationsStatus = status.loading;
      })
      .addCase(requestLocationByQuery.fulfilled, (state, action) => {
        state.loadLocationsStatus = status.loaded;
        locationAdapter.upsertMany(state, action.payload);
      })
      .addCase(requestLocationByQuery.rejected, (state, action) => {
        state.loadLocationsStatus = status.error(action.error);
      })
      .addCase(loadBuildingsByLocationId.fulfilled, (state, action) => {
        const building = action.payload[0];
        if (building && building.location) {
          locationAdapter.addOne(state, building.location)
        }
      })
      .addCase(createLocation.fulfilled, (state, action) => {
        locationAdapter.addOne(state, action.payload);
      })
      .addCase(updateLocation.fulfilled, (state, action) => {
        locationAdapter.upsertOne(state, action.payload);
      })
      .addCase(deleteLocation.fulfilled, (state, action) => {
        locationAdapter.removeOne(state, action.payload);
      })
      .addMatcher(isAnyOf(uploadLocationMapFile.fulfilled, deleteLocationMap.fulfilled), (state, action) => {
        locationAdapter.upsertOne(state, action.payload);
      })
      .addMatcher(isAnyOf(createLocation.pending, updateLocation.pending, deleteLocation.pending), (state) => {
        state.updateLocationStatus = status.loading;
      })
      .addMatcher(isAnyOf(createLocation.rejected, updateLocation.rejected, deleteLocation.rejected), (state, action) => {
        state.updateLocationStatus = status.error(action.error);
      }),
});

export const { setCurrentLocationId, setCurrentLocationIdByMapId } = locationSlice.actions;