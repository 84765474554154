import { createAsyncThunk } from "@reduxjs/toolkit";
import FloorModel from "../../models/FloorModel";
import { request } from "../../app/api";
import LocationModel from "../../models/LocationModel";
import { serializeError } from "../../app/shared";

const SLICE_NAME = "mapList";

export const uploadFloorMapFile = createAsyncThunk(
  `${SLICE_NAME}/upload-floor`,
  async ({ floorId, file }: { floorId: number, file: File }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("map-file", file);

      const floor: FloorModel = await request.post(
        `map/${floorId}/floor`,
        formData
      );
      return floor;
    }
    catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const deleteFloorMapFile = createAsyncThunk(
  `${SLICE_NAME}/delete-floor`,
  async ({ floorId }: { floorId: number }, { rejectWithValue }) => {
    try {
      const floor: FloorModel = await request.delete(`map/${floorId}/floor`);
      return floor;
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const uploadLocationMapFile = createAsyncThunk(
  `${SLICE_NAME}/upload-location`,
  async (
    { locationId, file }: { locationId: number; file: File },
    { rejectWithValue }
  ) => {
    const formData = new FormData();
    formData.append("map-file", file);

    try {
      const location: LocationModel = await request.post(
        `map/${locationId}/location`,
        formData
      );
      return location;
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const deleteLocationMap = createAsyncThunk(
  `${SLICE_NAME}/delete-location`,
  async (
    { locationId }: { locationId: number },
    { rejectWithValue }
  ) => {
    try {
      const location: LocationModel = await request.delete(`map/${locationId}/location`);
      return location;
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);