import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import NoMapContainer from "../NoMapContainer";
import MapModel from "../../../models/MapModel";
import { useDimensions } from "../../../hooks/dimensions.hook";
import { getMapUrl } from "../../../helpers/mapHelpers";
import ZoomMap from "../../../components/Map/ZoomMap";
import DeviceModel from "../../../models/DeviceModel";
import { selectCurrentDeviceId } from "../../../store/deviceStore/deviceSelectors";
import InfluenceArea from "../InfluenceArea";
import DeviceRenderer from "../DeviceRenderer";
import { selectEditMode } from "../../../store/generalStore/generalSelectors";
import { upsertDevicePoint } from "../../../store/deviceStore/deviceRequests";
import { useTypedDispatch } from "../../../app/state";
import { setEditMode } from "../../../store/generalStore/generalState";
import { selectInfluenceAreaEnabled } from "../../../store/filterStore/filterSelectors";
import { pagePathGenerator } from "../../../routes/pagePathGenerator";
import FilterChipContainer from "../../Shared/FilterChipContainer";

import classes from './index.module.scss';

interface DeviceMapProps {
  map?: MapModel,
  deviceList?: DeviceModel[],
}

const DeviceMapContainer = ({ map, deviceList = [] }: DeviceMapProps) => {
  const dispatch = useTypedDispatch();
  const history = useHistory();
  const container = useDimensions();
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const isMapPointEdit = useSelector(selectEditMode);
  const influenceAreaEnabled = useSelector(selectInfluenceAreaEnabled);
  const mapFileName = map?.url
  const mapUrl = mapFileName ? getMapUrl(mapFileName) : '';
  const isMapUploaded = !!mapUrl;

  const onClick = (x, y) => {
    if (isMapPointEdit && currentDeviceId) {
      dispatch(upsertDevicePoint({ point: { x, y }, deviceId: currentDeviceId }));
      dispatch(setEditMode(false));
    }
  }

  const onDeviceClick = (id: number) => {
    dispatch(setEditMode(false));
    const url = pagePathGenerator.DEVICES_MAP({
      mapId: map?.id,
      deviceId: id,
    });

    history.replace(url);
  }

  return (
    <Grid
      container
      item
      xs
      className={classes.gridContainer}
      direction='column'
      innerRef={container.elementRef}
    >
      <FilterChipContainer />
      {
        !isMapUploaded && (
          <NoMapContainer title='No map has been uploaded' />
        )
      }
      {
        isMapUploaded && (
          <InfluenceArea
            influenceAreaEnabled={influenceAreaEnabled}
            deviceList={deviceList}
            currentDeviceId={currentDeviceId}
          >
            <DeviceRenderer
              deviceList={deviceList}
              currentDeviceId={currentDeviceId}
              onClick={onDeviceClick}
            >
              <ZoomMap
                dimensions={container.dimensions}
                mapUrl={mapUrl}
                cursor={isMapPointEdit ? 'crosshair' : 'default'}
                onClick={onClick}
              />
            </DeviceRenderer>
          </InfluenceArea>
        )
      }
    </Grid>
  )
}

export default DeviceMapContainer;