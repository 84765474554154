import { LoadingStatus, status } from "../../app/shared";
import { createSlice } from "@reduxjs/toolkit";
import { editGlobalSettings, getGlobalSettings } from "./settingsRequests";
import { SettingsModel } from "./SettingsModel";

export const SETTINGS_SLICE_NAME = 'globalSettings';

type SettingsState = {
  getStatus: LoadingStatus,
  editStatus: LoadingStatus,
  settings: SettingsModel,
};

const initialState: SettingsState = {
  getStatus: status.default,
  editStatus: status.default,
  settings: {
    powerLossCoefficient: 0,
    distance: 0,
    interferenceLevel: 0,
    trial: 0,
  }
};

export const settingsSlice = createSlice({
  name: SETTINGS_SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGlobalSettings.pending, (state) => {
        state.getStatus = status.loading;
      })
      .addCase(getGlobalSettings.rejected, (state, action) => {
        state.getStatus = status.error(action.error as Error);
      })
      .addCase(getGlobalSettings.fulfilled, (state, action) => {
        state.getStatus = status.loaded;
        state.settings = { ...state.settings, ...(action?.payload || {}) };
      });

    builder
      .addCase(editGlobalSettings.pending, (state) => {
        state.editStatus = status.loading;
      })
      .addCase(editGlobalSettings.rejected, (state, action) => {
        state.editStatus = status.error(action.error as Error);
      })
      .addCase(editGlobalSettings.fulfilled, (state, action) => {
        state.editStatus = status.loaded;
        state.settings = { ...state.settings, ...(action?.payload || {}) };
      });
  },
});

export const { resetState } = settingsSlice.actions;