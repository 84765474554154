import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getWorkflowUserList } from "../../../store/userListStore/userListSelectors";
import { Dropdown } from "../../../app/components/Dropdown";
import { useTypedDispatch } from "../../../app/state";
import { assignDevice } from "../../../store/deviceStore/deviceRequests";

interface AssigneeDropdownProps {
  managerId?: number;
  deviceId?: number;
  disabled: boolean,
  onUpdate: () => any,
}

const AssigneeDropdown = ({ managerId, deviceId, disabled, onUpdate }: AssigneeDropdownProps) => {
  const managerList = useSelector(getWorkflowUserList) || [];
  const dispatch = useTypedDispatch();

  const onChange = useCallback((e) => {
    const userId = e.target.value || 0;
    if (deviceId && userId !== managerId) {
      dispatch(assignDevice({ id: deviceId, userId }))
        .then(() => {
          onUpdate();
        });
    }
  }, [deviceId, managerId]);

  const values = [{
    id: 0,
    name: 'Unassigned',
  }].concat(managerList.map((user) => {
    return {
      id: user.id,
      name: user?.profile?.fullName || '',
    }
  }));

  return (
    <Dropdown
      selectedValue={managerId || 0}
      values={values}
      disabled={disabled}
      label='Frequency manager'
      valueChange={onChange}
      fullWidth
    />
  )
}

export default AssigneeDropdown;