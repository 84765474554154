import { useState } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { getTrialVersionTime } from "../../../store/settingsStore/settingsSelectors";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { getDuration } from "../../../helpers/timeHelpers";

import classes from './index.module.scss';
import { Redirect } from "react-router-dom";
import PAGES from "../../../pages";

const TrialTime = () => {
  const trial = useSelector(getTrialVersionTime) || 0;
  const [now, setNow] = useState(+new Date());
  const isTrial = trial !== 0;
  const timeLeft = trial - now;
  const timeLeftDuration = getDuration(timeLeft < 0 ? 0 : timeLeft);
  const padString = (value: number) => value < 10 ? `0${value}` : `${value}`;
  const stringTimeLeft = timeLeftDuration.seconds % 2 === 1 ? (
    <>
      {
        timeLeftDuration.weeks > 0 && (
          <>
            {timeLeftDuration.weeks} weeks
            <span className={classes.span}>&nbsp;</span>
          </>
        )
      }
      {
        timeLeftDuration.days > 0 && (
          <>
            {timeLeftDuration.days} days
            <span className={classes.span}>&nbsp;</span>
          </>
        )
      }
      {padString(timeLeftDuration.hours)}
      <span className={classes.span}>:</span>
      {padString(timeLeftDuration.minutes)}
    </>
  ) : (
    <>
      {
        timeLeftDuration.weeks > 0 && (
          <>
            {timeLeftDuration.weeks} weeks
            <span className={classes.span}>&nbsp;</span>
          </>
        )
      }
      {
        timeLeftDuration.days > 0 && (
          <>
            {timeLeftDuration.days} days
            <span className={classes.span}>&nbsp;</span>
          </>
        )
      }
      {padString(timeLeftDuration.hours)}
      <span className={classes.span}>&nbsp;</span>
      {padString(timeLeftDuration.minutes)}
    </>
  );

  setInterval(() => {
    setNow(+new Date());
  }, 333);

  if (!isTrial) {
    return null;
  }

  if (timeLeft < 0) {
    return <Redirect  to={PAGES.TRIAL_EXPIRED} />
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={'Trial time left'} />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <Typography variant='h3' align='center'>
          {stringTimeLeft}
        </Typography>
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default TrialTime;