import FloorModel from "../models/FloorModel";
import MapModel from "../models/MapModel";
import MapRelativePointModel from "../models/MapRelativePointModel";
import MapPointModel from "../models/MapPointModel";
import { getDistanceFromLatLonInM } from "./converters/distance";
import LocationModel from "../models/LocationModel";

const { REACT_APP_API_URL, REACT_APP_API_PORT } = process.env;
const API_URL = REACT_APP_API_PORT ? `${REACT_APP_API_URL}:${REACT_APP_API_PORT}` : `${REACT_APP_API_URL}`;

export function getMapPointsFromMap(map?: MapModel | null) {
  return [map?.firstPoint, map?.secondPoint, map?.thirdPoint];
}

export function getMapPointsFromLocation(location?: LocationModel | null) {
  const map = location?.map;

  return getMapRelativePointsFromMap(map);
}

export function getMapPointsFromFloor(floor?: FloorModel | null) {
  const floorMap = floor?.map;

  return getMapRelativePointsFromMap(floorMap);
}

export function getMapRelativePointsFromMap(map?: MapModel | null) {
  const points = getMapPointsFromMap(map);
  return points.map((mapPoint) => {
    return mapPoint?.mapPoint;
  })
}

export function getGeoPointsFromLocation(location?: LocationModel | null) {
  const map = location?.map;

  return getGeoPointsFromMap(map);
}

export function getGeoPointsFromFloor(floor?: FloorModel | null) {
  const floorMap = floor?.map;

  return getGeoPointsFromMap(floorMap);
}

export function getGeoPointsFromMap(map?: MapModel | null) {
  return [map?.firstPoint?.geoPoint, map?.secondPoint?.geoPoint, map?.thirdPoint?.geoPoint];
}

export function isMapPointsSet(map?: MapModel | null) {
  const mapPoints = getMapRelativePointsFromMap(map).filter(Boolean);
  const geoPoints = getGeoPointsFromMap(map).filter(Boolean);

  return mapPoints.length === 3 && geoPoints.length === 3;
}

export function getMapUrl(filename: string) {
  return `${API_URL}/api/map/${filename}`;
}

export function getTheMostDistantPoints(mapPoints: MapPointModel[] = []) {
  let currentDistance = 0;
  let firstPoint;
  let secondPoint;
  mapPoints.forEach((mapPoint1) => {
    mapPoints.forEach((mapPoint2) => {
      const newDistance = getDistanceBetweenTwoPoints(mapPoint1.mapPoint, mapPoint2.mapPoint)
      if (currentDistance < newDistance) {
        currentDistance = newDistance;
        firstPoint = mapPoint1;
        secondPoint = mapPoint2;
      }
    })
  });

  return {
    firstPoint,
    secondPoint,
  };
}

export function getDistanceBetweenTwoPoints(point1?: MapRelativePointModel | null, point2?: MapRelativePointModel | null) {
  if (!point1 || !point2) {
    return 0;
  }
  const a = point1.x - point2.x;
  const b = point1.y - point2.y;

  return Math.sqrt(a * a + b * b);
}

export function getMapScale(map?: MapModel | null) {
  if (!map) {
    return 0;
  }

  const pointsSet = isMapPointsSet(map);

  if (!pointsSet) {
    return 0;
  }

  const mapPointsWithUndefined = getMapPointsFromMap(map);
  const mapPoints: MapPointModel[] = [];

  mapPointsWithUndefined.forEach((mapPoint) => mapPoint && mapPoints.push(mapPoint));

  const pairOfPoints = getTheMostDistantPoints(mapPoints);

  if (!pairOfPoints.firstPoint || !pairOfPoints.secondPoint) {
    return 0;
  }

  const distanceInPx = getDistanceBetweenTwoPoints(pairOfPoints.firstPoint.mapPoint, pairOfPoints.secondPoint.mapPoint);
  const distanceInMeters = getDistanceFromLatLonInM(
    pairOfPoints.firstPoint.geoPoint.latitude,
    pairOfPoints.firstPoint.geoPoint.longitude,
    pairOfPoints.secondPoint.geoPoint.latitude,
    pairOfPoints.secondPoint.geoPoint.longitude,
  );

  return distanceInPx / distanceInMeters;
}