import {
  AppBar,
  ButtonBase,
  IconButton,
  Popover,
  Card,
  CardContent,
  CardActions,
  Button,
  Tab,
  Toolbar,
  Typography,
  makeStyles,
  Divider,
} from "@material-ui/core";
import { AccountCircle, Settings } from "@material-ui/icons";
import { logout } from "../../../../store/userStore/userState";
import { UserLogo } from "app/components/index";
import { theme } from "app/theme";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { StyledTabs } from "../styled-tabs/StyledTabs";
import {
  selectTopUserRole,
  selectCurrentUser,
} from "../../../../store/userStore/userSelectors";
import { CheckPermission } from "../../../../requests/CheckPermission";
import { PERMISSIONS } from "../../../../store/userStore/permissions.enum";
import styles from "./DashboardBar.module.scss";
import { logoutRequest } from "../../../../store/userStore/userRequests";

//TODO move router navigation bar to separate file
interface RouteModel {
  label: string;
  href: string;
  id: string;
}

interface Props {
  openSettings: () => any;
  logoClicked: () => any;
  activeRoute?: string;
  routes: RouteModel[];
}

const a11yProps = (index: any) => ({
  id: `nav-tab-${index}`,
  "aria-controls": `nav-tabpanel-${index}`,
});

const useStyles = makeStyles({
  root: {
    minWidth: "204px",
    width: "204px",
    justifyContent: "center",
  },
  toolbar: {
    height: "64px",
    paddingRight: theme.spacing(2),
    background: theme.palette.primary.main,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 12,
  },
  logoText: {
    marginLeft: "10px",
    fontSize: "20px",
    fontWeight: 700,
  },
  tab: {
    minWidth: 0.73,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "15px",
    lineHeight: "26px",
  },
  cardAction: {
    margin: "0 8px",
    flexDirection: "column",
    justifyContent: "center",
  },
});

export const DashboardBar = ({
  openSettings,
  routes,
  activeRoute,
  logoClicked,
}: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const isMenuOpen = Boolean(anchorEl);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const role = useSelector(selectTopUserRole);

  const menuId = "primary-search-account-menu";
  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onLogOut = async () => {
    await dispatch(logoutRequest());
    dispatch(logout());
  };

  const menu = (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Card className={classes.root}>
        <CardContent>
          {user && (
            <Typography noWrap component="h2" color="textPrimary">
              {user.userName}
            </Typography>
          )}
          {user && user.profile && (
            <Typography
              noWrap
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {user.profile.fullName}
            </Typography>
          )}
          {user && (
            <Typography
              noWrap
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {user.email}
            </Typography>
          )}
        </CardContent>
        <Divider />
        <CardActions className={classes.cardAction}>
          {/* <div className={styles.btnProfile}>
            <Button
              size="large"
              onClick={(e) => {
                alert("Open profile");
              }}
            >
              <SvgIcon className={styles.personIcon}>
                <PersonRoundedIcon />
              </SvgIcon>
              Profile
            </Button>
          </div> */}

          <Button
            fullWidth
            variant="contained"
            size="small"
            color="primary"
            disableElevation
            onClick={onLogOut}
          >
            Log out
          </Button>
        </CardActions>
      </Card>
    </Popover>
  );

  return (
    <AppBar position="relative">
      <Toolbar className={classes.toolbar}>
        <ButtonBase disableRipple={true} onClick={logoClicked}>
          <UserLogo userRoleType={role.type} />
          <Typography
            className={classes.logoText}
            variant="h6"
            color="inherit"
            noWrap
          >
            {role.name || t(role.type)}
          </Typography>
        </ButtonBase>
        <div className={styles.navigation}>
          <StyledTabs
            value={activeRoute || false}
            aria-label="application navigation"
          >
            {routes.map((route, i) => (
              <Tab
                className={classes.tab}
                component={Link}
                to={route.href}
                key={route.id}
                label={route.label}
                value={route.href}
                {...a11yProps(i)}
              />
            ))}
          </StyledTabs>
        </div>

        <CheckPermission permissionName={PERMISSIONS.SETTINGS_MENU_ACCESS}>
          <IconButton
            aria-label="upload picture"
            color="inherit"
            component="span"
            onClick={openSettings}
          >
            <Settings />
          </IconButton>
        </CheckPermission>
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        {menu}
      </Toolbar>
    </AppBar>
  );
};
