import { useSelector } from "react-redux";
import { Button, Grid, Typography } from "@material-ui/core";
import { useTypedDispatch } from "../../../../app/state";
import HorizontalPanel from "../../../../components/HorizontalPanel";
import { selectImportStatus, selectImportCode, selectImportLog } from "../../../../store/import/importSelectors";
import {
  getImportState,
  sendImportAccept,
  sendImportCancel,
  sendImportDo,
  sendImportRevert,
} from "../../../../store/import/importRequests";
import { clearFileName } from "../../../../store/import/importState";

import styles from './index.module.scss';


const ImportLog = ({ logLevel }) => {
  const dispatch = useTypedDispatch();
  const status = useSelector(selectImportStatus) || '';
  const log = useSelector(selectImportLog) || '';
  const error = useSelector(selectImportCode) || '';

  const filteredLog = log
    .split('\n')
    .filter((line) => {
      if (logLevel === 0) {
        return true;
      }

      if (logLevel === 1) {
        return ~line.indexOf('ERROR:');
      }

      if (logLevel === 2) {
        return ~line.indexOf('WARNING:');
      }

      return !(~line.indexOf('ERROR:') || ~line.indexOf('WARNING:'));
    }).join('\n');

  const onSecondButtonClick = () => {
    if (['uploaded', 'validated', 'nothing', 'error'].includes(status)) {
      dispatch(sendImportCancel()).then(() => {
        dispatch(clearFileName());
        dispatch(getImportState());
      });
      return;
    }

    if (status === 'imported') {
      dispatch(sendImportRevert()).then(() => {
        dispatch(getImportState());
      });
    }
  };

  const onFirstButtonClick = () => {
    if (['validated'].includes(status)) {
      dispatch(sendImportDo()).then(() => {
        dispatch(getImportState());
      });

      return;
    }
    dispatch(sendImportAccept()).then(() => {
      dispatch(clearFileName());
      dispatch(getImportState());
    });
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <textarea
          wrap='off'
          value={filteredLog}
          className={styles.textArea}
          onChange={() => {}}
        />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Grid container className={styles.suppressNegativeMargin}>
          <Grid item xs={8} container alignItems='center' justifyContent='center'>
            <Typography variant='subtitle2' className={!!error ? styles.errorText : styles.text}>
              {
                error || status
              }
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={2} direction='row'>
              {
                status === 'reverted' && (
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      onClick={onFirstButtonClick}
                    >
                      Ok
                    </Button>
                  </Grid>
                )
              }
              {
                status !== 'reverted' && (
                  <>
                    <Grid item xs={6}>
                      <Button
                        disabled={!(status === 'validated' || status === 'imported')}
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={onFirstButtonClick}
                      >
                        {
                          status === 'validated' ?
                            'Import' :
                            'Accept Import'
                        }
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        disabled={!(['uploaded', 'validated', 'nothing', 'error', 'imported'].includes(status))}
                        fullWidth
                        variant='contained'
                        color='secondary'
                        onClick={onSecondButtonClick}
                      >
                        {
                          ['uploaded', 'validated', 'nothing', 'error'].includes(status) ?
                            'Cancel' :
                            'Revert Import'
                        }
                      </Button>
                    </Grid>
                  </>
                )
              }
            </Grid>
          </Grid>
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  );
}

export default ImportLog;