import React, { MutableRefObject } from "react";
import { IconButton } from "@material-ui/core";
import PublishIcon from '@material-ui/icons/Publish';
import { first } from "lodash/fp";
import ChildrenElement from "../../../../models/HelperModels/ChildrenElement";

type Props = {
  disabled?: boolean;
  handleFile: (file: File) => void;
  handleClick?: () => boolean | void;
  accept: string;
  label?: string;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  icon?: ChildrenElement;
  multiple?: boolean;
};

export const FileUploader = ({
  handleFile,
  handleClick = () => false,
  disabled = false,
  multiple = false,
  inputRef,
  label = 'upload file',
  icon,
  accept,
}: Props) => {
  const hiddenInputRef = React.useRef<HTMLInputElement>(null);
  const buttonIcon = icon || <PublishIcon />

  const handleClickInner = () => {
    if (!handleClick()) {
      (inputRef || hiddenInputRef)?.current?.click();
    }
  };

  const handleChange = (event: { target: HTMLInputElement }) => {
    const fileUploaded: File | undefined = first(event?.target?.files);
    if (!fileUploaded) {
      return;
    }
    handleFile(fileUploaded);
    event.target.value = "";
  };

  return (
    <>
      <IconButton
        disabled={disabled}
        aria-label={label}
        color="secondary"
        size="small"
        onClick={handleClickInner}
      >
        {buttonIcon}
      </IconButton>
      <input
        type="file"
        accept={accept}
        multiple={multiple}
        ref={inputRef || hiddenInputRef}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
};
