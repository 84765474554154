import React, { useState } from 'react';
import { useSelector } from "react-redux";
import {
  DialogContent,
  IconButton
} from '@material-ui/core';
import { AddCircleOutline } from "@material-ui/icons";
import MenuList, { MenuListItem } from "../../../../components/MenuList";
import ModalWithToggle from "../../../../components/ModalWithToggle";
import { selectCurrentDevice } from "../../../../store/deviceStore/deviceSelectors";
import { selectCurrentConfiguration } from "../../../../store/deviceConfigurationStore/deviceConfigurationSelectors";
import {
  selectTechnologyConfigurationListByCountryId
} from "../../../../store/technologyConfigurationStore/technologyConfigurationSelectors";
import { selectCurrentCountryId } from "../../../../store/countryStore/countrySelectors";

import classes from './index.module.scss';

export const AddWirelessInterfaceDialog = (props) => {
  const { onAdd, disabled } = props;
  const [value, setValue] = useState(0);

  const currentDevice = useSelector(selectCurrentDevice);
  const currentCountryId = useSelector(selectCurrentCountryId);
  const selectedConfiguration = useSelector(selectCurrentConfiguration);
  const currentConfiguration = selectedConfiguration || currentDevice?.configuration;
  const wirelessInterfaceList = currentConfiguration?.wirelessInterfaces || [];

  const currentDeviceTechnologiesId = wirelessInterfaceList.map((wirelessInterface) => wirelessInterface.technologyConfigurationId);
  const allTechnologyConfigurations = useSelector(selectTechnologyConfigurationListByCountryId(currentCountryId));
  const availableTechnologies = allTechnologyConfigurations.map((technologyConfiguration) =>
    !currentDeviceTechnologiesId.includes(technologyConfiguration.id) && technologyConfiguration).filter(Boolean);

  const isBtnDisabled = availableTechnologies.length === 0;

  const handleOk = () => {
    if (value) {
      onAdd(value);
    }

    setValue(0);
  };

  return (
    <ModalWithToggle
      title="Wireless Interfaces"
      confirmButtonText="Add"
      confirmButtonHandler={handleOk}
      modalContent={(
        <DialogContent className={classes.container}>
          <MenuList
            selectItem={(value) => setValue(value)}
            items={availableTechnologies as MenuListItem[]}
            size='small'
            selectedItemId={value}
          />
        </DialogContent>
      )}
    >
      <IconButton
        component="span"
        color="secondary"
        size="small"
        disabled={isBtnDisabled || disabled}
      >
        <AddCircleOutline />
      </IconButton>
    </ModalWithToggle>
  );
};
