import { createSlice } from "@reduxjs/toolkit";


export type ManualFilterType = {
  wirelessSystemIdList: number[],
  frequencyFrom: string | null,
  frequencyTo: string | null,
  technologyConfigurationIdList: number[],
}

type FilterState = {
  influenceAreaEnabled: boolean;
  filterPanelIsShown: boolean;
  manualFilters: ManualFilterType | null,
  smartFilterDeviceId: number | null;
  filtersAppliedForMapId: number | null;
}

const initialState: FilterState = {
  influenceAreaEnabled: true,
  filterPanelIsShown: false,
  smartFilterDeviceId: null,
  manualFilters: null,
  filtersAppliedForMapId: null,
}

export const filterSlice = createSlice({
  name: 'filterState',
  initialState,
  reducers: {
    setInfluenceAreaEnabled: (state, action) => {
      state.influenceAreaEnabled = !!action.payload;
    },
    setSmartFilterDeviceId: (state, action) => {
      state.smartFilterDeviceId = action.payload;
    },
    setFiltersMapId: (state, action) => {
      state.filtersAppliedForMapId = action.payload;
    },
    setFilterPanelVisibility: (state, action) => {
      state.filterPanelIsShown = !!action.payload;
    },
    setManualFilters: (state, action) => {
      state.manualFilters = action.payload;
    }
  }
});

export const {
  setInfluenceAreaEnabled,
  setSmartFilterDeviceId,
  setFiltersMapId,
  setFilterPanelVisibility,
  setManualFilters,
} = filterSlice.actions;