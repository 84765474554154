import { RootState } from "../../app/state";
import { technologyConfigurationAdapter } from "./technologyConfigurationState";
import { createSelector } from "@reduxjs/toolkit";

const selectRoot = (state: RootState) => state;
const selectTechnologyConfigurationSlice = (state: RootState) => state.technologyConfigurationList;

const technologyConfigurationAdapterSelectors = technologyConfigurationAdapter.getSelectors(selectTechnologyConfigurationSlice);

export const selectAllTechnologyConfigurations = technologyConfigurationAdapterSelectors.selectAll;
export const selectTechnologyConfigurationById = (id) => createSelector(
  selectRoot,
  (root) => technologyConfigurationAdapterSelectors.selectById(root, id)
);

export const selectCurrentTechnologyConfigurationId = createSelector(
  selectTechnologyConfigurationSlice,
  (state) => state.selectedTechnologyConfigurationId
);

export const selectCurrentTechnologyConfiguration = createSelector(
  selectRoot,
  selectCurrentTechnologyConfigurationId,
  (root, technologyConfigurationId) => (technologyConfigurationId && technologyConfigurationAdapterSelectors.selectById(root, technologyConfigurationId)) || null
);

export const selectTechnologyConfigurationAllLoadingState = createSelector(
  selectTechnologyConfigurationSlice,
  (state) => state.loadAllStatus
);

export const selectTechnologyConfigurationListByCountryId = (countryId?: number | null) => createSelector(
  technologyConfigurationAdapterSelectors.selectAll,
  (technologyList) => countryId ?
    technologyList.filter((technologyConfiguration) => technologyConfiguration
      .countries
      .find((country) => country.id === countryId)) :
    []
);

export const selectCurrentChannelId = createSelector(
  selectTechnologyConfigurationSlice,
  (state) => state.selectedChannelId
);

export const selectCurrentChannel = createSelector(
  selectCurrentTechnologyConfiguration,
  selectCurrentChannelId,
  (technologyConfiguration, channelId) => (channelId && (technologyConfiguration?.channels || []).find((channel) => channel.id === channelId)) || null
)