import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../app/state';
import React, { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash/fp';
import { useFormik } from 'formik';
import { Grid, IconButton, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Delete from '@material-ui/icons/Delete';
import Cancel from '@material-ui/icons/Cancel';
import { Toolbar } from '../../../../app/components';
import Multiselect from '../../../../components/Multiselect';
import {
  deleteWorkflow,
  editOrCreateWorkflow,
} from '../../../../store/workflows/workflowRequests';
import { selectCurrentWorkflow, selectCurrentWorkflowId, } from '../../../../store/workflows/workflowSelectors';
import { getFrequencyManagersList } from "../../../../store/userListStore/userListSelectors";
import { ModalType } from '../../../../app/shared/modal-context/constants';
import { useShowModal } from '../../../../app/shared/modal-context/modal.context';
import HorizontalPanel from "../../../../components/HorizontalPanel";
import getWorkflowValidation from "./validation";
import { WORKFLOW_TYPE } from "../WORKFLOW_TYPE";
import { Dropdown } from "../../../../app/components/Dropdown";
import CountryTypeForm from "./CountryTypeForm";
import LocationTypeForm from "./LocationTypeForm";
import BuildingTypeForm from "./BuildingTypeForm";
import { workflowTypeMap } from "../workflowTypeMap";
import { extractWorkflowData } from "../workflowHelper";
import { selectAllCountriesSorted } from "../../../../store/countryStore/countrySelectors";
import { selectAllLocations } from "../../../../store/locationStore/locationSelectors";
import UnsavedWorkflowModel from "../../../../models/UnsavedWorkflowModel";
import WorkflowModel from "../../../../models/WorkflowModel";
import { setCurrentWorkflowId } from "../../../../store/workflows/workflowState";
import {
  selectAllTechnologyConfigurations
} from "../../../../store/technologyConfigurationStore/technologyConfigurationSelectors";

import classes from './index.module.scss';

const EditWorkflow = () => {
  const dispatch = useDispatch<AppDispatch>();
  const currentWorkflowId = useSelector(selectCurrentWorkflowId);
  const currentWorkflow = useSelector(selectCurrentWorkflow);
  const allCountryList = useSelector(selectAllCountriesSorted) || [];
  const allLocationList = useSelector(selectAllLocations) || [];
  const managers = useSelector(getFrequencyManagersList) || [];
  const technologyConfigurationList = useSelector(selectAllTechnologyConfigurations);
  const { showModal } = useShowModal();
  const isNewWorkflow = currentWorkflowId === -1;


  const selectedManagersList = currentWorkflow?.frequencyManagers?.map((manager) => manager.id) || [];

  const typeList = useMemo(() => [
    {
      id: WORKFLOW_TYPE.COUNTRY_LEVEL,
      name: workflowTypeMap[WORKFLOW_TYPE.COUNTRY_LEVEL],
    },
    {
      id: WORKFLOW_TYPE.LOCATION_LEVEL,
      name: workflowTypeMap[WORKFLOW_TYPE.LOCATION_LEVEL],
    },
    {
      id: WORKFLOW_TYPE.BUILDING_LEVEL,
      name: workflowTypeMap[WORKFLOW_TYPE.BUILDING_LEVEL],
    },
  ], []);

  const workflowData = extractWorkflowData(currentWorkflow, allCountryList, allLocationList);

  const initialValues: UnsavedWorkflowModel = {
    ...workflowData,
    technologies: (currentWorkflow?.technologies || []).map((tech) => tech.id),
    frequencyManagers: selectedManagersList,
    type: currentWorkflow?.type === undefined ? WORKFLOW_TYPE.BUILDING_LEVEL : currentWorkflow?.type,
  }

  const workflowFormik = useFormik({
    initialValues,
    onSubmit: () => {},
    validationSchema: getWorkflowValidation(),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const filteredTechnologyConfigurations = technologyConfigurationList.filter((technologyConfiguration) => {
    return workflowFormik.values.countries.some((countryId) => technologyConfiguration.countries.find((country) => country.id === countryId))
  });

  const resetForm = useCallback(() => {
    workflowFormik.resetForm();
  }, [workflowFormik]);

  const onSaveChanges = async () => {
    const valid = await workflowFormik.validateForm();
    if (isEmpty(valid)) {

      const payload = {
        id: currentWorkflow?.id || 0,
        name: workflowFormik.values.name,
        type: workflowFormik.values.type,
        buildings: workflowFormik.values.buildings,
        locations: workflowFormik.values.locations,
        countries: workflowFormik.values.countries,
        technologies: workflowFormik.values.technologies,
        frequencyManagers: workflowFormik.values.frequencyManagers,
      };

      dispatch(editOrCreateWorkflow(payload))
        .then((action) => {
          const workflow = action.payload as WorkflowModel;
          dispatch(setCurrentWorkflowId(workflow.id));
        })
    } else {
      setTimeout(() => workflowFormik.setErrors({}), 10000);
    }
  };

  const onDeleteWorkflow = useCallback(() => {
    const deleteWorkflowHandler = () => {
      if (currentWorkflow) {
        dispatch(deleteWorkflow(currentWorkflow.id))
          .then(() => {
            dispatch(setCurrentWorkflowId(null));
          })
      }
    };

    if (currentWorkflow) {
      showModal(ModalType.MODAL_GENERIC_CONFIRM, {
        title: 'Delete selected workflow',
        text: 'Do you want to delete this workflow?',
        confirmButtonHandler: deleteWorkflowHandler,
        confirmButtonText: 'Delete',
      });
    }
  }, [currentWorkflow]);

  const managersItemList = managers.map((manager) => {
    return {
      id: manager.id,
      value: manager?.profile?.fullName || '',
    };
  });

  const onCountryChange = () => {
    workflowFormik.setFieldValue('technologies', []);
  }

  return !!currentWorkflowId ? (
    <>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={isNewWorkflow ? 'Create new workflow' : 'Workflow details'}>
          <IconButton
            disabled={!workflowFormik.dirty}
            aria-label='save changes'
            color='secondary'
            size='small'
            onClick={onSaveChanges}
          >
            <SaveIcon />
          </IconButton>
          {
            !isNewWorkflow && (
              <IconButton
                aria-label='delete'
                color='secondary'
                size='small'
                onClick={onDeleteWorkflow}
              >
                <Delete />
              </IconButton>
            )
          }
          <IconButton
            disabled={!workflowFormik.dirty}
            aria-label='clear'
            color='secondary'
            size='small'
            onClick={resetForm}
          >
            <Cancel />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel
        size={HorizontalPanel.SIZES.REST_SPACE}
        addScroll
        className={classes.panel}
      >
        <Grid
          container
          className={classes.container}
          spacing={2}
        >
          <Grid item xs={6}>
            <TextField
              label='Workflow Name'
              variant='outlined'
              name='name'
              required
              fullWidth
              value={workflowFormik.values.name}
              onChange={workflowFormik.handleChange}
              helperText={workflowFormik.errors.name}
              error={Boolean(workflowFormik.errors.name)}
            />
          </Grid>
          <Grid item xs={6}>&nbsp;</Grid>
          <Grid item xs={6}>
            <Dropdown
              selectedValue={workflowFormik.values.type}
              values={typeList}
              label='Workflow level'
              name='type'
              required
              valueChange={workflowFormik.handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>&nbsp;</Grid>
          {
            workflowFormik.values.type === WORKFLOW_TYPE.COUNTRY_LEVEL && (
              <CountryTypeForm formik={workflowFormik} onCountryChange={onCountryChange} />
            )
          }
          {
            workflowFormik.values.type === WORKFLOW_TYPE.LOCATION_LEVEL && (
              <LocationTypeForm formik={workflowFormik} onCountryChange={onCountryChange} />
            )
          }
          {
            workflowFormik.values.type === WORKFLOW_TYPE.BUILDING_LEVEL && (
              <BuildingTypeForm formik={workflowFormik} onCountryChange={onCountryChange} />
            )
          }
          <Grid item xs={6}>
            <Multiselect
              disabled={filteredTechnologyConfigurations.length === 0}
              title='Technology configurations'
              name='technologies'
              fullWidth
              items={Multiselect.getValues(filteredTechnologyConfigurations)}
              selectedItems={Multiselect.getSelectedValues(filteredTechnologyConfigurations, workflowFormik.values.technologies)}
              onChange={(values: number[]) => workflowFormik.setFieldValue('technologies', values)}
            />
          </Grid>
          <Grid item xs={6}>&nbsp;</Grid>
          <Grid item xs={6}>
            <Multiselect
              title='Frequency managers'
              name='managers'
              fullWidth
              required
              items={managersItemList}
              selectedItems={Multiselect.getSelectedValues(managersItemList, workflowFormik.values.frequencyManagers)}
              onChange={(values: number[]) => workflowFormik.setFieldValue('frequencyManagers', values)}
              helperText={workflowFormik.errors.frequencyManagers}
              error={Boolean(workflowFormik.errors.frequencyManagers)}
            />
          </Grid>
        </Grid>
      </HorizontalPanel>
    </>
  ) : null;
};

export default EditWorkflow;