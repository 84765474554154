import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/state";
import { settingsSlice } from "./settingsState";

export const selectSettingsState = (state: RootState) => state[ settingsSlice.name ];

export const selectGetSettingsState = createSelector(
  selectSettingsState,
  (settingsState) => settingsState.getStatus
);

export const selectEditSettingsState = createSelector(
  selectSettingsState,
  (settingsState) => settingsState.editStatus,
);

export const selectGlobalSettings = createSelector(
  selectSettingsState,
  (settingsState) => settingsState.settings,
);

export const getTrialVersionTime = createSelector(
  selectSettingsState,
  (settingsState) => settingsState.settings.trial,
);

export const isTrialVersionExpired = createSelector(
  getTrialVersionTime,
  (time) => time !== 0 && (+new Date() > (time || Infinity))
);