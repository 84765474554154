import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { Toolbar } from '../../../../app/components';
import { TableList } from "app/components/TableList";
import {
  useTypedDispatch,
} from 'app/state';
import {
  selectCurrentChannelId,
  selectCurrentTechnologyConfiguration,
} from "../../../../store/technologyConfigurationStore/technologyConfigurationSelectors";
import { setCurrentChannelId } from "../../../../store/technologyConfigurationStore/technologyConfigurationState";
import HorizontalPanel from "../../../../components/HorizontalPanel";
import FxIcon from '../assets/FxIcon';
import columns from './ChannelColumns';
import ChannelModel from "../../../../models/ChannelModel";
import ChannelsFormulaModal from "../ChannelsFormulaModal";

import styles from './ChannelsTable.module.scss';

function createData(channelModel: ChannelModel) {
  const { id, channelNumber, downlinkCF, EIRP, isBestPractice, outdoor } = channelModel;
  return { id, channelNumber, downlinkCF, EIRP, isBP: isBestPractice ? 'Yes' : 'No', outdoor: outdoor ? 'Yes' : 'No' };
}

export default function ChannelTable() {
  const dispatch = useTypedDispatch();
  const [isOpen, setOpen] = useState(false);
  const currentTechnologyConfiguration = useSelector(selectCurrentTechnologyConfiguration);

  let selectedTechnologyConfigurationChannels = (currentTechnologyConfiguration?.channels || []).slice();
  const selectedChannelId = useSelector(selectCurrentChannelId);

  selectedTechnologyConfigurationChannels.sort((a, b) => a.centralFrequencyHz - b.centralFrequencyHz);

  useEffect(() => {
    if (!selectedChannelId) {
      dispatch(
        setCurrentChannelId(
          selectedTechnologyConfigurationChannels[0]?.id || null
        )
      );
    }
  }, [selectedChannelId, selectedTechnologyConfigurationChannels])

  const rows = useMemo(() => {
    return selectedTechnologyConfigurationChannels.map(createData)
  }, [selectedTechnologyConfigurationChannels]);

  const toggleDialog = () => {
    setOpen(!isOpen);
  }

  return (
    <>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={'Channels'}>
          <IconButton
            disabled={false}
            aria-label='add channel'
            color='secondary'
            size='small'
            onClick={() => {
              dispatch(setCurrentChannelId(-1))
            }}
          >
            <AddCircleOutlineRoundedIcon />
          </IconButton>
          <IconButton
            aria-label='Open channels formula dialog'
            color='secondary'
            size='small'
            onClick={toggleDialog}
          >
            <FxIcon />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL} className={styles.wrapper} addScroll>
        <TableList
          columns={columns}
          rows={rows}
          selectRow={(id) => {
            dispatch(setCurrentChannelId(id));
          }}
          selectedId={selectedChannelId}
        />
      </HorizontalPanel>
      <ChannelsFormulaModal
        isOpen={isOpen}
        onClose={toggleDialog}
        onCreate={toggleDialog}
        technologyConfiguration={currentTechnologyConfiguration}
      />
    </>
  );
}
