import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import DeviceMenuList from "../../DeviceMenuList";
import { pagePathGenerator } from "../../../routes/pagePathGenerator";
import { selectCurrentMapId } from "../../../store/mapStore/mapSelectors";
import { setEditMode } from "../../../store/generalStore/generalState";
import { useTypedDispatch } from "../../../app/state";
import { selectCurrentDeviceId } from "../../../store/deviceStore/deviceSelectors";
import DeviceSummary from "../DeviceSummary";
import { IconButton } from "@material-ui/core";
import TransitEnterexitIcon from "@material-ui/icons/TransitEnterexit";
import useFilter from "../../../hooks/filter.hook";
import FilterPanel from "../../Shared/FilterPanel";
import { getAllWirelessSystems } from "../../../store/wirelessSystemStore/wirelessSystemRequests";
import {
  requestAllTechnologyConfigurations
} from "../../../store/technologyConfigurationStore/technologyConfigurationRequests";
import { GeneralRequest } from "../../../requests/GeneralRequest";

const DeviceMapLeftPanel = () => {
  const dispatch = useTypedDispatch();
  const history = useHistory();
  const { state } = useFilter();
  const { isAnyFilterChosen, isFilterPanelVisible } = state;
  const currentMapId = useSelector(selectCurrentMapId);
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const isDeviceSelected = !!currentDeviceId;

  const selectDevice = (id: number | null) => {
    dispatch(setEditMode(false));
    const url = pagePathGenerator.DEVICES_MAP({
      mapId: currentMapId,
      deviceId: id,
    });

    history.replace(url);
  }

  const onBackClick = () => selectDevice(null);

  if (isFilterPanelVisible) {
    return (
      <GeneralRequest requestAction={getAllWirelessSystems}>
        <GeneralRequest requestAction={requestAllTechnologyConfigurations}>
          <FilterPanel onSelectedDeviceClear={onBackClick} />
        </GeneralRequest>
      </GeneralRequest>
    )
  }

  if (isDeviceSelected) {
    return (
      <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
        <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
          <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
            <Toolbar title="Device summary">
              <IconButton
                aria-label='close window'
                color='secondary'
                size='small'
                onClick={onBackClick}
              >
                <TransitEnterexitIcon />
              </IconButton>
            </Toolbar>
          </HorizontalPanel>
          <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} addScroll>
            <DeviceSummary />
          </HorizontalPanel>
        </HorizontalPanel>
      </HorizontalPanel>
    )
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={isAnyFilterChosen ? "Filtered Device List" : "Device list"} />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} addScroll>
        <DeviceMenuList onDeviceClick={selectDevice} />
      </HorizontalPanel>
    </HorizontalPanel>
  );
};

export default DeviceMapLeftPanel;