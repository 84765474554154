import { useSelector } from "react-redux";
import { useTypedDispatch } from "../app/state";
import { useShowModal } from "../app/shared/modal-context/modal.context";
import { useSnackbar } from "../app/shared/snackbar-context/snackbar.context";
import { selectCurrentFloor, selectCurrentFloorId } from "../store/floorStore/floorSelectors";
import { ModalType } from "../app/shared/modal-context/constants";
import { createFloor, deleteFloor, editFloor } from "../store/floorStore/floorRequests";
import { setCurrentFloorId } from "../store/floorStore/floorState";
import { FloorModel } from "../app/types";

const useFloor = () => {
  const dispatch = useTypedDispatch();
  const { showModal } = useShowModal();
  const { openSnackbar } = useSnackbar();
  const currentFloorId = useSelector(selectCurrentFloorId);
  const currentFloor = useSelector(selectCurrentFloor);
  const isFloorCreateMode = currentFloorId === -1;

  const onDeleteFloor = () => {
    return new Promise((resolve, reject) => {
      showModal(ModalType.MODAL_GENERIC_CONFIRM, {
        title: 'Delete Floor',
        text: 'Do you want to delete this floor?',
        confirmButtonHandler: () => {
          if (currentFloorId) {
            dispatch(deleteFloor(currentFloorId))
              .then((action) => {
                if ((action as { error: any }).error) {
                  openSnackbar({
                    title: 'Cannot delete floor',
                    message: 'This floor is already in use. It has devices',
                    severity: 'error',
                  });

                  reject();
                } else {
                  dispatch(setCurrentFloorId(null));
                  resolve(true);
                }
              });
          }
        },
        confirmButtonText: 'Delete',
      });
    });
  };

  const onSaveFloor = (values: Partial<FloorModel>) => {
    return new Promise((resolve, reject) => {
      isFloorCreateMode ?
        dispatch(createFloor(values))
          .then((action) => {
            const floorId = (action as { payload: FloorModel }).payload.id;
            dispatch(setCurrentFloorId(floorId));
            resolve(true);
          })
        : dispatch(editFloor(values))
          .then(() => resolve(true))
    });
  }

  return {
    actions: {
      onDeleteFloor,
      onSaveFloor,
    },
    state: {
      currentFloorId,
      currentFloor,
      isFloorCreateMode,
    }
  }
}

export default useFloor;