import { ModalEntityState } from "app/shared/modal-context/modal.context";
import { MODAL_LOOKUP } from "app/shared/modal-context/constants";

type RootModalProps = ModalEntityState & { onClose: () => void }

export const RootModal = ({ modalType, modalProps, isOpen = false, onClose}: RootModalProps) => {
  const onCloseModal = () => {
    if (modalProps?.closeModal) {
      modalProps?.closeModal();
    }
    onClose();
  };

  const ActiveModal = MODAL_LOOKUP[modalType];

  return (
    <>
      {ActiveModal && (
        <ActiveModal
          isOpen={isOpen}
          {...modalProps}
          closeModal={onCloseModal}
        />
      )}
    </>
  );
};
