import { RootModal } from "app/components/feature/root-modal/RootModal";
import { find, set } from "lodash/fp";
import { createContext, FC, useContext, useState, useCallback } from "react";
import { ModalType } from "./constants";

export type ModalEntityState = {
  modalType: ModalType;
  modalProps: Record<string, any>;
  isOpen: boolean;
};

export type ModalState = {
  openedModals: ModalEntityState[];
};

export type ModalContextType = {
  state: ModalState;
  setState: (state: ModalState) => void;
};

export const initialState: ModalState = {
  openedModals: [],
};

const initialContext: ModalContextType = {
  state: initialState,
  setState: (state: ModalState) => {},
};

const modalContext = createContext(initialContext);
const Provider = modalContext.Provider;

export const ModalProvider: FC = ({ children }) => {
  const [state, setState] = useState(initialState);
  const openedModal = find({ isOpen: true }, state.openedModals);
  const closeModal = () => {
    const modalType = openedModal?.modalType;
    if (
      modalType &&
      !state.openedModals.some((modal) => modal.modalType === modalType)
    ) {
      return state;
    }
    const modalIndex = state.openedModals.findIndex(
      (modal) => modal.modalType === modalType
    );
    setState((oldState) =>
      set(`openedModals.${modalIndex}.isOpen`, false, oldState)
    );
    setTimeout(() => {
      const newOpenedModals = state.openedModals.filter(
        (modal) => modal.modalType !== modalType
      );
      if (newOpenedModals.length > 0) {
        newOpenedModals[0] = {
          ...newOpenedModals[0],
          isOpen: true,
        };
      }
      setState({ openedModals: newOpenedModals });
    }, 300);
  };

  return (
    <Provider value={{ state, setState }}>
      {openedModal && <RootModal {...openedModal} onClose={closeModal} />}
      {children}
    </Provider>
  );
};

export const useShowModal = () => {
  const { setState, state } = useContext(modalContext);

  const showModal = useCallback(
    (modalType: ModalType, modalProps: Record<string, any>) => {
      if (!state.openedModals.some((modal) => modal.modalType === modalType)) {
        setState({
          ...state,
          openedModals: [
            ...state.openedModals,
            {
              modalType,
              modalProps,
              isOpen: true,
            },
          ],
        });
      }
    },
    [setState, state]
  );
  return { showModal };
};
