import { ICONS_DIMENSIONS } from "./constants";

export const getPointOffset = (pointType) => {
  const pointSize = ICONS_DIMENSIONS[pointType];
  switch (pointType) {
    case 'scale':
    case 'anchor':
      return {
        width: pointSize.width / 2,
        height: pointSize.height
      }
    case 'device':
    case 'portableDevice':
    default:
      return {
        width: pointSize.width / 2,
        height: pointSize.height / 2
      }
  }
}