import React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import {
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuList, { MenuListItem } from "../MenuList";

import styles from './CollapsableListItem.module.scss';

interface CollapsableListItemProps {
  listTitle: string;
  items: MenuListItem[];
  selectItem: (id: number) => void;
  selectedItemId?: number | null;
  icon?: React.ReactElement | null;
}

export const CollapsableListItem = (props: CollapsableListItemProps) => {
  const { listTitle, items, selectItem, selectedItemId, icon } = props;
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        className={styles.title}
      >
        {
          icon && (
            <ListItemIcon>
              {
                icon
              }
            </ListItemIcon>
          )
        }
        <ListItemText
          primary={listTitle}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        className={styles.menu}
      >
        <MenuList
          items={items}
          selectItem={selectItem}
          selectedItemId={selectedItemId}
          classes={{
            menuItem: styles.listItem,
          }}
        />
      </Collapse>
    </>
  )
}