import List from "@material-ui/core/List";
import { uniq } from "lodash/fp";
import { Wifi } from "@material-ui/icons";
import { CollapsableListItem } from "../../components/CollapsableListItem/CollapsableListItem";
import DeviceIcon from "../../components/DeviceIcon/DeviceIcon";
import useDevicesPage from "../DevicesPage/devicePageHooks";
import { useSelector } from "react-redux";
import {
  selectCurrentDeviceId,
  selectDevicesByMapId,
} from "../../store/deviceStore/deviceSelectors";
import { selectWirelessSystemsByIdList } from "../../store/wirelessSystemStore/wirelessSystemSelectors";
import useFilter from "../../hooks/filter.hook";

interface DeviceMenuListProps {
  onDeviceClick: (deviceId: number) => any,
}

const DeviceMenuList = ({ onDeviceClick }: DeviceMenuListProps) => {
  const { state } = useDevicesPage();
  const { currentMapId } = state;
  const filter = useFilter();
  const currentPlaceDevices = useSelector(selectDevicesByMapId(currentMapId));
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const filteredDeviceList = filter.actions.filterDeviceList(currentPlaceDevices);
  const availableWirelessSystemIdList = uniq(filteredDeviceList.map((device) => device.configuration.wirelessSystemId));
  const availableWirelessSystems = useSelector(selectWirelessSystemsByIdList(availableWirelessSystemIdList));

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {
        availableWirelessSystems.map((wirelessSystem, idx) => {
          return (
            <CollapsableListItem
              icon={<Wifi />}
              key={idx}
              listTitle={wirelessSystem.name}
              selectItem={onDeviceClick}
              selectedItemId={currentDeviceId}
              items={
                filteredDeviceList.filter((device) => {
                  return wirelessSystem.id === device?.configuration?.wirelessSystemId
                }).map((device) => {
                  return {
                    secondary: device.name,
                    id: device.id,
                    icon: <DeviceIcon deviceConfiguration={device?.configuration} />,
                  }
                })
              }
            />
          )
        })
      }
    </List>
  )
}

export default DeviceMenuList;