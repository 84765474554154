const columns = [
  {
    field: 'channelNumber',
    headerName: 'Number',
    width: 50,
  },
  {
    field: 'downlinkCF',
    headerName: 'Frequency',
  },
  {
    field: 'EIRP',
    headerName: 'EIRP',
    width: 100,
  },
  {
    field: 'outdoor',
    headerName: 'Outdoor',
  },
  {
    field: 'isBP',
    headerName: 'BP',
  },
];

export default columns;