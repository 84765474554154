import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectCurrentRegionId } from "../../store/regionStore/regionSelectors";
import { selectAllLocations, selectCurrentLocationId } from "../../store/locationStore/locationSelectors";
import {
  selectAllBuildings,
  selectCurrentBuildingId,
} from "../../store/buildingStore/buildingsSelectors";
import { pagePathGenerator } from "../../routes/pagePathGenerator";
import { useTypedDispatch } from "../../app/state";
import { setCurrentRegionId } from "../../store/regionStore/regionState";
import { setCurrentLocationId } from "../../store/locationStore/locationState";
import { setCurrentBuildingId } from "../../store/buildingStore/buildingsState";
import { selectAllCountries, selectCurrentCountryId } from "../../store/countryStore/countrySelectors";
import { setCurrentCountryId } from "../../store/countryStore/countryState";

export interface LocationProps {
  locationId?: number | null,
  buildingId?: number | null,
  regionId?: number | null,
  countryId?: number | null,
  shouldReplace?: boolean,
}

const useLocationRoute = () => {
  const history = useHistory();
  const dispatch = useTypedDispatch();
  const currentRegionId = useSelector(selectCurrentRegionId);
  const currentCountryId = useSelector(selectCurrentCountryId);
  const currentLocationId = useSelector(selectCurrentLocationId);
  const currentBuildingId = useSelector(selectCurrentBuildingId);
  const countryList = useSelector(selectAllCountries);
  const locationList = useSelector(selectAllLocations);
  const buildingList = useSelector(selectAllBuildings);

  const checkNoDifference = (props: LocationProps) => {
    let {
      regionId = currentRegionId,
      countryId = currentCountryId,
      locationId = currentLocationId,
      buildingId = currentBuildingId,
    } = props;

    return (
      regionId === currentRegionId &&
      countryId === currentCountryId &&
      locationId === currentLocationId &&
      buildingId === currentBuildingId
    );
  }

  const updateProps = (props: LocationProps) => {
    let {
      regionId = currentRegionId,
      countryId = currentCountryId,
      locationId = currentLocationId,
      buildingId = currentBuildingId,
    } = props;

    const newProps = {
      regionId,
      countryId,
      locationId,
      buildingId,
    };

    const isRegionChanged = regionId !== currentRegionId;
    let isCountryChanged = countryId !== currentCountryId;
    let isLocationChanged = locationId !== currentLocationId;

    const regionCountryList = regionId ? countryList.filter((country) => country.regionId === regionId) : countryList;
    const regionCountryIdList = regionCountryList.map((country) => country.id);

    if (isRegionChanged) {
      const setCountry = () => {
        newProps.countryId = null;
        countryId = newProps.countryId;
        isCountryChanged = true;
      }

      if (countryId && countryId !== -1) {
        if (!regionCountryIdList.includes(countryId)) {
          setCountry();
        }
      } else {
        setCountry();
      }
    }

    const filteredLocationList = regionId || countryId ? locationList.filter((location) => {
      return countryId ? location.countryId === countryId : regionCountryIdList.includes(location.countryId);
    }) : locationList;
    const filteredLocationIdList = filteredLocationList.map((location) => location.id);

    if (isRegionChanged || isCountryChanged) {
      const setFirstLocation = () => {
        const firstLocation = filteredLocationList[0];
        newProps.locationId = firstLocation ? firstLocation.id : -1;
        locationId = newProps.locationId;
        isLocationChanged = true;
      }
      if (locationId === -1 || (locationId && !filteredLocationIdList.includes(locationId))) {
        setFirstLocation();
      }
    }

    if (isLocationChanged) {
      if (!locationId) {
        const firstLocation = filteredLocationList[0];
        newProps.locationId = firstLocation ? firstLocation.id : -1;
        locationId = newProps.locationId;
      }
      const isNoLocations = !locationId || locationId === -1;
      const filteredBuildingList = isNoLocations ? [] : buildingList.filter((building) => building.locationId === locationId);
      const filteredBuildingIdList = filteredBuildingList.map((building) => building.id);

      if (buildingId && !filteredBuildingIdList.includes(buildingId)) {
        const firstItem = filteredBuildingList[0];
        newProps.buildingId = firstItem ? firstItem.id : (isNoLocations ? null : -1);
        buildingId = newProps.buildingId;
      }
    }

    // set first building
    if (!buildingId) {
      const isNoLocations = !locationId || locationId === -1;
      const filteredBuildingList = isNoLocations ? [] : buildingList.filter((building) => building.locationId === locationId);
      const firstItem = filteredBuildingList[0];
      newProps.buildingId = firstItem ? firstItem.id : (isNoLocations ? null : -1);
    }

    return newProps;
  }

  const updateLocationUrl = (props: LocationProps) => {
    const {
      shouldReplace = false,
    } = props;

    const newProps = updateProps(props);

    if (checkNoDifference(newProps)) {
      return;
    }

    const url = pagePathGenerator.LOCATION_PAGE(newProps);

    shouldReplace ? history.replace(url) : history.push(url);
  };

  const checkAndDispatch = (value, action) => typeof value !== 'undefined' && dispatch(action(value));

  const updateSelectedValues = (props: LocationProps) => {
    const { regionId, countryId, locationId, buildingId } = props;

    if (checkNoDifference(props)) {
      return;
    }

    checkAndDispatch(regionId, setCurrentRegionId);
    checkAndDispatch(countryId, setCurrentCountryId);
    checkAndDispatch(locationId, setCurrentLocationId);
    checkAndDispatch(buildingId, setCurrentBuildingId);
  }

  return {
    state: {
      currentRegionId,
      currentCountryId,
      currentLocationId,
      currentBuildingId,
    },
    actions: {
      updateLocationUrl,
      updateSelectedValues,
    }
  }
}

export default useLocationRoute;