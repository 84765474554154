import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../app/api";
import TechnologyConfigurationModel from "../../models/TechnologyConfigurationModel";
import { serializeError } from "../../app/shared";

const SLICE_NAME = 'technology-configuration';

export const requestAllTechnologyConfigurations = createAsyncThunk(
  `${SLICE_NAME}/configuration/all`,
  async (props: { buildingId: number | undefined }, { rejectWithValue }) => {
    const buildingId = props?.buildingId
    // todo: split into two thunks
    let url = '/technology/configuration/all';

    if (buildingId) {
      url = `/technology/configuration/${buildingId}`;
    }
    try {
      const technologyConfigurationList: TechnologyConfigurationModel[] = await request.get(url);
      return technologyConfigurationList;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const createTechnologyConfiguration = createAsyncThunk(
  `${SLICE_NAME}/create`,
  async (body: any, { rejectWithValue }) => {
    try {
      const resp: TechnologyConfigurationModel = await request.post('/technology/configuration', body);
      return resp;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const updateTechnologyConfiguration = createAsyncThunk(
  `${SLICE_NAME}/configuration/edit`,
  async (body: any, { rejectWithValue }) => {
    try {
      const resp: TechnologyConfigurationModel = await request.put('/technology/configuration', body);
      return resp;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const deleteTechnologyConfiguration = createAsyncThunk(
  `${SLICE_NAME}/configuration/delete`,
  async (id: number | undefined = undefined, { rejectWithValue }) => {
    try {
      await request.delete(`/technology/configuration/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const deleteTechnologyConfigurationChannel = createAsyncThunk(
  `${SLICE_NAME}/configuration/channel/delete`,
  async (id: number | undefined = undefined, { rejectWithValue }) => {
    try {
      const response: TechnologyConfigurationModel = await request.delete(`/channel/${id}`);
      return response;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const updateTechnologyConfigurationChannel = createAsyncThunk(
  `${SLICE_NAME}/configuration/channel/edit`,
  async (body: any, { rejectWithValue }) => {
    try {
      const resp: TechnologyConfigurationModel = await request.put('/channel', body);
      return resp;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const createTechnologyConfigurationChannel = createAsyncThunk(
  `${SLICE_NAME}/configuration/channel/new`,
  async (body: any, { rejectWithValue }) => {
    try {
      const resp: TechnologyConfigurationModel = await request.post('/channel', body);
      return resp;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);