import { useSelector } from "react-redux";
import { CheckPermission } from "../../../requests/CheckPermission";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import { selectAllWirelessSystems } from "../../../store/wirelessSystemStore/wirelessSystemSelectors";
import { useTypedDispatch } from "../../../app/state";
import { createNewWirelessSystem } from "../../../store/wirelessSystemStore/wirelessSystemRequests";
import WirelessSystemModel from "../../../models/WirelessSystemModel";
import SelectAndAction from "../../../components/TextFields/SelectAndAction/SelectAndAction";

type WirelessSystemSelectProps = {
  wirelessSystemId?: number;
  disabled?: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
}

const WirelessSystemSelect = ({ wirelessSystemId = 1, setFieldValue, disabled = false }: WirelessSystemSelectProps) => {
  const dispatch = useTypedDispatch();
  const wirelessSystemList = useSelector(selectAllWirelessSystems);
  const itemsForSelect = wirelessSystemList.map((wirelessSystem) => {
    return {
      title: wirelessSystem.name,
      valueOption: wirelessSystem.id,
    }
  });

  const updateValue = (value?: number) => setFieldValue('wirelessSystemId', value || 1)
  const onChange = (valueOption) => {
    updateValue(valueOption);
  }
  const onCreateNew = (name: string) => {
    dispatch(createNewWirelessSystem(name))
      .then((action) => {
        const data = action.payload as { wirelessSystem: WirelessSystemModel, error?: string };
        const wirelessSystem = data.wirelessSystem;
        updateValue(wirelessSystem.id);
      })
  }

  return (
    <CheckPermission
      permissionName={PERMISSIONS.WIRELESS_SYSTEM_ACTIONS}
      noPermissionComponent={(
        <SelectAndAction
          label='Wireless System'
          disabled={disabled}
          currentValue={wirelessSystemId}
          isAddNewAvailable={false}
          listFields={itemsForSelect}
          onChange={onChange}
        />
      )}
    >
      <SelectAndAction
        label='Wireless System'
        disabled={disabled}
        currentValue={wirelessSystemId}
        isAddNewAvailable={true}
        labelForAdd={' (Create a new)'}
        listFields={itemsForSelect}
        onChange={onChange}
        onCreateNew={onCreateNew}
      />
    </CheckPermission>
  );
}

export default WirelessSystemSelect;