import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid } from "@material-ui/core";
import { DeviceStatus } from "../../../models/enums/DeviceStatus.enum";
import { selectAllCountries } from "../../../store/countryStore/countrySelectors";
import { selectAllLocations } from "../../../store/locationStore/locationSelectors";
import { selectAllBuildings } from "../../../store/buildingStore/buildingsSelectors";
import { selectAllFloors } from "../../../store/floorStore/floorSelectors";
import { getRequestersList } from "../../../store/userListStore/userListSelectors";
import { selectAllWirelessSystems } from "../../../store/wirelessSystemStore/wirelessSystemSelectors";
import { GenericModal } from "../../../app/components/ui/generic-modal/GenericModal";
import { Dropdown } from "../../../app/components/Dropdown";
import Multiselect from "../../../components/Multiselect";
import UnitsInput from "../../../components/TextFields/UnitsInput";

import classes from './index.module.scss';
import {
  selectAllTechnologyConfigurations
} from "../../../store/technologyConfigurationStore/technologyConfigurationSelectors";

export interface SearchFormValues {
  countryId?: number;
  locationId?: number;
  buildingList?: number[];
  floorList?: number[];
  frequencyFrom?: string;
  frequencyTo?: string;
  technologies?: number[];
  wirelessSystemId?: number[];
  requesterId?: number[];
  status?: DeviceStatus[];
}

export interface SearchDialogFormProps {
  isOpen: boolean;
  onSearch?: (values: SearchFormValues) => void;
  onClose?: () => void;
  searchValues?: SearchFormValues;
}

// todo: clear values on values change
// e.g. if country changes - location should clear it's value

const SearchDialogForm = ({
  isOpen,
  onSearch = () => {},
  onClose = () => {},
  searchValues = {}
}: SearchDialogFormProps) => {
  const countryList = useSelector(selectAllCountries);
  const locationList = useSelector(selectAllLocations);
  const buildingList = useSelector(selectAllBuildings);
  const floorList = useSelector(selectAllFloors);
  const requesterList = useSelector(getRequestersList) || [];
  const wirelessSystemList = useSelector(selectAllWirelessSystems);
  const technologyList = useSelector(selectAllTechnologyConfigurations);

  const DEVICE_STATUSES = [
    DeviceStatus.NEW,
    DeviceStatus.REQUESTED,
    DeviceStatus.APPROVED,
    DeviceStatus.REJECTED,
    DeviceStatus.ACTIVE,
    DeviceStatus.RETIRED,
  ];

  const searchFormik = useFormik({
    initialValues: {
      ...{
        countryId: null,
        locationId: null,
        buildingList: [] as number[],
        floorList: [] as number[],
        frequencyFrom: '',
        frequencyTo: '',
        technologies: [] as number[],
        wirelessSystemId: [] as number[],
        requesterId: [] as number[],
        status: [] as DeviceStatus[],
      },
      ...searchValues
    },
    onSubmit: () => {},
    validationSchema: yup.object({
      countryId: yup.number().typeError('Country is required').required('Country is required'),
      locationId: yup.number().typeError('Location is required').required('Location is required'),
      frequencyFrom: yup.string().matches(/^\d*\.?\d+ +(nHz|Hz|MHz|GHz|kHz|µHz|THz)$/, 'Invalid value'),
      frequencyTo: yup.string().matches(/^\d*\.?\d+ +(nHz|Hz|MHz|GHz|kHz|µHz|THz)$/, 'Invalid value'),
    }),
    validateOnChange: false
  });

  const availableTechnologyList = technologyList.filter((technologyConfiguration) => {
    const countryIdList = technologyConfiguration.countries.map((country) => country.id);

    return searchFormik.values.countryId ? countryIdList.includes(searchFormik.values.countryId) : true;
  })

  const onSearchClick = async () => {
    const valid = await searchFormik.validateForm();
    if (isEmpty(valid)) {
      onSearch(searchFormik.values as SearchFormValues);
    }
  }

  const onDeleteKey = (key, id) => {
    searchFormik.setFieldValue(
      key,
      searchFormik.values[key].filter((item) => item !== id)
    )
  };

  const onCountryChange = (e) => {
    searchFormik.setFieldValue('locationId', '');
    searchFormik.setFieldValue('technologies', []);
    searchFormik.handleChange(e);
  }

  const locationFilteredList = useMemo(() => {
    return locationList.filter((location) => location.countryId === searchFormik?.values?.countryId);
  }, [searchFormik?.values?.countryId, locationList]);

  const buildingFilteredList = useMemo(() => {
    return buildingList.filter((building) => building.locationId === searchFormik?.values?.locationId).map((building) => ({
      id: building.id,
      value: building.name
    }));
  }, [searchFormik?.values?.locationId, buildingList]);

  const floorFilteredList = useMemo(() => {
    return floorList.filter((floor) => searchFormik?.values?.buildingList.includes(floor.buildingId)).map((floor) => ({
      id: floor.id,
      value: floor.name
    }));
  }, [searchFormik?.values?.buildingList, floorList]);

  const requesterMenuList = useMemo(() => {
    return requesterList.map((requester) => ({
      id: requester.id,
      value: requester.profile?.fullName || '',
    }))
  }, [requesterList]);

  const wirelessSystemMenuList = useMemo(() => {
    return wirelessSystemList.map((wirelessSystem) => ({
      id: wirelessSystem.id,
      value: wirelessSystem.name
    }))
  }, [wirelessSystemList]);

  const technologyMenuList = useMemo(() => {
    return availableTechnologyList.map((technology) => ({
      id: technology.id,
      value: technology.name
    }))
  }, [availableTechnologyList]);

  const statusMenuList = DEVICE_STATUSES.map((status) => ({
    id: status,
    value: status.charAt(0).toUpperCase() + status.slice(1)
  }));

  return (
    <GenericModal
      isOpen={isOpen}
      title="Device Search"
      confirmButtonText="Search"
      closeModal={onClose}
      confirmButtonHandler={onSearchClick}
    >
      <Grid container direction='column' spacing={2}>
        <Grid container direction='row' spacing={2} className={classes.searchLineWithTwoInputs}>
          <Grid item xs={6}>
            <Dropdown
              name='countryId'
              fullWidth
              required
              label={'Country'}
              values={countryList}
              valueChange={onCountryChange}
              selectedValue={searchFormik?.values?.countryId}
              error={searchFormik?.errors?.countryId}
            />
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              name='locationId'
              fullWidth
              required
              label={'Location'}
              values={locationFilteredList}
              valueChange={searchFormik.handleChange}
              selectedValue={searchFormik?.values?.locationId}
              disabled={!searchFormik?.values?.countryId}
              error={searchFormik?.errors?.locationId}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Multiselect
            title='Building'
            name='buildingList'
            fullWidth
            items={buildingFilteredList}
            // @ts-ignore
            selectedItems={searchFormik.values.buildingList.map((id) => buildingFilteredList.find((building) => building.id === id)).filter(Boolean)}
            onChange={(values) => {
              searchFormik.setFieldValue('buildingList', values)
            }}
            disabled={!searchFormik?.values?.locationId}
            onDelete={({ id }) => onDeleteKey('buildingList', id)}
          />
        </Grid>
        <Grid item xs={12}>
          <Multiselect
            title='Floor'
            name='floorList'
            fullWidth
            items={floorFilteredList}
            // @ts-ignore
            selectedItems={searchFormik.values.floorList.map((id) => floorFilteredList.find((floor) => floor.id === id)).filter(Boolean)}
            onChange={(values) => {
              searchFormik.setFieldValue('floorList', values);
            }}
            disabled={!searchFormik?.values?.buildingList.length}
            onDelete={({ id }) => onDeleteKey('floorList', id)}
          />
        </Grid>
        <Grid container direction='row' spacing={2} className={classes.searchLineWithTwoInputs}>
          <Grid item xs={6}>
            <UnitsInput
              unitList={UnitsInput.UNIT_TEMPLATE.FREQUENCY}
              fullWidth
              name='frequencyFrom'
              variant='outlined'
              label={'Frequency range from'}
              onChange={searchFormik?.handleChange}
              value={searchFormik?.values?.frequencyFrom}
              error={Boolean(searchFormik?.errors?.frequencyFrom)}
              helperText={searchFormik?.errors?.frequencyFrom}
            />
          </Grid>
          <Grid item xs={6}>
            <UnitsInput
              unitList={UnitsInput.UNIT_TEMPLATE.FREQUENCY}
              fullWidth
              name='frequencyTo'
              variant='outlined'
              label={'Frequency range to'}
              onChange={searchFormik?.handleChange}
              value={searchFormik?.values?.frequencyTo}
              error={Boolean(searchFormik?.errors?.frequencyTo)}
              helperText={searchFormik?.errors?.frequencyTo}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Multiselect
            title='Technology'
            name='technologies'
            fullWidth
            items={technologyMenuList}
            // @ts-ignore
            selectedItems={searchFormik.values.technologies.map((id) => technologyMenuList.find((technology) => technology.id === id)).filter(Boolean)}
            onChange={(values) => {
              searchFormik.setFieldValue('technologies', values);
            }}
            disabled={!searchFormik?.values?.locationId}
            onDelete={({ id }) => onDeleteKey('technologies', id)}
          />
        </Grid>
        <Grid item xs={12}>
          <Multiselect
            title='Wireless System'
            name='wirelessSystemId'
            fullWidth
            items={wirelessSystemMenuList}
            // @ts-ignore
            selectedItems={searchFormik.values.wirelessSystemId.map((id) => wirelessSystemMenuList.find((wirelessSystem) => wirelessSystem.id === id)).filter(Boolean)}
            onChange={(values) => {
              searchFormik.setFieldValue('wirelessSystemId', values);
            }}
            disabled={!searchFormik?.values?.locationId}
            onDelete={({ id }) => onDeleteKey('wirelessSystemId', id)}
          />
        </Grid>
        <Grid item xs={12}>
          <Multiselect
            title='Status'
            name='status'
            fullWidth
            items={statusMenuList}
            // @ts-ignore
            selectedItems={searchFormik.values?.status?.map((id) => statusMenuList.find((status) => status.id === id)).filter(Boolean)}
            onChange={(values) => {
              searchFormik.setFieldValue('status', values);
            }}
            disabled={!searchFormik?.values?.locationId}
            onDelete={({ id }) => onDeleteKey('status', id)}
          />
        </Grid>
        <Grid item xs={12}>
          <Multiselect
            title='Requester'
            name='requesterId'
            fullWidth
            items={requesterMenuList}
            // @ts-ignore
            selectedItems={searchFormik.values.requesterId.map((id) => requesterMenuList.find((requester) => requester.id === id)).filter(Boolean)}
            onChange={(values) => {
              searchFormik.setFieldValue('requesterId', values);
            }}
            disabled={!searchFormik?.values?.locationId}
            onDelete={({ id }) => onDeleteKey('requesterId', id)}
          />
        </Grid>
      </Grid>
    </GenericModal>
  );
}

export default SearchDialogForm;