import { useCallback } from 'react';
import { ListItem } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItem: {
      height: '32px',
      fontSize: '14px',
      boxShadow: 'inset 0px -1px 0px #E0E0E0',
    },
    letter: {
      paddingLeft: theme.spacing(2),
    },
    root: {
      '& .MuiTypography-root': { fontSize: '14px' },
    },
  })
);

type MenuItemModel = { name: string; id: number, configuration?: any };

type MenuProps = {
  menuItems: MenuItemModel[];
  selectItem: (id: MenuItemModel['id']) => void;
  selectedItemId?: MenuItemModel['id'];
  showDeviceIcon?: Boolean;
};

export const Menu = ({ menuItems, selectItem, selectedItemId, showDeviceIcon }: MenuProps) => {
  const classes = useStyles();
  const onSelect = useCallback(
    (id: MenuItemModel['id']) => {
      if (selectedItemId !== id) {
        selectItem(id);
      }
    },
    [selectItem, selectedItemId]
  );
  return (
    <List component='nav' className={classes.list}>
      {menuItems.map((item) => (
        <ListItem
          className={classes.listItem}
          key={item.id}
          button
          selected={selectedItemId === item.id}
          onClick={() => {
            onSelect(item.id);
          }}
        >
          {/*{*/}
          {/*  showDeviceIcon &&*/}
          {/*  !(*/}
          {/*    (item?.configuration.status === DeviceStatus.APPROVED && item?.configuration.isDraft) ||*/}
          {/*    (item?.configuration.status === DeviceStatus.RETIRED && item?.configuration.isDraft)*/}
          {/*  ) &&*/}
          {/*  <ListItemIcon>*/}
          {/*    <DeviceIcon device={item} />*/}
          {/*  </ListItemIcon>*/}
          {/*}*/}
          <ListItemText primary={item.name} className={classes.root} />
        </ListItem>
      ))}
    </List>
  );
};
