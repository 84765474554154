import { useEffect } from "react";
import { Redirect } from 'react-router-dom';
import LeftPanel from '../../containers/DeviceMap/LeftPanel';
import { getGlobalSettings } from "../../store/settingsStore/settingsRequests";
import { ThreeColumnLayout } from '../../layouts/ThreeColumnLayout/ThreeColumnLayout';
import { GeneralRequest } from "../../requests/GeneralRequest";
import Pages, { getQueryObject, isEmptyId } from "../index";
import PageUpdater from "../../containers/PageUpdater";
import { setCurrentFloorByMapId } from "../../store/floorStore/floorState";
import CentralDeviceMapPanel from "../../containers/DeviceMap/CentralPanel";
import { setCurrentDeviceId } from "../../store/deviceStore/deviceState";
import { setCurrentMapId } from "../../store/mapStore/mapState";
import { setCurrentLocationIdByMapId } from "../../store/locationStore/locationState";
import { requestAllDevicesByQuery } from "../../store/deviceStore/deviceRequests";
import FetchDeviceRequirementsWrapper from "../../containers/DevicesPage/FetchDeviceRequirementsWrapper";
import DeviceCurrentIdsSetter from "../../containers/DevicesPage/DeviceCurrentIdsSetter";
import { getAllFloors } from "../../store/floorStore/floorRequests";
import { setInfluenceAreaEnabled } from "../../store/filterStore/filterState";
import { useTypedDispatch } from "../../app/state";
import useFilter from "../../hooks/filter.hook";

export const DeviceMapPage = () => {
  const dispatch = useTypedDispatch();
  const { actions } = useFilter();
  const urlProps = getQueryObject();
  const mapId = parseInt(urlProps.mapId as string, 10);
  const deviceId = parseInt(urlProps.deviceId as string, 10);

  useEffect(() => {
    dispatch(setInfluenceAreaEnabled(!isEmptyId(deviceId)));

    if (!isEmptyId(deviceId) && !isEmptyId(mapId)) {
      actions.initializeFiltersForPage({ smartFilterDeviceId: deviceId, mapId });
    }
  }, []);

  if (isEmptyId(mapId)) {
    return <Redirect to={Pages.LOCATIONS_PAGE} />
  }


  return (
    <>
      <ThreeColumnLayout
        leftSideBar={<LeftPanel />}
        centerMainPanel={(
          <>
            <PageUpdater
              watchItemList={[
                {
                  title: 'deviceId',
                  action: setCurrentDeviceId,
                },
                {
                  title: 'mapId',
                  action: setCurrentMapId,
                },
                {
                  title: 'mapId',
                  action: setCurrentLocationIdByMapId,
                },
                {
                  title: 'mapId',
                  action: setCurrentFloorByMapId,
                },
              ]}
            />
            <GeneralRequest
              requestAction={requestAllDevicesByQuery}
              requestArguments={[{ mapId }]}
            >
              <FetchDeviceRequirementsWrapper>
                <GeneralRequest requestAction={getGlobalSettings}>
                  <GeneralRequest requestAction={getAllFloors}>
                    <CentralDeviceMapPanel />
                  </GeneralRequest>
                </GeneralRequest>
              </FetchDeviceRequirementsWrapper>
            </GeneralRequest>
          </>
        )}
      />
      <DeviceCurrentIdsSetter />
    </>
  );
};

export default DeviceMapPage;
