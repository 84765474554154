import Grid, { GridProps } from "@material-ui/core/Grid";
import cn from 'classnames';
import ChildrenElement from "../../models/HelperModels/ChildrenElement";

import styles from './index.module.scss';

enum HORIZONTAL_PANEL_SIZES {
  SMALL,
  REST_SPACE,
  WHOLE_SPACE
}

type HorizontalPanelProps = {
  size: HORIZONTAL_PANEL_SIZES;
  children?: ChildrenElement,
  className?: string;
  addScroll?: boolean;
  noWrap?: boolean;
} & GridProps;

const HorizontalPanel = ({ size, children, className, addScroll, noWrap = true, ...gridProps }: HorizontalPanelProps) => (
  <Grid
    container
    direction='column'
    className={cn(className,
      {
        [styles.smallPanel]: size === HORIZONTAL_PANEL_SIZES.SMALL,
        [styles.restSpacePanel]: size === HORIZONTAL_PANEL_SIZES.REST_SPACE,
        [styles.wholeSpacePanel]: size === HORIZONTAL_PANEL_SIZES.WHOLE_SPACE,
        [styles.addScroll]: addScroll,
        [styles.noWrap]: noWrap,
      }
    )}
    {...gridProps}
  >
    {
      children ? children : null
    }
  </Grid>
);

HorizontalPanel.SIZES = HORIZONTAL_PANEL_SIZES;

export default HorizontalPanel;