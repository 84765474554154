import { LoadingStatus, status } from '../../app/shared';
import {
  createEntityAdapter,
  createSlice, EntityState,
} from '@reduxjs/toolkit';
import { WorkflowModel } from '../../models/WorkflowModel';
import { deleteWorkflow, editOrCreateWorkflow, getWorkflowListRequest } from './workflowRequests';

export const workflowAdapter = createEntityAdapter<WorkflowModel>({
  selectId: (workflow) => workflow.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

type WorkflowState = {
  selectedWorkflowId: number | null;
  editWorkflowStatus: LoadingStatus;
  workflowStatus: LoadingStatus;
} & EntityState<WorkflowModel>

const initialState: WorkflowState = {
  selectedWorkflowId: null,
  editWorkflowStatus: status.default,
  workflowStatus: status.default,
  ...workflowAdapter.getInitialState(),
};

export const workflowSlice = createSlice({
  name: 'workflows',
  initialState,
  reducers: {
    setCurrentWorkflowId: (state, action) => {
      state.selectedWorkflowId = action?.payload;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(getWorkflowListRequest.pending, (state) => {
        state.workflowStatus = status.loading;
      })
      .addCase(getWorkflowListRequest.fulfilled, (state, action) => {
        state.workflowStatus = status.loaded;
        workflowAdapter.setAll(state, action.payload);
      })
      .addCase(editOrCreateWorkflow.fulfilled, (state, action) => {
        workflowAdapter.upsertOne(state, action.payload);
      })
      .addCase(deleteWorkflow.fulfilled, (state, action) => {
        workflowAdapter.removeOne(state, action.payload);
      }),
});

export const { setCurrentWorkflowId } = workflowSlice.actions;