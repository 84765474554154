import { Grid } from "@material-ui/core";
import { CheckPermission } from "../../../../requests/CheckPermission";
import { PERMISSIONS } from "../../../../store/userStore/permissions.enum";
import { GeneralRequest } from "../../../../requests/GeneralRequest";
import { getImportCountries, getImportState } from "../../../../store/import/importRequests";
import ImportContainer from "../ImportContainer";

import styles from './index.module.scss';

const ImportMainPanel = () => (
  <CheckPermission permissionName={PERMISSIONS.IMPORT_ACCESS}>
    <GeneralRequest requestAction={getImportCountries}>
      <GeneralRequest requestAction={getImportState}>
        <Grid container direction='column' className={styles.panel}>
          <ImportContainer />
        </Grid>
      </GeneralRequest>
    </GeneralRequest>
  </CheckPermission>
);

export default ImportMainPanel;