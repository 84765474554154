import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.appBar - 1,
      maxHeight: "100%",
      minHeight: "100%",
    },
  })
);

export const Panel = ({ component }: { component?: JSX.Element }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
      flexGrow={1}
      boxShadow={4}
      bgcolor="background.paper"
    >
      {component}
    </Box>
  );
};
