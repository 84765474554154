import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../app/api";
import { serializeError } from "../../app/shared";
import CountryModel from "../../models/CountryModel";

export const uploadImportFile = createAsyncThunk(
  'import/upload',
  async (
    { formData, countryId }: { formData: FormData, countryId: number },
    { rejectWithValue }
  ) => {
    try {
      return await request.post(
        `import/${countryId}/upload`,
        formData
      );
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const getImportState = createAsyncThunk(
  `/import/state`,
  async (
    _,
    { rejectWithValue },
  ) => {
    try {
      const response = await request.get(
        `/import/state`,
      );

      return response as any;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const sendImportCancel = createAsyncThunk(
  `/import/cancel`,
  async (
    _,
    { rejectWithValue },
  ) => {
    try {
      const response = await request.post(
        `/import/cancel`,
      );

      return response as any;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const sendImportDo = createAsyncThunk(
  `/import/do`,
  async (
    _,
    { rejectWithValue },
  ) => {
    try {
      const response = await request.post(
        `/import/do`,
      );

      return response as any;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const sendImportAccept = createAsyncThunk(
  `/import/accept`,
  async (
    _,
    { rejectWithValue },
  ) => {
    try {
      const response = await request.post(
        `/import/accept`,
      );

      return response as any;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const sendImportRevert = createAsyncThunk(
  `/import/revert`,
  async (
    _,
    { rejectWithValue },
  ) => {
    try {
      const response = await request.post(
        `/import/revert`,
      );

      return response as any;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const getImportCountries = createAsyncThunk(
  `/import/countries`,
  async (
    _,
    { rejectWithValue },
  ) => {
    try {
      const countryList: CountryModel[] = await request.get(
        `/country`,
      );

      return countryList;

    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);