import { useState } from 'react';
import { useSelector } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import FileUpload from "../../../../components/FileUpload";
import { Dropdown } from "../../../../app/components/Dropdown";
import { useTypedDispatch } from "../../../../app/state";
import { selectImportCountry, selectImportFile } from "../../../../store/import/importState";
import {
  importCountryList,
  importCurrentFile,
  importCurrentlySelectedCountry, selectImportFileStatus, selectImportStatus,
} from "../../../../store/import/importSelectors";
import { uploadImportFile, getImportState } from "../../../../store/import/importRequests";

import styles from './index.module.scss';

const ImportTopPanel = ({ children }) => {
  const [currentFile, setCurrentFile] = useState('');
  const dispatch = useTypedDispatch();
  const currentFileName = useSelector(importCurrentFile);
  const countryList = useSelector(importCountryList);
  const currentCountry = useSelector(importCurrentlySelectedCountry);
  const uploadStatus = useSelector(selectImportFileStatus);
  const status = useSelector(selectImportStatus) || '';

  const countryNames = countryList.map((country) => {
    return {
      name: country.name,
      id: country.id,
    }
  });

  // to use file here
  const handleFile = (file) => {
    setCurrentFile(file);
    dispatch(selectImportFile(file.name));
  }
  const handleCountryChange = (e) => {
    const countryId = e.target.value;

    const countryToSelect = countryList.find((country) => country.id === countryId);
    dispatch(selectImportCountry(countryToSelect));
  };

  const onUploadClick = (e) => {
    e.stopPropagation();
    const formData = new FormData();
    if (currentFile && currentCountry) {
      formData.append('importFile', currentFile);
      dispatch(uploadImportFile({ countryId: currentCountry.id, formData }))
        .finally(() => {
          dispatch(getImportState());
        });
    }
  }

  return (
    <Grid container spacing={2} className={styles.panel}>
      <Grid item xs={2}>
        <Dropdown
          disabled={!!status}
          valueChange={handleCountryChange}
          selectedValue={currentCountry?.id || 0}
          values={countryNames}
          label='Country'
          className={styles.countrySelect}
        />
      </Grid>
      <Grid item xs={4}>
        <FileUpload
          disabled={!!status}
          handleFile={handleFile}
          fullWidth
          accept={'.csv'}
          value={currentFileName}
          placeholder={'CSV file import'}
          icon={<FolderOpenIcon />}
        />
      </Grid>
      <Grid item xs={2} className={styles.uploadButtonContainer}>
        <Button
          disabled={!currentFile || !currentCountry}
          variant='contained'
          color={uploadStatus.loading ? 'secondary' : 'primary'}
          fullWidth
          onClick={onUploadClick}
        >
          {uploadStatus.loading ?
            'Stop upload' :
            'Upload file'
          }
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Grid container justifyContent='center'>
          {
            children
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ImportTopPanel;