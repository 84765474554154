import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { AsyncThunk } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../app/state';
import { isUserLoggedIn } from '../store/userStore/userSelectors';
import Loading from '../components/Loading/Loading';
import ChildrenElement from "../models/HelperModels/ChildrenElement";

export interface GeneralRequestProps {
  requestAction: AsyncThunk<any, any, any> | AsyncThunk<any, void, any>;
  requestArguments?: any[];
  children?: ChildrenElement;
  loadingElement?: ReactElement | null;
  shouldBeLoggedIn?: boolean;
}

enum Status {
  LOADING,
  LOADED,
}

export const GeneralRequest = ({
  children,
  requestAction,
  requestArguments = [],
  loadingElement = <Loading />,
  shouldBeLoggedIn = true,
}: GeneralRequestProps) => {
  const isMounted = useRef(true);
  const dispatch = useDispatch<AppDispatch>();
  const isLoggedIn = useSelector(isUserLoggedIn);
  const [requestStatus, setLoading] = useState(Status.LOADING);
  // request arguments are checked as a string so if you pass a map or an array it will be an [object Object]
  // all the time and changing arguments won't lead to a new request
  const requestArgumentsString = requestArguments.join(',');

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

  useEffect(() => {
    if (shouldBeLoggedIn && !isLoggedIn) {
      return;
    }

    setLoading(Status.LOADING);

    dispatch(requestAction.apply(null, requestArguments))
      .then(() => {
        if (isMounted.current) {
          setLoading(Status.LOADED);
        }
      });
  }, [isLoggedIn, requestArgumentsString]);


  if (requestStatus === Status.LOADING) {
    return loadingElement;
  }

  if (!isLoggedIn && shouldBeLoggedIn) {
    return loadingElement;
  }

  return (
    <>
      {
        children || null
      }
    </>
  );
};