import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../store/userStore/userSelectors";
import { DeviceStatus } from "../../../models/enums/DeviceStatus.enum";
import { selectCurrentConfiguration } from "../../../store/deviceConfigurationStore/deviceConfigurationSelectors";
import { selectCurrentDevice } from "../../../store/deviceStore/deviceSelectors";

const CanEditDevice = ({ children, ...parentProps }) => {
  const currentConfiguration = useSelector(selectCurrentConfiguration);
  const isHistorySelected = Boolean(currentConfiguration?.parentDeviceConfiguration);
  const selectedDevice = useSelector(selectCurrentDevice);
  const currentUser = useSelector(selectCurrentUser);

  const formDisabled = useMemo(() => {
      const configuration = selectedDevice?.configuration;
      const requesterId = configuration?.requesterId;
      const assigneeId = configuration?.assigneeId;
      const status = configuration?.status || DeviceStatus.NEW;
      const isNewlyCreated = !selectedDevice?.id;

      if (Boolean(isHistorySelected)) {
        return true;
      }

      switch (status) {
        case DeviceStatus.NEW:
          return requesterId !== currentUser.id && !isNewlyCreated;
        case DeviceStatus.REQUESTED:
          return assigneeId !== currentUser.id;
        case DeviceStatus.REJECTED:
          return requesterId !== currentUser.id;
        case DeviceStatus.APPROVED:
          // TODO: add approved to active transition
          return true;
        case DeviceStatus.ACTIVE:
          return !((configuration?.isDraft === false) || (configuration?.isDraft && requesterId === currentUser.id));
        default:
          return true;
      }
    },
    [isHistorySelected, selectedDevice, currentUser]);

  return (
    <>
      {
        React.Children.map(children, child => {
          const props = {
            ...child?.props,
            ...parentProps,
            formDisabled,
          };

          return child ? React.cloneElement(child, props) : null;
        })
      }
    </>
  )
}

export default CanEditDevice;