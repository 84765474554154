import HorizontalPanel from "../../../components/HorizontalPanel";
import BreadcrumbsContainer from "../../Shared/BreadcrumbsContainer";
import { BuildingDetails } from "../../BuildingDetails";
import FloorListPanel from "../FloorListPanel";
import MapPreview from "../../Shared/MapPreview";

const BuildingCentralArea = () => {
  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <BreadcrumbsContainer items={['locations', 'buildings']} />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <BuildingDetails />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <FloorListPanel />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <MapPreview />
      </HorizontalPanel>
    </HorizontalPanel>
  );
}

export default BuildingCentralArea;