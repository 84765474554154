import ChannelsTable from './ChannelsTable/ChannelsTable';
import ChannelDetails from './ChannelDetails/ChannelDetails';
import HorizontalPanel from "../../../components/HorizontalPanel";

function TechnologiesActionPanel() {
  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <ChannelsTable />
      <ChannelDetails />
    </HorizontalPanel>
  );
}

export default TechnologiesActionPanel;
