import NoMapImage from '../images/mapUploadPlaceholder.svg';
import { Grid, Typography } from "@material-ui/core";
import classes from './index.module.scss';

const NoMapContainer = ({ title }) => (
  <Grid
    container
    direction='column'
    alignItems='center'
    justifyContent='center'
    className={classes.noItemsContainer}
  >
    <img src={NoMapImage} alt={title} className={classes.image} />
    <Typography
      variant='body1'
      className={classes.text}
    >
      {title}
    </Typography>
  </Grid>
);

export default NoMapContainer;