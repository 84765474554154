import React from 'react';
import cn from 'classnames';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { CheckPermission } from '../../../requests/CheckPermission';
import { NavigationMenuType } from './NavigationMenuType';
import classes from './index.module.scss';

interface NavigationMenuProps {
  pagesArray: NavigationMenuType[],
  menuClasses?: {
    menu?: string,
    listItem?: string,
    listItemText?: string,
    primaryText?: string,
    secondaryText?: string,
    selected?: string,
  },
}

const NavigationMenu = (props: NavigationMenuProps) => {
  const { pagesArray, menuClasses = {} } = props;
  const location = useLocation();

  return (
    <List component='nav' className={cn(menuClasses.menu, classes.menu)}>
      {pagesArray.map((page) => {
          let listItem = (
            <ListItem
              button
              component={Link}
              selected={location.pathname.includes(page.route)}
              to={page.route}
              key={page.route}
              classes={{
                root: cn(menuClasses.listItem, classes.listItem),
                selected: cn(menuClasses.selected, classes.selected)
              }}
            >
              {page.icon}
              <ListItemText
                primary={page.title}
                secondary={page.secondaryText}
                classes={{
                  root: cn(menuClasses.listItemText, classes.listItemText),
                  primary: cn(menuClasses.primaryText, classes.primaryText),
                  secondary: cn(menuClasses.secondaryText, classes.secondaryText),
                }}
              />
            </ListItem>
          );
          if (page.nestedMenu) {
            listItem = (
              <React.Fragment key={page.route}>
                {listItem}
                {page.nestedMenu}
              </React.Fragment>
            );
          }
          if (page.permissionName) {
            listItem = (
              <CheckPermission permissionName={page.permissionName} key={page.route}>
                {listItem}
              </CheckPermission>
            );
          }
          return listItem;
        },
      )}
    </List>
  );
};

export default NavigationMenu;