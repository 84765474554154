import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { useTypedDispatch } from "../../../app/state";
import { pagePathGenerator } from "../../../routes/pagePathGenerator";
import MenuListWithImage from "../../../components/MenuListWithImage";
import {
  selectBuildingsByLocationId,
  selectCurrentBuilding,
  selectCurrentBuildingId,
} from "../../../store/buildingStore/buildingsSelectors";
import { selectCurrentLocationId } from "../../../store/locationStore/locationSelectors";

const BuildingsList = () => {
  const history = useHistory();
  const currentLocationId = useSelector(selectCurrentLocationId)
  const selectedBuildingId = useSelector(selectCurrentBuildingId);
  const selectedBuilding = useSelector(selectCurrentBuilding);
  const allBuildings = useSelector(selectBuildingsByLocationId(currentLocationId));

  const navigateToLocation = () => {
    const url = pagePathGenerator.LOCATION_PAGE({
      buildingId: -1,
      locationId: currentLocationId,
    });

    history.replace(url);
  }

  const selectBuildingHandler = (buildingId?: number, shouldReplace = false) => {
    const url = pagePathGenerator.BUILDING_PAGE({
      buildingId,
      locationId: currentLocationId,
    });

    shouldReplace ? history.replace(url) : history.push(url);
  }

  useEffect(() => {
    // if wrong building id is in url
    if (!selectedBuildingId || (selectedBuildingId && !selectedBuilding)) {
      const first = allBuildings[0];
      if (first) {
        selectBuildingHandler(first.id, true);
      } else {
        navigateToLocation();
      }
    }
  }, [selectedBuildingId, selectedBuilding, allBuildings]);

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title="Building list" />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <MenuListWithImage
          items={allBuildings}
          selectItem={selectBuildingHandler}
          selectedItemId={selectedBuildingId}
        />
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default BuildingsList;