import React, { useCallback } from 'react';
import { extractNumber, extractUnits } from "../../../helpers/converters/units";
import OutlinedInput, { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import InputAdornment from "@material-ui/core/InputAdornment";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText, InputLabel } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

import classes from './index.module.scss';

type UnitsInputProps = OutlinedInputProps & {
  unitList: string[],
  defaultUnit?: string,
  defaultValue?: string,
  helperText?: React.ReactNode,
  size?: string,
  variant?: 'standard' | 'outlined' | 'filled',
}

const UnitsInput = ({
  onChange,
  helperText,
  name,
  value,
  label,
  defaultUnit,
  defaultValue,
  size = 'small',
  variant = 'outlined',
  autoFocus,
  unitList,
  required,
  ...props
}: UnitsInputProps) => {
  const inputRef = React.createRef<HTMLInputElement>();

  const modifyValue = useCallback((value) => {
    const unit = extractUnits(value);
    const amount = extractNumber(value);

    if (!unit) {
      return value;
    }

    // TODO: check if many
    // find units case-sensitive
    let currentUnits = unitList.filter((u) => u.startsWith(unit));
    // if after unit something is added
    const extraUnit = unitList.find((u) => unit.startsWith(u) && unit !== u);

    // if there is no units found, try case-insensitive
    if (currentUnits.length === 0) {
      currentUnits = unitList.filter((u) => u.toLowerCase().startsWith(unit.toLowerCase()));
    }

    if (extraUnit) {
      return `${amount} ${extraUnit}`;
    }

    if (currentUnits.length === 0 || currentUnits.length > 1) {
      return value.replace(unit, '');
    }

    return `${amount} ${currentUnits[0]}`;
  }, [unitList]);

  const modifiedOnChange = useCallback((e) => {
    const newValue = e.target.value;

    // deletion
    if (newValue.length < (value as string).length) {
      const newUnit = extractUnits(newValue);
      const unit = extractUnits(value as string);

      if (newUnit.length < unit.length) {
        e.target.value = e.target.value.replace(newUnit, '');
      }
    }

    if (newValue.length > (value as string).length) {
      const newUnit = extractUnits(newValue);
      const unit = extractUnits(value as string);

      // units were changed
      if (newUnit !== unit) {
        // units were added or updated
        if ((!unit && newUnit) || (unit && newUnit)) {
          e.target.value = modifyValue(newValue);
        }
      }
    }

    onChange && onChange(e);
  }, [name, value]);

  const tooltipText = `Possible units here: ${unitList.join(', ')}`;

  const onBlur = (e) => {
    const value = e.target.value;
    const newUnit = extractUnits(value);
    const newValue = extractNumber(value) || 0;

    if (typeof defaultValue !== 'undefined' && (newValue === 0 && newUnit === '')) {
      e.target.value = defaultValue;
    } else {
      e.target.value = `${newValue} ${newUnit || defaultUnit || unitList[0]}`;
    }

    onChange && onChange(e);
    props.onBlur && props.onBlur(e);
  }

  return (
    <FormControl
      variant="outlined"
      fullWidth
      required={required}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <OutlinedInput
        {...props}
        inputRef={inputRef}
        name={name}
        label={label}
        value={value}
        autoFocus={autoFocus}
        onChange={modifiedOnChange}
        onBlur={onBlur}
        endAdornment={(
          <InputAdornment position='end'>
            <Tooltip title={tooltipText}>
              <InfoOutlinedIcon color='secondary' className={classes.tooltipIcon} />
            </Tooltip>
          </InputAdornment>
        )}
      />
      {
        helperText && (
          <FormHelperText error id={name}>
            {helperText}
          </FormHelperText>
        )
      }
    </FormControl>
  )
}

UnitsInput.UNIT_TEMPLATE = {
  FREQUENCY: ['Hz', 'kHz', 'MHz', 'GHz'],
  DISTANCE: ['cm', 'm', 'km'],
  SHORT_DISTANCE: ['sm', 'm'],
  EXTENDED_POWER: ['dBm', 'mW', 'W'],
}

export default UnitsInput;