import * as yup from "yup";
import { convertToHz } from "../../../helpers/converters/frequency";

const validationSchema = yup.object({
  frequencyFrom: yup.string(),
  frequencyTo: yup.string().when(
    'frequencyFrom',
    (frequencyFrom, schema) => schema.test({
      test: (frequencyTo) => {
        const frequencyFromHz = convertToHz(frequencyFrom);
        const frequencyToHz = convertToHz(frequencyTo);

        return !frequencyTo || !frequencyFrom || frequencyFromHz < frequencyToHz;
      },
      message: "Wrong frequency range",
    })
  ),
  technologyConfigurationIdList: yup.array(),
  wirelessSystemIdList: yup.array(),
});

export default validationSchema;