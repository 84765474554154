import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentFloor,
} from "../../../store/floorStore/floorSelectors";
import { getFloorByQuery } from "../../../store/floorStore/floorRequests";
import { useTypedDispatch } from "../../../app/state";
import { setCurrentBuildingId } from "../../../store/buildingStore/buildingsState";
import { selectCurrentBuilding } from "../../../store/buildingStore/buildingsSelectors";
import { setCurrentLocationId } from "../../../store/locationStore/locationState";
import { selectCurrentDevice } from "../../../store/deviceStore/deviceSelectors";
import { setCurrentWirelessSystemId } from "../../../store/wirelessSystemStore/wirelessSystemState";
import { setCurrentConfigurationId } from "../../../store/deviceConfigurationStore/deviceConfigurationState";
import { selectCurrentLocation } from "../../../store/locationStore/locationSelectors";
import { setCurrentCountryId } from "../../../store/countryStore/countryState";
import FloorModel from "../../../models/FloorModel";
import { setCurrentFloorId } from "../../../store/floorStore/floorState";
import { requestLocationByQuery } from "../../../store/locationStore/locationRequests";
import LocationModel from "../../../models/LocationModel";

const DeviceCurrentIdsSetter = () => {
  const dispatch = useTypedDispatch();
  const currentDevice = useSelector(selectCurrentDevice);
  const currentFloor = useSelector(selectCurrentFloor);
  const currentBuilding = useSelector(selectCurrentBuilding);
  const currentLocation = useSelector(selectCurrentLocation);

  useEffect(() => {
    if (currentDevice) {
      dispatch(setCurrentWirelessSystemId(currentDevice.configuration.wirelessSystemId));

      // if floor device is selected
      dispatch(getFloorByQuery({ mapId: currentDevice.mapId }))
        .then((action) => {
          const floorList = action?.payload as FloorModel[];
          const floor = floorList[0];
          dispatch(setCurrentFloorId(floor?.id));
        });

      // if location device is selected
      dispatch(requestLocationByQuery({ mapId: currentDevice?.mapId }))
        .then((action) => {
          const locationList = action?.payload as LocationModel[];
          const location = locationList[0];
          if (location) {
            dispatch(setCurrentLocationId(location.id));
          } else {
            // location will be set by floor (floor device is selected)
          }
        })
    } else {
      dispatch(setCurrentWirelessSystemId(1));
    }
    // reset history if device has changed
    dispatch(setCurrentConfigurationId(null));
  }, [currentDevice]);

  useEffect(() => {
    if (currentFloor) {
      dispatch(setCurrentBuildingId(currentFloor.buildingId));
    }
  }, [currentFloor]);

  useEffect(() => {
    if (currentBuilding) {
      dispatch(setCurrentLocationId(currentBuilding.locationId));
    }
  }, [currentBuilding]);

  useEffect(() => {
    if (currentLocation) {
      dispatch(setCurrentCountryId(currentLocation.countryId));
    }
  }, [currentLocation]);

  return null;
}

export default DeviceCurrentIdsSetter;