import { useState } from "react";
import { AppBar, Divider, Tab, Tabs } from "@material-ui/core";
import ChildrenElement from "../../models/HelperModels/ChildrenElement";
import HorizontalPanel from "../HorizontalPanel";
import TabPanel from "./TabPanel";

import classes from './index.module.scss';

interface TabItem {
  label: string | ChildrenElement,
  id?: number | string,
  children: ChildrenElement,
}

interface TabsContentProps {
  items: TabItem[],
}

const TabsContent = ({ items }: TabsContentProps) => {
  const [value, setValue] = useState(items[0]?.id || 0);

  const onChange = (e, newValue) => setValue(newValue);

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <AppBar
          position="static"
          color='transparent'
          className={classes.tabsAppBar}
        >
          <Tabs
            value={value}
            onChange={onChange}
            aria-label='simple-tabs'
            className={classes.tab}
            indicatorColor='primary'
            classes={{
              indicator: classes.indicator,
            }}
          >
            {
              items.map((tabItem, index) => (
                <Tab
                  label={tabItem.label}
                  key={tabItem.id || index}
                  id={`simple-tab-${tabItem.id || index}`}
                  aria-controls={`simple-tabpanel-${tabItem.id || index}`}
                />
              ))
            }
          </Tabs>
        </AppBar>
        <Divider />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        {
          items.map((tabItem, index) => (
              <TabPanel
                active={(tabItem.id || index) === value}
                key={tabItem.id || index}
              >
                {
                  tabItem.children
                }
              </TabPanel>
            )
          )
        }
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default TabsContent;