import { useState } from "react";
import { useSelector } from "react-redux";
import { selectImportStatus } from "../../../../store/import/importSelectors";
import HorizontalPanel from "../../../../components/HorizontalPanel";
import { Toolbar } from "../../../../app/components";
import ImportTopPanel from "../ImportTopPanel";
import ImportLog from "../ImportLog";
import { Dropdown } from "../../../../app/components/Dropdown";

import classes from './index.module.scss';

const ImportContainer = () => {
  const status = useSelector(selectImportStatus) || '';
  const [logLevel, setStatusFilter] = useState(0);
  const showDropdown = ['uploaded', 'validated', 'nothing', 'error', 'imported'].includes(status);

  const statuses = [
    {
      name: 'All',
      id: 0,
    },
    {
      name: 'Error',
      id: 1,
    },
    {
      name: 'Warning',
      id: 2,
    },
    {
      name: 'Info',
      id: 3,
    },
  ];

  const onChange = (e) => {
    setStatusFilter(e.target.value);
  }

  return (
    <>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Import tool' />
        <ImportTopPanel>
          {
            showDropdown && (
              <Dropdown
                values={statuses}
                label="Log level"
                valueChange={onChange}
                selectedValue={logLevel}
                className={classes.dropdown}
              />
            )
          }
        </ImportTopPanel>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
          <Toolbar title='Log area' />
        </HorizontalPanel>
        <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
          <ImportLog logLevel={logLevel} />
        </HorizontalPanel>
      </HorizontalPanel>
    </>
  )
}

export default ImportContainer;