import React from "react";
import { useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorIcon from '@material-ui/icons/Error';
import TabsContent from "../../../components/TabsContent";
import RequestDeviceHistory from "../../../requests/DeviceHistory";
import DeviceHistory from "../../../components/DeviceHistory/DeviceHistory";
import { selectCurrentConfiguration } from "../../../store/deviceConfigurationStore/deviceConfigurationSelectors";
import { useTypedDispatch } from "../../../app/state";
import { setCurrentConfigurationId } from "../../../store/deviceConfigurationStore/deviceConfigurationState";
import { setCurrentWirelessInterfaceId } from "../../../store/deviceStore/deviceState";
import { selectCurrentDeviceId } from "../../../store/deviceStore/deviceSelectors";
import WirelessFormAggregator from "../WirelessFormAggregator";

type DeviceRightPanelProps = {
  formDisabled?: boolean;
  onUpdate: () => any;
}

const DeviceRightPanel = (props: DeviceRightPanelProps) => {
  const dispatch = useTypedDispatch();
  const currentConfiguration = useSelector(selectCurrentConfiguration);
  const isHistoryEnabled = currentConfiguration && !!currentConfiguration.parentDeviceConfiguration;
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const isCreateMode = currentDeviceId === -1;

  const onHistoryReset = () => {
    dispatch(setCurrentConfigurationId(null));
    dispatch(setCurrentWirelessInterfaceId(null));
  }

  return (
    <TabsContent
      items={[
        {
          label: 'Configuration',
          children: isCreateMode ? null : <WirelessFormAggregator {...props} />
        },
        {
          label: isHistoryEnabled ? (
              <span>
                History
                <Tooltip title='History mode is enabled. All controls are locked'>
                  <ErrorIcon
                    htmlColor='red'
                    fontSize='small'
                    style={{ marginLeft: '4px', verticalAlign: 'middle' }}
                    onClick={(e) => {
                      e.stopPropagation()
                      onHistoryReset();
                    }}

                  />
                </Tooltip>
              </span>
            ) :
            'History',
          children: isCreateMode ? null : (
            <RequestDeviceHistory>
              <DeviceHistory />
            </RequestDeviceHistory>
          )
        }
      ]}
    />
  )
}

export default DeviceRightPanel;