import { WirelessSystemModel } from "../../app/types";
import HorizontalPanel from "../HorizontalPanel";
import { Toolbar } from "../../app/components";
import { IconButton } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import MenuList from "../MenuList";

interface WirelessSystemMenuProps {
  items?: WirelessSystemModel[],
  selectedItemId?: number | null,
  onCreateNew?: () => any,
  onChoose: (wirelessSystemId: number) => any,
  showCreate?: boolean,
}

const WirelessSystemMenu = (props: WirelessSystemMenuProps) => {
  const { items = [], onCreateNew = () => {}, onChoose, selectedItemId, showCreate = true } = props;

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Wireless systems'>
          {
            showCreate && (
              <IconButton
                component='span'
                color='secondary'
                size='small'
                onClick={onCreateNew}
              >
                <AddCircleOutline />
              </IconButton>
            )
          }
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <MenuList
          items={items}
          selectedItemId={selectedItemId ? selectedItemId : undefined}
          selectItem={onChoose}
        />
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default WirelessSystemMenu;