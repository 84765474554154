import { TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getWorkflowUserList } from "../../../store/userListStore/userListSelectors";

interface AssigneeInputProps {
  id?: number;
}

const AssigneeInput = ({ id }: AssigneeInputProps) => {
  const managerList = useSelector(getWorkflowUserList) || [];
  const selectedUser = managerList.find((user) => user.id === id);
  const name = id ? selectedUser?.profile?.fullName : 'Unassigned';

  return (
    <TextField
      fullWidth
      label='Frequency manager'
      variant='outlined'
      name='assignee'
      value={name}
      disabled={true}
    />
  );
}

export default AssigneeInput;