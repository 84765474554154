import classNames from "classnames";
import { Grid } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import classes from "./ModalPageLayout.module.scss";
import ChildrenElement from "../../models/HelperModels/ChildrenElement";

export default function ModalPageLayout(props: {
  children?: ChildrenElement;
  classes?: {
    container?: string,
    paper?: string,
  };
}) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classNames(classes.root, props?.classes?.container)}
    >
      <Paper elevation={2} className={props?.classes?.paper}>{props.children}</Paper>
    </Grid>
  );
}
