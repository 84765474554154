import { createSelector } from "@reduxjs/toolkit";
import { get } from "lodash/fp";
import { RootState } from "../../app/state";
import { buildingAdapter } from "./buildingsState";

const selectRoot = (state: RootState) => state;
export const selectBuildingsSlice = (state: RootState) => state.buildingsList;

const buildingsAdapterSelectors = buildingAdapter.getSelectors(selectBuildingsSlice)

export const selectAllBuildings = buildingsAdapterSelectors.selectAll;

export const selectAllBuildingsSorted = createSelector(
  selectAllBuildings,
  (buildingList) => buildingList.sort((a, b) => a.name.localeCompare(b.name))
);

export const selectBuildingsByLocationId = (locationId?: number | null) => createSelector(
  selectAllBuildings,
  (buildingList) => locationId ? buildingList.filter((building) => building.locationId === locationId) : buildingList
);

export const selectBuildingById = buildingsAdapterSelectors.selectById;

export const selectCurrentBuildingId = createSelector(
  selectBuildingsSlice,
  get('selectedBuildingId')
);

export const selectCurrentBuilding = createSelector(
  selectRoot,
  selectCurrentBuildingId,
  (store, id ) => (id && selectBuildingById(store, id)) || null,
);

export const selectAllBuildingLoadingState = createSelector(
  selectBuildingsSlice,
  get('loadAllStatus')
);

export const selectOtherBuildingLoadingState = createSelector(
  selectBuildingsSlice,
  get('otherLoadings')
);