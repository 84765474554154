import { GeneralRequest } from "../../../requests/GeneralRequest";
import { getAllWirelessSystems } from "../../../store/wirelessSystemStore/wirelessSystemRequests";
import { requestRequestersList } from "../../../store/userListStore/userListRequests";
import { requestLocationByQuery } from "../../../store/locationStore/locationRequests";
import { requestAllCountries } from "../../../store/countryStore/countryRequests";
import {
  requestAllTechnologyConfigurations
} from "../../../store/technologyConfigurationStore/technologyConfigurationRequests";
import SearchDialogForm, { SearchDialogFormProps } from "./SearchDialogForm";
import { getAllFloors } from "../../../store/floorStore/floorRequests";

const SearchDialog = (props: SearchDialogFormProps) => {
  return (
    <GeneralRequest requestAction={getAllWirelessSystems} loadingElement={null}>
      <GeneralRequest requestAction={requestRequestersList} loadingElement={null}>
        <GeneralRequest requestAction={requestAllCountries} loadingElement={null}>
          <GeneralRequest requestAction={requestLocationByQuery} loadingElement={null}>
            <GeneralRequest
              requestAction={requestAllTechnologyConfigurations}
              loadingElement={null}
            >
              <GeneralRequest requestAction={getAllFloors}>
                <SearchDialogForm
                  {...props}
                />
              </GeneralRequest>
            </GeneralRequest>
          </GeneralRequest>
        </GeneralRequest>
      </GeneralRequest>
    </GeneralRequest>
  )
}

export default SearchDialog;