import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { Grid, IconButton, TextField } from "@material-ui/core";
import { Cancel, Save } from "@material-ui/icons";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import UnitsInput from "../../../components/TextFields/UnitsInput";
import { selectCurrentWirelessInterface } from "../../../store/deviceStore/deviceSelectors";
import { useTypedDispatch } from "../../../app/state";
import { updateWirelessInterface } from "../../../store/deviceStore/deviceRequests";
import ChannelDetails from "../ChannelDetails";
import {
  selectTechnologyConfigurationById
} from "../../../store/technologyConfigurationStore/technologyConfigurationSelectors";
import ChannelModel from "../../../models/ChannelModel";

import classes from './index.module.scss';
import ChannelListDialog from "../ChannelListDialog";

export interface WirelessInterfaceFormProps {
  formDisabled?: boolean;
  onUpdate: () => any;
}

const WirelessInterfaceForm = ({
  formDisabled = false,
  onUpdate,
}: WirelessInterfaceFormProps) => {
  const dispatch = useTypedDispatch();
  const currentWirelessInterface = useSelector(selectCurrentWirelessInterface);
  const selectedTechnologyConfiguration = useSelector(selectTechnologyConfigurationById(currentWirelessInterface?.technologyConfigurationId));
  const availableChannels = selectedTechnologyConfiguration?.channels || [];
  const currentChannels = currentWirelessInterface?.channels || [];
  const currentChannelIdList = currentChannels.map((channel) => channel.id);

  const wirelessInterfaceFormik = useFormik({
    initialValues: {
      bandwidth: selectedTechnologyConfiguration?.bandwidth || '',
      EIRP: currentWirelessInterface?.EIRP || '',
    },
    onSubmit: () => {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const channelsInitialValues = {};

  currentChannels.forEach((channel) => {
    const name = ('EIRP' + channel.id);
    channelsInitialValues[name] = channel?.WirelessInterfaceChannel?.EIRP || channel.EIRP;
  });

  const channelsFormik = useFormik({
    initialValues: channelsInitialValues,
    onSubmit: () => {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const isSaveDisabled = !channelsFormik.dirty && !wirelessInterfaceFormik.dirty;

  const onSaveClick = () => {
    onSaveWirelessInterface();
  }

  const onSaveWirelessInterface = (channelIdList?: number[]) => {
    if (!currentWirelessInterface) {
      return;
    }

    const getChannels = (channelsModel: (ChannelModel | undefined)[]) => {
      return channelsModel.map((channel) => {
        const EIRP = channelsFormik.values['EIRP' + channel?.id || ''] || undefined;
        return {
          channelId: channel?.id,
          EIRP,
        }
      })
    }

    const channelsModel = (channelIdList || currentChannelIdList)
      .map((id: number) => availableChannels.find((channel) => channel.id === id))
      .filter(Boolean);

    const data = {
      id: currentWirelessInterface.id,
      EIRP: wirelessInterfaceFormik.values.EIRP,
      channels: getChannels(channelsModel),
    }

    dispatch(updateWirelessInterface(data))
      .then(onUpdate);
  }

  const onCancelChanges = () => {
    wirelessInterfaceFormik.resetForm({
      values: wirelessInterfaceFormik.initialValues,
    });
    channelsFormik.resetForm({
      values: channelsFormik.initialValues,
    });
  }

  return (
    <>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title="Details">
          <IconButton
            disabled={formDisabled || isSaveDisabled}
            aria-label='save device changes'
            color='secondary'
            size='small'
            onClick={onSaveClick}
          >
            <Save />
          </IconButton>
          <IconButton
            disabled={formDisabled || isSaveDisabled}
            aria-label='cancel device changes'
            color='secondary'
            size='small'
            onClick={onCancelChanges}
          >
            <Cancel />
          </IconButton>
          <ChannelListDialog
            // reset channel state
            key={currentWirelessInterface?.id || 1}
            formDisabled={formDisabled}
            onSave={onSaveWirelessInterface}
          />
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel
        size={HorizontalPanel.SIZES.SMALL}
        spacing={2}
        noWrap={false}
        className={classes.formContainer}
        direction="row"
      >
        <Grid item xs={12}>
          <TextField
            label='Bandwidth'
            name='bandwidth'
            fullWidth
            disabled
            variant='outlined'
            value={wirelessInterfaceFormik.values?.bandwidth}
            onChange={() => {}}
          />
        </Grid>
        <Grid item xs={12}>
          <UnitsInput
            unitList={UnitsInput.UNIT_TEMPLATE.EXTENDED_POWER}
            label='EIRP'
            variant='outlined'
            name='EIRP'
            fullWidth
            value={wirelessInterfaceFormik.values?.EIRP}
            onChange={wirelessInterfaceFormik.handleChange}
            disabled={formDisabled}
            error={Boolean(wirelessInterfaceFormik?.errors?.EIRP)}
            helperText={wirelessInterfaceFormik?.errors?.EIRP}
          />
        </Grid>
      </HorizontalPanel>
      <ChannelDetails
        formDisabled={formDisabled}
        channelDetailsFormik={channelsFormik}
      />
    </>
  )
}

export default WirelessInterfaceForm;