import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentDevice,
  selectCurrentDeviceId,
  selectSearchDeviceList
} from "../../../store/deviceStore/deviceSelectors";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { IconButton } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import SearchDialog from "../SearchDialog";
import { useTypedDispatch } from "../../../app/state";
import { SearchFormValues } from "../SearchDialog/SearchDialogForm";
import MenuListWithImage from "../../../components/MenuListWithImage";
import { setCurrentDeviceId } from "../../../store/deviceStore/deviceState";
import DeviceIcon from "../../../components/DeviceIcon/DeviceIcon";
import DeviceModel from "../../../models/DeviceModel";

interface SearchLeftPanelProps {
  onSearch: (params: SearchFormValues) => void,
  searchValues: SearchFormValues,
}

const SearchLeftPanel = ({ onSearch, searchValues }: SearchLeftPanelProps) => {
  const dispatch = useTypedDispatch();
  const deviceList = useSelector(selectSearchDeviceList);
  const [isOpen, setIsOpen] = useState(deviceList.length === 0);
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const currentDevice = useSelector(selectCurrentDevice);

  const menuItemsList = deviceList.map((device) => {
    return {
      id: device?.id || 1,
      name: device?.name || '',
      icon: <DeviceIcon deviceConfiguration={device?.configuration || null} />
    }
  });

  const selectSearchDevice = (device: DeviceModel) => {
    if (!device || currentDeviceId === device.id) {
      return;
    }

    dispatch(setCurrentDeviceId(device.id));
  }

  useEffect(() => {
    const first = deviceList[0];
    if (!currentDevice && first) {
      selectSearchDevice(first);
    }
  }, [deviceList, currentDevice])

  const onSearchClicked = (params) => {
    setIsOpen(false);
    onSearch(params);
  }

  const onSelectDevice = (deviceId: number) => {
    const device = deviceList.find((device) => device?.id === deviceId);
    if (device) {
      selectSearchDevice(device);
    }
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Search Results'>
          <IconButton
            component="span"
            color="secondary"
            size="small"
            onClick={() => setIsOpen(true)}
          >
            <SearchIcon />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <MenuListWithImage
          selectItem={onSelectDevice}
          selectedItemId={currentDeviceId}
          items={menuItemsList}
        />
      </HorizontalPanel>
      <SearchDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSearch={onSearchClicked}
        searchValues={searchValues}
      />
    </HorizontalPanel>
  )
}

export default SearchLeftPanel;