import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../app/state';
import { isUserLoggedIn } from '../store/userStore/userSelectors';
import { getAllUserRoles } from '../store/userListStore/userListRequests';
import { selectRolesListStatus } from '../store/userListStore/userListSelectors';

export interface UserRoleListRequestProps {
  children: ReactElement,
}

export const UserRoleListRequest = ({children}: UserRoleListRequestProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const isLoggedIn = useSelector(isUserLoggedIn);
  const roleListStatus = useSelector(selectRolesListStatus);

  useEffect(() => {
    if (!roleListStatus?.loaded && isLoggedIn) {
      dispatch(getAllUserRoles());
    }
  }, [isLoggedIn]);

  if (!roleListStatus?.loading) {
    return children;
  }

  return null;
};