import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const DatePicker = (props) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
      autoOk
      format='dd/MM/yyyy'
      InputAdornmentProps={{ position: 'start' }}
      {...props}
    />
  </MuiPickersUtilsProvider>
);

export default DatePicker;