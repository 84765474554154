import { CheckPermission } from '../../../../requests/CheckPermission';
import { PERMISSIONS } from '../../../../store/userStore/permissions.enum';
import { GeneralRequest } from '../../../../requests/GeneralRequest';
import {
  getWorkflowListRequest,
} from '../../../../store/workflows/workflowRequests';
import { requestManagersList } from "../../../../store/userListStore/userListRequests";
import WorkflowList from '../WorkflowList';
import EditWorkflow from '../EditWorkflow';
import HorizontalPanel from "../../../../components/HorizontalPanel";
import { requestLocationByQuery } from "../../../../store/locationStore/locationRequests";
import { requestAllCountries } from "../../../../store/countryStore/countryRequests";

import classes from './index.module.scss';
import {
  requestAllTechnologyConfigurations
} from "../../../../store/technologyConfigurationStore/technologyConfigurationRequests";

const WorkflowPageContainer = () => {
  return (
    <CheckPermission permissionName={PERMISSIONS.WORKFLOW_ACTIONS}>
      <GeneralRequest requestAction={getWorkflowListRequest}>
        <GeneralRequest requestAction={requestManagersList}>
          <GeneralRequest requestAction={requestAllCountries}>
            <GeneralRequest requestAction={requestLocationByQuery}>
              <GeneralRequest requestAction={requestAllTechnologyConfigurations}>
                <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
                  <HorizontalPanel size={HorizontalPanel.SIZES.SMALL} className={classes.workflowList} noWrap>
                    <WorkflowList />
                  </HorizontalPanel>
                  <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
                    <EditWorkflow />
                  </HorizontalPanel>
                </HorizontalPanel>
              </GeneralRequest>
            </GeneralRequest>
          </GeneralRequest>
        </GeneralRequest>
      </GeneralRequest>
    </CheckPermission>
  );
};

export default WorkflowPageContainer;