import { useEffect } from "react";
import { useSelector } from "react-redux";
import { uniq } from "lodash/fp";
import { IconButton } from "@material-ui/core";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { useTypedDispatch } from "../../../../app/state";
import RegionModel from "../../../../models/RegionModel";
import { selectAllRegions } from "../../../../store/regionStore/regionSelectors";
import {
  selectAllTechnologyConfigurations,
  selectCurrentTechnologyConfiguration, selectCurrentTechnologyConfigurationId
} from "../../../../store/technologyConfigurationStore/technologyConfigurationSelectors";
import {
  setCurrentTechnologyConfigurationId
} from "../../../../store/technologyConfigurationStore/technologyConfigurationState";
import columns from './technologyConfigurationColumns';
import { truncateString } from "../../../../helpers/stringHelpers";
import { TableList } from "../../../../app/components/TableList";
import HorizontalPanel from "../../../../components/HorizontalPanel";
import { Toolbar } from "../../../../app/components";

import classes from './index.module.scss';

const TechnologyConfigurationList = () => {
  const dispatch = useTypedDispatch();
  const regionList: RegionModel[] = useSelector(selectAllRegions);
  const currentTechnologyConfigurationId = useSelector(selectCurrentTechnologyConfigurationId);
  const currentTechnologyConfiguration = useSelector(selectCurrentTechnologyConfiguration);
  const allTechnologyConfigurations = useSelector(selectAllTechnologyConfigurations);

  useEffect(() => {
    const first = allTechnologyConfigurations[0];
    if (!currentTechnologyConfiguration && currentTechnologyConfigurationId !== -1 && first) {
      dispatch(setCurrentTechnologyConfigurationId(first.id));
    }
  }, [currentTechnologyConfiguration, allTechnologyConfigurations]);

  const rows = allTechnologyConfigurations.map((technologyConfiguration) => {
    const countries = technologyConfiguration.countries;
    const countriesName = countries.map((country) => country.name);
    const selectedRegionList = uniq(countries.map((country) => regionList.find((region) => region.id === country.regionId)));
    const regionListNames = selectedRegionList.map((region) => region?.name);

    return {
      id: technologyConfiguration.id,
      technology: technologyConfiguration.name,
      region: truncateString(regionListNames.join(', '), 100),
      countries: truncateString(countriesName.join(', '), 200),
      bandwidth: technologyConfiguration.bandwidth,
    }
  });

  const onSelect = (id: number) => dispatch(setCurrentTechnologyConfigurationId(id));

  const onCreateNew = () => onSelect(-1);

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Technology Configurations'>
          <IconButton
            aria-label='add technology configurations'
            color='secondary'
            size='small'
            onClick={onCreateNew}
          >
            <AddCircleOutlineRoundedIcon />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} addScroll className={classes.list}>
        <TableList
          columns={columns}
          rows={rows}
          selectedId={currentTechnologyConfiguration?.id}
          selectRow={onSelect}
        />
      </HorizontalPanel>
    </HorizontalPanel>
  );
}

export default TechnologyConfigurationList;