import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { IconButton } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useTypedDispatch } from "../../../app/state";
import { setCurrentWirelessSystemId } from "../../../store/wirelessSystemStore/wirelessSystemState";
import { useSelector } from "react-redux";
import {
  selectAllWirelessSystems,
  selectCurrentWirelessSystemId,
} from "../../../store/wirelessSystemStore/wirelessSystemSelectors";
import MenuList from "../../../components/MenuList";
import { GeneralRequest } from "../../../requests/GeneralRequest";
import { getAllWirelessSystems } from "../../../store/wirelessSystemStore/wirelessSystemRequests";

const WirelessSystemSettingsList = () => {
  const dispatch = useTypedDispatch();
  const currentWirelessSystemId = useSelector(selectCurrentWirelessSystemId);
  const wirelessSystemList = useSelector(selectAllWirelessSystems)

  const onWirelessSystemSelect = (id: number) => {
    dispatch(setCurrentWirelessSystemId(id))
  }

  const onCreateNew = () => {
    onWirelessSystemSelect(-1);
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title="Wireless systems">
          <IconButton
            aria-label='add wireless system'
            color='secondary'
            size='small'
            onClick={onCreateNew}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <GeneralRequest requestAction={getAllWirelessSystems}>
          <MenuList
            selectItem={onWirelessSystemSelect}
            selectedItemId={currentWirelessSystemId}
            items={wirelessSystemList}
          />
        </GeneralRequest>
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default WirelessSystemSettingsList;