import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { Button, Divider, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { useTypedDispatch } from "../../../app/state";
import { selectCurrentLocation } from "../../../store/locationStore/locationSelectors";
import { selectCurrentlyEditPoint, selectEditedPoints } from "../../../store/mapPointsStore/mapPointSelectors";
import { getGeoPointsFromLocation, getMapPointsFromLocation } from "../../../helpers/mapHelpers";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import { PERMISSIONS_STATUS } from "../../../store/userStore/permissions.status.enum";
import { selectPermissions } from "../../../store/userStore/userSelectors";
import {
  clearMapPointState,
  deletePointByIndex,
  MapPointIdEnum,
  setSelectedMapPointId,
} from "../../../store/mapPointsStore/mapPointsState";
import { isEmpty } from "lodash/fp";
import { updateFloorPoints } from "../../../store/floorStore/floorRequests";
import { requestLocationByQuery } from "../../../store/locationStore/locationRequests";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { CheckPermission } from "../../../requests/CheckPermission";

import classes from './index.module.scss';

const validationSchema = yup.object({
  firstPointLongitude: yup.number().required('Required'),
  firstPointLatitude: yup.number().required('Required'),
  secondPointLongitude: yup.number().required('Required'),
  secondPointLatitude: yup.number().required('Required'),
  thirdPointLongitude: yup.number().required('Required'),
  thirdPointLatitude: yup.number().required('Required'),
});

export const LocationMapRightPanel = () => {
  const dispatch = useTypedDispatch();
  const currentLocation = useSelector(selectCurrentLocation);
  const currentlyEditedPoints = useSelector(selectEditedPoints);
  const mapRelativePoints = getMapPointsFromLocation(currentLocation);
  const geoPoints = getGeoPointsFromLocation(currentLocation);
  const permissions = useSelector(selectPermissions) || {};
  const hasEditPermission = permissions[PERMISSIONS.LOCATION_ACTIONS] === PERMISSIONS_STATUS.GRANTED && !!currentLocation?.mapId;
  const mapPointEditId = useSelector(selectCurrentlyEditPoint);

  const isMapRelativePointsTouched = ['', '', ''].some((_, index) => {
    const editedPoint = currentlyEditedPoints[index];
    const currentPoint = mapRelativePoints[index];

    return (editedPoint.removed && currentPoint) || (
      (editedPoint.x && editedPoint.x !== currentPoint?.x) &&
      (editedPoint.y && editedPoint.y !== currentPoint?.y)
    )
  });

  const pointsList = mapRelativePoints.map((point, index) => {
    const editedPoint = currentlyEditedPoints[index];

    // removed
    if (editedPoint.removed) {
      return undefined;
    }

    const result = {
      ...point,
      ...editedPoint,
    };

    return result.x && result.y && result;
  });

  const geoPointsFormik = useFormik({
    initialValues: {
      firstPointLongitude: geoPoints[0]?.longitude || '',
      firstPointLatitude: geoPoints[0]?.latitude || '',
      secondPointLongitude: geoPoints[1]?.longitude || '',
      secondPointLatitude: geoPoints[1]?.latitude || '',
      thirdPointLongitude: geoPoints[2]?.longitude || '',
      thirdPointLatitude: geoPoints[2]?.latitude || '',
    },
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onBlur = (name: string, minValue, maxValue) => (e) => {
    const currentValue = e.target.value;
    const floatValue = parseFloat(currentValue);
    if (isNaN(floatValue)) {
      geoPointsFormik.setFieldValue(name, '');
      return;
    }

    if (floatValue.toString() !== currentValue) {
      geoPointsFormik.setFieldValue(name, floatValue.toString());
      return;
    }

    if (floatValue < minValue) {
      geoPointsFormik.setFieldValue(name, minValue.toString());
      return;
    }

    if (floatValue > maxValue) {
      geoPointsFormik.setFieldValue(name, maxValue.toString());
    }
  }

  const onButtonClick = (pointId: number) => {
    if (pointsList[pointId]) {
      dispatch(deletePointByIndex(pointId));
    } else {
      dispatch(setSelectedMapPointId(pointId));
    }
  }

  const onSaveClick = async () => {
    const errors = await geoPointsFormik.validateForm();
    if (isEmpty(errors) && currentLocation && currentLocation.mapId) {
      const values = geoPointsFormik.values;
      const data = {
        id: currentLocation.mapId,
        firstPoint: {
          longitude: values.firstPointLongitude,
          latitude: values.firstPointLatitude,
          x: currentlyEditedPoints[0].removed ? 0 : currentlyEditedPoints[0]?.x || mapRelativePoints[0]?.x,
          y: currentlyEditedPoints[0].removed ? 0 : currentlyEditedPoints[0]?.y || mapRelativePoints[0]?.y,
        },
        secondPoint: {
          longitude: values.secondPointLongitude,
          latitude: values.secondPointLatitude,
          x: currentlyEditedPoints[1].removed ? 0 : currentlyEditedPoints[1]?.x || mapRelativePoints[1]?.x,
          y: currentlyEditedPoints[1].removed ? 0 : currentlyEditedPoints[1]?.y || mapRelativePoints[1]?.y,
        },
        thirdPoint: {
          longitude: values.thirdPointLongitude,
          latitude: values.thirdPointLatitude,
          x: currentlyEditedPoints[2].removed ? 0 : currentlyEditedPoints[2]?.x || mapRelativePoints[2]?.x,
          y: currentlyEditedPoints[2].removed ? 0 : currentlyEditedPoints[2]?.y || mapRelativePoints[2]?.y,
        },
      };

      let checkForPoints = true;

      if (!data.firstPoint.x || !data.firstPoint.y) {
        geoPointsFormik.setFieldError('firstPointLatitude', 'All points should be set on map');
        geoPointsFormik.setFieldError('firstPointLongitude', 'All points should be set on map');

        checkForPoints = false;
      }

      if (!data.secondPoint.x || !data.secondPoint.y) {
        geoPointsFormik.setFieldError('secondPointLatitude', 'All points should be set on map');
        geoPointsFormik.setFieldError('secondPointLongitude', 'All points should be set on map');

        checkForPoints = false;
      }

      if (!data.thirdPoint.x || !data.thirdPoint.y) {
        geoPointsFormik.setFieldError('thirdPointLatitude', 'All points should be set on map');
        geoPointsFormik.setFieldError('thirdPointLongitude', 'All points should be set on map');

        checkForPoints = false;
      }

      if (!checkForPoints) {
        return;
      }

      dispatch(updateFloorPoints(data))
        .then(() => {
          dispatch(requestLocationByQuery({}));
        })
    }
  };

  const onCancelClick = () => {
    dispatch(clearMapPointState());
    geoPointsFormik.setErrors({});
    geoPointsFormik.resetForm();
  };

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Map Scale Points'>
          <CheckPermission permissionName={PERMISSIONS.LOCATION_ACTIONS}>
            <IconButton
              disabled={!(isMapRelativePointsTouched || geoPointsFormik.dirty)}
              onClick={onSaveClick}
              color="secondary"
              size="small"
              aria-label="Save"
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              disabled={!(isMapRelativePointsTouched || geoPointsFormik.dirty)}
              onClick={onCancelClick}
              color="secondary"
              size="small"
              aria-label="Cancel"
            >
              <CancelIcon />
            </IconButton>
          </CheckPermission>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <Grid
          container
          className={classes.container}
        >
          <Grid
            container
            alignItems='center'
            spacing={2}
          >
            <Grid item xs={6}>
              <TextField
                label='Latitude'
                variant='outlined'
                name='firstPointLatitude'
                fullWidth
                required
                disabled={!hasEditPermission}
                value={geoPointsFormik.values.firstPointLatitude}
                onChange={geoPointsFormik.handleChange}
                onBlur={onBlur('firstPointLatitude', -90, 90)}
                helperText={geoPointsFormik.errors.firstPointLatitude}
                error={Boolean(geoPointsFormik.errors.firstPointLatitude)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label='Longitude'
                variant='outlined'
                name='firstPointLongitude'
                fullWidth
                required
                disabled={!hasEditPermission}
                value={geoPointsFormik.values.firstPointLongitude}
                onChange={geoPointsFormik.handleChange}
                onBlur={onBlur('firstPointLongitude', 0, 360)}
                helperText={geoPointsFormik.errors.firstPointLongitude}
                error={Boolean(geoPointsFormik.errors.firstPointLongitude)}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1' className={classes.pointTitle}>Point 1</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="secondary"
                variant={mapPointEditId === MapPointIdEnum.first ? 'outlined' : 'text'}
                onClick={() => onButtonClick(MapPointIdEnum.first)}
                disabled={!hasEditPermission}
              >
                {pointsList[0] ? 'Delete' : 'Set'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          className={classes.container}
        >
          <Grid
            container
            alignItems='center'
            spacing={2}
          >
            <Grid item xs={6}>
              <TextField
                label='Latitude'
                variant='outlined'
                name='secondPointLatitude'
                fullWidth
                required
                disabled={!hasEditPermission}
                value={geoPointsFormik.values.secondPointLatitude}
                onChange={geoPointsFormik.handleChange}
                onBlur={onBlur('secondPointLatitude', -90, 90)}
                helperText={geoPointsFormik.errors.secondPointLatitude}
                error={Boolean(geoPointsFormik.errors.secondPointLatitude)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label='Longitude'
                variant='outlined'
                name='secondPointLongitude'
                fullWidth
                required
                disabled={!hasEditPermission}
                value={geoPointsFormik.values.secondPointLongitude}
                onChange={geoPointsFormik.handleChange}
                onBlur={onBlur('secondPointLongitude', 0, 360)}
                helperText={geoPointsFormik.errors.secondPointLongitude}
                error={Boolean(geoPointsFormik.errors.secondPointLongitude)}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1' className={classes.pointTitle}>Point 2</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="secondary"
                variant={mapPointEditId === MapPointIdEnum.second ? 'outlined' : 'text'}
                onClick={() => onButtonClick(MapPointIdEnum.second)}
                disabled={!hasEditPermission}
              >
                {pointsList[1] ? 'Delete' : 'Set'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          className={classes.container}
        >
          <Grid
            container
            alignItems='center'
            spacing={2}
          >
            <Grid item xs={6}>
              <TextField
                label='Latitude'
                variant='outlined'
                name='thirdPointLatitude'
                fullWidth
                required
                disabled={!hasEditPermission}
                value={geoPointsFormik.values.thirdPointLatitude}
                onChange={geoPointsFormik.handleChange}
                onBlur={onBlur('thirdPointLatitude', -90, 90)}
                helperText={geoPointsFormik.errors.thirdPointLatitude}
                error={Boolean(geoPointsFormik.errors.thirdPointLatitude)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label='Longitude'
                variant='outlined'
                name='thirdPointLongitude'
                fullWidth
                required
                disabled={!hasEditPermission}
                value={geoPointsFormik.values.thirdPointLongitude}
                onChange={geoPointsFormik.handleChange}
                onBlur={onBlur('thirdPointLongitude', 0, 360)}
                helperText={geoPointsFormik.errors.thirdPointLongitude}
                error={Boolean(geoPointsFormik.errors.thirdPointLongitude)}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1' className={classes.pointTitle}>Point 3</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="secondary"
                variant={mapPointEditId === MapPointIdEnum.third ? 'outlined' : 'text'}
                onClick={() => onButtonClick(MapPointIdEnum.third)}
                disabled={!hasEditPermission}
              >
                {pointsList[2] ? 'Delete' : 'Set'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  );
}