import { get } from "lodash/fp";
import { RootState } from "../../app/state";
import { locationAdapter } from "./locationState";
import { createSelector } from "@reduxjs/toolkit";

const selectRoot = (state: RootState) => state;
export const selectLocationSlice = (state: RootState) => state.locationsList;

const locationAdapterSelectors = locationAdapter.getSelectors(selectLocationSlice);

export const selectAllLocations = locationAdapterSelectors.selectAll;

export const selectLocationById = locationAdapterSelectors.selectById;

export const selectAllLocationsSorted = createSelector(
  selectAllLocations,
  (locationList) => locationList.sort((a, b) => a.name.localeCompare(b.name))
);

export const selectLocationsByCountryId = (countryId: number) => createSelector(
  selectAllLocations,
  (locationList) => countryId ? locationList.filter((location) => location.countryId === countryId) : locationList
);

export const selectLocationsByCountryIdList = (countryList: number[] = []) => createSelector(
  selectAllLocations,
  (locationList) => countryList.length > 0 ? locationList.filter((location) => countryList.includes(location.countryId)) : locationList
);

export const selectCurrentLocationId = createSelector(
  selectLocationSlice,
  get('selectedLocationId')
);

export const selectCurrentLocation = createSelector(
  selectRoot,
  selectCurrentLocationId,
  (state, locationId) => locationId ? selectLocationById(state, locationId) : null
);

export const selectAllLocationsLoading = createSelector(
  selectLocationSlice,
  (state) => state.loadLocationsStatus
)

