import { createSelector } from "@reduxjs/toolkit";
import { get } from "lodash/fp";
import { RootState } from "../../app/state";
import { floorAdapter } from "./floorState";


const selectRoot = (state: RootState) => state;
export const selectFloorSlice = (state: RootState) => state.floorsList;

const floorAdapterSelectors = floorAdapter.getSelectors(selectFloorSlice);

export const selectAllFloors = floorAdapterSelectors.selectAll;

export const selectFloorsByBuildingId = (buildingId?: number | null) => createSelector(
  selectAllFloors,
  (floorList) => buildingId ? floorList.filter((floor) => floor.buildingId === buildingId) : floorList
);

export const selectFloorById = floorAdapterSelectors.selectById;

export const selectCurrentFloorId = createSelector(
  selectFloorSlice,
  get('selectedFloorId')
);

export const selectCurrentFloor = createSelector(
  selectRoot,
  selectCurrentFloorId,
  (state, floorId) => floorId ? selectFloorById(state, floorId) : null,
);

export const selectAllFloorsLoadingState = createSelector(
  selectFloorSlice,
  get('loadAllFloors')
);

export const selectFloorAllLoadingState = createSelector(
  selectFloorSlice,
  get('allLoadings')
);