import { createSelector } from '@reduxjs/toolkit';
import { importSlice } from './importState';
import { RootState } from "../../app/state";

export const selectImportState = (state: RootState) => state[ importSlice.name ];

export const importCurrentlySelectedCountry = createSelector(
  selectImportState,
  (importState) => importState.selectedCountry
);

export const importCountryList = createSelector(
  selectImportState,
  (importState) => importState.countryList
);

export const importCountryState = createSelector(
  selectImportState,
  (importState) => importState.countryRequestState
);

export const importCurrentFile = createSelector(
  selectImportState,
  (importState) => importState.selectedFile
);

export const selectImportFileStatus = createSelector(
  selectImportState,
  (importState) => importState.importFileState
);

export const selectImportStateStatus = createSelector(
  selectImportState,
  (importState) => importState.importStatusState
);

export const selectImportLog = createSelector(
  selectImportState,
  (importState) => importState.importLog
);

export const selectImportStatus = createSelector(
  selectImportState,
  (importState) => importState.importStatus
);

export const selectImportCode = createSelector(
  selectImportState,
  (importState) => importState.importCode
);