import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../app/api";
import DeviceModel from "../../models/DeviceModel";
import DeviceConfigurationModel from "../../models/DeviceConfigurationModel";
import { serializeError } from "../../app/shared";

const SLICE_NAME = 'deviceConfiguration';

export const loadDeviceHistory = createAsyncThunk(
  `${SLICE_NAME}/get-device-history`,
  async (id: number | undefined = undefined, { rejectWithValue }) => {
    if (!id) {
      return;
    }

    try {
      const history: { device: DeviceModel, history: DeviceConfigurationModel[]} = await request.get(`/device/${id}/history`);
      return history;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);