import { useSelector } from "react-redux";
import { isUserLoggedIn } from "../store/userStore/userSelectors";
import ConditionalRoute from "./ConditionalRoute";
import PAGES from "../pages";
import { App } from "../app/App";

const InnerRoutes = () => {
  const loggedIn = useSelector(isUserLoggedIn);

  return (
    <ConditionalRoute
      condition={() => loggedIn}
      redirectTo={PAGES.LOGIN_PAGE}
    >
      <App />
    </ConditionalRoute>
  )
}

export default InnerRoutes;