import { FormControl, IconButton, MenuItem, Select, } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../app/state";
import { getEditUser } from "../../../store/userActionStore/userActionSelector";
import { useSnackbar } from "../../../app/shared/snackbar-context/snackbar.context";
import { USER_STATUS } from "../../../store/userListStore/userStatus";
import { useShowModal } from "../../../app/shared/modal-context/modal.context";
import { ModalType } from "../../../app/shared/modal-context/constants";
import { deactivateUser, getTemporaryUserPassword } from "../../../store/userActionStore/userActionRequests";

import classes from "./index.module.scss";


const UserKebabMenu = () => {
  const dispatch = useDispatch<AppDispatch>();
  const editedUser = useSelector(getEditUser);
  const { openSnackbar } = useSnackbar();
  const { showModal } = useShowModal();

  const dispatchActivate = (isReset: boolean) => {
    if (!editedUser) {
      return;
    }

    dispatch(getTemporaryUserPassword({ userId: editedUser.id })).then(({ payload }) => {
      // @ts-ignore
      if (payload.status === 'OK') {
        openSnackbar({
          title: '',
          message: isReset ? 'Password reset link has been sent to the user\'s email' : 'Activation link has been sent to the user\'s email',
          severity: 'success',
        });
      } else {
        openSnackbar({
          title: 'Error',
          message: isReset ? 'Error sending a password reset link to the user\'s email' : 'Error sending an activation reset link to the user\'s email',
          severity: 'error',
        });
      }
    });
  }

  const onPasswordReset = () => {
    dispatchActivate(true);
  }

  const onActivateUser = () => {
    dispatchActivate(false);
  }

  const onPasswordResetClick = () => {
    showModal(ModalType.MODAL_GENERIC_CONFIRM, {
      title: 'Reset user password',
      text: 'You are going to reset the user\'s password. Are you sure?',
      confirmButtonHandler: onPasswordReset,
      confirmButtonText: 'Reset',
    });
  }

  const onActivateUserClick = () => {
    showModal(ModalType.MODAL_GENERIC_CONFIRM, {
      title: 'Activate user',
      text: 'You are going to activate the user. Activation link will be sent to the user\'s email. Are you sure?',
      confirmButtonHandler: onActivateUser,
      confirmButtonText: 'Activate',
    });
  }

  const onDeactivateClick = () => {
    if (!editedUser) {
      return;
    }
    showModal(ModalType.MODAL_GENERIC_CONFIRM, {
      title: 'Deactivate user',
      text: 'You are going to deactivate the user. Are you sure?',
      confirmButtonHandler: () => {
        dispatch(deactivateUser({ userId: editedUser.id }))
      },
      confirmButtonText: 'Deactivate',
    });
  }

  if (!editedUser) {
    return null;
  }

  return (
    <FormControl>
      <IconButton
        aria-label='open user menu'
        color='secondary'
        size='small'
      >
        <MoreVertIcon />
      </IconButton>
      <Select
        className={classes.select}
        value={-1}
      >
        {
          (editedUser?.status === USER_STATUS.ACTIVE) && (
            <MenuItem
              value={0}
              onClick={onPasswordResetClick}
              disabled={!editedUser}
            >
              Reset password
            </MenuItem>
          )
        }
        {
          (editedUser?.status === USER_STATUS.NEW || editedUser?.status === USER_STATUS.INACTIVE) && (
            <MenuItem
              value={1}
              onClick={onActivateUserClick}
              disabled={!editedUser}
            >
              Activate user
            </MenuItem>
          )
        }
        {
          (editedUser?.status === USER_STATUS.ACTIVE || editedUser?.status === USER_STATUS.ACTIVATION || editedUser?.status === USER_STATUS.NEW) && (
            <MenuItem
              value={2}
              onClick={onDeactivateClick}
              disabled={!editedUser}
            >
              Deactivate user
            </MenuItem>
          )
        }
      </Select>
    </FormControl>
  );
}

export default UserKebabMenu;