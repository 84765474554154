import { useSelector } from 'react-redux';
import NavigationMenu from '../../Shared/NavigationMenu';
import PAGES from '../../../pages';
import {
  selectUserList,
  selectNewUsers,
  selectActivationUsers,
  selectActiveUsers,
  selectInactiveUsers,
} from '../../../store/userListStore/userListSelectors';
import classes from './index.module.scss';
import { UserListRequest } from '../../../requests/UserListRequest';

const UserManagementFilters = () => {
  const allUsers = useSelector(selectUserList) || [];
  const newUsers = useSelector(selectNewUsers) || [];
  const activationUsers = useSelector(selectActivationUsers) || [];
  const activeUsers = useSelector(selectActiveUsers) || [];
  const inactiveUsers = useSelector(selectInactiveUsers) || [];
  const filters = [
    {
      title: 'All',
      secondaryText: allUsers.length.toString(),
      route: PAGES.USER_MANAGEMENT_ALL,
    },
    {
      title: 'New',
      secondaryText: newUsers.length.toString(),
      route: PAGES.USER_MANAGEMENT_NEW,
    },
    {
      title: 'Activation',
      secondaryText: activationUsers.length.toString(),
      route: PAGES.USER_MANAGEMENT_ACTIVATION,
    },
    {
      title: 'Active',
      secondaryText: activeUsers.length.toString(),
      route: PAGES.USER_MANAGEMENT_ACTIVE,
    },
    {
      title: 'Inactive',
      secondaryText: inactiveUsers.length.toString(),
      route: PAGES.USER_MANAGEMENT_INACTIVE,
    },
  ];

  return (
    <UserListRequest>
      <NavigationMenu
        pagesArray={ filters }
        menuClasses={ classes }
      />
    </UserListRequest>
  );
};

export default UserManagementFilters;