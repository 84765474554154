import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectCurrentFloor, selectCurrentFloorId } from "../../../store/floorStore/floorSelectors";
import {
  selectCurrentWirelessSystemId,
  selectWirelessSystemsByIdList
} from "../../../store/wirelessSystemStore/wirelessSystemSelectors";
import {
  selectDevicesByMapId,
  selectWirelessSystemsByMapId
} from "../../../store/deviceStore/deviceSelectors";
import { pagePathGenerator } from "../../../routes/pagePathGenerator";
import WirelessSystemMenu from "../../../components/WirelessSystemMenu";
import { GeneralRequest } from "../../../requests/GeneralRequest";
import { getAllWirelessSystems } from "../../../store/wirelessSystemStore/wirelessSystemRequests";
import { requestAllDevicesByQuery } from "../../../store/deviceStore/deviceRequests";
import { selectCurrentLocation, selectCurrentLocationId } from "../../../store/locationStore/locationSelectors";
import { isMapPointsSet } from "../../../helpers/mapHelpers";

const WirelessSystemList = () => {
  const history = useHistory();
  const currentFloorId = useSelector(selectCurrentFloorId);
  const currentLocationId = useSelector(selectCurrentLocationId);
  const currentFloor = useSelector(selectCurrentFloor);
  const currentLocation = useSelector(selectCurrentLocation);
  const currentModel = currentFloor || currentLocation;
  const currentMapId = currentModel?.mapId;
  const currentMap = currentModel?.map;
  const wirelessSystemIdList = useSelector(selectWirelessSystemsByMapId(currentMapId));
  const deviceList = useSelector(selectDevicesByMapId(currentMapId));
  const wirelessSystemList = useSelector(selectWirelessSystemsByIdList(wirelessSystemIdList));
  const currentWirelessSystemId = useSelector(selectCurrentWirelessSystemId);
  const isCreateMode = currentLocationId === -1 || currentFloorId === -1;
  const isCreateButtonAvailable = !isCreateMode && isMapPointsSet(currentMap);
  const shouldShowWirelessSystems = !isCreateMode && currentMapId;

  const navigateToWirelessSystem = (wirelessSystemId?: number) => {
    const device = deviceList.find((device) => {
      return device.configuration.wirelessSystemId === wirelessSystemId;
    });

    const deviceId = device?.id || -1;
    const url = pagePathGenerator.SINGLE_DEVICE_PAGE({
      deviceId,
      mapId: currentMapId,
    });

    history.push(url);
  }

  return (
    <GeneralRequest requestAction={getAllWirelessSystems}>
      {
        !!shouldShowWirelessSystems && (
          <GeneralRequest
            key={currentMapId}
            requestAction={requestAllDevicesByQuery}
            requestArguments={[{ mapId: currentMapId }]}
          >
            <WirelessSystemMenu
              items={wirelessSystemList}
              onCreateNew={() => navigateToWirelessSystem()}
              onChoose={navigateToWirelessSystem}
              selectedItemId={currentWirelessSystemId}
              showCreate={isCreateButtonAvailable}
            />
          </GeneralRequest>
        )
      }
    </GeneralRequest>
  );
}

export default WirelessSystemList;