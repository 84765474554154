import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import cn from "classnames";
import { selectGlobalSettings } from "../../../store/settingsStore/settingsSelectors";
import AddPropsToChildren from "../../../components/AddPropsToChildren";
import {
  getMapScale,
} from "../../../helpers/mapHelpers";
import { selectCurrentMap } from "../../../store/mapStore/mapSelectors";
import { filterDevicesWithoutMapPoint, filterPortableDevices, getDeviceRadius } from "../zoomMapHelpers";
import DeviceModel from "../../../models/DeviceModel";

import classes from './index.module.scss';
import ChildrenElement from "../../../models/HelperModels/ChildrenElement";

type InfluenceAreaData = {
  id: number;
  wirelessInterfaceId: number;
  isSelected: boolean;
  radiusInPx: number;
  x: number;
  y: number;
}

type InfluenceAreaProps = {
  children: ChildrenElement,
  deviceList: DeviceModel[],
  currentDeviceId?: number | null,
  influenceAreaEnabled?: boolean,
  mapChildren?: ChildrenElement,
}

const InfluenceArea = ({ children, deviceList, currentDeviceId, influenceAreaEnabled = true, mapChildren, ...props }: InfluenceAreaProps) => {
  const [scale, setScale] = useState(1);
  const [mapImageSize, setMapImageSize] = useState([0, 0]);
  const globalSettings = useSelector(selectGlobalSettings);
  const currentMap = useSelector(selectCurrentMap)
  const mapScale = getMapScale(currentMap);
  const { interferenceLevel = 0, powerLossCoefficient = 1 } = globalSettings;
  const portableDevices = deviceList.filter((device) => device.configuration.type === '1');
  const hasPortableDevices = portableDevices.length > 0;
  const deviceListToRender = deviceList
    .filter(filterDevicesWithoutMapPoint)
    .filter(filterPortableDevices);

  const dataToRender: InfluenceAreaData[] = [];

  const onZoom = (newScale) => {
    setScale(newScale);
  }

  deviceListToRender.forEach((device: DeviceModel) => {
    const point = device?.configuration?.mapPoint?.mapPoint;

    (device?.configuration?.wirelessInterfaces || [])
      .forEach((wirelessInterface) => {
        const radiusInM = getDeviceRadius(wirelessInterface, interferenceLevel, powerLossCoefficient);
        const radiusInPx = radiusInM * mapScale;

        dataToRender.push({
          id: device.id,
          wirelessInterfaceId: wirelessInterface.id,
          isSelected: device.id === currentDeviceId,
          radiusInPx,
          x: point?.x || 0,
          y: point?.y || 0,
        })
      })
  });

  useEffect(() => {
    console.log(`Map scale is ${mapScale}`);

    deviceList.forEach((device) => {
      device.configuration?.wirelessInterfaces?.forEach((wi) => {
        const radiusInM = getDeviceRadius(wi, interferenceLevel, powerLossCoefficient);

        const radiusInPx = radiusInM * mapScale;

        if (device.configuration?.mapPointId) {
          console.log('Device id: ', device.id, 'Technology: ', wi.technologyConfiguration?.name, ' Radius in px: ', radiusInPx);
        }
      });
    });
  }, []);

  const imageWidth = mapImageSize[0]
  const imageHeight = mapImageSize[1];

  const onMapSizeUpdate = (imageSize) => {
    setMapImageSize(imageSize);
  }

  const extraProps = {
    onZoomHandlers: [onZoom],
    onImageSizeUpdateHandlers: [onMapSizeUpdate],
    mapChildren: (
      <>
        {mapChildren}
        {
          hasPortableDevices && (
            <rect
              x={0}
              y={0}
              width={imageWidth}
              height={imageHeight}
              fill="rgba(128, 203, 196, 0.08)"
              className={cn(
                'device',
                classes.device,
                {
                  [classes.invisible]: !influenceAreaEnabled,
                }
              )}
            />
          )
        }
        {
          dataToRender.map((item) => {
            return (
              <circle
                key={item.wirelessInterfaceId}
                cx={item.x}
                cy={item.y}
                r={item.radiusInPx}
                stroke={item.isSelected ? 'rgba(0, 125, 255, 1)' : 'rgba(0, 125, 255, 0.2)'}
                strokeWidth={Math.min(2 / scale, 2)}
                fill='rgba(0, 125, 255, 0.4)'
                className={cn(
                  classes.device,
                  {
                    [classes.invisible]: !influenceAreaEnabled,
                    [classes.deviceSelected]: item.isSelected,
                    [classes.deviceNotSelected]: !item.isSelected,
                  }
                )}
              />
            )
          })
        }
      </>
    )
  }

  return (
    <AddPropsToChildren
      extraProps={extraProps}
      {...props}
    >
      {children}
    </AddPropsToChildren>
  )
}

export default InfluenceArea;
