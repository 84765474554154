import React from 'react';

const AdminLogo = () => (
  <>
    <g clipPath='url(#clip0)'>
      <path fillRule='evenodd' clipRule='evenodd'
            d='M31.1789 6.33498C30.6075 5.32396 29.5401 4.70772 28.3789 4.7184L15.8801 4.8333C14.7188 4.84398 13.6401 5.47994 13.0502 6.50164L6.70127 17.4983C6.1114 18.52 6.10001 19.7722 6.67139 20.7832L12.8213 31.6649C13.3927 32.676 14.46 33.2922 15.6213 33.2815L28.1201 33.1666C29.2814 33.1559 30.3601 32.52 30.95 31.4983L37.2989 20.5016C37.8888 19.4799 37.9002 18.2277 37.3288 17.2167L31.1789 6.33498ZM18.6979 24.7195C21.7907 26.5051 25.7763 25.3919 27.6001 22.2331C29.4238 19.0743 28.395 15.0661 25.3023 13.2804C22.2095 11.4948 18.2238 12.608 16.4001 15.7668C14.5763 18.9256 15.6051 22.9339 18.6979 24.7195Z'
            fill='white' fillOpacity='0.5' />
      <path
        d='M10.3386 34.2757C10.7132 30.1551 14.1681 27 18.3057 27H25.6943C29.8319 27 33.2868 30.1551 33.6614 34.2757L33.8017 35.8189C33.9082 36.9902 32.986 38 31.8099 38H12.1901C11.014 38 10.0918 36.9902 10.1983 35.8189L10.3386 34.2757Z'
        fill='white' />
      <circle cx='22' cy='19' r='5' fill='white' />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='44' height='44' fill='white' />
      </clipPath>
    </defs>
  </>
);

export default AdminLogo;