import { useSelector } from "react-redux";
import { selectCurrentDevice } from "../../../store/deviceStore/deviceSelectors";
import FilterListIcon from '@material-ui/icons/FilterList';
import { IconButton } from "@material-ui/core";
import useFilter from "../../../hooks/filter.hook";

const ApplySmartButton = () => {
  const currentDevice = useSelector(selectCurrentDevice);
  const { state, actions } = useFilter();
  const { smartFilterDeviceId } = state;
  const wirelessInterfaces = currentDevice?.configuration?.wirelessInterfaces || [];
  const hasNoChannels = wirelessInterfaces.every((wirelessInterface) => (wirelessInterface.channels || []).length === 0);
  const smartFilterButtonIsShown = smartFilterDeviceId !== currentDevice?.id && wirelessInterfaces.length > 0 && !hasNoChannels;

  const applySmartFilter = () => {
    if (currentDevice?.id) {
      actions.updateSmartFilterDeviceId(currentDevice.id);
    }
  }

  return (
    <IconButton
      disabled={!smartFilterButtonIsShown}
      aria-label='Apply smart filter'
      color='secondary'
      size='small'
      onClick={applySmartFilter}
      title='Apply smart filter'
    >
      <FilterListIcon />
    </IconButton>
  );
}

export default ApplySmartButton;