import { createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { WirelessSystemModel } from "../../app/types";
import { LoadingStatus, status } from "../../app/shared";
import {
  createNewWirelessSystem,
  deleteWirelessSystem,
  editWirelessSystem,
  getAllWirelessSystems
} from "./wirelessSystemRequests";

export const wirelessSystemAdapter = createEntityAdapter<WirelessSystemModel>({
  selectId: (wirelessSystem) => wirelessSystem.id,
  sortComparer: (a, b) => a.id - b.id,
});

type WirelessSystemState = {
  selectedWirelessSystemId?: number | null;
  loadAllStatus: LoadingStatus;
} & EntityState<WirelessSystemModel>

const initialState: WirelessSystemState = {
  selectedWirelessSystemId: null,
  loadAllStatus: status.default,
  ...wirelessSystemAdapter.getInitialState(),
};

export const wirelessSystemSlice = createSlice({
  name: 'wirelessSystemList',
  initialState,
  reducers: {
    setCurrentWirelessSystemId: (state, action) => {
      state.selectedWirelessSystemId = action.payload;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(createNewWirelessSystem.fulfilled, (state, action) => {
        if (!action.payload.error) {
          wirelessSystemAdapter.upsertOne(state, action.payload.wirelessSystem);
        }
      })
      .addCase(editWirelessSystem.fulfilled, (state, action) => {
        if (!action.payload.error) {
          wirelessSystemAdapter.upsertOne(state, action.payload.wirelessSystem);
        }
      })
      .addCase(deleteWirelessSystem.fulfilled, (state, action) => {
        if (!action.payload.error) {
          wirelessSystemAdapter.removeOne(state, action.payload.id);
        }
      })
      .addCase(getAllWirelessSystems.pending, (state) => {
        state.loadAllStatus = status.loading;
      })
      .addCase(getAllWirelessSystems.rejected, (state, action) => {
        state.loadAllStatus = status.error(action.error);
      })
      .addCase(getAllWirelessSystems.fulfilled, (state, action) => {
        wirelessSystemAdapter.setAll(state, action.payload);
        state.loadAllStatus = status.loaded;
      })
});

export const { setCurrentWirelessSystemId } = wirelessSystemSlice.actions;