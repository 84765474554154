import React, { useMemo, useCallback } from 'react';
import cn from 'classnames';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { isEmpty } from 'lodash/fp';
import { Grid, IconButton, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import Delete from '@material-ui/icons/Delete';
import {
  getEditStatus,
  getEditUser,
  getEditUserRoleIds,
  getEditUserRoles,
  isNewUnsavedUser,
  isUserEditMode,
} from '../../../store/userActionStore/userActionSelector';
import { Toolbar } from '../../../app/components';
import { Dropdown } from '../../../app/components/Dropdown';
import { AppDispatch } from '../../../app/state';
import {
  startEditUser,
  clearEditUserError,
  deleteRole,
  setRoles,
} from '../../../store/userActionStore/userActionState';
import Multiselect from '../../../components/Multiselect';
import {
  selectRoleListTypes,
  selectRolesList,
  selectUserList,
} from '../../../store/userListStore/userListSelectors';
import { createAUser, deleteUser, updateUser } from '../../../store/userActionStore/userActionRequests';
import { USER_STATUS } from '../../../store/userListStore/userStatus';
import { useShowModal } from '../../../app/shared/modal-context/modal.context';
import { ModalType } from '../../../app/shared/modal-context/constants';
import UserKebabMenu from "../UserKebabMenu";
import { selectGlobalSettings } from "../../../store/settingsStore/settingsSelectors";

import classes from './index.module.scss';

// todo: extract form into a component

const UserEditPanel = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { showModal } = useShowModal();
  const settings = useSelector(selectGlobalSettings);
  const { emailWhitelist, usernameMask } = settings;
  const editUser = useSelector(getEditUser);
  const roles = useSelector(getEditUserRoles);
  const roleIdList = useSelector(getEditUserRoleIds);
  const newUnsavedUser = useSelector(isNewUnsavedUser);
  const roleList = useSelector(selectRolesList);
  const roleListTypes = useSelector(selectRoleListTypes);
  const editUserStatus = useSelector(getEditStatus);
  const userList = useSelector(selectUserList);
  const editMode = useSelector(isUserEditMode);
  const isUnsavedUser = editUser?.id === 0;
  const currentlySavedUser = (userList || []).find(
    (user) => user.id === editUser?.id,
  );

  const validationSchema = useMemo(
    () =>
      yup.object({
        userName: yup
          .string()
          .test('userNameMask', 'User name format is invalid', (value = '') => {
            if (!usernameMask) {
              return true;
            }
            try {
              const maskRegExp = new RegExp(usernameMask, 'i');
              return maskRegExp.test(value);
            } catch (e) {
              return false;
            }
          })
          .test('len-max', 'User name is too long', (value = '') => value.length <= 50)
          .test('len-min', 'User name is too short', (value = '') => value.length >= 4)
          .required('User name is required'),
        status: yup.string(),
        email: yup
          .string()
          .email('Enter a valid email')
          .test(
            'domain',
            'Please, use a valid domain',
            (value = '') => {
              if (!emailWhitelist) {
                return true;
              }
              const chunks = value.trim().split('@');
              const domain = chunks[1] || '';
              const validDomainList = emailWhitelist.split('|');
              return validDomainList.some((validDomain) => domain === validDomain);
            },
          )
          .required('Email is required'),
        fullName: yup.string().required('Name is required'),
        phone: yup.string(),
        external: yup.number(),
        unitName: yup.string().required('Business unit is required'),
      }),
    [editUser],
  );

  const userFormik = useFormik({
    initialValues: {
      userName: editUser?.userName || '',
      status: editUser?.status || 'New',
      email: editUser?.email || '',
      fullName: editUser?.profile?.fullName || '',
      phone: editUser?.profile?.phone || '',
      external: Number(editUser?.profile?.external) || 0,
      unitName: editUser?.profile?.unitName || '',
    },
    onSubmit: () => {
    },
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  if (editUserStatus?.error) {
    // todo to add translations
    userFormik.errors = JSON.parse(editUserStatus.error.message);
  }

  const checkDifference = () => {
    const roleIds = (editUser?.userRoles || []).map((role) => role.id);
    const currentRoleIds = (currentlySavedUser?.userRoles || []).map(
      (role) => role.id,
    );

    roleIds.sort();
    currentRoleIds.sort();

    const nameChanged =
      (currentlySavedUser?.profile?.fullName || '') !==
      userFormik.values.fullName;
    const phoneChanged =
      (currentlySavedUser?.profile?.phone || '') !== userFormik.values.phone;
    const externalChanged =
      !!currentlySavedUser?.profile?.external !== !!userFormik.values.external;
    const unitNameChanged =
      (currentlySavedUser?.profile?.unitName || '') !==
      userFormik.values.unitName;
    const rolesChanged = roleIds.join() !== currentRoleIds.join();

    return (
      nameChanged ||
      phoneChanged ||
      externalChanged ||
      unitNameChanged ||
      unitNameChanged ||
      rolesChanged
    );
  };

  const saveButtonAvailability = newUnsavedUser || checkDifference();

  const isEditUserSU = () => {
    return !!(editUser?.userRoles || []).find((role) => role.id === 4);
  }

  const onSaveChanges = async () => {
    if (editUserStatus?.loading) {
      return;
    }
    const errors = await userFormik.validateForm();
    if (isEmpty(errors)) {
      const payload = {
        userName: userFormik.values.userName,
        email: userFormik.values.email,
        fullName: userFormik.values.fullName,
        phone: userFormik.values.phone,
        external: !!userFormik.values.external,
        unitName: userFormik.values.unitName,
        roleIdList: isEditUserSU() ? [4, ...roleIdList] : roleIdList,
      };
      if (newUnsavedUser) {
        dispatch(createAUser(payload));
        return;
      }

      dispatch(updateUser({
        ...payload,
        id: currentlySavedUser?.id,
      }));
    }
  };

  const resetEditUser = () => {
    userFormik.setFieldValue('userName', currentlySavedUser?.userName || '');
    userFormik.setFieldValue('email', currentlySavedUser?.email || '');
    userFormik.setFieldValue(
      'fullName',
      currentlySavedUser?.profile?.fullName || '',
    );
    userFormik.setFieldValue('phone', currentlySavedUser?.profile?.phone || '');
    userFormik.setFieldValue(
      'external',
      Number(currentlySavedUser?.profile?.external) || 0,
    );
    userFormik.setFieldValue(
      'unitName',
      currentlySavedUser?.profile?.unitName || '',
    );
    dispatch(startEditUser({ user: currentlySavedUser }));
  };

  // todo: add roles sorting by id
  const onRolesChange = (values: number[]) => {
    const newRoleList = values.map((value) =>
      (roleList || []).find((role) => role.id === value),
    );
    dispatch(setRoles({ roles: newRoleList }));
  };

  const onDeleteRole = (roleType: { id: number, value: string }) => {
    dispatch(deleteRole({ type: roleType.value }));
  };

  const onDeleteUser = useCallback(() => {
    const deleteUserHandler = () => {
      if (editUser) {
        dispatch(deleteUser({ id: editUser.id }));
      }
    };

    if (editUser) {
      showModal(ModalType.MODAL_GENERIC_CONFIRM, {
        title: 'Delete selected user',
        text: 'Do you want to delete this user?',
        confirmButtonHandler: deleteUserHandler,
        confirmButtonText: 'Delete',
      });
    }
  }, [editUser]);

  const handleChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    dispatch(clearEditUserError());
    userFormik.handleChange(e);
  };

  if (!editMode) {
    return null;
  }

  return (
    <>
      <Toolbar title={newUnsavedUser ? 'New User' : 'User details'}>
        <IconButton
          disabled={!saveButtonAvailability}
          aria-label='save changes'
          color='secondary'
          size='small'
          onClick={onSaveChanges}
        >
          <SaveIcon />
        </IconButton>
        {
          editUser?.status === USER_STATUS.NEW && (
            <IconButton
              aria-label='delete'
              color='secondary'
              size='small'
              onClick={onDeleteUser}
            >
              <Delete />
            </IconButton>
          )
        }
        <IconButton
          disabled={!saveButtonAvailability}
          aria-label='clear'
          color='secondary'
          size='small'
          onClick={resetEditUser}
        >
          <Cancel />
        </IconButton>
        <UserKebabMenu />
      </Toolbar>
      <Grid container>
        <TextField
          disabled={!isUnsavedUser}
          label='User Name'
          variant='outlined'
          name='userName'
          required
          fullWidth
          className={classes.inputBox}
          inputProps={{
            className: cn({
              [classes.disabledInput]: !isUnsavedUser,
            }),
          }}
          value={userFormik.values.userName}
          onChange={handleChange}
          helperText={userFormik.errors.userName}
          error={Boolean(userFormik.errors.userName)}
        />
        <TextField
          disabled={true}
          label='Status'
          variant='outlined'
          name='status'
          fullWidth
          className={classes.inputBox}
          inputProps={{
            className: classes.statusInput,
          }}
          value={userFormik.values.status}
          onChange={handleChange}
          helperText={userFormik.errors.status}
          error={Boolean(userFormik.errors.status)}
        />

        <TextField
          disabled={!isUnsavedUser}
          label='Email'
          variant='outlined'
          name='email'
          required
          fullWidth
          className={classes.inputBox}
          inputProps={{
            className: cn({
              [classes.disabledInput]: !isUnsavedUser,
            }),
          }}
          value={userFormik.values.email}
          onChange={handleChange}
          helperText={userFormik.errors.email}
          error={Boolean(userFormik.errors.email)}
        />

        <TextField
          label='Name'
          variant='outlined'
          name='fullName'
          required
          fullWidth
          className={classes.inputBox}
          value={userFormik.values.fullName}
          onChange={handleChange}
          helperText={userFormik.errors.fullName}
          error={Boolean(userFormik.errors.fullName)}
        />

        <TextField
          label='Phone'
          variant='outlined'
          name='phone'
          fullWidth
          className={classes.inputBox}
          value={userFormik.values.phone}
          onChange={handleChange}
          helperText={userFormik.errors.phone}
          error={Boolean(userFormik.errors.phone)}
        />

        <Dropdown
          label='External user'
          values={[
            { id: 1, name: 'Yes' },
            { id: 0, name: 'No' },
          ]}
          selectedValue={userFormik.values.external}
          valueChange={handleChange}
          className={classes.inputBox}
          name='external'
        />

        <TextField
          label='Business unit'
          variant='outlined'
          name='unitName'
          required
          fullWidth
          className={classes.inputBox}
          value={userFormik.values.unitName}
          onChange={handleChange}
          helperText={userFormik.errors.unitName}
          error={Boolean(userFormik.errors.unitName)}
        />

        <Multiselect
          title='Roles'
          name='userRoles'
          classes={{
            wrapper: classes.inputBox,
          }}
          items={roleListTypes}
          selectedItems={roles}
          onChange={onRolesChange}
          onDelete={onDeleteRole}
        />
      </Grid>
    </>
  );
};

export default UserEditPanel;
