import { RootState } from "../../app/state";
import { mapAdapter } from "./mapState";
import { createSelector } from "@reduxjs/toolkit";
import { get } from "lodash/fp";

export const selectMapSlice = (state: RootState) => state.mapSlice;

const mapAdapterSelectors = mapAdapter.getSelectors();
export const selectAllMaps = mapAdapterSelectors.selectAll;
export const selectMapById = mapAdapterSelectors.selectById;

export const selectCurrentMapId = createSelector(
  selectMapSlice,
  get('selectedMapId')
);

export const selectCurrentMap = createSelector(
  selectMapSlice,
  selectCurrentMapId,
  (root, currentMapId) => currentMapId ? selectMapById(root, currentMapId) : null
);