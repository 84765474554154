import { extractNumber, extractUnits } from "./units";

function dBmTomW(value: number) {
  return Math.pow(10, 0.1 * value);
}

const toMilliWattMap = {
  'dBm': dBmTomW,
  'W': (num: number) => num * 1000,
  'mW': (num: number) => num,
}

export function convertToMilliWatt(data = '') {
  const powerUnits = extractUnits(data);
  const powerValue = extractNumber(data);
  const defaultConverter = (_) => 0;

  const converter = toMilliWattMap[powerUnits] || defaultConverter;

  return converter(powerValue);
}

const toDBmMap = {
  'dBm': (num: number) => num,
  'W': (num: number) => 10 * Math.log10(num * 1000),
  'mW': (num: number) => 10 * Math.log10(num),
}

export function convertToDBm(data = '') {
  const powerUnits = extractUnits(data);
  const powerValue = extractNumber(data);
  const defaultConverter = (_) => 0;

  const converter = toDBmMap[powerUnits] || defaultConverter;

  return converter(powerValue)
}

export function checkPowerUnits(value: string) {
  const unit = extractUnits(value);

  return ['dBm', 'W', 'mW'].includes(unit);
}