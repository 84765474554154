import * as yup from "yup";
import { WORKFLOW_TYPE } from "../WORKFLOW_TYPE";

const getWorkflowValidation = () => yup.object({
  type: yup
    .number(),
  name: yup
    .string()
    .test('len', 'Name is too long', (value = '') => value.length <= 100)
    .required('Name is required'),
  countries: yup
    .array()
    .min(1, 'Set at least one country')
    .when('type', {
      is: (type) => type !== WORKFLOW_TYPE.COUNTRY_LEVEL,
      then: yup.array().min(1, 'Country is required'),
    }),
  locations: yup
    .array()
    .when('type', {
      is: (type) => type === WORKFLOW_TYPE.BUILDING_LEVEL,
      then: yup.array().min(1, 'Location is required'),
    })
    .when('type', {
      is: (type) => type === WORKFLOW_TYPE.LOCATION_LEVEL,
      then: yup
        .array()
        .min(1, 'Set at least one location')
    }),
  buildings: yup
    .array()
    .when('type', {
      is: (type) => type === WORKFLOW_TYPE.BUILDING_LEVEL,
      then: yup.array().min(1, 'Set at least one building')
    }),
  frequencyManagers: yup
    .array()
    .min(1, 'Assigned managers are required'),
});

export default getWorkflowValidation;