import { createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { RegionModel } from "../../app/types";
import { LoadingStatus, status } from "../../app/shared";
import { addRegion, getAllRegions, removeRegion, updateRegion } from "./regionRequests";

export const regionAdapter = createEntityAdapter<RegionModel>({
  selectId: (region) => region.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

type RegionState = {
  selectedRegionId?: number | null;
  loadAllStatus: LoadingStatus,
  editLoading: LoadingStatus,
} & EntityState<RegionModel>;

const initialState: RegionState = {
  selectedRegionId: null,
  loadAllStatus: status.default,
  editLoading: status.default,
  ...regionAdapter.getInitialState(),
};

export const regionSlice = createSlice({
  name: 'regionList',
  initialState,
  reducers: {
    setCurrentRegionId: (state, action) => {
      state.selectedRegionId = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllRegions.pending, (state) => {
        state.loadAllStatus = status.loading;
      })
      .addCase(getAllRegions.fulfilled, (state, action) => {
        state.loadAllStatus = status.loaded;
        regionAdapter.setAll(state, action.payload);
      })
      .addCase(getAllRegions.rejected, (state, action) => {
        state.loadAllStatus = status.error(action.error);
      })
      .addCase(addRegion.fulfilled, (state, action) => {
        regionAdapter.addOne(state, action.payload);
      })
      .addCase(updateRegion.fulfilled, (state, action) => {
        regionAdapter.upsertOne(state, action.payload);
      })
      .addCase(removeRegion.fulfilled, (state, action) => {
        regionAdapter.removeOne(state, action.payload);
      })
});

export const { setCurrentRegionId } = regionSlice.actions;