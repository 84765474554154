import i18next, { i18n as i18nModel } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const i18n: i18nModel = i18next
  .use(Backend)
  .use(initReactI18next);

i18n.init({
  fallbackLng: 'en',
  lng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export { i18n };
