import React from 'react';
import { isEmpty } from 'lodash/fp';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormHelperText,
} from '@material-ui/core';
import { selectPasswordStatus } from '../../../store/userStore/userSelectors';
import PAGES from '../../../pages';
import { AppDispatch } from '../../../app/state';
import { useSnackbar } from '../../../app/shared/snackbar-context/snackbar.context';
import { attachTokenToUser } from '../../../store/userStore/userRequests';
import { softLogout } from "../../../store/userStore/userState";
import classes from './index.module.scss';

const CreatePasswordForm = ({userToken}: {userToken: string}) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const {openSnackbar} = useSnackbar();
  const passwordStatus = useSelector(selectPasswordStatus);
  const validationSchema = yup.object({
    password: yup
      .string()
      .required('Password is required')
      .test('min-length', 'Password should contain at least 8 characters', (val = '') => val.length >= 8)
      .test('max-length', 'Password is too long', (val = '') => val.length <= 64)
      .test('lowercaseUppercase', 'Password should be a mixture of lower case and upper case letters, numbers and special characters (#, ?, !)',
        (val = '') => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(val)),
    passwordConfirmation: yup.string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  });

  const passwordForm = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onSubmit = async () => {
    const errors = await passwordForm.validateForm();
    if (isEmpty(errors)) {
      dispatch(attachTokenToUser({token: userToken, password: passwordForm.values.password}))
        .then(() => {
          openSnackbar({
            title: '',
            message: 'Now you can login with a new password',
            severity: 'success',
          });

          dispatch(softLogout());

          history.push(PAGES.LOGIN_PAGE);
        })
    }
  };

  return (
    <Grid container direction='column' alignItems='stretch' spacing={3}>
      <Grid item>
        <Typography variant='h6'>
          Create a new password
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          label='New password'
          fullWidth
          variant='outlined'
          name='password'
          type='password'
          value={passwordForm.values.password}
          onChange={passwordForm.handleChange}
          helperText={passwordForm.errors.password}
          error={Boolean(passwordForm.errors.password)}
        />
      </Grid>
      <Grid item>
        <TextField
          label='Confirm password'
          fullWidth
          variant='outlined'
          name='passwordConfirmation'
          type='password'
          value={passwordForm.values.passwordConfirmation}
          onChange={passwordForm.handleChange}
          helperText={passwordForm.errors.passwordConfirmation}
          error={Boolean(passwordForm.errors.passwordConfirmation)}
        />
      </Grid>
      <Grid item className={classes.errorWrapper}>
        {Boolean(passwordStatus?.error) && (
          <FormHelperText error>
            This link has expired. Please contact your administrator
          </FormHelperText>
        )}
      </Grid>
      <Grid item container spacing={2}>
        <Button
          disabled={passwordStatus?.loading}
          variant='contained'
          color='primary'
          type='submit'
          fullWidth
          onClick={onSubmit}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};


export default CreatePasswordForm;