import React from 'react';
import cn from 'classnames';
import {
  InputLabel,
  Grid,
  Chip,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import styles from './index.module.scss';

export type MultiSelectItem = {id: number, value: string};

type MultiChipsProps = {
  title?: string;
  name: string;
  items?: MultiSelectItem[];
  classes?: {
    wrapper?: string;
    select?: string;
  };
  helperText?: React.ReactNode,
  error?: boolean,
  selectedItems?: MultiSelectItem[];
  onChange: (values?: number[]) => any;
  onDelete?: (value: MultiSelectItem) => any;
};

const MultiChips = ({
  title,
  name,
  items = [],
  onChange,
  selectedItems = [],
  classes,
  onDelete,
  helperText = null,
  error,
}: MultiChipsProps) => {
  const onChipClick = (value: MultiSelectItem) => {
    if (selectedItems.find((item) => item.id === value.id)) {
      if (onDelete) {
        onDelete(value);
      } else {
        const newValues = selectedItems.filter((item) => item.id !== value.id).map((item) => item.id);
        onChange(newValues);
      }

      return;
    }

    const newValues = [...selectedItems, value].map((item) => item.id);
    onChange(newValues);
  };

  return (
    <FormControl
      className={cn(classes?.wrapper, styles.wrapper)}
      variant='outlined'
      error={error}
    >
      <InputLabel id={name} className={styles.label}>
        {title}
      </InputLabel>
      <Grid container className={styles.chipsContainer}>
        {items.map(({id, value}) => {
            return (
              <Chip
                key={id}
                label={value}
                className={styles.chip}
                color={selectedItems.find((item) => item.id === id) ? 'primary' : 'default'}
                onClick={() => {onChipClick({id, value});}}
              />
            );
          },
        )}
      </Grid>
      {
        error && <FormHelperText>{helperText}</FormHelperText>
      }
    </FormControl>
  );
};

export default MultiChips;
