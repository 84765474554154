import { Route, Switch } from 'react-router-dom';
import PAGES from '../../../pages';
import RegionAndCountryRightPanel from '../RegionAndCountryRightPanel';
import UserEditPanel from '../UserEditPanel';
import TechnologiesActionPanel from '../TechnologiesActionPanel';
import WirelessSystemEdit from "../WirelessSystemEdit";

const SettingsSideBar = () => {
  return (
    <Switch>
      <Route exact path={PAGES.REGIONS_AND_COUNTRY}>
        <RegionAndCountryRightPanel />
      </Route>
      <Route path={PAGES.TECHNOLOGIES}>
        <TechnologiesActionPanel />
      </Route>
      <Route path={PAGES.WIRELESS_SYSTEM}>
        <WirelessSystemEdit />
      </Route>
      <Route path={PAGES.WORKFLOW_MANAGEMENT} />
      <Route path={PAGES.IMPORT} />
      <Route path={PAGES.GLOBAL_SETTINGS} />
      <Route path={PAGES.SETTINGS_PAGE}>
        <UserEditPanel />
      </Route>
    </Switch>
  );
};

export default SettingsSideBar;
