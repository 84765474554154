import { useCallback, useMemo } from 'react';
import {
  IconButton,
  TextField,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import _, { isEmpty } from 'lodash';
import { uniq } from "lodash/fp";
import { Delete, Cancel, Save } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { useFormik } from 'formik';
import { Toolbar } from 'app/components';
import { Dropdown } from 'app/components/Dropdown';
import { validationsTechConfiguration } from './validationsTechConfiguration';
import {
  useTypedDispatch,
} from 'app/state';
import MultiChips from '../../../../components/MiltiChips';
import { useSnackbar } from "../../../../app/shared/snackbar-context/snackbar.context";
import { useShowModal } from "app/shared/modal-context/modal.context";
import { ModalType } from "../../../../app/shared/modal-context/constants";
import UnitsInput from "../../../../components/TextFields/UnitsInput";
import HorizontalPanel from "../../../../components/HorizontalPanel";
import { selectAllRegions } from "../../../../store/regionStore/regionSelectors";
import {
  selectCurrentTechnologyConfiguration,
  selectCurrentTechnologyConfigurationId
} from "../../../../store/technologyConfigurationStore/technologyConfigurationSelectors";
import { selectAllCountriesSorted } from "../../../../store/countryStore/countrySelectors";
import {
  setCurrentTechnologyConfigurationId
} from "../../../../store/technologyConfigurationStore/technologyConfigurationState";
import {
  createTechnologyConfiguration,
  deleteTechnologyConfiguration, updateTechnologyConfiguration
} from "../../../../store/technologyConfigurationStore/technologyConfigurationRequests";
import TechnologyConfigurationModel from "../../../../models/TechnologyConfigurationModel";

import styles from './ConfigurationDetails.module.scss';

export default function ConfigurationDetails() {
  const { openSnackbar } = useSnackbar();
  const { showModal } = useShowModal();
  const dispatch = useTypedDispatch();
  const regionList = useSelector(selectAllRegions);
  const currentTechnologyConfigurationId = useSelector(selectCurrentTechnologyConfigurationId);
  const currentTechnologyConfiguration = useSelector(selectCurrentTechnologyConfiguration);
  const countryList = useSelector(selectAllCountriesSorted);
  const isCreateMode = currentTechnologyConfigurationId === -1;

  const countries = currentTechnologyConfiguration?.countries || [];
  const selectedRegionList = uniq(countries.map((country) => regionList.find((region) => region.id === country.regionId)));

  const techConfFormik = useFormik({
    initialValues: {
      name: currentTechnologyConfiguration?.name || '',
      countries: currentTechnologyConfiguration?.countries.map((country) => ({
        id: country.id,
        value: country.name
      })) || [],
      bandwidth: currentTechnologyConfiguration?.bandwidth || '',
      downlinkUplink: currentTechnologyConfiguration?.downlinkUplink || '',
      maxEIRP: currentTechnologyConfiguration?.maxEIRP || '',
      channelSelection: currentTechnologyConfiguration?.channelSelection || 0,
      frequencyHopping: currentTechnologyConfiguration?.frequencyHopping || 0,
      licence: currentTechnologyConfiguration?.licence || '',
      licenceExpirationDate: currentTechnologyConfiguration?.licenceExpirationDate || null,
      licenceNotify: currentTechnologyConfiguration?.licenceNotify || '',
      expirationDate: currentTechnologyConfiguration?.expirationDate || null,
      expirationNotify: currentTechnologyConfiguration?.expirationNotify || '',
      bestPracticeMaxEIRP: currentTechnologyConfiguration?.bestPracticeMaxEIRP || '',
      bestPracticeMinEIRP: currentTechnologyConfiguration?.bestPracticeMinEIRP || '',
      technology: currentTechnologyConfiguration?.technologyId || 1,
      channels: currentTechnologyConfiguration?.channels,
      region: selectedRegionList[0]?.id || '',
    },
    onSubmit: () => {
      console.log('fire');
    },
    validationSchema: validationsTechConfiguration,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const selectedRegionCountries = useMemo(() => {
    const currentRegion = regionList.find((region) => region?.id === techConfFormik.values?.region);
    const countries = currentRegion ? countryList.filter((country) => country.regionId === currentRegion.id) : [];

    return countries.map((country) => {
      return {
        id: country.id,
        value: country.name,
      }
    })
  }, [techConfFormik.values?.region])

  const saveTechConf = (values) => {

    const countries = values.countries.map((country) => country.id);

    const payload = {
      id: currentTechnologyConfiguration?.id,
      name: values.name,
      bandwidth: values.bandwidth || '',
      downlinkUplink: values.downlinkUplink || '',
      maxEIRP: values.maxEIRP || '',
      channelSelection: values.channelSelection,
      frequencyHopping: values.frequencyHopping,
      licence: values.licence || '',
      licenceExpirationDate: values.licenceExpirationDate || null,
      licenceNotify: values.licenceNotify || null,
      expirationDate: values.expirationDate || null,
      bestPracticeMaxEIRP: values.bestPracticeMaxEIRP || '',
      bestPracticeMinEIRP: values.bestPracticeMinEIRP || '',
      expirationNotify: values.expirationNotify || null,
      ...(countries.length ? { countries } : {}),
      channels: values.channels || [],
    };

    if (isCreateMode) {
      dispatch(
        createTechnologyConfiguration(
          _.omitBy(payload, (value) => !value && value !== 0 && value !== '')
        )
      ).then((resp) => {
        // @ts-ignore
        if (resp?.error) {
          openSnackbar({
            title: "Cannot create configuration",
            message: "There is a technology configuration with the same name.",
            severity: "error",
          });
          return;
        }

        const payload = (resp as { payload: TechnologyConfigurationModel }).payload;
        dispatch(setCurrentTechnologyConfigurationId(payload?.id))
      })
    } else {
      dispatch(
        updateTechnologyConfiguration(
          _.omitBy(payload, (value) => !value && value !== 0 && value !== '')
        )
      ).then((data) => {
        // @ts-ignore
        if (data?.error) {
          openSnackbar({
            title: "Cannot edit configuration",
            message: "This configuration cannot be edited. There is a technology configuration with the same name or it is already in use",
            severity: "error",
          });
          return;
        }
      })
    }
  }

  const onSave = useCallback(async () => {
    const valid = await techConfFormik.validateForm();
    if (isEmpty(valid)) {
      saveTechConf(techConfFormik.values);
    }
  }, [techConfFormik, saveTechConf]);

  const onDeleteHandler = useCallback(async () => {
    if (!currentTechnologyConfigurationId) {
      return;
    }
    dispatch(
      deleteTechnologyConfiguration(currentTechnologyConfigurationId)
    ).then((data) => {
      // @ts-ignore
      if (data?.error) {
        openSnackbar({
          title: "Cannot delete configuration",
          message: "This configuration is already in use.",
          severity: "error",
        });
        return;
      }

      dispatch(setCurrentTechnologyConfigurationId(null));
    })
  }, [currentTechnologyConfigurationId])

  const onDelete = useCallback(() => {
    showModal(ModalType.MODAL_GENERIC_CONFIRM, {
      title: 'Delete configuration',
      text: `Are you sure you want to delete this configuration?`,
      confirmButtonHandler: onDeleteHandler,
      confirmButtonText: 'Delete',
    });
  }, [currentTechnologyConfigurationId]);

  const onCancelChanges = useCallback(() => {
    techConfFormik.resetForm(techConfFormik.initialValues as any);
  }, [techConfFormik]);

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={
          isCreateMode ?
            'New Configuration' :
            'Configuration Details'
        }>
          <>
            <IconButton
              disabled={!techConfFormik.dirty}
              aria-label='save configuration changes'
              color='secondary'
              size='small'
              onClick={onSave}
            >
              <Save />
            </IconButton>
            <IconButton
              disabled={techConfFormik.dirty}
              aria-label='delete configuration'
              color='secondary'
              size='small'
              onClick={onDelete}
            >
              <Delete />
            </IconButton>
            <IconButton
              disabled={!techConfFormik.dirty}
              aria-label='cancel configuration changes'
              color='secondary'
              size='small'
              onClick={onCancelChanges}
            >
              <Cancel />
            </IconButton>
          </>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel
        size={HorizontalPanel.SIZES.REST_SPACE}
        addScroll
        className={styles.formContainer}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={6}>
            <TextField
              label='Configuration name'
              variant='outlined'
              name='name'
              fullWidth
              value={techConfFormik.values?.name}
              onChange={techConfFormik.handleChange}
              placeholder='Configuration name'
            />
          </Grid>
          <Grid item xs={6}>
            <UnitsInput
              label='Downlink/Uplink separation'
              variant='outlined'
              name='downlinkUplink'
              fullWidth
              unitList={UnitsInput.UNIT_TEMPLATE.FREQUENCY}
              value={techConfFormik.values?.downlinkUplink}
              onChange={techConfFormik.handleChange}
              disabled={!techConfFormik}
              error={Boolean(techConfFormik?.errors?.downlinkUplink)}
              helperText={techConfFormik?.errors?.downlinkUplink}
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              name='channelSelection'
              values={[
                {
                  id: 0,
                  name: 'No',
                },
                {
                  id: 1,
                  name: 'Yes',
                },
              ]}
              fullWidth
              label='Channel Selection'
              valueChange={techConfFormik.handleChange}
              selectedValue={
                techConfFormik.values?.channelSelection ? 1 : 0
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              name='frequencyHopping'
              values={[
                {
                  id: 0,
                  name: 'No',
                },
                {
                  id: 1,
                  name: 'Yes',
                },
              ]}
              fullWidth
              label='Frequency Hopping'
              valueChange={techConfFormik.handleChange}
              selectedValue={
                techConfFormik.values?.frequencyHopping ? 1 : 0
              }
            />
          </Grid>

          <Grid item xs={6}>
            <UnitsInput
              unitList={UnitsInput.UNIT_TEMPLATE.EXTENDED_POWER}
              label='Max EIRP'
              variant='outlined'
              name='maxEIRP'
              fullWidth
              value={techConfFormik.values?.maxEIRP}
              onChange={techConfFormik.handleChange}
              placeholder='Max EIRP'
              disabled={!techConfFormik}
              error={Boolean(techConfFormik?.errors?.maxEIRP)}
              helperText={techConfFormik?.errors?.maxEIRP}
            />
          </Grid>

          <Grid item xs={6}>
            <Dropdown
              name='region'
              label='Region'
              fullWidth
              selectedValue={techConfFormik.values?.region}
              values={regionList}
              valueChange={techConfFormik.handleChange}
            />
          </Grid>

          <Grid item xs={6}>
            <UnitsInput
              unitList={['MHz']}
              label='Bandwidth'
              variant='outlined'
              name='bandwidth'
              fullWidth
              value={techConfFormik.values?.bandwidth}
              onChange={techConfFormik.handleChange}
              disabled={!techConfFormik}
              error={Boolean(techConfFormik?.errors?.bandwidth)}
              helperText={techConfFormik?.errors?.bandwidth}
            />
          </Grid>

          <Grid item xs={12}>
            <MultiChips
              name='countries'
              items={selectedRegionCountries}
              selectedItems={techConfFormik.values.countries}
              onChange={(values: number[]) => techConfFormik.setFieldValue(
                'countries',
                selectedRegionCountries.filter((country) => values.includes(country.id))
              )}
              helperText={techConfFormik.errors.countries}
              error={Boolean(techConfFormik.errors.countries)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='h4'>License</Typography>
          </Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={3}>
              <TextField
                variant='outlined'
                label='License name'
                name='licence'
                fullWidth
                value={techConfFormik.values?.licence}
                onChange={techConfFormik.handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              <KeyboardDatePicker
                autoOk
                variant='inline'
                inputVariant='outlined'
                label='Expiration date'
                name='licenceExpirationDate'
                format='dd/MM/yyyy'
                fullWidth
                value={techConfFormik?.values?.licenceExpirationDate}
                InputAdornmentProps={{ position: 'start' }}
                onChange={(value) => {
                  return techConfFormik.setFieldValue(
                    'licenceExpirationDate',
                    value
                  );
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label='Notify in Days before end'
                variant='outlined'
                name='licenceNotify'
                fullWidth
                value={techConfFormik.values?.licenceNotify}
                onChange={techConfFormik.handleChange}
              />
            </Grid>
            <Grid item xs={3}>&nbsp;</Grid>
          </MuiPickersUtilsProvider>
          <Grid item xs={12}>
            <Typography variant='h4'>Valid to</Typography>
          </Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={3}>
              <KeyboardDatePicker
                autoOk
                variant='inline'
                inputVariant='outlined'
                label='Expiration date'
                name='expirationDate'
                format='dd/MM/yyyy'
                fullWidth
                value={techConfFormik?.values?.expirationDate}
                InputAdornmentProps={{ position: 'start' }}
                onChange={(value) => {
                  return techConfFormik.setFieldValue(
                    'expirationDate',
                    value
                  );
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label='Notify in Days before end'
                variant='outlined'
                name='expirationNotify'
                fullWidth
                value={techConfFormik.values?.expirationNotify}
                onChange={techConfFormik.handleChange}
              />
            </Grid>
            <Grid item xs={6}>&nbsp;</Grid>
          </MuiPickersUtilsProvider>
          <Grid item xs={12}>
            <Typography variant='h4'>Best Practices</Typography>
          </Grid>
          <Grid item xs={6}>
            <UnitsInput
              unitList={UnitsInput.UNIT_TEMPLATE.EXTENDED_POWER}
              label='Min EIRP'
              variant='outlined'
              name='bestPracticeMinEIRP'
              fullWidth
              defaultValue=""
              value={techConfFormik.values?.bestPracticeMinEIRP}
              onChange={techConfFormik.handleChange}
              disabled={!techConfFormik}
              error={Boolean(techConfFormik?.errors?.bestPracticeMinEIRP)}
              helperText={techConfFormik?.errors?.bestPracticeMinEIRP}
            />
          </Grid>
          <Grid item xs={6}>
            <UnitsInput
              unitList={UnitsInput.UNIT_TEMPLATE.EXTENDED_POWER}
              label='Max EIRP'
              variant='outlined'
              name='bestPracticeMaxEIRP'
              fullWidth
              defaultValue=""
              value={techConfFormik.values?.bestPracticeMaxEIRP}
              onChange={techConfFormik.handleChange}
              disabled={!techConfFormik}
              error={Boolean(techConfFormik?.errors?.bestPracticeMaxEIRP)}
              helperText={techConfFormik?.errors?.bestPracticeMaxEIRP}
            />
          </Grid>
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  );
}
