import { useEffect } from "react";
import { Action } from "@reduxjs/toolkit";
import { useTypedDispatch } from "../../app/state";
import { getQueryObject } from "../../pages";

interface WatchItem {
  title: string;
  action: Action;
  type?: 'number' | 'string';
  defaultValue?: unknown;
}

interface PageUpdaterProps {
  watchItemList: WatchItem[];
}

const PageUpdater = ({ watchItemList }: PageUpdaterProps) => {
  const urlProps = getQueryObject();
  const dispatch = useTypedDispatch();
  const selectedUrlProps = watchItemList.map((item) => urlProps[item.title]);

  const checkAndSelect = (property: unknown, action) => {
    if (typeof property === 'number') {
      if (!isNaN(property)) {
        dispatch(action(property));
      }
    } else {
      dispatch(action(property));
    }
  };

  useEffect(() => {
    watchItemList.forEach((item) => {
      const action = item.action;
      const urlValue = urlProps[item.title];

      if (typeof urlValue === 'undefined') {
        checkAndSelect(item.defaultValue || null, action);
        return;
      }

      switch (item.type) {
        case 'string':
          checkAndSelect(urlValue as string, action);
          break;
        case 'number':
        default:
          checkAndSelect(parseInt(urlValue as string, 10), action);
          break;
      }
    });
  }, selectedUrlProps);

  return null;
}

export default PageUpdater;