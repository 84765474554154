import { GridColDef } from "@material-ui/data-grid";
import { Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

const columns: GridColDef[] = [
  {
    field: 'technology',
    flex: 1,
    headerName: 'Technology',
  },
  {
    field: 'region',
    flex: 1,
    headerName: 'Region',
    renderCell: (params: any): any => {
      return (
        <Tooltip title={params.value}>
          <Typography
            noWrap
            component='span'
            align='left'
          >
            {params.value}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: 'countries',
    flex: 2,
    headerName: 'Countries',
    renderCell: (params: any): any => {
      return (
        <Tooltip title={params.value}>
          <Typography
            noWrap
            component='span'
            align='left'
          >
            {params.value}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: 'bandwidth',
    flex: 1,
    headerName: 'Bandwidth',
  },
];

export default columns;