import { LoadingStatus, status } from '../../app/shared';
import {
  createSlice,
} from '@reduxjs/toolkit';
import { getUserByToken, loginWithPassword, checkPermission, attachTokenToUser } from './userRequests';
import { PERMISSIONS_STATUS } from './permissions.status.enum';
import { request } from '../../app/api';
import PAGES from "../../pages";
import { USER_STATUS } from "../userListStore/userStatus";

type UserState = {
  user: any,
  roles: any,
  permissions: { [key: string]: number },
  token: string,
  loginStatus: LoadingStatus,
  tokenStatus: LoadingStatus,
  passwordStatus: LoadingStatus,
}

const initialState: UserState = {
  user: null,
  roles: [],
  permissions: {},
  token: '',
  loginStatus: status.default,
  tokenStatus: status.default,
  passwordStatus: status.default,
};

const resetUserStateFunction = (state: UserState) => Object.assign(state, initialState);
const setAxiousToken = (token?: string) => request.defaults.headers.common['x-access-token'] = token;

const logoutInnerFunction = () => {
  localStorage.removeItem('token');
  // load forced login page
  // eslint-disable-next-line no-restricted-globals
  location.href = location.protocol + '//' + location.host + PAGES.LOGIN_PAGE + `?${+ new Date()}`;
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: logoutInnerFunction,
    softLogout: (state) => {
      localStorage.removeItem('token');
      resetUserStateFunction(state);
      setAxiousToken();
    },
    setUserToken: (state, action) => {
      state.token = action?.payload || '';
      setAxiousToken(state.token);
    },
    startPermissionRequest: (state, action) => {
      // TODO: redo this approach somehow
      const { permissionName } = (action?.payload || {});
      state.permissions = {
        ...state.permissions,
        [permissionName]: PERMISSIONS_STATUS.LOADING,
      };
    },
    resetUserState: resetUserStateFunction,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginWithPassword.pending, (state) => {
        state.loginStatus = status.loading;
      })
      .addCase(loginWithPassword.fulfilled, (
        state,
        action,
      ) => {
        state.loginStatus = status.loaded;
        state.token = action?.payload?.token;
        state.user = action?.payload?.user;
        state.roles = action?.payload?.roles;
        localStorage.setItem('token', state.token);
        setAxiousToken(state.token);
      })
      .addCase(loginWithPassword.rejected, (
        state,
        action,
      ) => {
        state.loginStatus = status.error(action.error as Error);
      })
      .addCase(getUserByToken.pending, (state) => {
        state.tokenStatus = status.loading;
      })
      .addCase(getUserByToken.fulfilled, (
        state,
        action,
      ) => {
        state.tokenStatus = status.loaded;
        const user = action?.payload?.user;
        if (user?.status === USER_STATUS.ACTIVE) {
          state.user = user;
          state.roles = action?.payload?.roles;
        } else {
          logoutInnerFunction();
        }
      })
      .addCase(getUserByToken.rejected, (
        state,
      ) => {
        logoutInnerFunction();
        state.tokenStatus = status.default;
      })
      .addCase(checkPermission.fulfilled, (
        state,
        action,
      ) => {
        state.permissions = {
          ...state.permissions,
          ...action.payload,
        };
      })
      .addCase(attachTokenToUser.pending, (
        state,
      ) => {
        state.passwordStatus = status.loading;
      })
      .addCase(attachTokenToUser.fulfilled, (
        state,
        action,
      ) => {
        if (action.payload.success) {
          state.passwordStatus = status.loaded;
        }
      })
      .addCase(attachTokenToUser.rejected, (
        state,
        action,
      ) => {
        state.passwordStatus = status.error(action.error as Error);
      });
  },
});

export const { logout, softLogout, resetUserState, setUserToken, startPermissionRequest } = userSlice.actions;