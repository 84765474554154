import StyledBreadcrumbs from "../../../components/StyledBreadcrumbs";
import { pagePathGenerator } from "../../../routes/pagePathGenerator";
import { useSelector } from "react-redux";
import { selectCurrentLocation, selectCurrentLocationId } from "../../../store/locationStore/locationSelectors";
import { selectCurrentBuilding, selectCurrentBuildingId } from "../../../store/buildingStore/buildingsSelectors";
import { selectCurrentFloorId } from "../../../store/floorStore/floorSelectors";

type BreadcrumbsContainerItem = 'home' | 'locations' | 'buildings' | 'devices'

interface BreadcrumbsContainerProps {
  items: BreadcrumbsContainerItem[];
}

const BreadcrumbsContainer = (props: BreadcrumbsContainerProps) => {
  const { items = [] } = props;
  const currentLocation = useSelector(selectCurrentLocation);
  const locationId = useSelector(selectCurrentLocationId);
  const currentBuilding = useSelector(selectCurrentBuilding)
  const buildingId = useSelector(selectCurrentBuildingId);
  const floorId = useSelector(selectCurrentFloorId);

  const breadCrumbsItems = items.map((containerItemType, index) => {
    const hasLink = index < items.length - 1;
    switch (containerItemType) {
      case 'buildings':
        return {
          title: 'Buildings & Floors',
          tooltip: currentBuilding?.name,
          link: hasLink ? pagePathGenerator.BUILDING_PAGE({
            locationId: locationId || 1,
            buildingId: buildingId || undefined,
            floorId: floorId || undefined,
          }) : '',
        }
      case 'locations':
        return {
          title: 'Locations',
          tooltip: currentLocation?.name,
          link: hasLink ? pagePathGenerator.LOCATION_PAGE({
            locationId: locationId || 1,
            buildingId: buildingId || undefined,
          }) : '',
        }
      case 'devices':
        return {
          title: 'Devices',
          link: '',
        }
      case 'home':
      default:
        return {
          title: 'Home',
          link: hasLink ? pagePathGenerator.HOME() : '',
        }
    }
  });

  return <StyledBreadcrumbs items={breadCrumbsItems} />
}

export default BreadcrumbsContainer;