import { createSlice } from "@reduxjs/toolkit";

type GeneralState = {
  isEditMode: boolean;
}

const initialState: GeneralState = {
  isEditMode: false,
}

export const generalSlice = createSlice({
  name: 'generalState',
  initialState,
  reducers: {
    setEditMode: (state, action) => {
      state.isEditMode = action.payload;
    }
  },
});

export const { setEditMode } = generalSlice.actions;