import cn from 'classnames';
import { Grid, GridProps } from '@material-ui/core';
import classes from './Panel.module.scss';

export const Panel = ({ children, className, ...props }: GridProps) => {
  return (
    <Grid
      item
      container
      direction='column'
      className={cn(classes.panel, className)}
      {...props}
    >
      {children}
    </Grid>
  );
};
