import { createAsyncThunk } from "@reduxjs/toolkit";
import { LocationModel } from "../../app/types";
import { request } from "../../app/api";
import { serializeError } from "../../app/shared";

const SLICE_NAME = 'locations';

export const requestLocationByQuery = createAsyncThunk(
  `${SLICE_NAME}/query`,
  async (
    query: { mapId?: number },
    { rejectWithValue }
  ) => {
    try {
      const locationList: LocationModel[] = await request.get("/location", {
        params: query || {},
      });
      return locationList;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const createLocation = createAsyncThunk(
  `${SLICE_NAME}/create`,
  async (body: Partial<LocationModel>, { rejectWithValue }) => {
    try {
      const location: LocationModel = await request.post("/location", body);
      return location;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const updateLocation = createAsyncThunk(
  `${SLICE_NAME}/update`,
  async (
    data: Partial<LocationModel> | LocationModel,
    { rejectWithValue }
  ) => {
    try {
      const location: LocationModel = await request.put(`/location/${data.id}`, data);
      return location;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const deleteLocation = createAsyncThunk(
  `${SLICE_NAME}/delete`,
  async (id: number, { rejectWithValue }) => {
    try {
      await request.delete(`/location/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);