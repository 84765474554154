import { withStyles, Theme, createStyles, Tabs } from "@material-ui/core";

export const StyledTabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTab-root": {
        lineHeight: "52px",
      },
    },
    indicator: {
      width: "4rem",
      height: "4px",
      borderRadius: "99px 99px 0px 0px",
      backgroundColor: theme.palette.background.default,
    },
  })
)((props: { [key: string]: any }) => {
  return <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />;
});
