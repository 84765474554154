import { RootState } from "../../app/state";
import { regionAdapter } from "./regionState";
import { createSelector } from "@reduxjs/toolkit";

const selectRoot = (state: RootState) => state;
export const selectRegionsSlice = (state: RootState) => state.regionList;

const regionAdapterSelectors = regionAdapter.getSelectors(selectRegionsSlice);

export const selectAllRegions = regionAdapterSelectors.selectAll;

export const selectRegionById = regionAdapterSelectors.selectById;

export const selectCurrentRegionId = createSelector(
  selectRegionsSlice,
  (state) => state.selectedRegionId
);

export const selectCurrentRegion = createSelector(
  selectRoot,
  selectCurrentRegionId,
  (state, regionId) => (regionId && selectRegionById(state, regionId)) || null
)

export const selectAllRegionsLoading = createSelector(
  selectRegionsSlice,
  (state) => state.loadAllStatus
);

export const selectRegionEditLoading = createSelector(
  selectRegionsSlice,
  (state) => state.editLoading
);