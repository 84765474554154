import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/state";
import { mapPointsState } from "./mapPointsState";

export const selectMapPointsState = (state: RootState) => state[mapPointsState.name];

export const selectCurrentlyEditPoint = createSelector(
  selectMapPointsState,
  (state) => state.selectedMapPointId
);

export const selectEditedPoints = createSelector(
  selectMapPointsState,
  (state) => state.pointsToSave,
)