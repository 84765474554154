import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../app/state';
import { isUserLoggedIn } from '../../store/userStore/userSelectors';
import { getAllUserList } from '../../store/userListStore/userListRequests';
import { selectUserListStatus } from '../../store/userListStore/userListSelectors';

export interface UserListRequestProps {
  children: ReactElement,
}

export const UserListRequest = ({ children }: UserListRequestProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const isLoggedIn = useSelector(isUserLoggedIn);
  const userListStatus = useSelector(selectUserListStatus);

  // todo: remove double requests on page reload
  useEffect(() => {
    if (!userListStatus?.loaded && isLoggedIn && !userListStatus?.error) {
      dispatch(getAllUserList());
    }
  }, [userListStatus]);

  // todo: add error handling

  if (!userListStatus?.loading) {
    return children;
  }

  return null;
}