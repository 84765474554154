import {
  IconButton,
  TextField,
  Divider,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import { ModalType } from "app/shared/modal-context/constants";
import { useShowModal } from "app/shared/modal-context/modal.context";
import { CountryModel } from "app/types";
import { useFormik } from "formik";
import { omitBy, isNil, isEmpty } from "lodash/fp";
import { useSnackbar } from "app/shared/snackbar-context/snackbar.context";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { selectCurrentCountry } from "../../../store/countryStore/countrySelectors";
import { selectCurrentRegion, selectCurrentRegionId } from "../../../store/regionStore/regionSelectors";
import { addCountry, removeCountry, updateCountry } from "../../../store/countryStore/countryRequests";
import { useHistory } from "react-router-dom";
import { pagePathGenerator } from "../../../routes/pagePathGenerator";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { useTypedDispatch } from "../../../app/state";
import classes from './index.module.scss';
import Grid from "@material-ui/core/Grid";

const validationSchema = yup.object({
  regionId: yup.number().required("Region is required"),
  name: yup
    .string()
    .required("Country Name is required")
    .max(30, "Country name should be less then 30 symbols"),
});

export const CountryForm = () => {
  const history = useHistory();
  const dispatch = useTypedDispatch();
  const { showModal } = useShowModal();
  const { openSnackbar } = useSnackbar();
  const currentRegionId = useSelector(selectCurrentRegionId);
  const currentRegion = useSelector(selectCurrentRegion);
  const currentCountry = useSelector(selectCurrentCountry);
  const isCreate = !currentCountry;
  const regionName = currentRegion?.name || '';

  const navigateToCountryId = (countryId: number | null) => {
    const url = pagePathGenerator.REGIONS_AND_COUNTRY({
      regionId: currentRegionId,
      countryId: countryId,
    });

    history.replace(url);
  }

  const countryForm = useFormik({
    initialValues: omitBy(isNil, {
      ...currentCountry,
      regionId: currentRegionId,
    }) as any,
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onSaveCountry = async () => {
    const valid = await countryForm.validateForm();
    if (!isEmpty(valid)) {
      return;
    }
    if (countryForm.values.id) {
      dispatch(updateCountry(countryForm.values as CountryModel));
    } else {
      dispatch(addCountry(countryForm.values as CountryModel))
        .then((action) => {
          const country = action.payload as CountryModel;
          navigateToCountryId(country.id);
        })
    }
  }

  const onDeleteCountry = () => {
    showModal(ModalType.MODAL_GENERIC_CONFIRM, {
      title: "Delete Country",
      text: "You want to delete this Country. Are you sure?",
      confirmButtonHandler: onContinueDeleteCountry,
      confirmButtonText: "Delete",
    });
  }

  const onContinueDeleteCountry = () => {
    if (countryForm.values.id) {
      dispatch(removeCountry(countryForm.values.id))
        .then((action) => {
          if (action.type === removeCountry.rejected.type) {
            openSnackbar({
              title: "Cannot delete country",
              message: "Country contains locations. Remove locations first",
              severity: "info",
            });
          } else {
            navigateToCountryId(null)
          }
        })
    }
  }

  const onCancelChanges = () => {
    countryForm.resetForm(countryForm.initialValues);
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={!isCreate ? "Country details" : "Add new country"}>
          <IconButton
            disabled={!countryForm.dirty}
            aria-label="save country changes"
            color="secondary"
            size="small"
            onClick={onSaveCountry}
          >
            <SaveIcon />
          </IconButton>
          <IconButton
            disabled={isCreate}
            aria-label="Delete country"
            onClick={onDeleteCountry}
            color="secondary"
            size="small"
          >
            <Delete />
          </IconButton>
          <IconButton
            disabled={!countryForm.dirty}
            aria-label="cancel country changes"
            onClick={onCancelChanges}
            color="secondary"
            size="small"
          >
            <CancelIcon />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <Divider />
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL} className={classes.padding} spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Region"
            variant="outlined"
            name="region"
            disabled
            fullWidth
            value={regionName}
          />
        </Grid>
        <Grid item xs={12}>

          <TextField
            label="Country"
            variant="outlined"
            id="country-title"
            autoFocus={true}
            name="name"
            fullWidth
            onChange={countryForm.handleChange}
            value={countryForm.values.name || ""}
            helperText={countryForm.errors.name}
            error={countryForm.touched && Boolean(countryForm.errors.name)}
          />
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  );
};
