import { Redirect } from 'react-router-dom';
import { PERMISSIONS } from '../../store/userStore/permissions.enum';
import { ThreeColumnLayout } from 'layouts/ThreeColumnLayout/ThreeColumnLayout';
import SettingsNavigation from '../../containers/SettingsPage/SettingsNavigation';
import SettingsMainPanel from '../../containers/SettingsPage/SettingsMainPanel';
import SettingsSideBar from '../../containers/SettingsPage/SettingsSideBar';
import { CheckPermission } from '../../requests/CheckPermission';
import PAGES from '../index';

const SettingsPage = () => {
  return (
    <CheckPermission
      permissionName={PERMISSIONS.SETTINGS_MENU_ACCESS}
      loading={null}
      noPermissionComponent={<Redirect to={PAGES.HOME} />}
    >
      <ThreeColumnLayout
        leftSideBar={<SettingsNavigation />}
        centerMainPanel={<SettingsMainPanel />}
        rightSideBar={<SettingsSideBar />}
      />
    </CheckPermission>
  );
};

export default SettingsPage;
