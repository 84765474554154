import { useState } from "react";
import AddPropsToChildren from "../AddPropsToChildren";
import ChildrenElement from "../../models/HelperModels/ChildrenElement";
import { GenericModal } from "../../app/components/ui/generic-modal/GenericModal";

type ModalWithToggleProps = {
  title: string,
  children: ChildrenElement,
  modalContent: ChildrenElement,
  confirmButtonText?: string,
  confirmButtonHandler?: () => void;
}

const ModalWithToggle = ({ children, modalContent, ...props }: ModalWithToggleProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const onClick = () => {
    setIsOpened(true);
  }

  const onClose = () => {
    setIsOpened(false)
  }


  return (
    <>
      <AddPropsToChildren
        extraProps={{
          onClick,
        }}
      >
        {children}
      </AddPropsToChildren>
      <GenericModal
        {...props}
        isOpen={isOpened}
        closeModal={onClose}
      >
        {modalContent}
      </GenericModal>
    </>
  )
}

export default ModalWithToggle;