import React, { useEffect, useState } from "react";
import { getGlobalSettings } from "../store/settingsStore/settingsRequests";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../app/state";
import { setUserToken } from "../store/userStore/userState";
import { getUserByToken } from "../store/userStore/userRequests";
import Loading from "../components/Loading/Loading";

enum Status {
  LOADING,
  LOADED,
}

const ApplicationStartup = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [requestStatus, setLoading] = useState(Status.LOADING);
  const savedToken = localStorage.getItem('token');

  useEffect(() => {
    dispatch(setUserToken(savedToken));
    if (savedToken) {
      dispatch(getUserByToken({ token: savedToken }))
        .then(() => {
          dispatch(getGlobalSettings())
            .then(() => {
              setLoading(Status.LOADED);
            })
        })
    } else {
      dispatch(getGlobalSettings())
        .then(() => {
          setLoading(Status.LOADED);
        })
    }
  }, []);

  if (requestStatus === Status.LOADED) {
    return children;
  }

  return (
    <Loading />
  )
}

export default ApplicationStartup;