import React, { useCallback, useMemo } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { DashboardBar } from './components';
import { AppRoutesEnum, ROUTES } from './routes';
import { ModalProvider } from './shared/modal-context/modal.context';
import PAGES from '../pages';

const MenuOptions = new Set<string>([
  AppRoutesEnum.Requests,
  AppRoutesEnum.Locations,
  AppRoutesEnum.Search,
]);

export const App = () => {
  const history = useHistory();
  const selectedRoute = useMemo(() => {
    const route = `/${history.location.pathname.split('/')[1]}`;
    return MenuOptions.has(route) ? route : undefined;
  }, [history.location.pathname]);
  const openSettings = useCallback((): void => {
    history.push(PAGES.SETTINGS_PAGE);
  }, [history]);
  const openHome = useCallback((): void => {
      history.push(PAGES.REQUESTS);
    }, [history]);

  // TODO move routes to configuration or state
  const routes = useMemo(
    () => [
      {
        label: 'Location',
        href: AppRoutesEnum.Locations,
        id: '0',
      },
      {
        label: 'Requests',
        href: AppRoutesEnum.Requests,
        id: '1',
      },
      {
        label: 'Search',
        href: AppRoutesEnum.Search,
        id: '2'
      }
    ],
    []
  );
  
  return (
    <ModalProvider>
      <DashboardBar
        openSettings={openSettings}
        routes={routes}
        activeRoute={selectedRoute}
        logoClicked={openHome}
      />
      <Switch>
        {ROUTES.map(({ exact, path, component }, key) => (
          <Route key={path} exact={exact} path={path} component={component} />
        ))}
        <Route>
          <Redirect to={AppRoutesEnum.Locations} />
        </Route>
      </Switch>
    </ModalProvider>
  );
};
