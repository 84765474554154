import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { ThreeColumnLayout } from "../../../layouts/ThreeColumnLayout/ThreeColumnLayout";
import {
  selectCurrentDevice, selectCurrentDeviceId,
} from "../../../store/deviceStore/deviceSelectors";
import { DeviceLeftPanel } from "../DeviceLeftPanel/DeviceLeftPanel";
import DeviceCentralPanel from "../DeviceCentralPanel";
import { useFormik } from "formik";
import {
  selectWirelessSystemById,
} from "../../../store/wirelessSystemStore/wirelessSystemSelectors";
import { selectCurrentConfiguration } from "../../../store/deviceConfigurationStore/deviceConfigurationSelectors";
import { getDeviceFormValidation } from "./deviceFormValidation";
import { DeviceStatus } from "../../../models/enums/DeviceStatus.enum";
import { selectCurrentUser } from "../../../store/userStore/userSelectors";
import CanEditDevice from "../CanEditDevice";
import DeviceRightPanel from "../DeviceRightPanel";

type DeviceGeneralContainerProps = {
  leftPanel?: ReactElement,
  showBreadCrumbs?: boolean,
  showSmartFilterButton?: boolean,
  onUpdate?: (params?: unknown) => void,
  onLastDeviceDeleted?: () => void,
}

const DeviceGeneralContainer = (props: DeviceGeneralContainerProps) => {
  const { leftPanel, onUpdate, showBreadCrumbs, showSmartFilterButton, onLastDeviceDeleted } = props;
  const currentUser = useSelector(selectCurrentUser);
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const currentDevice = useSelector(selectCurrentDevice);
  const selectedConfiguration = useSelector(selectCurrentConfiguration);
  const currentConfiguration = selectedConfiguration || currentDevice?.configuration;
  const currentWirelessSystem = useSelector(selectWirelessSystemById(currentConfiguration?.wirelessSystemId));
  const requester = currentConfiguration?.requester || currentUser;
  const status = currentConfiguration?.status || DeviceStatus.NEW;
  const isHistory = !!currentConfiguration?.parentDeviceConfiguration;
  const isAssignDisabled = isHistory || status === DeviceStatus.APPROVED || status === DeviceStatus.ACTIVE;
  const isWrongDevice = !currentDevice && currentDeviceId !== -1;

  const initialValues = {
    id: currentDevice?.id || undefined,
    name: currentDevice?.name || '',
    wirelessSystemId: currentWirelessSystem?.id || 1,
    type: currentConfiguration?.type || '0',
    height: currentConfiguration?.height || '',
    room: currentConfiguration?.room || '',
    axis: currentConfiguration?.axis || '',
    description: currentConfiguration?.description || '',
    affectedUnits: currentConfiguration?.affectedUnits || '',
    modelName: currentConfiguration?.modelName || '',
    manufacturer: currentConfiguration?.manufacturer || '',
    operator: currentConfiguration?.operator || '',
    operatorInfo: currentConfiguration?.operatorInfo || '',
    implementationStart: currentConfiguration?.implementationStart || null,
    implementationEnd: currentConfiguration?.implementationEnd || null,
    implementationNotificationDays: currentConfiguration?.implementationNotificationDays || '',
    pilotStart: currentConfiguration?.pilotStart || null,
    pilotEnd: currentConfiguration?.pilotEnd || null,
    pilotNotificationDays: currentConfiguration?.pilotNotificationDays || '',
    expirationDate: currentConfiguration?.expirationDate || null,
    expirationNotificationDays: currentConfiguration?.expirationNotificationDays || '',
    notifyOnExpiration: currentConfiguration?.notifyOnExpiration || false,
  }

  const deviceFormik = useFormik({
    initialValues,
    onSubmit: () => {},
    validationSchema: getDeviceFormValidation(),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onDeviceUpdate = () => {
    onUpdate && onUpdate();
  }

  return (
    <ThreeColumnLayout
      leftSideBar={leftPanel ? leftPanel : <DeviceLeftPanel />}
      centerMainPanel={(
        isWrongDevice
          ? null
          : (
            <CanEditDevice>
              <DeviceCentralPanel
                formProps={{
                  deviceFormik: deviceFormik,
                  deviceExtraData: {
                    status: currentConfiguration?.status || DeviceStatus.NEW,
                    requester: requester?.profile?.fullName || '',
                  },
                  isAssignDisabled,
                  onUpdate: onDeviceUpdate,
                }}
                onLastDeviceDeleted={onLastDeviceDeleted}
                showBreadCrumbs={showBreadCrumbs}
                showSmartFilterButton={showSmartFilterButton}
              />
            </CanEditDevice>
          )
      )}
      rightSideBar={(
        isWrongDevice ?
          null : (
            <CanEditDevice>
              <DeviceRightPanel
                onUpdate={onDeviceUpdate}
              />
            </CanEditDevice>
          )
      )}
    />
  )
}

export default DeviceGeneralContainer;