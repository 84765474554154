import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectCanvasWidthInHz,
  selectChannelsForD3,
  selectRangeWithUnits
} from "../../../store/freq-analysis/selectors";
import { Grid, IconButton } from "@material-ui/core";
import { Toolbar } from "../../../app/components";
import TransitEnterexitIcon from "@material-ui/icons/TransitEnterexit";
import FreqAnalysisD3 from "../FrequencyAnalysisCanvas";

import styles from "./index.module.scss";

const AnalysisCentralPanel = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const data = useSelector(selectChannelsForD3);
  const canvasWidthInHz = useSelector(selectCanvasWidthInHz);
  const rangeWithUnits = useSelector(selectRangeWithUnits);

  return (
    <Grid container item xs direction='column'>
      <Toolbar title={t('navigation_freq_analysis')}>
        <>
          <IconButton
            aria-label='close window'
            color='secondary'
            size='small'
            onClick={history.goBack} // TODO use state for going back
          >
            <TransitEnterexitIcon />
          </IconButton>
        </>
      </Toolbar>
      <Grid container item xs direction='column'>
        <Grid
          container
          item
          xs
          direction='column'
          className={styles.mapContainer}
        >
          <FreqAnalysisD3
            data={data}
            canvasWidthInHz={canvasWidthInHz}
            rangeWithUnits={rangeWithUnits}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AnalysisCentralPanel;