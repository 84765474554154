import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../app/state';
import { isUserLoggedIn, selectUserToken, selectPermissions } from '../../store/userStore/userSelectors';
import { checkPermission } from '../../store/userStore/userRequests';
import { PERMISSIONS_STATUS } from '../../store/userStore/permissions.status.enum';
import { addClassToChildren } from './addClassToChildren';

import classes from './index.module.scss';
import { PERMISSIONS } from '../../store/userStore/permissions.enum';
import { startPermissionRequest } from "../../store/userStore/userState";

export interface CheckPermissionProps {
  permissionName: PERMISSIONS,
  children: ReactElement | ReactElement[] | null,
  noPermissionComponent?: ReactElement | null,
  loading?: ReactElement | null,
}

export const CheckPermission = ({
  permissionName,
  children,
  noPermissionComponent,
  loading,
}: CheckPermissionProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const isLoggedIn = useSelector(isUserLoggedIn);
  const permissions = useSelector(selectPermissions) || {};
  const userToken: string = useSelector(selectUserToken) as string;

  const currentPermissionStatus = permissions[permissionName];

  useEffect(() => {
    if (isLoggedIn && typeof currentPermissionStatus === 'undefined') {
      dispatch(startPermissionRequest({ permissionName }))
      dispatch(checkPermission({ permissionName, token: userToken }));
    }
  }, [permissionName]);

  if (currentPermissionStatus === PERMISSIONS_STATUS.GRANTED) {
    return addClassToChildren(children, classes.enabled);
  }

  if (currentPermissionStatus === PERMISSIONS_STATUS.DENIED) {
    return <>
      {noPermissionComponent}
    </>;
  }

  return loading || null;
};