import { ThreeColumnLayout } from "../../layouts/ThreeColumnLayout/ThreeColumnLayout";
import { getQueryObject } from "../index";
import { loadBuildingsByLocationId } from "../../store/buildingStore/buildingsRequests";
import { GeneralRequest } from "../../requests/GeneralRequest";
import BuildingsList from "../../containers/BuildingsPage/BuildingsList";
import BuildingCentralArea from "../../containers/BuildingsPage/BuildingCentralArea";
import { setCurrentLocationId } from "../../store/locationStore/locationState";
import { setCurrentBuildingId } from "../../store/buildingStore/buildingsState";
import { setCurrentFloorId } from "../../store/floorStore/floorState";
import BuildingRightPanel from "../../containers/BuildingsPage/BuildingRightPanel";
import PageUpdater from "../../containers/PageUpdater";
import useRoutes from "../../hooks/routeHooks";
import { getAllFloors } from "../../store/floorStore/floorRequests";

const BuildingPage = () => {
  const urlProps = getQueryObject();
  const { actions } = useRoutes();
  const locationId = parseInt(urlProps.locationId as string);

  if (isNaN(locationId)) {
    actions.redirectWrongId();
    return null;
  }

  return (
    <>
      <PageUpdater watchItemList={[
        {
          title: 'locationId',
          action: setCurrentLocationId,
        },
        {
          title: 'buildingId',
          action: setCurrentBuildingId,
        },
        {
          title: 'floorId',
          action: setCurrentFloorId,
        },
      ]} />
      <GeneralRequest
        requestAction={loadBuildingsByLocationId}
        requestArguments={[locationId]}
      >
        <GeneralRequest requestAction={getAllFloors}>
          <ThreeColumnLayout
            leftSideBar={<BuildingsList />}
            centerMainPanel={<BuildingCentralArea />}
            rightSideBar={<BuildingRightPanel />}
          />
        </GeneralRequest>
      </GeneralRequest>
    </>
  )
}

export default BuildingPage;