import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import { CheckPermission } from "../../../requests/CheckPermission";
import GlobalSettingsForm from "../GlobalSettingsForm";
import HorizontalPanel from "../../../components/HorizontalPanel";
import TrialTime from "../TrialTime";
import SettingsWhitelist from "../SettingsWhitelist";

const GlobalAppSettings = () => {
  return (
    <CheckPermission permissionName={PERMISSIONS.GLOBAL_SETTINGS_ACTIONS}>
      <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
        <TrialTime />
        <GlobalSettingsForm />
        <SettingsWhitelist />
      </HorizontalPanel>
    </CheckPermission>
  );
}

export default GlobalAppSettings;