import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import HorizontalPanel from "../../../components/HorizontalPanel";
import {
  useTypedDispatch
} from "../../../app/state";
import {
  selectCurrentDevice, selectCurrentDeviceId,
  selectCurrentWirelessInterface
} from "../../../store/deviceStore/deviceSelectors";
import { selectCurrentConfiguration } from "../../../store/deviceConfigurationStore/deviceConfigurationSelectors";
import WirelessInterfaceBestPracticeBadge from "../../Shared/WirelessInterfaceBestPracticeBadge";
import { TableList } from "../../../app/components/TableList";
import { setCurrentWirelessInterfaceId } from "../../../store/deviceStore/deviceState";
import { Toolbar } from "../../../app/components";
import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { AddWirelessInterfaceDialog } from "../DevicesMainPanel/dialogs/AddWirelessInterfaceDialog";
import { ModalType } from "../../../app/shared/modal-context/constants";
import { addWirelessInterfaceToDevice, deleteWirelessInterface } from "../../../store/deviceStore/deviceRequests";
import DeviceModel from "../../../models/DeviceModel";
import { useShowModal } from "../../../app/shared/modal-context/modal.context";

interface WirelessInterfaceListProps {
  formDisabled?: boolean;
  showButtons?: boolean;
  onUpdate: () => any;
}

const WirelessInterfaceList = ({ onUpdate, formDisabled, showButtons = true }: WirelessInterfaceListProps) => {
  const dispatch = useTypedDispatch();
  const { showModal } = useShowModal();
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const currentDevice = useSelector(selectCurrentDevice);
  const selectedConfiguration = useSelector(selectCurrentConfiguration);
  const currentWirelessInterface = useSelector(selectCurrentWirelessInterface);
  const currentWirelessInterfaceId = currentWirelessInterface?.id || 0;
  const currentConfiguration = selectedConfiguration || currentDevice?.configuration;
  const wirelessInterfaceList = currentConfiguration?.wirelessInterfaces || [];
  const isCreateMode = currentDeviceId === -1;
  const isWirelessInterfacesEditEnabled = !formDisabled && !isCreateMode;

  const columns = useMemo(() => {
    return [
      { field: 'name', headerName: 'Name' },
      { field: 'bestPractice', headerName: 'BP' },
    ];
  }, []);

  const rows = useMemo(() => {
    return wirelessInterfaceList.map((wirelessInterface) => {
      return {
        id: wirelessInterface.id,
        name: wirelessInterface?.technologyConfiguration?.name,
        bestPractice: <WirelessInterfaceBestPracticeBadge wirelessInterface={wirelessInterface} />
      }
    })
  }, [wirelessInterfaceList]);

  const onWirelessInterfaceSelect = (id?: number) => {
    dispatch(setCurrentWirelessInterfaceId(id));
  }

  const onAddWirelessInterface = (technologyConfigurationId: number) => {
    if (!currentDevice || !technologyConfigurationId) {
      return;
    }
    dispatch(
      addWirelessInterfaceToDevice({
        deviceId: currentDevice.id,
        technologyConfigurationId
      })
    )
      .then((action) => {
        const device = action.payload as DeviceModel;
        const wirelessInterfaceList = (device.configuration.wirelessInterfaces || []).slice();
        const last = wirelessInterfaceList.pop();
        dispatch(setCurrentWirelessInterfaceId(last?.id));
        onUpdate && onUpdate()
      })
  }

  const onDelete = () => {
    if (!currentWirelessInterface) {
      return;
    }

    dispatch(deleteWirelessInterface(currentWirelessInterface.id))
      .then(onUpdate)
    dispatch(setCurrentWirelessInterfaceId(null));
  }

  const onDeleteWirelessInterface = () => {
    showModal(ModalType.MODAL_GENERIC_CONFIRM, {
      title: 'Delete wireless interface',
      text: 'You are about to delete the selected wireless interface. Are you sure?',
      confirmButtonHandler: onDelete,
      confirmButtonText: "Delete",
    });
  }

  return (
    <>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Interfaces'>
          {
            showButtons ? (
              <>
                <IconButton
                  disabled={!isWirelessInterfacesEditEnabled || !currentWirelessInterface}
                  aria-label='Delete wireless interface'
                  color='secondary'
                  size='small'
                  onClick={onDeleteWirelessInterface}
                >
                  <Delete />
                </IconButton>
                <AddWirelessInterfaceDialog
                  disabled={!isWirelessInterfacesEditEnabled}
                  onAdd={onAddWirelessInterface}
                />
              </>
            ) : null
          }
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <TableList
          columns={columns}
          rows={rows}
          selectRow={onWirelessInterfaceSelect}
          selectedId={currentWirelessInterfaceId}
          hideHeader={true}
        />
      </HorizontalPanel>
    </>
  )
}

export default WirelessInterfaceList;