import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { selectCurrentLocation, selectCurrentLocationId } from "../../../store/locationStore/locationSelectors";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { pagePathGenerator } from "../../../routes/pagePathGenerator";
import MiniMapContainer from "../../Map/MiniMapContainer";
import MapBreadcrumbs from "../../Map/MapBreadcrumbs";
import WifiTethering from "@material-ui/icons/WifiTethering";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import { CheckPermission } from "../../../requests/CheckPermission";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import FAIcon from "../../Shared/MapPreview/images/freqAnalysis.svg";

const LocationSmallMapPanel = () => {
  const history = useHistory();
  const currentLocation = useSelector(selectCurrentLocation)
  const currentLocationId = useSelector(selectCurrentLocationId);
  const mapDisabled = !currentLocationId || currentLocationId === -1

  const onMapClick = () => {
    if (mapDisabled) {
      return;
    }

    const url = pagePathGenerator.MAP_PAGE({
      locationId: currentLocationId,
    });

    history.push(url);
  }

  const onDevicesMapClick = () => {
    const devicesMapUrl = pagePathGenerator.DEVICES_MAP({ mapId: currentLocation?.mapId });
    history.push(devicesMapUrl);
  }

  const onFrequencyAnalysisClick = () => {
    const frequencyAnalysisUrl = pagePathGenerator.FREQUENCY_ANALYSIS({ mapId: currentLocation?.mapId });
    history.push(frequencyAnalysisUrl);
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Location map'>
          <IconButton
            aria-label='open device map'
            color='secondary'
            size='small'
            onClick={onDevicesMapClick}
          >
            <WifiTethering />
          </IconButton>
          <IconButton
            disabled={mapDisabled}
            aria-label='location map'
            color='secondary'
            size='small'
            onClick={onMapClick}
          >
            <FullscreenIcon />
          </IconButton>
          <CheckPermission permissionName={PERMISSIONS.FREQUENCY_ANALYSIS_ACCESS} noPermissionComponent={null}>
            <IconButton
              component="span"
              color="secondary"
              size="small"
              onClick={onFrequencyAnalysisClick}
            >
              <img
                src={FAIcon}
                alt={'Frequency Analysis'}
              />
            </IconButton>
          </CheckPermission>
        </Toolbar>
      </HorizontalPanel>
      <MiniMapContainer
        map={currentLocation?.map}
        title={(
          <MapBreadcrumbs />
        )}
      />
    </HorizontalPanel>
  )
}

export default LocationSmallMapPanel;