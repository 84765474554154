import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { WorkflowFormProps } from "./workflowFormProps";
import { useSelector } from "react-redux";
import { selectAllLocationsSorted } from "../../../../store/locationStore/locationSelectors";
import { selectAllBuildingsSorted } from "../../../../store/buildingStore/buildingsSelectors";
import { Dropdown } from "../../../../app/components/Dropdown";
import Multiselect from "../../../../components/Multiselect";
import { selectAllCountriesSorted } from "../../../../store/countryStore/countrySelectors";

const BuildingTypeForm = (props: WorkflowFormProps) => {
  const countryList = useSelector(selectAllCountriesSorted) || [];
  const locationList = useSelector(selectAllLocationsSorted) || [];
  const buildingsList = useSelector(selectAllBuildingsSorted) || [];

  const { formik, onCountryChange } = props;
  const currentCountryId = formik.values.countries[0] || 0;
  const currentLocationId = formik.values.locations[0] || 0;

  const filteredLocationList = locationList.filter((location) => location.countryId === currentCountryId);
  const filteredBuildingsList = buildingsList.filter((building) => building.locationId === currentLocationId);

  useEffect(() => {
    if (formik.values.countries.length !== 1) {
      formik.setFieldValue('countries', []);
      formik.setFieldValue('locations', []);
      formik.setFieldValue('buildings', []);
    } else {
      if (formik.values.locations.length !== 1) {
        formik.setFieldValue('locations', []);
        formik.setFieldValue('buildings', []);
      } else {
        const location = locationList.find((location) => location.id === currentLocationId);

        if (location?.countryId !== currentCountryId) {
          formik.setFieldValue('locations', []);
          formik.setFieldValue('buildings', []);
        }
      }
    }
  }, []);

  const _onCountryChange = (e) => {
    formik.setFieldValue('locations', []);
    formik.setFieldValue('buildings', []);
    formik.setFieldValue('countries', [e.target.value]);
    onCountryChange();
  }

  const onLocationChange = (e) => {
    formik.setFieldValue('buildings', []);
    formik.setFieldValue('locations', [e.target.value]);
  }

  const onBuildingsChange = (values: number[]) => {
    formik.setFieldValue('buildings', values);
  }

  const onBuildingsDelete = ({ id }) => {
    formik.setFieldValue(
      'buildings',
      formik.values.buildings.filter((item) => item !== id)
    )
  }

  const selectBuildingsList = Multiselect.getValues(filteredBuildingsList);
  const currentBuildingsList = Multiselect.getSelectedValues(filteredBuildingsList, formik.values.buildings);

  return (
    <>
      <Grid item xs={6}>
        <Dropdown
          selectedValue={currentCountryId}
          values={countryList}
          label='Country'
          name='countries'
          valueChange={_onCountryChange}
          fullWidth
          required
          error={formik.errors.countries}
        />
      </Grid>
      <Grid item xs={6}>&nbsp;</Grid>
      <Grid item xs={6}>
        <Dropdown
          selectedValue={currentLocationId}
          values={filteredLocationList}
          disabled={filteredLocationList.length === 0}
          label='Locations'
          name='locations'
          valueChange={onLocationChange}
          fullWidth
          required
          error={formik.errors.locations}
        />
      </Grid>
      <Grid item xs={6}>&nbsp;</Grid>
      <Grid item xs={6}>
        <Multiselect
          title='Buildings'
          name='buildings'
          disabled={selectBuildingsList.length === 0}
          fullWidth
          required
          items={selectBuildingsList}
          selectedItems={currentBuildingsList}
          onChange={onBuildingsChange}
          onDelete={onBuildingsDelete}
          helperText={formik.errors.buildings}
          error={Boolean(formik.errors.buildings)}
        />
      </Grid>
      <Grid item xs={6}>&nbsp;</Grid>
    </>
  )
}

export default BuildingTypeForm;