import { useSelector } from "react-redux";
import { Cancel, CheckCircle } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import WirelessInterfaceModel from "../../../models/WirelessInterfaceModel";
import {
  selectTechnologyConfigurationById,
} from "../../../store/technologyConfigurationStore/technologyConfigurationSelectors";
import TechnologyConfigurationModel from "../../../models/TechnologyConfigurationModel";
import { convertToDBm } from "../../../helpers/converters/power";

import classes from './index.module.scss';

interface WirelessInterfaceBestPracticeBadgeProps {
  wirelessInterface: WirelessInterfaceModel | null;
}

const WirelessInterfaceBestPracticeBadge = ({ wirelessInterface }: WirelessInterfaceBestPracticeBadgeProps) => {
  const currentTechnologyConfiguration: TechnologyConfigurationModel | undefined = useSelector(selectTechnologyConfigurationById(wirelessInterface?.technologyConfigurationId));
  const currentChannels = wirelessInterface?.channels || [];
  const hasChannels = currentChannels.length > 0;
  const { bestPracticeMinEIRP, bestPracticeMaxEIRP } = currentTechnologyConfiguration || {};
  const minEIRPValue = convertToDBm(bestPracticeMinEIRP) || 0;
  const maxEIRPValue = convertToDBm(bestPracticeMaxEIRP) || Infinity;

  const allChannelsAreBestPractice = currentChannels.every((channel) => {
    const { EIRP, WirelessInterfaceChannel } = channel;
    const value = WirelessInterfaceChannel?.EIRP || EIRP;
    const EIRPValue = convertToDBm(value);
    return channel.isBestPractice && EIRPValue >= minEIRPValue && EIRPValue <= maxEIRPValue;
  });

  const isBestPractice = hasChannels && allChannelsAreBestPractice;

  if (!wirelessInterface) {
    return null;
  }

  return (
    isBestPractice ?
      <Tooltip title='Aligned with best practice'><CheckCircle color="primary" className={classes.icon} /></Tooltip> :
      <Tooltip title='Not aligned with best practice'><Cancel color="error" className={classes.icon} /></Tooltip>
  )
}

export default WirelessInterfaceBestPracticeBadge;