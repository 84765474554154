import HorizontalPanel from "../../../components/HorizontalPanel";
import BreadcrumbsContainer from "../../Shared/BreadcrumbsContainer";
import LocationEditForm from "../LocationEditForm";
import BuildingList from "../BuildingList";
import LocationSmallMapPanel from "../LocationSmallMapPanel";

const LocationCentralPanel = () => (
  <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
    <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
      <BreadcrumbsContainer items={['locations']} />
    </HorizontalPanel>
    <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
      <LocationEditForm />
    </HorizontalPanel>
    <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
      <LocationSmallMapPanel />
    </HorizontalPanel>
    <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
      <BuildingList />
    </HorizontalPanel>
  </HorizontalPanel>
);

export default LocationCentralPanel;