import {
  createSlice,
} from "@reduxjs/toolkit";

const SLICE_NAME = "freqAnalysis";

const initialState = {
  channelsForD3: [],
  canvasWidthInHz: 1,
  rangeWithUnits: []
};


export const freqAnalysisSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setChannelsForD3 (state, action) {
        state.channelsForD3 = action.payload;
    },
    setCanvasWidthInHz (state, action) {
      state.canvasWidthInHz = action.payload;
    },
    setRangeWithUnits (state, action) {
      state.rangeWithUnits = action.payload;
    }
  }
});

export const { setChannelsForD3, setCanvasWidthInHz, setRangeWithUnits } = freqAnalysisSlice.actions;

