import {
  setCanvasWidthInHz,
  setChannelsForD3,
  setRangeWithUnits,
  useTypedDispatch
} from "../../../app/state";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { convertToHz } from "../../../helpers/converters/frequency";
import _ from "lodash";
import { Toolbar } from "../../../app/components";
import { Button, Grid } from "@material-ui/core";
import UnitsInput from "../../../components/TextFields/UnitsInput";
import { Menu } from "../../../app/shared/menu/Menu";
import useDevicesPage from "../../DevicesPage/devicePageHooks";
import { selectDevicesByMapId } from "../../../store/deviceStore/deviceSelectors";
import {
  selectAllTechnologyConfigurations
} from "../../../store/technologyConfigurationStore/technologyConfigurationSelectors";
import getValidation from './validation';
import { useSnackbar } from "../../../app/shared/snackbar-context/snackbar.context";
import ChannelModel from "../../../models/ChannelModel";

import styles from "./index.module.scss";

const AnalysisRightPanel = () => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
  const { openSnackbar } = useSnackbar();
  const inputRef = useRef<HTMLDivElement>();
  const { state } = useDevicesPage();
  const { currentMapId } = state;
  const currentPlaceDevices = useSelector(selectDevicesByMapId(currentMapId));

  const allTechnologies = useSelector(selectAllTechnologyConfigurations);
  const [filteredDevices, setFilteredDevices] = useState([] as any);
  const [filteredTechnologies, setFilteredTechnologies] = useState([] as any);
  const [selectedTechnologyId, setSelectedTechnologyId] = useState(0);
  const [selectedDeviceId, setSelectedDeviceId] = useState(0);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  useEffect(() => {
    setFilteredDevices([]);
  }, []);

  const rangeFormik = useFormik({
    initialValues: {
      frequencyFrom: '',
      frequencyTo: '',
    },
    onSubmit: () => {},
    validationSchema: getValidation(),
    validateOnChange: false,
  });

  const rawFrequencyFrom = useMemo(() => {
    return convertToHz(rangeFormik.values.frequencyFrom);
  }, [rangeFormik.values.frequencyFrom])

  const rawFrequencyTo = useMemo(() => {
    return convertToHz(rangeFormik.values.frequencyTo);
  }, [rangeFormik.values.frequencyTo])

  const checkChannel = (channel: ChannelModel, bandwidth = '0 Hz') => {
    return (
      (channel.centralFrequencyHz - convertToHz(bandwidth) / 2) <= rawFrequencyTo &&
      (channel.centralFrequencyHz - convertToHz(bandwidth) / 2) >= rawFrequencyFrom
    ) || (
      (channel.centralFrequencyHz + convertToHz(bandwidth) / 2) <= rawFrequencyTo &&
      (channel.centralFrequencyHz + convertToHz(bandwidth) / 2) >= rawFrequencyFrom
    )
  }

  const filterDevices = useCallback(() => {
    const filteredDevices = currentPlaceDevices.filter((device) => {
      return (device?.configuration?.wirelessInterfaces || []).some((wi) => {
        return (wi?.channels || []).some((channel) => checkChannel(channel, wi.technologyConfiguration?.bandwidth))
      })
    });

    if (filteredDevices.length === 0) {
      openSnackbar({
        title: 'No devices found',
        message: 'No devices according selected filters',
        severity: 'warning',
      });
    }

    setFilteredDevices(filteredDevices);

  }, [currentPlaceDevices, rawFrequencyFrom, rawFrequencyTo])

  useEffect(() => {
    const technologyIds: number[] = [];
    filteredDevices.forEach((device) => {
      device?.configuration?.wirelessInterfaces.forEach((wi) => {
        if (wi?.channels.some((channel) => checkChannel(channel, wi.technologyConfiguration?.bandwidth))) {
          const { technologyConfigurationId } = wi;
          const technologyConfiguration = allTechnologies.find((technology) => technology.id === technologyConfigurationId)

          if (technologyConfiguration) {
            technologyIds.push(technologyConfiguration.id)
          }
        }
      })
    })

    const filteredTechnologies = _.sortBy(allTechnologies.filter((technology) => {
      return technologyIds.includes(technology.id);
    }), 'id', 'asc');

    setFilteredTechnologies(filteredTechnologies);
    setSelectedTechnologyId(filteredTechnologies[0]?.id)
  }, [filteredDevices, allTechnologies])

  const canvasWidthInHz = useMemo(() => {
    return rawFrequencyTo - rawFrequencyFrom;
  }, [rawFrequencyFrom, rawFrequencyTo])

  useEffect(() => {
    setTimeout(() => {
      dispatch(setCanvasWidthInHz(canvasWidthInHz));
      dispatch(setRangeWithUnits([rangeFormik.values.frequencyFrom, rangeFormik.values.frequencyTo]))
    }, 0);
  }, [canvasWidthInHz])

  const channelsForD3 = useMemo(() => { // redo

    const channels = [] as any;

    filteredDevices.forEach((device) => {
      device?.configuration?.wirelessInterfaces.forEach((wi) => {
        wi?.channels.filter((channel) => checkChannel(channel, wi.technologyConfiguration?.bandwidth))
          .forEach((channel) => {
            channels.push({
              technologyConfigurationId: wi.technologyConfigurationId,
              centralFrequencyHz: channel.centralFrequencyHz,
              bandwidth: convertToHz(wi.technologyConfiguration?.bandwidth)
            })
          })
      })
    })

    const filtered = _.uniqWith(
      _.sortBy(
        channels,
        'technologyConfigurationId',
        'asc'
      ),
      _.isEqual
    );

    return (
      filtered.map((item) => item.technologyConfigurationId)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => {
          return {
            technologyConfigurationId: value,
            technologyName: allTechnologies.find((technology) => technology.id === value)?.name,
            channels: filtered.filter((channel) => channel.technologyConfigurationId === value).map((channel) => {
              return {
                centralFrequencyHz: channel.centralFrequencyHz,
                bandwidth: channel.bandwidth
              }
            })
          }
        })
    )

  }, [filteredDevices, rawFrequencyFrom, rawFrequencyTo])

  useEffect(() => {
    setTimeout(() => {
      dispatch(setChannelsForD3(channelsForD3));
    }, 0)
  }, [channelsForD3])

  const devicesByTechnology = useMemo(() => {
    const selectedTechnologyConfiguration = allTechnologies.find((technology) => technology.id === selectedTechnologyId);
    return filteredDevices.filter((device) => {
      return device?.configuration?.wirelessInterfaces.some((wi) => wi.technologyConfigurationId === selectedTechnologyConfiguration?.id);
    })
  }, [filteredDevices, selectedTechnologyId])

  useEffect(() => {
    setSelectedDeviceId(devicesByTechnology[0]?.id);
  }, [devicesByTechnology])

  return (
    <>
      <Toolbar title={'Frequency Range'} />
      <Grid container className={styles.container}>
        <Grid container spacing={2}>
          <Grid container item xs>
            <UnitsInput
              inputRef={inputRef}
              autoFocus={true}
              unitList={UnitsInput.UNIT_TEMPLATE.FREQUENCY}
              fullWidth
              name='frequencyFrom'
              variant='outlined'
              label={'Frequency range from'}
              disabled={false}
              onChange={rangeFormik?.handleChange}
              value={rangeFormik?.values?.frequencyFrom}
              error={Boolean(rangeFormik?.errors?.frequencyFrom)}
              helperText={rangeFormik?.errors?.frequencyFrom}
            />
          </Grid>
          <Grid container item xs>
            <UnitsInput
              unitList={UnitsInput.UNIT_TEMPLATE.FREQUENCY}
              fullWidth
              name='frequencyTo'
              variant='outlined'
              label={'Frequency range to'}
              disabled={false}
              onChange={rangeFormik?.handleChange}
              value={rangeFormik?.values?.frequencyTo}
              error={Boolean(rangeFormik?.errors?.frequencyTo)}
              helperText={rangeFormik?.errors?.frequencyTo}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid container item xs>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={styles.button}
              disabled={
                !rangeFormik.values.frequencyFrom ||
                !rangeFormik.values.frequencyTo
              }
              onClick={async () => {
                const valid = await rangeFormik.validateForm();
                if (_.isEmpty(valid)) {
                  filterDevices()
                }
              }}
            >
              Show
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {
        Boolean(filteredTechnologies.length) && (
          <>
            <Toolbar title={t('Technologies')} />
            <Menu
              menuItems={filteredTechnologies}
              selectItem={setSelectedTechnologyId}
              selectedItemId={selectedTechnologyId}
            />
          </>
        )}
      {
        Boolean(devicesByTechnology.length) &&
        <>
          <Toolbar title={t('Devices')} />
          <Menu
            menuItems={devicesByTechnology}
            selectItem={setSelectedDeviceId}
            selectedItemId={selectedDeviceId}
            showDeviceIcon={true}
          />
        </>
      }
    </>
  );
};

export default AnalysisRightPanel;