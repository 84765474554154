import { DataGrid, DataGridProps, GridCellParams } from "@material-ui/data-grid";
import { createStyles, makeStyles } from "@material-ui/styles";

// todo: review these styles
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxHeight: '50vh',
      minHeight: '150px',
      fontSize: '14px',
      '& .MuiDataGrid-columnSeparator': {
        color: 'transparent',
        width: '0px',
      },
      '& .MuiDataGrid-columnHeader': {
        padding: 0,
        fontWeight: 500,
        fontSize: '14px',
      },
      '& .MuiDataGrid-cell': {
        padding: 0,
        wordWrap: 'break-word',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer:first-child': {

      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        paddingRight: '0px',
        paddingLeft: '0px',
      },
      '& .MuiDataGrid-window': {
        overflowX: 'hidden',
      },
      '& .MuiDataGrid-checkboxInput': {
        width: '15px',
        height: '15px',
      },
    },
  })
);

const StyledDataGrid = (props: DataGridProps) => (
  <DataGrid
    hideFooter={true}
    onCellClick={(params: GridCellParams) => {
      params.hasFocus = false;
    }}
    onRowClick={() => {}}
    onCellBlur={() => {}}
    scrollbarSize={3}
    classes={useStyles()}
    {...props}
  />
);

export default StyledDataGrid;