import qs from "query-string";
import _ from "lodash/fp";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../app/api";
import DeviceModel from "../../models/DeviceModel";
import UnsavedWirelessInterfaceModel from "../../models/UnsavedWirelessInterfaceModel";
import { convertToHz } from "../../helpers/converters/frequency";
import { SearchInterface } from "./searchInterface";
import { serializeError } from "../../app/shared";

const SLICE_NAME = "deviceList";

export const requestAllDevicesByQuery = createAsyncThunk(
  `${SLICE_NAME}/get-all`,
  async (query: { mapId?: number }, { rejectWithValue }) => {
    try {
      const deviceList: DeviceModel[] = await request.get(
        "/device",
        {
          params: query || {},
        }
      );
      return deviceList;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const requestDeviceById = createAsyncThunk(
  `${SLICE_NAME}/get-by-id`,
  async (id: number, { rejectWithValue }) => {
    try {
      const device: DeviceModel = await request.get(
        "/device/" + id
      );
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const addWirelessInterfaceToDevice = createAsyncThunk(
  `${SLICE_NAME}/add-wireless-interface`,
  async ({ deviceId, technologyConfigurationId }: { deviceId: number, technologyConfigurationId: number }, { rejectWithValue }) => {
    try {
      const device: DeviceModel = await request.post('/device/wireless-interface', {
        deviceId,
        technologyList: [technologyConfigurationId],
      });
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const createNewDevice = createAsyncThunk(
  `${SLICE_NAME}/create`,
  async (payload: unknown, { rejectWithValue }) => {
    try {
      const device: DeviceModel = await request.post(`/device`, payload);
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const updateDevice = createAsyncThunk(
  `${SLICE_NAME}/update`,
  async (payload: unknown, { rejectWithValue }) => {
    try {
      const device: DeviceModel = await request.put(`/device`, payload);
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const submitDevice = createAsyncThunk(
  `${SLICE_NAME}/submit`,
  async (id: number | undefined | null, { rejectWithValue }) => {
    try {
      const info: { device?: DeviceModel, errorCode: string } = await request.post(`/device/${id}/submit`);
      return info;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const deleteDevice = createAsyncThunk(
  `${SLICE_NAME}/delete`,
  async (id: number | null, { rejectWithValue }) => {
    try {
      await request.delete(`/device/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const assignDevice = createAsyncThunk(
  `${SLICE_NAME}/assign`,
  async ({ id, userId }: { id: number, userId: number }, { rejectWithValue }) => {
    try {
      const device: DeviceModel = await request.post(`/device/${id}/assign`, { userId });
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const approveDevice = createAsyncThunk(
  `${SLICE_NAME}/approve`,
  async ({ id, reason }: { id: number, reason: string }, { rejectWithValue }) => {
    try {
      const info: { device?: DeviceModel, errorCode: string } = await request.post(`/device/${id}/approve`, { reason });
      return info;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const rejectDevice = createAsyncThunk(
  `${SLICE_NAME}/reject`,
  async ({ id, reason }: { id: number, reason: string }, { rejectWithValue }) => {
    try {
      const info: { device?: DeviceModel, errorCode: string } = await request.post(`/device/${id}/reject`, { reason });
      return info;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const deleteWirelessInterface = createAsyncThunk(
  `${SLICE_NAME}/delete-wireless-interface`,
  async (id: number, { rejectWithValue }) => {
    try {
      const device: DeviceModel = await request.delete('/wireless-interface', { data: { id } });
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const updateWirelessInterface = createAsyncThunk(
  `${SLICE_NAME}/update-wireless-interface`,
  async (body: UnsavedWirelessInterfaceModel, { rejectWithValue }) => {
    try {
      const device: DeviceModel = await request.put('/wireless-interface', body);
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const doSearch = createAsyncThunk(
  `${SLICE_NAME}/search`,
  async (query: SearchInterface, { rejectWithValue }) => {
    const clonedQuery = _.clone(query);
    if (clonedQuery.frequencyFrom) {
      clonedQuery.frequencyFrom = convertToHz(clonedQuery.frequencyFrom as string)
    }
    if (query.frequencyTo) {
      clonedQuery.frequencyTo = convertToHz(clonedQuery.frequencyTo as string)
    }
    try {
      const response: { devices: DeviceModel[] } = await request.get(`/search`, {
        params: clonedQuery,
        paramsSerializer: (requestQuery) => {
          for (const param in requestQuery) {
            if (Array.isArray(requestQuery[param])) {
              if (!requestQuery[param].length) {
                delete requestQuery[param];
              } else {
                requestQuery[`${param}[]`] = requestQuery[param].join(',');
                delete requestQuery[param];
              }
            } else {
              if (!requestQuery[param]) {
                delete requestQuery[param];
              }
            }
          }
          return qs.stringify(requestQuery, { encode: false });
        }
      });
      return response.devices;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);


export const requestMyRequests = createAsyncThunk(
  `${SLICE_NAME}/requests`,
  async (_, { rejectWithValue }) => {
    try {
      const myDevicesList: DeviceModel[] = await request.get('/request/me');
      const myWorkflowList: DeviceModel[] = await request.get('/request/workflow');
      return {
        myDevicesList,
        myWorkflowList,
      };
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const upsertDevicePoint = createAsyncThunk(
  'point/device/create',
  async (devicePoint: { deviceId: number, point: { x: number, y: number } }, { rejectWithValue }) => {
    try {
      const { device }: { device: DeviceModel } = await request.post('/point/device/', devicePoint);
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const deleteDevicePoint = createAsyncThunk(
  'point/device/delete',
  async (deviceId: number, { rejectWithValue }) => {
    try {
      const { device }: { device: DeviceModel } = await request.delete(`/point/device/${deviceId}`);
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);