import { ReactElement } from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import NoMapContainer from "../NoMapContainer";
import MapModel from "../../../models/MapModel";
import { useDimensions } from "../../../hooks/dimensions.hook";
import { getMapUrl, isMapPointsSet } from "../../../helpers/mapHelpers";
import DeviceModel from "../../../models/DeviceModel";
import { selectCurrentDeviceId } from "../../../store/deviceStore/deviceSelectors";
import InfluenceArea from "../InfluenceArea";
import DeviceRenderer from "../DeviceRenderer";
import ZoomMap from "../../../components/Map/ZoomMap";

import classes from './index.module.scss';

interface MiniMapProps {
  map?: MapModel,
  deviceList?: DeviceModel[],
  title?: ReactElement | null,
}

const MiniMapContainer = ({ map, deviceList = [], title = null }: MiniMapProps) => {
  const container = useDimensions();
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const mapFileName = map?.url
  const mapUrl = mapFileName ? getMapUrl(mapFileName) : '';
  const isMapUploaded = !!mapUrl;
  const hasMapPoints = isMapPointsSet(map);

  return (
    <Grid
      container
      item
      xs
      className={classes.gridContainer}
      direction='column'
      innerRef={container.elementRef}
    >
      {title}
      {
        !isMapUploaded && (
          <NoMapContainer title='No map has been uploaded' />
        )
      }
      {
        isMapUploaded && !hasMapPoints && (
          <NoMapContainer title='Map has not been positioned' />
        )
      }
      {
        isMapUploaded && hasMapPoints && (
          <InfluenceArea
            deviceList={deviceList}
            currentDeviceId={currentDeviceId}
          >
            <DeviceRenderer
              deviceList={deviceList}
              currentDeviceId={currentDeviceId}
            >
              <ZoomMap
                isZoomEnabled={false}
                dimensions={container.dimensions}
                mapUrl={mapUrl}
              />
            </DeviceRenderer>
          </InfluenceArea>
        )
      }
    </Grid>
  )
}

export default MiniMapContainer;