import { createEntityAdapter, createSlice, EntityState, isAnyOf } from "@reduxjs/toolkit";
import { CountryModel } from "../../app/types";
import { LoadingStatus, status } from "../../app/shared";
import { getAllRegions } from "../regionStore/regionRequests";
import { addCountry, removeCountry, requestAllCountries, updateCountry } from "./countryRequests";


export const countryAdapter = createEntityAdapter<CountryModel>({
  selectId: (country) => country.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

type CountryState = {
  selectedCountryId?: number | null;
  loadAllStatus: LoadingStatus;
  editLoadingStatus: LoadingStatus;
} & EntityState<CountryModel>;

const initialState: CountryState = {
  selectedCountryId: null,
  loadAllStatus: status.default,
  editLoadingStatus: status.default,
  ...countryAdapter.getInitialState(),
};

export const countrySlice = createSlice({
  name: 'countryList',
  initialState,
  reducers: {
    setCurrentCountryId: (state, action) => {
      state.selectedCountryId = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllRegions.fulfilled, (state, action) => {
        let countryList: CountryModel[] = [];
        action.payload.forEach((region) => {
          countryList = countryList.concat(region?.countries || [])
        });

        countryAdapter.setAll(state, countryList);
      })
      .addCase(requestAllCountries.pending, (state) => {
        state.loadAllStatus = status.loading;
      })
      .addCase(requestAllCountries.rejected, (state, action) => {
        state.loadAllStatus = status.error(action.error);
      })
      .addCase(requestAllCountries.fulfilled, (state, action) => {
        state.loadAllStatus = status.loaded;
        countryAdapter.setAll(state, action.payload);
      })
      .addCase(removeCountry.fulfilled, (state, action) => {
        countryAdapter.removeOne(state, action.payload);
      })
      .addMatcher(isAnyOf(addCountry.fulfilled, updateCountry.fulfilled), (state, action) => {
        countryAdapter.upsertOne(state, action.payload);
      })
});

export const { setCurrentCountryId } = countrySlice.actions;