import { LoadingStatus, status } from '../../app/shared';
import {
  createSlice,
} from '@reduxjs/toolkit';
import { CountryModel } from "../../app/types";
import { getImportCountries, getImportState, uploadImportFile } from "./importRequests";

type ImportState = {
  countryList: CountryModel[];
  selectedCountry: CountryModel | null;
  importLog: string | null;
  importStatus: string | null;
  importCode: string | null;
  selectedFile: string | null;
  countryRequestState: LoadingStatus,
  importFileState: LoadingStatus,
  importStatusState: LoadingStatus,
};

const initialState: ImportState = {
  countryList: [],
  selectedCountry: null,
  importLog: null,
  importStatus: null,
  importCode: null,
  selectedFile: null,
  countryRequestState: status.default,
  importFileState: status.default,
  importStatusState: status.default,
};

export const importSlice = createSlice({
  name: 'import',
  initialState,
  reducers: {
    clearState: (state) => Object.assign(state, initialState),
    clearFileName: (state) => {
      state.selectedFile = null;
    },
    selectImportCountry: (state, action) => {
      state.selectedCountry = action?.payload;
    },
    selectImportFile: (state, action) => {
      state.selectedFile = action?.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getImportCountries.pending, (state) => {
        state.countryRequestState = status.loading
      })
      .addCase(getImportCountries.rejected, (state, action) => {
        state.countryRequestState = status.error(action.error as Error)
      })
      .addCase(getImportCountries.fulfilled, (state, action) => {
        state.countryRequestState = status.loaded;
        state.countryList = action?.payload;
      })
      .addCase(uploadImportFile.pending, (state) => {
        state.importFileState = status.loading;
      })
      .addCase(uploadImportFile.rejected, (state, action) => {
        state.importFileState = status.error(action.error as Error);
      })
      .addCase(uploadImportFile.fulfilled, (state) => {
        state.importFileState = status.loaded;
      })
      .addCase(getImportState.pending, (state) => {
        state.importStatusState = status.loading;
      })
      .addCase(getImportState.rejected, (state, action) => {
        state.importStatusState = status.error(action.error as Error);
      })
      .addCase(getImportState.fulfilled, (state, action) => {
        state.importStatusState = status.loaded;
        state.importLog = action?.payload?.importLog;
        state.importCode = action?.payload?.error;
        state.importStatus = action?.payload?.status;
        state.selectedCountry = state.countryList.find((country) => country.id === action?.payload?.countryId) || null;
      })
  ,
});

export const { clearState, selectImportCountry, selectImportFile, clearFileName } = importSlice.actions;