import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { CheckPermission } from "../../../requests/CheckPermission";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import { pagePathGenerator } from "../../../routes/pagePathGenerator";
import { selectCurrentFloor, selectCurrentFloorId } from "../../../store/floorStore/floorSelectors";
import FAIcon from './images/freqAnalysis.svg';
import { WifiTethering } from "@material-ui/icons";
import { isMapPointsSet } from "../../../helpers/mapHelpers";
import MiniMapContainer from "../../Map/MiniMapContainer";
import DeviceModel from "../../../models/DeviceModel";
import { selectCurrentDevice, selectCurrentWirelessInterface } from "../../../store/deviceStore/deviceSelectors";
import { selectCurrentConfiguration } from "../../../store/deviceConfigurationStore/deviceConfigurationSelectors";
import MapBreadcrumbs from "../../Map/MapBreadcrumbs";
import { selectCurrentLocation, selectCurrentLocationId } from "../../../store/locationStore/locationSelectors";

const MapPreview = ({ showCurrentDevice = false }) => {
  const history = useHistory();
  const currentLocationId = useSelector(selectCurrentLocationId);
  const currentLocation = useSelector(selectCurrentLocation);
  const currentFloorId = useSelector(selectCurrentFloorId);
  const currentFloor = useSelector(selectCurrentFloor);
  const currentDevice = useSelector(selectCurrentDevice);
  const currentWirelessInterface = useSelector(selectCurrentWirelessInterface);
  const selectedConfiguration = useSelector(selectCurrentConfiguration);
  const currentConfiguration = selectedConfiguration || currentDevice?.configuration;
  const mapModel = (currentFloor || currentLocation)?.map;
  const mapPreviewDisabled = currentFloorId === -1 || currentLocationId === -1;
  const isDeviceMapEnabled = isMapPointsSet(mapModel);
  const isLocationMap = !currentFloor;
  const mapUploadUrl = pagePathGenerator.FLOOR_MAP({ floorId: currentFloorId });
  const locationMapUrl = pagePathGenerator.MAP_PAGE({ locationId: currentLocation?.id });
  const devicesMap = pagePathGenerator.DEVICES_MAP({ mapId: mapModel?.id, deviceId: currentDevice?.id });
  const frequencyAnalysisUrl = pagePathGenerator.FREQUENCY_ANALYSIS({ mapId: mapModel?.id });

  const onDevicesMapClick = () => history.push(devicesMap);
  const onMapClick = () => history.push(isLocationMap ? locationMapUrl : mapUploadUrl);
  const onFrequencyAnalysisClick = () => history.push(frequencyAnalysisUrl);

  const deviceForMiniMap = {
    ...currentDevice,
    configuration: {
      ...currentConfiguration,
      wirelessInterfaces: currentWirelessInterface ? [currentWirelessInterface] : [],
    }
  } as DeviceModel;

  const devicesForPreview = mapPreviewDisabled
    ? []
    : showCurrentDevice
      ? [deviceForMiniMap]
      : []

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Map preview'>
          <IconButton
            disabled={!isDeviceMapEnabled || mapPreviewDisabled}
            aria-label='open big map'
            color='secondary'
            size='small'
            onClick={onDevicesMapClick}
          >
            <WifiTethering />
          </IconButton>
          <CheckPermission permissionName={PERMISSIONS.FLOOR_MAP_ACTIONS} noPermissionComponent={null}>
            <IconButton
              disabled={mapPreviewDisabled}
              aria-label='open frequency analysis'
              color='secondary'
              size='small'
              onClick={onMapClick}
            >
              <FullscreenIcon />
            </IconButton>
          </CheckPermission>
          <CheckPermission permissionName={PERMISSIONS.FREQUENCY_ANALYSIS_ACCESS} noPermissionComponent={null}>
            <IconButton
              disabled={mapPreviewDisabled}
              component="span"
              color="secondary"
              size="small"
              onClick={onFrequencyAnalysisClick}
            >
              <img
                src={FAIcon}
                alt={'Frequency Analysis'}
              />
            </IconButton>
          </CheckPermission>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <MiniMapContainer
          map={mapPreviewDisabled ? undefined : mapModel}
          deviceList={devicesForPreview}
          title={!mapPreviewDisabled ? (
            <MapBreadcrumbs
              {
                  ...(
                    isLocationMap ?
                      {
                        country: true,
                        location: true,
                      } :
                      {
                        building: true,
                        floor: true,
                      }
                  )
              }
            />
          ) : null}
        />
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default MapPreview;