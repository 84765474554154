import { isEmpty } from "lodash/fp";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { Grid, IconButton } from "@material-ui/core";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import UnitsInput from "../../../components/TextFields/UnitsInput";
import { selectCurrentManualFilters } from "../../../store/filterStore/filterSelectors";
import { useTypedDispatch } from "../../../app/state";
import validationSchema from './manualFilterValidation';
import Multiselect from "../../../components/Multiselect";
import {
  selectTechnologyConfigurationListByMapId,
  selectWirelessSystemListByMapId
} from "../../../store/deviceStore/deviceSelectors";
import useDevicesPage from "../../DevicesPage/devicePageHooks";
import { selectAllWirelessSystems } from "../../../store/wirelessSystemStore/wirelessSystemSelectors";
import {
  selectAllTechnologyConfigurations
} from "../../../store/technologyConfigurationStore/technologyConfigurationSelectors";
import Chip from "@material-ui/core/Chip";
import {
  setFilterPanelVisibility,
  setManualFilters,
  setSmartFilterDeviceId
} from "../../../store/filterStore/filterState";
import TransitEnterexitIcon from "@material-ui/icons/TransitEnterexit";

import classes from './index.module.scss';

const FilterPanel = ({ onSelectedDeviceClear }) => {
  const dispatch = useTypedDispatch();
  const { state } = useDevicesPage();
  const { currentMapId } = state;
  const manualFilters = useSelector(selectCurrentManualFilters);
  const fullWirelessSystemList = useSelector(selectAllWirelessSystems);
  const wirelessSystemIdList = useSelector(selectWirelessSystemListByMapId(currentMapId));
  const fullTechnologyList = useSelector(selectAllTechnologyConfigurations);
  const technologyConfigurationId = useSelector(selectTechnologyConfigurationListByMapId(currentMapId));

  const initialValues = {
    frequencyFrom: manualFilters?.frequencyFrom || '',
    frequencyTo: manualFilters?.frequencyTo || '',
    technologyConfigurationIdList: manualFilters?.technologyConfigurationIdList || [],
    wirelessSystemIdList: manualFilters?.wirelessSystemIdList || [],
  };

  const filterFormik = useFormik({
    initialValues,
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false
  });

  const wirelessSystemsInUse = Multiselect.getSelectedValues(
    fullWirelessSystemList,
    wirelessSystemIdList,
  );

  const wirelessSystemItems = Multiselect.getValues(wirelessSystemsInUse, 'id', 'value');

  const selectedWirelessSystemList = Multiselect.getSelectedValues(
    fullWirelessSystemList,
    filterFormik.values.wirelessSystemIdList,
  );

  const technologyListInUse = Multiselect.getSelectedValues(
    fullTechnologyList,
    technologyConfigurationId,
  );

  const technologyListItems = Multiselect.getValues(technologyListInUse, 'id', 'value');

  const selectedTechnologyListItems = Multiselect.getSelectedValues(
    fullTechnologyList,
    filterFormik.values.technologyConfigurationIdList,
  );

  const onApply = async () => {
    const valid = await filterFormik.validateForm();
    if (!isEmpty(valid)) {
      return;
    }

    dispatch(setManualFilters(filterFormik.values));
    dispatch(setSmartFilterDeviceId(null));
    dispatch(setFilterPanelVisibility(false));
    onSelectedDeviceClear();
  }

  const onClear = () => {
    dispatch(setManualFilters(null))
  }

  const onCloseClick = () => {
    dispatch(setFilterPanelVisibility(false));
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title="Manual filters">
          <IconButton
            aria-label='close window'
            color='secondary'
            size='small'
            onClick={onCloseClick}
          >
            <TransitEnterexitIcon />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <Grid
          container
          className={classes.container}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <UnitsInput
                name="frequencyFrom"
                unitList={UnitsInput.UNIT_TEMPLATE.FREQUENCY}
                label="Frequency from"
                variant='outlined'
                fullWidth
                value={filterFormik.values?.frequencyFrom}
                onChange={filterFormik.handleChange}
                error={Boolean(filterFormik?.errors?.frequencyFrom)}
                helperText={filterFormik?.errors?.frequencyFrom}
              />
            </Grid>
            <Grid item xs={6}>
              <UnitsInput
                name="frequencyTo"
                unitList={UnitsInput.UNIT_TEMPLATE.FREQUENCY}
                label="Frequency to"
                variant='outlined'
                fullWidth
                value={filterFormik.values?.frequencyTo}
                onChange={filterFormik.handleChange}
                error={Boolean(filterFormik?.errors?.frequencyTo)}
                helperText={filterFormik?.errors?.frequencyTo}
              />
            </Grid>
            <Grid item xs={12}>
              <Multiselect
                title='Wireless systems'
                name='wirelessSystemIdList'
                disabled={wirelessSystemItems.length === 0}
                fullWidth
                items={wirelessSystemItems}
                selectedItems={selectedWirelessSystemList}
                onChange={(values: number[]) => filterFormik.setFieldValue('wirelessSystemIdList', values)}
              />
            </Grid>
            <Grid item xs={12}>
              <Multiselect
                title='Technologies'
                name='technologyConfigurationIdList'
                disabled={technologyListItems.length === 0}
                fullWidth
                items={technologyListItems}
                selectedItems={selectedTechnologyListItems}
                onChange={(values: number[]) => filterFormik.setFieldValue('technologyConfigurationIdList', values)}
              />
            </Grid>
            <Grid item xs={6} container justifyContent='flex-start'>
              <Chip
                label="Apply"
                color={'primary'}
                onClick={onApply}
              />
            </Grid>
            <Grid item xs={6} container justifyContent='flex-end'>
              <Chip
                label="Clear"
                color={'default'}
                onClick={onClear}
              />
            </Grid>
          </Grid>
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default FilterPanel;