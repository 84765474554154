import { createAsyncThunk } from "@reduxjs/toolkit";
import { RegionModel } from "../../app/types";
import { request } from "../../app/api";
import { serializeError } from "../../app/shared";

const SLICE_NAME = 'region';

export const getAllRegions = createAsyncThunk(
  `${SLICE_NAME}/fetch-regions`,
  async (_, { rejectWithValue }) => {
    try {
      const regionList: RegionModel[] = await request.get("/region");
      return regionList;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const addRegion = createAsyncThunk(
  `${SLICE_NAME}/add-region`,
  async ({ name }: { name: string }, {rejectWithValue}) => {
  try {
    const region: RegionModel = await request.post("/region", { name });
    return region;
  } catch (error) {
    return rejectWithValue(serializeError(error));
  }
})

export const updateRegion = createAsyncThunk(
  `${SLICE_NAME}/update-region`,
  async ({ id, name }: RegionModel, { rejectWithValue }) => {
    try {
      const region: RegionModel = await request.put(`/region/${id}`, { name });
      return region;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const removeRegion = createAsyncThunk(
  `${SLICE_NAME}/delete-region`,
  async (id: number, {rejectWithValue}) => {
    try {
      await request.delete(`/region/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);