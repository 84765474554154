import PortableDeviceIcon from './images/portableDevice.svg';

const ScalePointUrl = `${process.env.PUBLIC_URL}/assets/icons/scalePoint.svg`;
const AnchorPointUrl = `${process.env.PUBLIC_URL}/assets/icons/anchor.svg`;
const DevicePointUrl = `${process.env.PUBLIC_URL}/assets/icons/device.svg`;

export const ICONS_DIMENSIONS = {
  scale: {
    width: 16,
    height: 28
  },
  anchor: {
    width: 16,
    height: 28
  },
  device: {
    width: 24,
    height: 24
  },
  portableDevice: {
    width: 60,
    height: 60,
  }
}

export const ICONS = {
  scale: ScalePointUrl,
  anchor: AnchorPointUrl,
  device: DevicePointUrl,
  portableDevice: PortableDeviceIcon,
}