import { useState, useRef, useLayoutEffect, useEffect } from "react";

export const useDimensions = () => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const elementRef = useRef<any>(null);

  const onResize = () => {
    if (elementRef.current) {
      setDimensions({
        width: elementRef.current.offsetWidth,
        height: elementRef.current.offsetHeight,
      });
    }

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onResize);
  }, []);

  useLayoutEffect(onResize, []);

  return { dimensions, elementRef };
};
