import React from "react";

const AddPropsToChildren = ({ children, extraProps = {}, ...snowballProps }) => {
  return React.Children.map(children, child => {
    const props = {
      ...child?.props,
    };

    const mergeSingleProp = (key, object) => {
      const value = object[key];

      if (!value) {
        return;
      }

      if (!props[key]) {
        props[key] = value;
        return;
      }

      if (key === 'className') {
        props[key] = props[key] + ' ' + value;
        return;
      }

      if (Array.isArray(props[key])) {
        props[key] = [...props[key], ...value];
        return;
      }

      if (typeof props[key] === 'object') {
        props[key] = {
          ...props[key],
          ...value,
        }
      }

      // to add functionality here
    }

    Object.keys(snowballProps).forEach((key) => {
      mergeSingleProp(key, snowballProps);
    });

    Object.keys(extraProps).forEach((key) => {
      mergeSingleProp(key, extraProps);
    });

    return child ? React.cloneElement(child, props) : null;
  })
}

export default AddPropsToChildren;