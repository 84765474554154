import React from "react";
import useDevicesPage from "../devicePageHooks";
import { TextField } from "@material-ui/core";

const IndoorOutdoorField = () => {
  const { state } = useDevicesPage();
  const { currentFloor } = state;
  const isOutdoorDevice = !currentFloor;

  return (
    <TextField
      label='Indoor\outdoor'
      variant='outlined'
      name='indoor'
      fullWidth
      disabled={true}
      value={isOutdoorDevice ? 'Outdoor': 'Indoor'}
    />
  )
}

export default IndoorOutdoorField;