import { GridColDef } from "@material-ui/data-grid";
import { Typography } from "@material-ui/core";

import classes from './index.module.scss';

const channelColumns: GridColDef[] = [
  {
    field: 'channelNumber',
    headerName: 'Channel Number',
    flex: 2,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: any): any => {
      return (
        <Typography variant='body1' className={classes.text}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: 'downlinkCF',
    headerName: 'Central Frequency',
    flex: 2,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: any): any => {
      return (
        <Typography variant='body1' className={classes.text}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: 'isBestPractice',
    headerName: 'Best Practice',
    flex: 1,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: any): any => {
      return (
        <Typography variant='body1' className={classes.text}>
          {params.value ? 'Yes' : 'No'}
        </Typography>
      );
    },
  },
];

export default channelColumns;