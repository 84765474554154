import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, IconButton } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {
  selectAllWorkflowList, selectCurrentWorkflow,
} from '../../../../store/workflows/workflowSelectors';
import { AppDispatch } from '../../../../app/state';
import { Toolbar } from '../../../../app/components';
import { TableList } from '../../../../app/components/TableList';
import NoItems from '../../../../components/NoItems';
import HorizontalPanel from "../../../../components/HorizontalPanel";
import { selectAllCountries } from "../../../../store/countryStore/countrySelectors";
import { selectAllLocations } from "../../../../store/locationStore/locationSelectors";
import { extractWorkflowViewModel } from "../workflowHelper";
import { setCurrentWorkflowId } from "../../../../store/workflows/workflowState";

const WorkflowList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const allWorkflows = useSelector(selectAllWorkflowList) || [];
  const selectedWorkflow = useSelector(selectCurrentWorkflow);
  const allCountryList = useSelector(selectAllCountries) || [];
  const allLocationList = useSelector(selectAllLocations) || [];

  const modifiedWorkflows = allWorkflows.map((workflow) => extractWorkflowViewModel(workflow, allCountryList, allLocationList));

  const columns = [
    { flex: 1, field: 'name', headerName: 'Workflow name', maxLength: 50 },
    { flex: 1, field: 'type', headerName: 'Level', maxLength: 50 },
    { flex: 1, field: 'countries', headerName: 'Countries', maxLength: 50 },
    { flex: 1, field: 'locations', headerName: 'Locations', maxLength: 50 },
    { flex: 1, field: 'buildings', headerName: 'Buildings', maxLength: 50 },
    { flex: 1, field: 'technologies', headerName: 'Technologies', maxLength: 50 },
  ];

  const startEditWorkflowHandler = useCallback(
    (id) => {
      dispatch(setCurrentWorkflowId(id));
    },
    [allWorkflows],
  );

  const createWorkflow = () => {
    dispatch(setCurrentWorkflowId(-1));
  };

  return (
    <>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Workflow list'>
          <IconButton
            aria-label='add workflow'
            color='secondary'
            size='small'
            onClick={createWorkflow}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} addScroll>
        {modifiedWorkflows.length === 0 && (
          <Grid container justifyContent='center'>
            <NoItems title={'There is no workflow yet'} />
          </Grid>
        )}
        {modifiedWorkflows.length > 0 && (
          <TableList
            selectedId={selectedWorkflow?.id}
            columns={columns}
            rows={modifiedWorkflows}
            selectRow={startEditWorkflowHandler} />
        )}
      </HorizontalPanel>
    </>
  );
};

export default WorkflowList;