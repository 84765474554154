import { extractNumber, extractUnits } from "./units";

const frequencyFactors = {
  'nHz': 0.000000001,
  // 'µHz': 0.000001,
  'mHz': 0.001,
  'Hz': 1,
  'kHz': 1000,
  'MHz': 1000000,
  'GHz': 1000000000,
  'THz': 1000000000000,
}

export function convertToHz(str: string) {
  const unit = extractUnits(str);
  const value = extractNumber(str);

  let factor = frequencyFactors[unit];

  if (unit === 'µHz') {
    factor = 0.000001;
  }

  return Math.floor(value * factor);
}