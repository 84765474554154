import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../app/api';
import { serializeError } from '../../app/shared';

export const getAllUserList = createAsyncThunk(
  `/user/all`,
  async (
    _,
    { rejectWithValue },
  ) => {
    try {
      const response = await request.get(
        `/user/all`,
      );
      const { users } = response as any;
      return { users };
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const getAllUserRoles = createAsyncThunk(
  `/role/all`,
  async (
    _,
    { rejectWithValue },
  ) => {
    try {
      const response = await request.get(
        `/role/all`,
      );
      const { roles } = response as any;
      return { roles };
    } catch (error) {
      rejectWithValue(serializeError(error));
    }
  },
);

export const requestManagersList = createAsyncThunk(
  `/user/role/2`,
  async (
    _,
    { rejectWithValue },
  ) => {
    try {
      const response = await request.get(
        `/user/role/2`,
      );

      const { users } = response as any;

      return { users };
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const requestWorkflowList = createAsyncThunk(
  `/workflow/user`,
  async (
    byDeviceId: number,
    { rejectWithValue },
  ) => {
    try {
      const response = await request.get(
        `/workflow/user`,
        {
          params: { byDeviceId }
        }
      );

      const { users } = response as any;

      return { users };
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const requestRequestersList = createAsyncThunk(
  `/device/requester/all`,
  async (
    _,
    {rejectWithValue},
  ) => {
    try {
      const response = await request.get(
        `/device/requester/all`,
      );

      const {requesters} = response as any;

      return {requesters};
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);