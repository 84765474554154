import { createEntityAdapter, createSlice, EntityState, isAnyOf } from "@reduxjs/toolkit";
import { BuildingModel, FloorModel } from "../../app/types";
import { LoadingStatus, status } from "../../app/shared";
import { loadBuildingsByLocationId } from "../buildingStore/buildingsRequests";
import {
  createFloor,
  deleteFloor,
  editFloor,
  getAllFloors, getFloorByQuery,
  requestFloorById,
} from "./floorRequests";
import { deleteFloorMapFile, uploadFloorMapFile } from "../mapStore/mapRequests";

export const floorAdapter = createEntityAdapter<FloorModel>({
  selectId: (floor) => floor.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

type FloorState = {
  selectedFloorId: number | null;
  loadAllFloors: LoadingStatus;
  allLoadings: LoadingStatus;
} & EntityState<FloorModel>;

const initialState: FloorState = {
  selectedFloorId: null,
  loadAllFloors: status.default,
  allLoadings: status.default,
  ...floorAdapter.getInitialState(),
}

export const floorSlice = createSlice({
  name: 'floorsList',
  initialState,
  reducers: {
    setCurrentFloorId: (state, action) => {
      state.selectedFloorId = action.payload;
    },
    setCurrentFloorByMapId: (state, action) => {
      const mapId = action.payload as number;

      let currentFloor: FloorModel;
      const selectors = floorAdapter.getSelectors();
      const floorList = selectors.selectAll(state);

      (floorList || []).forEach((floor) => {
        if (floor?.mapId === mapId) {
          currentFloor = floor;
        }
      });

      // @ts-ignore
      state.selectedFloorId = currentFloor?.id || null;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(loadBuildingsByLocationId.fulfilled, (state, action) => {
        const buildingList: BuildingModel[] = action.payload;
        let floorList: FloorModel[] = [];

        buildingList.forEach((building) => {
          if (building.floors) {
            floorList = floorList.concat(building.floors || []);
          }
        });

        floorAdapter.upsertMany(state, floorList);
      })
      .addCase(createFloor.fulfilled, (state, action) => {
        floorAdapter.addOne(state, action.payload);
      })
      .addCase(editFloor.fulfilled, (state, action) => {
        floorAdapter.upsertOne(state, action.payload);
      })
      .addCase(deleteFloor.fulfilled, (state, action) => {
        floorAdapter.removeOne(state, action.payload);
      })
      .addCase(getAllFloors.pending, (state) => {
        state.loadAllFloors = status.loading;
      })
      .addCase(getAllFloors.fulfilled, (state, action) => {
        state.loadAllFloors = status.loaded;
        floorAdapter.setAll(state, action.payload);
      })
      .addCase(getAllFloors.rejected, (state, action) => {
        state.loadAllFloors = status.error(action.error);
      })
      .addCase(requestFloorById.fulfilled, (state, action) => {
        state.allLoadings = status.loaded;
        floorAdapter.setOne(state, action.payload);
      })
      .addCase(getFloorByQuery.fulfilled, (state, action) => {
        if (action.payload) {
          floorAdapter.upsertMany(state, action.payload);
        }
      })
      .addMatcher(isAnyOf(uploadFloorMapFile.fulfilled, deleteFloorMapFile.fulfilled), (state, action) => {
        floorAdapter.setOne(state, action.payload);
      })
      .addMatcher(isAnyOf(createFloor.pending, editFloor.pending, requestFloorById.pending), (state) => {
        state.allLoadings = status.loading;
      })
      .addMatcher(isAnyOf(createFloor.rejected, editFloor.rejected, requestFloorById.rejected), (state, action) => {
        state.allLoadings = status.error(action.error);
      })
});

export const { setCurrentFloorId, setCurrentFloorByMapId } = floorSlice.actions;