import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { GeneralRequest } from "../../../requests/GeneralRequest";
import { CheckPermission } from "../../../requests/CheckPermission";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import AssigneeDropdown from "./AssigneeDropdown";
import AssigneeInput from "./AssigneeInput";
import { requestWorkflowList } from "../../../store/userListStore/userListRequests";
import { DeviceStatus } from "../../../models/enums/DeviceStatus.enum";
import { selectCurrentDevice } from "../../../store/deviceStore/deviceSelectors";
import { selectCurrentConfiguration } from "../../../store/deviceConfigurationStore/deviceConfigurationSelectors";

interface DeviceAssigneeProps {
  onUpdate: () => any,
  disabled?: boolean
}

const DeviceAssignee = ({ onUpdate, disabled }: DeviceAssigneeProps) => {
  const currentDevice = useSelector(selectCurrentDevice);
  const selectedConfiguration = useSelector(selectCurrentConfiguration);
  const currentConfiguration = selectedConfiguration || currentDevice?.configuration;
  const managerId = currentConfiguration?.assigneeId || 0;
  const id = currentDevice?.id;
  const _disabled = disabled || currentConfiguration?.status === DeviceStatus.NEW || (!currentConfiguration?.status);

  const input = (
    <Grid container item>
      <AssigneeInput id={managerId} />
    </Grid>
  );

  if (_disabled) {
    // don't make request if a manager isn't assigned
    return managerId ? (
      <GeneralRequest
        requestAction={requestWorkflowList}
        requestArguments={[id]}
        loadingElement={null}
      >
        {input}
      </GeneralRequest>
    ) : input;
  }

  return (
    <GeneralRequest
      requestAction={requestWorkflowList}
      requestArguments={[id]}
      loadingElement={null}
    >
      <CheckPermission
        permissionName={PERMISSIONS.DEVICE_WORKFLOW_UPDATE}
        noPermissionComponent={input}
        loading={input}
      >
        <Grid container item>
          <AssigneeDropdown
            managerId={managerId}
            deviceId={id}
            disabled={_disabled}
            onUpdate={onUpdate}
          />
        </Grid>
      </CheckPermission>
    </GeneralRequest>
  )
};

export default DeviceAssignee;