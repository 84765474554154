import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash/fp';
import Role from '../../app/auth/Role.model';
import { RootState } from 'app/state';
import { userSlice } from './userState';

export const selectUserState = (state: RootState) => state[ userSlice.name ];

export const isUserLoggedIn = createSelector(
  selectUserState,
  (userState) => Boolean(userState.user)
);

export const selectLoginStatus = createSelector(
  selectUserState,
  get('loginStatus')
);

export const selectTokenStatus = createSelector(
  selectUserState,
  get('tokenStatus')
);

export const selectCurrentUser = createSelector(
  selectUserState,
  get('user')
);

export const selectCurrentUserRoles = createSelector(
  selectUserState,
  get('roles')
);

export const selectUserToken = createSelector(
  selectUserState,
  get('token')
);

export const selectPermissions = createSelector(
  selectUserState,
  get('permissions')
);

const _rolesOrder = ['Admin', 'Frequency manager', 'Requester'];
const _defaultRoleObject = {
  id: 0,
  type: 'Requester'
};

export const selectTopUserRole = createSelector(
  selectCurrentUserRoles,
  (rolesArray = []) => rolesArray.reduce((accum: Role, currentRole: Role) => {
    if (!accum.type) {
      return currentRole;
    }

    if (_rolesOrder.indexOf(accum.type) > _rolesOrder.indexOf(currentRole.type)) {
      return currentRole;
    }

    return accum;
  }, _defaultRoleObject as Role)
);

export const selectPasswordStatus = createSelector(
  selectUserState,
  get('passwordStatus')
);