import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../app/api';
import { serializeError } from '../../app/shared';
import UnsavedWorkflowModel from "../../models/UnsavedWorkflowModel";
import WorkflowModel from "../../models/WorkflowModel";

export const getWorkflowListRequest = createAsyncThunk(
  `/workflow/all`,
  async (
    _,
    { rejectWithValue },
  ) => {
    try {
      const response = await request.get(
        `/workflow/all`,
      );
      const { workflows } = response as any;
      return workflows as WorkflowModel[];
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const editOrCreateWorkflow = createAsyncThunk(
  `/workflow/edit`,
  async (
    data: UnsavedWorkflowModel,
    { rejectWithValue },
  ) => {
    try {
      const method = data.id ? 'put' : 'post';
      const response = await request[method](
        `/workflow`,
        data,
      );
      const { workflow } = response as any;
      return workflow as WorkflowModel;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const deleteWorkflow = createAsyncThunk(
  `/workflow/delete`,
  async (
    id: number,
    { rejectWithValue },
  ) => {
    try {
      await request.delete(
        `/workflow/${id}`,
      );

      return id;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);