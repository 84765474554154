import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import { CheckPermission } from "../../../requests/CheckPermission";
import { pagePathGenerator } from "../../../routes/pagePathGenerator";
import { selectCurrentBuildingId } from "../../../store/buildingStore/buildingsSelectors";
import { selectCurrentLocationId } from "../../../store/locationStore/locationSelectors";
import { FloorList } from "../../FloorTable";
import {
  selectCurrentFloorId,
  selectFloorsByBuildingId,
} from "../../../store/floorStore/floorSelectors";
import { useTypedDispatch } from "../../../app/state";
import { setCurrentFloorId } from "../../../store/floorStore/floorState";

const FloorListPanel = () => {
  const dispatch = useTypedDispatch();
  const history = useHistory();
  const buildingId = useSelector(selectCurrentBuildingId);
  const locationId = useSelector(selectCurrentLocationId);
  const floorList = useSelector(selectFloorsByBuildingId(buildingId));
  const currentFloorId = useSelector(selectCurrentFloorId);

  const floorAddEnabled = true;

  const updateBuildingUrl = (floorId?: number | null, shouldReplace = false) => {
    const url = pagePathGenerator.BUILDING_PAGE({
      buildingId,
      locationId,
      floorId,
    });

    shouldReplace ? history.replace(url) : history.push(url);
  }

  const updateCurrentFloorId = (floorId: number) => dispatch(setCurrentFloorId(floorId));

  const createFloorHandler = () => {
    updateBuildingUrl(-1);
    updateCurrentFloorId(-1);
  }

  const onFloorSelected = (floorId) => {
    updateCurrentFloorId(floorId);
    updateBuildingUrl(floorId);
  }

  useEffect(() => {
    if (!currentFloorId) {
      let id = -1;

      if (floorList.length) {
        id = floorList[0]?.id;
      }
      updateCurrentFloorId(id);
      updateBuildingUrl(id, true);
    }
  }, [currentFloorId, buildingId]);

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Floor list'>
          <CheckPermission permissionName={PERMISSIONS.FLOOR_ACTIONS} noPermissionComponent={null}>
            <IconButton
              aria-label='add floor'
              color='secondary'
              size='small'
              disabled={!floorAddEnabled}
              onClick={createFloorHandler}
            >
              <AddCircleOutline />
            </IconButton>
          </CheckPermission>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <FloorList
          selectedId={currentFloorId}
          selectFloor={onFloorSelected}
        />
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default FloorListPanel;