import { GridColumns } from "@material-ui/data-grid";
import { Cancel, CheckCircle } from "@material-ui/icons";
import { TableList } from "app/components/TableList";
import { FloorModel } from "app/types";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCurrentBuildingId } from "../../store/buildingStore/buildingsSelectors";
import { selectFloorsByBuildingId } from "../../store/floorStore/floorSelectors";
import { isMapPointsSet } from "../../helpers/mapHelpers";

function getIcon(check: boolean): JSX.Element {
  if (check) {
    return <CheckCircle color="primary" />;
  }
  return <Cancel color="error" />;
}

interface FloorListProps {
  newFloor?: boolean;
  floors?: FloorModel[];
  selectedId?: FloorModel["id"] | null;
  selectFloor: (id: FloorModel["id"]) => any;
}

export const FloorList = ({
  newFloor,
  floors,
  selectFloor,
  selectedId,
}: FloorListProps) => {
  const currenBuildingId = useSelector(selectCurrentBuildingId);
  const floorList = useSelector(selectFloorsByBuildingId(currenBuildingId)) || floors;

  const columns = useMemo<GridColumns>(() => {
    return [
      { flex: 1, field: "name", headerName: "Floor name" },
      { flex: 1, field: "floor", headerName: "Floor number" },
      { flex: 1, field: "mapUrl", headerName: "Map" },
      { flex: 1, field: "geoPoints", headerName: "Geo Points" },
    ];
  }, []);

  const rows = floorList.map((floor) => {
    return {
      ...floor,
      mapUrl: getIcon(!!floor?.map?.url),
      geoPoints: getIcon(isMapPointsSet(floor?.map)),
    };
  });

  const floorSelected = useCallback(
    (id: FloorModel["id"]) => {
      selectFloor(id);
    },
    [selectFloor]
  );
  return (
    <TableList
      columns={columns}
      selectedId={selectedId}
      newItem={newFloor}
      rows={rows}
      selectRow={floorSelected}
    ></TableList>
  );
};
