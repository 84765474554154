import { Grid, Typography } from '@material-ui/core';
import cn from 'classnames';
import image from './images/Satelite_blank.svg';

import styles from './index.module.scss';

type NoItemProps = {
  title: string,
  classes?: {
    image: string,
    container: string,
  }
}

const NoItems = ({ title, classes }: NoItemProps) => (
  <Grid
    container
    direction='column'
    alignItems='center'
    justifyContent='center'
    className={cn(classes?.container, styles.noItemsContainer)}
  >
    <Grid item>
      <img src={image} alt={title} className={cn(classes?.image, styles.image)} />
      <Typography
        variant='body1'
        className={styles.text}
      >
        {title}
      </Typography>
    </Grid>
  </Grid>
);

export default NoItems;