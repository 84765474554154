import {
  createStyles,
  makeStyles,
  SvgIcon,
  Theme,
  SvgIconProps
} from '@material-ui/core';
import React from 'react';
import AdminLogo from './Admin';
import FMLogo from './FMLogo';
import RequesterLogo from './RequesterLogo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      width: '44px',
      height: '44px'
    }
  })
);

interface UserIconMap {
  [ key: string ]: Function,
}

const _logosMap: UserIconMap = {
  'Admin': AdminLogo,
  'Frequency manager': FMLogo,
  'Requester': RequesterLogo
};

interface UserLogoProps extends SvgIconProps {
  userRoleType: string;
}

export const UserLogo = (props: UserLogoProps) => {
    const { userRoleType, ...svgProps } = props;
    const classes = useStyles();
    const Logo = _logosMap[ userRoleType ];
    return (
      <React.Fragment>
        <SvgIcon
          { ...svgProps }
          viewBox='0 0 44 44'
          fill='none'
          className={ classes.logo }
        >
          <Logo />
        </SvgIcon>
      </React.Fragment>
    );
  }
;
