import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { WorkflowFormProps } from "./workflowFormProps";
import { selectAllLocationsSorted } from "../../../../store/locationStore/locationSelectors";
import React, { useEffect } from "react";
import { Dropdown } from "../../../../app/components/Dropdown";
import Multiselect from "../../../../components/Multiselect";
import { selectAllCountriesSorted } from "../../../../store/countryStore/countrySelectors";

const LocationTypeForm = (props: WorkflowFormProps) => {
  const countryList = useSelector(selectAllCountriesSorted) || [];
  const locationList = useSelector(selectAllLocationsSorted) || [];
  const { formik, onCountryChange } = props;
  const currentCountryId = formik.values.countries[0] || 0;
  const filteredLocationList = locationList.filter((location) => location.countryId === currentCountryId);

  useEffect(() => {
    if (formik.values.countries.length === 1) {
      const previousValues = formik.values.locations;
      const currentCountry = formik.values.countries[0];

      const newValues = previousValues.filter((locationId) => {
        const location = locationList.find((loc) => loc.id === locationId);

        return location?.countryId === currentCountry;
      });

      formik.setFieldValue('locations', newValues);
    } else {
      formik.setFieldValue('countries', []);
      formik.setFieldValue('locations', []);
    }
  }, []);

  const _onCountryChange = (e) => {
    formik.setFieldValue('countries', [e.target.value]);
    formik.setFieldValue('locations', []);
    onCountryChange();
  }

  const onChangeLocations = (values: number[]) => {
    formik.setFieldValue('locations', values);
  }

  const onLocationDelete = ({ id }) => {
    formik.setFieldValue(
      'locations',
      formik.values.locations.filter((item) => item !== id)
    )
  }

  const selectLocationList = Multiselect.getValues(filteredLocationList);
  const currentLocationList = Multiselect.getSelectedValues(filteredLocationList, formik.values.locations);

  return (
    <>
      <Grid item xs={6}>
        <Dropdown
          selectedValue={currentCountryId}
          values={countryList}
          label='Country'
          name='countries'
          valueChange={_onCountryChange}
          fullWidth
          required
          error={formik.errors.countries}
        />
      </Grid>
      <Grid item xs={6}>&nbsp;</Grid>
      <Grid item xs={6}>
        <Multiselect
          title='Locations'
          name='locations'
          disabled={filteredLocationList.length === 0}
          fullWidth
          required
          items={selectLocationList}
          selectedItems={currentLocationList}
          onChange={onChangeLocations}
          onDelete={onLocationDelete}
          helperText={formik.errors.locations}
          error={Boolean(formik.errors.locations)}
        />
      </Grid>
      <Grid item xs={6}>&nbsp;</Grid>
    </>
  )
}

export default LocationTypeForm;