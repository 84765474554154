import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { WorkflowFormProps } from "./workflowFormProps";
import Multiselect from "../../../../components/Multiselect";
import { selectAllCountriesSorted } from "../../../../store/countryStore/countrySelectors";

const CountryTypeForm = (props: WorkflowFormProps) => {
  const countryList = useSelector(selectAllCountriesSorted) || [];
  const { formik, onCountryChange } = props;
  const onChangeCountries = (values: number[]) => {
    formik.setFieldValue('countries', values);
    onCountryChange();
  }

  const onCountryDelete = ({ id }) => {
    formik.setFieldValue(
      'countries',
      formik.values.countries.filter((item) => item !== id)
    );

    onCountryChange();
  }

  const selectCountryList = Multiselect.getValues(countryList);
  const currentCountryList = Multiselect.getSelectedValues(countryList, formik.values.countries);

  return (
    <>
      <Grid item xs={6}>
        <Multiselect
          title='Countries'
          name='countries'
          fullWidth
          required
          items={selectCountryList}
          selectedItems={currentCountryList}
          onChange={onChangeCountries}
          onDelete={onCountryDelete}
          helperText={formik.errors.countries}
          error={Boolean(formik.errors.countries)}
        />
      </Grid>
      <Grid item xs={6}>&nbsp;</Grid>
    </>
  )
}

export default CountryTypeForm;