import HorizontalPanel from "../../../../components/HorizontalPanel";
import TechnologyConfigurationList from "../TechnologyConfigurationList";
import {
  requestAllTechnologyConfigurations
} from "../../../../store/technologyConfigurationStore/technologyConfigurationRequests";
import { GeneralRequest } from 'requests/GeneralRequest';
import ConfigurationDetails from "../ConfigurationDetails/ConfigurationDetails";
import { getAllRegions } from "../../../../store/regionStore/regionRequests";

import classes from './TechnologyConfigurations.module.scss';

export default function TechnologyConfigurations() {
  return (
    <GeneralRequest requestAction={getAllRegions}>
      <GeneralRequest requestAction={requestAllTechnologyConfigurations}>
        <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE} noWrap className={classes.container}>
          <TechnologyConfigurationList />
          <ConfigurationDetails />
        </HorizontalPanel>
      </GeneralRequest>
    </GeneralRequest>
  )
}
