import { createEntityAdapter, createSlice, EntityState, isAnyOf } from "@reduxjs/toolkit";
import MapModel from "../../models/MapModel";
import { LoadingStatus, status } from "../../app/shared";
import { uploadFloorMapFile, uploadLocationMapFile } from "./mapRequests";
import { loadBuildingsByLocationId } from "../buildingStore/buildingsRequests";
import { requestLocationByQuery } from "../locationStore/locationRequests";
import LocationModel from "../../models/LocationModel";
import FloorModel from "../../models/FloorModel";
import { getAllFloors, getFloorByQuery, requestFloorById } from "../floorStore/floorRequests";
import BuildingModel from "../../models/BuildingModel";

export const mapAdapter = createEntityAdapter<MapModel>({
  selectId: (map) => map.id,
  sortComparer: (a, b) => a.id - b.id,
});

type MapState = {
  selectedMapId?: number | null,
  loadAllStatus?: LoadingStatus,
} & EntityState<MapModel>

const initialState: MapState = {
  selectedMapId: null,
  loadAllStatus: status.default,
  ...mapAdapter.getInitialState(),
}

function addMapsFromList(data: (FloorModel | LocationModel | undefined)[], state: MapState) {
  const mapList: MapModel[] = [];

  data.forEach((item) => {
    if (item?.map) {
      mapList.push(item.map);
    }
  });

  mapAdapter.upsertMany(state, mapList);
}

export const mapSlice = createSlice({
  name: 'mapSlice',
  initialState,
  reducers: {
    setCurrentMapId: (state, action) => {
      state.selectedMapId = action.payload;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(loadBuildingsByLocationId.fulfilled, (state, action) => {
        const buildingList: BuildingModel[] = action.payload;
        let floorList: FloorModel[] = [];

        buildingList.forEach((building) => {
          if (building.floors) {
            floorList = floorList.concat(building.floors || []);
          }
        });

        const locationList = buildingList.map((building) => building.location);

        addMapsFromList(floorList, state);
        addMapsFromList(locationList, state);
      })
      .addMatcher(
        isAnyOf(
          requestLocationByQuery.fulfilled,
          getFloorByQuery.fulfilled,
          getAllFloors.fulfilled,

        ),
        (state, action) => {
          const modelList = action.payload;
          addMapsFromList(modelList || [], state);
        })
      .addMatcher(
        isAnyOf(
          uploadLocationMapFile.fulfilled,
          requestFloorById.fulfilled,
          uploadFloorMapFile.fulfilled,
        ),
        (state, action) => {
          const model = action.payload;
          model && addMapsFromList([model], state);
        }
      )
});

export const { setCurrentMapId } = mapSlice.actions;