import { ChangeEvent, ReactNode } from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

type DropDownItem = {
  id: number | string;
  name: string;
};

type DropdownProps = {
  selectedValue: string | null | number | undefined;
  values: DropDownItem[];
  label: string;
  valueChange?: (
    value: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => void;
  fullWidth?: boolean;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  useDefaultItem?: boolean;
  defaultText?: string;
  id?: string;
  name?: string;
  error?: ReactNode;
};

export const Dropdown = ({
  useDefaultItem = false,
  defaultText = 'All',
  selectedValue,
  values,
  valueChange = (
    value: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {},
  label,
  className,
  disabled = false,
  id = "filter-id",
  name = "dropdown",
  error,
  fullWidth,
  required,
}: DropdownProps) => {
  let value = values.find((item) => item.id === selectedValue) ? selectedValue : '';

  if (selectedValue === 0 && useDefaultItem ) {
    value = 0;
  }

  return (
    <FormControl
      variant="outlined"
      className={className}
      disabled={disabled}
      error={Boolean(error)}
      fullWidth={fullWidth}
    >
      <InputLabel
        id={`filter-id-${name}`}
        required={required}
      >
        {label}
      </InputLabel>
      <Select
        labelId={`filter-id-${name}`}
        id={id}
        name={name}
        value={value}
        onChange={valueChange}
        label={label}
      >
        {useDefaultItem && (
          <MenuItem key={-1} value={0}>
            {defaultText}
          </MenuItem>
        )}
        {values.map((value: DropDownItem) => (
          <MenuItem key={value.id} value={value.id}>
            {value.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error> {error}</FormHelperText>
    </FormControl>
  );
};
