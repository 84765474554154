import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { isEmpty } from "lodash/fp";
import { Grid, IconButton, TextField, Typography } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useTypedDispatch } from "../../../app/state";
import { selectGlobalSettings } from "../../../store/settingsStore/settingsSelectors";
import { editGlobalSettings } from "../../../store/settingsStore/settingsRequests";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";

import classes from './index.module.scss';

const SettingsWhitelist = () => {
  const dispatch = useTypedDispatch();
  const settings = useSelector(selectGlobalSettings);
  const { emailWhitelist = '' } = settings;
  const domainList = !!emailWhitelist ? emailWhitelist.split('|') : [];
  const domainListString = domainList.join(', ');

  const validationSchema = useMemo(
    () =>
      yup.object({
        emailWhitelist: yup.string(),
        usernameMask: yup.string().test('isRegExp', 'Regular expression is invalid', (value: string) => {
          try {
            new RegExp(value);
            return true;
          } catch (e) {
            return false;
          }
        }),
      }),
    [],
  );

  const whitelistFormik = useFormik({
    initialValues: {
      emailWhitelist: settings.emailWhitelist || '',
      usernameMask: settings.usernameMask || '',
    },
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onSave = async () => {
    const errors = await whitelistFormik.validateForm();
    if (isEmpty(errors)) {
      const { emailWhitelist, usernameMask } = whitelistFormik.values
      dispatch(editGlobalSettings({
        emailWhitelist: emailWhitelist.replace(/[*\s]/ig, ''),
        usernameMask,
      }));
    }
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='User settings'>
          <IconButton
            disabled={!whitelistFormik.dirty}
            aria-label='save changes'
            color='secondary'
            size='small'
            onClick={onSave}
          >
            <SaveIcon />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <Grid
          container
          spacing={2}
          className={classes.form}
        >
          <Grid item xs={12}>
            <Typography variant='body1'>Domain whitelist should be "|" separated. All the spaces and asterisk signs (*) will be omitted. e.g. "example.com|example1.com"</Typography>
            <TextField
              label='Domain whitelist'
              variant='outlined'
              name='emailWhitelist'
              fullWidth
              className={classes.input}
              value={whitelistFormik.values.emailWhitelist}
              onChange={whitelistFormik.handleChange}
              helperText={whitelistFormik.errors.emailWhitelist}
              error={Boolean(whitelistFormik.errors.emailWhitelist)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>User name mask, a usual regular expression</Typography>
            <TextField
              label='Username mask'
              variant='outlined'
              name='usernameMask'
              fullWidth
              className={classes.input}
              value={whitelistFormik.values.usernameMask}
              onChange={whitelistFormik.handleChange}
              helperText={whitelistFormik.errors.usernameMask}
              error={Boolean(whitelistFormik.errors.usernameMask)}
            />
          </Grid>
          {
            domainList.length > 0 && (
              <Grid item xs={12}>
                <Typography variant='subtitle1'>There are {domainList.length} currently whitelisted domains: <b>{domainListString}</b></Typography>
              </Grid>
            )
          }
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default SettingsWhitelist;