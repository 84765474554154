import { useHistory } from "react-router-dom";
import { pagePathGenerator } from "../routes/pagePathGenerator";

const useRoutes = () => {
  const history = useHistory();

  const redirectWrongId = (redirectUrl: string = pagePathGenerator.HOME()) => {
    if (history.length === 1) {
      history.replace(redirectUrl);
    } else {
      history.goBack();
    }
  }

  return {
    actions: {
      redirectWrongId,
    }
  }
}

export default useRoutes;