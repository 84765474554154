import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { Toolbar } from 'app/components';
import { Index } from 'components/BuildingForm';
import { ModalType } from 'app/shared/modal-context/constants';
import { useShowModal } from 'app/shared/modal-context/modal.context';
import {
  RootState,
  useTypedDispatch,
} from 'app/state';
import { BuildingModel, LocationModel } from 'app/types';
import { FormikState, useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'app/shared/snackbar-context/snackbar.context';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { CheckPermission } from '../../requests/CheckPermission';
import { PERMISSIONS } from '../../store/userStore/permissions.enum';
import { selectAllLocations, selectCurrentLocationId } from "../../store/locationStore/locationSelectors";
import { selectCurrentBuilding, selectCurrentBuildingId } from "../../store/buildingStore/buildingsSelectors";
import { selectFloorsByBuildingId } from "../../store/floorStore/floorSelectors";
import { createBuilding, deleteBuilding, updateBuilding } from "../../store/buildingStore/buildingsRequests";
import { pagePathGenerator } from "../../routes/pagePathGenerator";
import { useHistory } from "react-router-dom";

export const BuildingDetails = () => {
  const dispatch = useTypedDispatch();
  const history = useHistory();
  const { showModal } = useShowModal();
  const { openSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const locationId = useSelector(selectCurrentLocationId);
  const buildingId = useSelector(selectCurrentBuildingId);
  const building = useSelector(selectCurrentBuilding);
  const floors = useSelector(selectFloorsByBuildingId(buildingId));

  const validationSchema = useMemo(() => {
    return yup.object({
      locationId: yup
        .number()
        .required(t('error_message_location_id_required')),
      name: yup
        .string()
        .max(20, t('error_message_building_name_max'))
        .required(t('error_message_building_name_required')),
    });
  }, []);

  const saveBuilding = useCallback(
    (building: Partial<BuildingModel>) => {
      if (building?.id) {
        dispatch(
          updateBuilding(building),
        );
      } else {
        dispatch(createBuilding(building));
      }
    },
    [dispatch, buildingId],
  );

  const locations = useSelector<RootState, LocationModel[]>(selectAllLocations);

  const buildingForm = useFormik<Partial<BuildingModel>>({
    initialValues: {
      ...building,
      locationId: locationId || 1,
    },
    onSubmit: () => {
      onSaveBuilding();
    },
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onSaveBuilding = useCallback(async () => {
    const valid = await buildingForm.validateForm();
    if (isEmpty(valid)) {
      saveBuilding(buildingForm.values);
    }
  }, [buildingForm, saveBuilding]);

  const onCancelChanges = useCallback(() => {
    buildingForm.resetForm(
      buildingForm.initialValues as Partial<FormikState<Partial<BuildingModel>>>,
    );
  }, [buildingForm]);

  const onContinueDeleteBuilding = () => {
    if (buildingId) {
      dispatch(deleteBuilding(buildingId))
        .then(() => {
          const url = pagePathGenerator.BUILDING_PAGE({
            buildingId: null,
            locationId,
          });

          history.replace(url);
        })
    }
  }

  const openDeleteBuildingConfirmation = () => {
    if (floors.length) {
      openSnackbar({
        title: t('title_delete_building_error'),
        message: t('confirmation_delete_building_error'),
        severity: 'info',
      });
      return;
    } else {
      showModal(ModalType.MODAL_GENERIC_CONFIRM, {
        title: t('title_delete_building'),
        text: t('confirmation_delete_building'),
        confirmButtonHandler: onContinueDeleteBuilding,
        confirmButtonText: t('button_delete'),
      });
    }
  };

  return (
    <>
      <Toolbar title='Building details'>
        <CheckPermission permissionName={PERMISSIONS.BUILDING_ACTIONS} noPermissionComponent={null}>
          <>
            <IconButton
              disabled={!buildingForm.dirty}
              aria-label='save building changes'
              color='secondary'
              size='small'
              onClick={onSaveBuilding}
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              disabled={buildingForm.dirty}
              aria-label='delete building'
              color='secondary'
              size='small'
              onClick={openDeleteBuildingConfirmation}
            >
              <Delete />
            </IconButton>
            <IconButton
              disabled={!buildingForm.dirty}
              aria-label='cancel building changes'
              color='secondary'
              size='small'
              onClick={onCancelChanges}
            >
              <CancelIcon />
            </IconButton>
          </>
        </CheckPermission>
      </Toolbar>
      <Index
        locations={locations}
        locationDisabled={true}
        errors={buildingForm.errors}
        values={buildingForm.values}
        handleChange={buildingForm.handleChange}
      />
    </>
  );
};
