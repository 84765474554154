import { createEntityAdapter, EntityState, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { BuildingModel } from "../../app/types";
import { LoadingStatus, status } from "../../app/shared";
import {
  createBuilding,
  deleteBuilding,
  loadBuildingById,
  loadBuildingsByLocationId,
  updateBuilding,
} from "./buildingsRequests";
import { requestLocationByQuery } from "../locationStore/locationRequests";
import { requestFloorById } from "../floorStore/floorRequests";

export const buildingAdapter = createEntityAdapter<BuildingModel>({
  selectId: (building) => building.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

type BuildingsState = {
  selectedBuildingId: number | null;
  loadAllStatus: LoadingStatus,
  // todo: divide into separate loadings
  otherLoadings: LoadingStatus,
} & EntityState<BuildingModel>

const initialState: BuildingsState = {
  selectedBuildingId: null,
  loadAllStatus: status.default,
  otherLoadings: status.default,
  ...buildingAdapter.getInitialState(),
}

export const buildingSlice = createSlice({
  name: 'buildingsList',
  initialState,
  reducers: {
    setCurrentBuildingId: (state, action) => {
      state.selectedBuildingId = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createBuilding.fulfilled, (state, action) => {
        state.otherLoadings = status.loaded;
        buildingAdapter.addOne(state, action.payload);
      })
      .addCase(updateBuilding.fulfilled, (state, action) => {
        state.otherLoadings = status.loaded;
        buildingAdapter.upsertOne(state, action.payload);
      })
      .addCase(deleteBuilding.fulfilled, (state, action) => {
        state.otherLoadings = status.loaded;
        buildingAdapter.removeOne(state, action.payload);
      })
      .addCase(loadBuildingsByLocationId.pending, (state) => {
        state.loadAllStatus = status.loading;
      })
      .addCase(loadBuildingsByLocationId.fulfilled, (state, action) => {
        state.loadAllStatus = status.loaded;
        buildingAdapter.upsertMany(state, action.payload);
      })
      .addCase(loadBuildingsByLocationId.rejected, (state, action) => {
        state.loadAllStatus = status.error(action.error);
      })
      .addCase(requestLocationByQuery.fulfilled, (state, action) => {
        let buildingsToAdd: BuildingModel[] = [];
        (action.payload || []).forEach((location) => {
          if (location.buildings) {
            buildingsToAdd = buildingsToAdd.concat(location.buildings);
          }
        });
        buildingAdapter.upsertMany(state, buildingsToAdd);
      })
      .addCase(loadBuildingById.fulfilled, (state, action) => {
        buildingAdapter.addOne(state, action.payload);
        state.otherLoadings = status.loaded;
      })
      .addCase(requestFloorById.fulfilled, (state, action) => {
        const floor = action.payload;
        if (floor.building) {
          buildingAdapter.addOne(state, floor.building)
        }
      })
      .addMatcher(isAnyOf(createBuilding.pending, updateBuilding.pending, deleteBuilding.pending, loadBuildingById.pending), (state) => {
        state.otherLoadings = status.loading;
      })
      .addMatcher(isAnyOf(createBuilding.rejected, updateBuilding.rejected, deleteBuilding.rejected, loadBuildingById.rejected), (state, action) => {
        state.otherLoadings = status.error(action.error);
      })
});

export const { setCurrentBuildingId } = buildingSlice.actions;