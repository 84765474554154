import React, { ReactElement } from 'react';
import cn from 'classnames';

export const addClassToChildren = (children: ReactElement | ReactElement[] | null, className: string) => {
  return <>
    {
      React.Children.map(children, child => {
        const props = {
          ...child?.props,
          className: cn(
            child?.props?.className,
            className,
          ),
        };

        return child ? React.cloneElement(child, props) : null;
      })
    }
  </>
}