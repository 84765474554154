import React from 'react';
import cn from 'classnames';
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';

import classes from './index.module.scss';

interface LoadingProps {
  invisibleLoading?: boolean,
  key?: string;
}

const Loading = ({ invisibleLoading = false, key = 'loading' }: LoadingProps) => (
  <Grid
    container
    justifyContent='center'
    alignItems='center'
    className={classes.loading}
    key={key}
  >
    <Grid
      item
      className={cn({
        [classes.invisible]: invisibleLoading,
      })}
    >
      <CircularProgress />
    </Grid>
  </Grid>
);

export default Loading;
