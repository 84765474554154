import { useEffect } from "react";
import { useSelector } from "react-redux";
import useLocationRoute from "../locationRoute.hook";
import { selectCountriesByRegionId } from "../../../store/countryStore/countrySelectors";
import { selectCurrentLocation, selectLocationsByCountryIdList } from "../../../store/locationStore/locationSelectors";
import { AlphabeticList } from "../../../app/components/AlphabeticList";

const LocationsList = () => {
  const { state, actions } = useLocationRoute();
  const { currentCountryId, currentRegionId, currentLocationId } = state;
  const currentLocation = useSelector(selectCurrentLocation);
  const countryList = useSelector(selectCountriesByRegionId(currentRegionId)) || [];
  const countryIdList = currentCountryId ? [currentCountryId] : countryList.map((country) => country.id);
  const locationList = useSelector(selectLocationsByCountryIdList(countryIdList));

  useEffect(() => {
    if (currentLocationId === -1) {
      return;
    }

    if (!currentLocationId || !currentLocation) {
      const firstLocation = locationList[0];
      actions.updateLocationUrl({ locationId: firstLocation ? firstLocation.id : -1, buildingId: null });
    }
  }, [currentLocationId, currentLocation, locationList])

  const onSelect = (locationId: number) => {
    actions.updateLocationUrl({ locationId, buildingId: null });
  }

  return (
    <AlphabeticList
      items={locationList}
      selectItem={onSelect}
      selectedItem={currentLocationId}
    />
  );
}

export default LocationsList;