import { createAsyncThunk } from "@reduxjs/toolkit";
import { FloorModel } from "../../app/types";
import { request } from "../../app/api";
import { serializeError } from "../../app/shared";

const SLICE_NAME = "floorList";

export const getAllFloors = createAsyncThunk(
  `${SLICE_NAME}/all`,
  async (params: { buildingId?: number }, { rejectWithValue }) => {
    try {
      const floorList: FloorModel[] = await request.get("/floor",
        {
          params
        });
      return floorList;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const requestFloorById = createAsyncThunk(
  `${SLICE_NAME}/by-id`,
  async (id: number, { rejectWithValue }) => {
    try {
      const floor: FloorModel = await request.get("/floor/" + id)
      return floor;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const createFloor = createAsyncThunk(
  `${SLICE_NAME}/add`,
  async (body: Partial<FloorModel>, { rejectWithValue }) => {
    try {
      const floor: FloorModel = await request.post("/floor", body);
      return floor;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const editFloor = createAsyncThunk(
  `${SLICE_NAME}/edit`,
  async (
    floorToSave: Partial<FloorModel>,
    { rejectWithValue }
  ) => {
    try {
      const floor: FloorModel = await request.put(`/floor/${floorToSave.id}`, floorToSave);
      return floor;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const deleteFloor = createAsyncThunk(
  `${SLICE_NAME}/delete`,
  async (id: number, { rejectWithValue }) => {
    try {
      await request.delete(`/floor/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

// todo: rename to a general
export const updateFloorPoints = createAsyncThunk(
  `${SLICE_NAME}/map-point`,
  async ({ id, ...body }: { id: number }, { rejectWithValue }) => {
    try {
      await request.put(`/map-point/${id}`, body);
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const getFloorByQuery = createAsyncThunk(
  `${SLICE_NAME}/query`,
  async (query: { buildingId?: number, mapId?: number }, { rejectWithValue }) => {
    try {
      const floor: FloorModel[] = await request.get(`/floor`, {
        params: query,
      });

      return floor;
    }
    catch(error) {
      rejectWithValue(serializeError(error));
    }
  }
);