import DeviceModel from "../../models/DeviceModel";
import { extractNumber } from "../../helpers/converters/units";
import { convertToDBm } from "../../helpers/converters/power";

export const filterDevicesWithoutMapPoint = (device: DeviceModel) => device.configuration.mapPoint && device.configuration.mapPoint.mapPoint;

export const filterPortableDevices = (device: DeviceModel) => device.configuration.type !== '1';

export const getDeviceRadius = (wirelessInterface, interferenceLevel, powerLossCoefficient) => {
  const maxChannel = wirelessInterface.channels.reduce((prev, current) => {
    return convertToDBm(prev?.EIRP) > convertToDBm(current?.EIRP) ? prev : current
  }, {});

  return Math.pow(10, (
    (convertToDBm(wirelessInterface.EIRP) - interferenceLevel) -
    (20 * Math.log10(extractNumber(maxChannel?.downlinkCF)) - 28)
  ) / powerLossCoefficient);
}