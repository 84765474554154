import * as yup from 'yup';
import { convertToMilliWatt } from "../../../../helpers/converters/power";

export const validationsTechConfiguration = yup.object().shape({
  name: yup.string().required('Name is required').max(100),
  region: yup.string().max(50).required(),
  countries: yup.array().min(1, 'At least one country must be selected'),
  bandwidth: yup.string().max(15).required().matches(/^\d*\.?\d+ +(MHz|GHz|kHz)$/, 'Invalid value'),
  downlinkUplink: yup.string().max(15).required().matches(/^\d*\.?\d+ +(MHz|GHz|kHz)$/, 'Invalid value'),
  maxEIRP: yup.string().max(15).required().test(
    'isCorrect',
    'Invalid value',
    (value) => {
      const valueInMilliW = convertToMilliWatt(value);

      return valueInMilliW > 0;
    }),
  licence: yup.string().max(100),
  licenceExpirationDate: yup.date().nullable(true),
  licenceNotify: yup.number().nullable(true),
  expirationDate: yup.date().nullable(true),
  expirationNotify: yup.number().nullable(true),
  bestPracticeMaxEIRP: yup.string().test(
    'isCorrect',
    'Invalid value',
    (value) => {
      const valueInMilliW = convertToMilliWatt(value);

      return !value || valueInMilliW > 0;
    })
    .when('bestPracticeMinEIRP', (bestPracticeMinEIRP, schema) => {
      return schema.test({
        test: (bestPracticeMaxEIRP) => {
          const maxValueInMilliW = convertToMilliWatt(bestPracticeMaxEIRP);
          const minValueInMilliW = convertToMilliWatt(bestPracticeMinEIRP);

          return !minValueInMilliW || (minValueInMilliW && maxValueInMilliW > minValueInMilliW);
        },
        message: 'Best practice max EIRP should be greater',
      })
    })
    .when('maxEIRP', (maxEIRP, schema) => {
      return schema.test({
        test: (bestPracticeMaxEIRP) => {
          const maxValueInMilliW = convertToMilliWatt(bestPracticeMaxEIRP);
          const valueInMilliW = convertToMilliWatt(maxEIRP);

          return !maxValueInMilliW || (maxValueInMilliW && !(maxValueInMilliW < valueInMilliW))
        },
        message: `Max EIRP field is greater`,
      })
    }),
  bestPracticeMinEIRP: yup.string().test(
    'isCorrect',
    'Invalid value',
    (value) => {
      const valueInMilliW = convertToMilliWatt(value);

      return !value || valueInMilliW > 0;
    })
    .when('maxEIRP', (maxEIRP, schema) => {
      return schema.test({
        test: (bestPracticeMinEIRP) => {
          const minValueInMilliW = convertToMilliWatt(bestPracticeMinEIRP);
          const valueInMilliW = convertToMilliWatt(maxEIRP);

          return !minValueInMilliW || (minValueInMilliW && !(minValueInMilliW > valueInMilliW))
        },
        message: `Max EIRP field is less`,
      })
    }),
});
