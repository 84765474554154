import { Icon } from '@material-ui/core';
import DeviceConfigurationModel from "../../models/DeviceConfigurationModel";

import deviceActive from './images/device_active.svg';
import deviceApproved from './images/device_approved.svg';
import deviceNew from './images/device_new.svg';
import deviceRejected from './images/device_rejected.svg';
import deviceRequested from './images/device_requested.svg';
import deviceRetired from './images/device_retired.svg';

import draftActive from './images/draft_active.svg';
// import draftApproved from './images/draft_approved.svg';
import draftNew from './images/draft_new.svg';
import draftRejected from './images/draft_rejected.svg';
import draftRequested from './images/draft_requested.svg';
// import draftRetired from './images/draft_retired.svg';

const IMAGES_MAP = {
  device: {
    active: deviceActive,
    approved: deviceApproved,
    'new': deviceNew,
    rejected: deviceRejected,
    requested: deviceRequested,
    retired: deviceRetired,
  },
  draft: {
    active: draftActive,
    // approved: draftApproved,
    'new': draftNew,
    rejected: draftRejected,
    requested: draftRequested,
    // retired: draftRetired,
  },
}

type DeviceIconProps = {
  deviceConfiguration?: DeviceConfigurationModel | null;
}

const DeviceIcon = ({ deviceConfiguration }: DeviceIconProps) => {
  const { isDraft = true, status = 'new' } = (deviceConfiguration || {});
  const mapping = isDraft ? IMAGES_MAP.draft : IMAGES_MAP.device;
  return (
    <Icon>
      <img
        src={mapping[status]}
        alt={status}
        style={{
          display: 'block'
        }}
      />
    </Icon>
  );
}

export default DeviceIcon;