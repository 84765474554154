import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "app/api";
import { BuildingModel } from "app/types";
import { isNil, omitBy } from "lodash/fp";
import { serializeError } from "../../app/shared";

const SLICE_NAME = "buildings";

export const createBuilding = createAsyncThunk(
  `${SLICE_NAME}/create`,
  async (building: Partial<BuildingModel>, { rejectWithValue }) => {
    try {
      const buildingItem: BuildingModel = await request.post(
        "/building",
        building
      );
      return buildingItem;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const updateBuilding = createAsyncThunk(
  `${SLICE_NAME}/update`,
  async (
    body: Partial<BuildingModel>,
    { rejectWithValue }
  ) => {
    try {
      const buildingItem: BuildingModel = await request.put(
        `/building/${body.id}`,
        body
      );
      return buildingItem;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const deleteBuilding = createAsyncThunk(
  `${SLICE_NAME}/delete`,
  async (id: number, { rejectWithValue }) => {
    try {
      await request.delete(`/building/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const loadBuildingById = createAsyncThunk(
  `${SLICE_NAME}/byId`,
  async (buildingId: number | null = null, { rejectWithValue }) => {
    try {
      const building: BuildingModel = await request.get(`/building/${buildingId}`);
      return building;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
)

export const loadBuildingsByLocationId = createAsyncThunk(
  `${SLICE_NAME}/loadByLocationId`,
  async (locationId: number | null = null, { rejectWithValue }) => {
    try {
      const buildings: BuildingModel[] = await request.get(`/building`, {
        params: omitBy(isNil,{ locationId }),
      });
      return buildings;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);
