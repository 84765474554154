import { GenericModal } from "app/components/ui/generic-modal/GenericModal";
import { UnsavedChangesModal } from "app/components/ui/unsaved-changes-modal/UnsavedChangesModal";
import { GenericModalWithText } from "../../components/ui/generic-modal-with-text";

export enum ModalType {
  MODAL_UNSAVED_CHANGES = 'MODAL_UNSAVED_CHANGES',
  MODAL_GENERIC_CONFIRM = 'MODAL_GENERIC_CONFIRM',
  MODAL_CONFIRM_WITH_TEXT = 'MODAL_CONFIRM_WITH_TEXT',
}

export const MODAL_LOOKUP: Record<ModalType, any> = {
  [ModalType.MODAL_UNSAVED_CHANGES]: UnsavedChangesModal,
  [ModalType.MODAL_GENERIC_CONFIRM]: GenericModal,
  [ModalType.MODAL_CONFIRM_WITH_TEXT]: GenericModalWithText,
};
