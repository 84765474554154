import NoItems from "../../components/NoItems";
import { ThreeColumnLayout } from "../../layouts/ThreeColumnLayout/ThreeColumnLayout";
import HorizontalPanel from "../../components/HorizontalPanel";

const TrialExpiredPage = () => {
  return (
    <ThreeColumnLayout
      centerMainPanel={(
        <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE} justifyContent='center'>
          <NoItems title={'Your trial version has expired'} />
        </HorizontalPanel>
      )}
    />
  );
}

export default TrialExpiredPage;