import { SerializedError } from "@reduxjs/toolkit";

export type LoadingStatus = { loading: false, loaded: false, error: null }
  | { loading: true, loaded: false, error: null }
  | { loading: false, loaded: true, error: null }
  | { loading: false, loaded: false, error: Error };

interface Statuses {
  default: LoadingStatus;
  loading: LoadingStatus;
  loaded: LoadingStatus;
  error: ((error: Error | SerializedError) => LoadingStatus);
}

export const status: Statuses = {
  default: { loading: false, loaded: false, error: null },
  loading: { loading: true, loaded: false, error: null },
  loaded: { loading: false, loaded: true, error: null },
  error: (error: Error) => ({ loading: false, loaded: false, error })
};
