import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, IconButton } from "@material-ui/core";
import TransitEnterexitIcon from "@material-ui/icons/TransitEnterexit";
import {
  selectCurrentDeviceId,
  selectDevicesByMapId,
} from "../../../store/deviceStore/deviceSelectors";
import { pagePathGenerator } from "../../../routes/pagePathGenerator";
import { Toolbar } from "../../../app/components";
import DeviceMapContainer from "../../Map/DeviceMapContainer";
import useDevicesPage from "../../DevicesPage/devicePageHooks";
import useFilter from "../../../hooks/filter.hook";

const CentralDeviceMapPanel = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { state, actions } = useDevicesPage();
  const filter = useFilter();
  const { currentMapId, currentMap } = state;
  const currentPlaceDevices = useSelector(selectDevicesByMapId(currentMapId));
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const filteredDeviceList = filter.actions.filterDeviceList(currentPlaceDevices);

  const onBackClick = () => {
    let url;
    if (!currentDeviceId) {
      actions.onDeviceGoBack();
      return;
    } else {
      url = pagePathGenerator.SINGLE_DEVICE_PAGE({
        mapId: currentMapId,
        deviceId: currentDeviceId,
      });
    }

    history.replace(url);
  }

  return (
    <Grid container item xs direction='column'>
      <Toolbar title={t('device_map.page_title')}>
        <>
          <IconButton
            aria-label='close window'
            color='secondary'
            size='small'
            onClick={onBackClick}
          >
            <TransitEnterexitIcon />
          </IconButton>
        </>
      </Toolbar>
      <DeviceMapContainer
        map={currentMap}
        deviceList={filteredDeviceList}
      />
    </Grid>
  );
};

export default CentralDeviceMapPanel;