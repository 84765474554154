import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { IconButton, TextField } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Delete from "@material-ui/icons/Delete";
import { useTypedDispatch } from "../../../app/state";
import {
  selectCurrentWirelessSystem,
  selectCurrentWirelessSystemId,
} from "../../../store/wirelessSystemStore/wirelessSystemSelectors";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import {
  createNewWirelessSystem,
  deleteWirelessSystem,
  editWirelessSystem
} from "../../../store/wirelessSystemStore/wirelessSystemRequests";
import { useSnackbar } from "../../../app/shared/snackbar-context/snackbar.context";
import { useShowModal } from "../../../app/shared/modal-context/modal.context";

import classes from './index.module.scss';
import WirelessSystemModel from "../../../models/WirelessSystemModel";
import { setCurrentWirelessSystemId } from "../../../store/wirelessSystemStore/wirelessSystemState";
import { ModalType } from "../../../app/shared/modal-context/constants";

const WirelessSystemEdit = () => {
  const dispatch = useTypedDispatch();
  const { openSnackbar } = useSnackbar();
  const { showModal } = useShowModal();
  const currentWirelessSystemId = useSelector(selectCurrentWirelessSystemId);
  const currentWirelessSystem = useSelector(selectCurrentWirelessSystem);
  const isCreateMode = currentWirelessSystemId === -1;
  const title = isCreateMode ? 'Create wireless system' : 'Edit wireless system';

  const wirelessSystemFormik = useFormik({
    initialValues: {
      name: currentWirelessSystem?.name || '',
    },
    onSubmit: () => {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  // create mode and default wireless system
  const isDeleteDisabled = currentWirelessSystemId <= 1;

  const onSave = () => {
    const name = wirelessSystemFormik.values.name;
    if (!name) {
      return;
    }
    if (isCreateMode) {
      dispatch(createNewWirelessSystem(name))
        .then((action) => {
          const data = action.payload as { wirelessSystem: WirelessSystemModel, error?: string };
          if (data.error) {
            openSnackbar({
              title: "Cannot create wireless system",
              message: "There is a wireless system with the same name",
              severity: "error",
            })
          } else {
            dispatch(setCurrentWirelessSystemId(data.wirelessSystem.id));
          }
        })
    } else {
      dispatch(editWirelessSystem({
        id: currentWirelessSystemId,
        name,
      }))
        .then((action) => {
          const data = action.payload as { wirelessSystem: WirelessSystemModel, error?: string };
          if (data.error) {
            openSnackbar({
              title: "Cannot update wireless system",
              message: "There is a wireless system with the same name",
              severity: "error",
            });
          }
        })
    }
  }

  const onDelete = () => {
    dispatch(deleteWirelessSystem(currentWirelessSystemId))
      .then((action) => {
        const payload = action.payload as { error?: string };

        if (!payload.error) {
          dispatch(setCurrentWirelessSystemId(1));
        } else {
          openSnackbar({
            title: "Cannot delete wireless system",
            message: "This wireless system is already in use",
            severity: "error",
          });
        }
      })
  }

  const onDeleteHandler = () => {
    showModal(ModalType.MODAL_GENERIC_CONFIRM, {
      title: 'Delete wireless system',
      text: `Are you sure you want to delete this wireless system?`,
      confirmButtonHandler: onDelete,
      confirmButtonText: 'Delete',
    });
  }

  return currentWirelessSystemId ? (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={title}>
          <IconButton
            disabled={!wirelessSystemFormik.dirty}
            aria-label='save changes'
            color='secondary'
            size='small'
            onClick={onSave}
          >
            <SaveIcon />
          </IconButton>
          <IconButton
            disabled={isDeleteDisabled}
            aria-label='delete'
            color='secondary'
            size='small'
            onClick={onDeleteHandler}
          >
            <Delete />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} className={classes.form}>
        <TextField
          fullWidth
          label="Wireless system name"
          variant="outlined"
          name="name"
          required
          value={wirelessSystemFormik.values.name}
          onChange={wirelessSystemFormik.handleChange}
          helperText={wirelessSystemFormik.errors.name}
          error={Boolean(wirelessSystemFormik.errors.name)}
        />
      </HorizontalPanel>
    </HorizontalPanel>
  ) : null;
}

export default WirelessSystemEdit;