import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
import { Login } from 'app/auth';
import { Loader } from 'app/components';
import { SnackbarProvider } from 'app/shared/snackbar-context/snackbar.context';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { i18n } from './app/i18n';
import { store } from './app/state';
import { theme } from './app/theme';
import './index.scss';
import { reportWebVitals } from './reportWebVitals';
import PAGES from './pages';
import CreatePasswordPage from './pages/CreatePasswordPage';
import TrialRoute from "./routes/TrialRoute";
import TrialExpiredPage from "./pages/TrialExpiredPage";
import ClearCurrentStateContainer from "./containers/ClearCurrentStateContainer";
import ApplicationStartup from "./routes/ApplicationStartup";
import InnerRoutes from "./routes/InnerRoutes";

ReactDOM.render(
  <StylesProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <SnackbarProvider>
            <Suspense fallback={<Loader />}>
              <Router>
                <ClearCurrentStateContainer />
                <ApplicationStartup>
                  <Switch>
                    <Route path={PAGES.TRIAL_EXPIRED} component={TrialExpiredPage} />
                    <TrialRoute>
                      {/* todo: replace this with global routes */}
                      <Switch>
                        <Route path={PAGES.LOGIN_PAGE} component={Login} />
                        <Route
                          path={PAGES.USER_CREATE_PASSWORD_PAGE}
                          component={CreatePasswordPage}
                        />
                        <InnerRoutes />
                      </Switch>
                    </TrialRoute>
                  </Switch>
                </ApplicationStartup>
              </Router>
            </Suspense>
          </SnackbarProvider>
        </I18nextProvider>
      </Provider>
    </ThemeProvider>
  </StylesProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
