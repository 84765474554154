import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

type ModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  handleConfirmNavigationClick: () => void;
}

export const UnsavedChangesModal = ({ isOpen, closeModal, handleConfirmNavigationClick }: ModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={ isOpen }
      onClose={ closeModal }
      fullWidth
      maxWidth='sm'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        <Box display='flex' alignItems='center'>
          <Box flexGrow={ 1 }>{ t('unsaved_changes.title') }</Box>
          <Box>
            <IconButton onClick={ closeModal }>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          { t('unsaved_changes.body') }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box marginRight='20px'>
          <Button onClick={ handleConfirmNavigationClick } color='secondary'>
            { t('unsaved_changes.confirm_button') }
          </Button>
          <Button onClick={ closeModal } color='primary' autoFocus>
            { t('unsaved_changes.cancel_button') }
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};