import { useSelector } from "react-redux";
import { RegionForm } from './RegionForm';
import { CountryForm } from './CountryForm';
import { selectCurrentRegionId } from "../../../store/regionStore/regionSelectors";
import { selectCurrentCountryId } from "../../../store/countryStore/countrySelectors";
import HorizontalPanel from "../../../components/HorizontalPanel";

const RegionAndCountryRightPanel = () => {
  const currentRegionId = useSelector(selectCurrentRegionId);
  const currentCountryId = useSelector(selectCurrentCountryId);
  const regionIsSet = !!currentRegionId;

  const form = currentCountryId ? <CountryForm /> : <RegionForm />;

  return regionIsSet ? (
      <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
        {
          form
        }
      </HorizontalPanel>
    ) :
    null;
};

export default RegionAndCountryRightPanel;