import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { Delete, MyLocation } from "@material-ui/icons";
import { IconButton, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { selectCurrentDevice } from "../../../store/deviceStore/deviceSelectors";
import { selectCurrentConfiguration } from "../../../store/deviceConfigurationStore/deviceConfigurationSelectors";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { useTypedDispatch } from "../../../app/state";
import { setEditMode } from "../../../store/generalStore/generalState";
import { deleteDevicePoint } from "../../../store/deviceStore/deviceRequests";

import classes from './index.module.scss';

export const DevicePosition = (props) => {
  const dispatch = useTypedDispatch();
  const { formDisabled } = props;
  const selectedDevice = useSelector(selectCurrentDevice);
  const selectedConfiguration = useSelector(selectCurrentConfiguration);
  const currentConfiguration = selectedConfiguration || selectedDevice?.configuration;
  const isDeviceSetOnMap = () => Boolean(currentConfiguration?.mapPoint);
  const isChangePointButtonEnabled = () => !formDisabled && !!selectedDevice;

  const isDevicePortable = currentConfiguration?.type === '1';
  const latitude = currentConfiguration?.mapPoint?.geoPoint?.latitude;
  const longitude = currentConfiguration?.mapPoint?.geoPoint?.longitude;
  const geoPointExists = !!latitude && !!longitude;

  useEffect(() => {
    dispatch(setEditMode(false));
  }, [])

  const setDeviceOnMap = () => {
    dispatch(setEditMode(true));
  };

  const removeDeviceFromMap = () => {
    if (!selectedDevice) {
      return;
    }
    dispatch(setEditMode(false));
    dispatch(deleteDevicePoint(selectedDevice.id));
  };

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Device Position' />
      </HorizontalPanel>
      <HorizontalPanel
        size={HorizontalPanel.SIZES.REST_SPACE}
        className={classes.container}
      >
        {
          geoPointExists && (
            <Grid container spacing={2} className={classes.coordinatesBlock}>
              <Grid item xs={12}>
                <TextField
                  label='Latitude'
                  required
                  variant='outlined'
                  name='latitude'
                  fullWidth
                  disabled
                  value={latitude}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='Longitude'
                  required
                  variant='outlined'
                  name='longitude'
                  fullWidth
                  disabled
                  value={longitude}
                />
              </Grid>
            </Grid>
          )
        }
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item><Typography variant='subtitle1' className={classes.line}>Point on map</Typography></Grid>
          <Grid item>
            {
              isDeviceSetOnMap() ?
                (
                  <IconButton
                    aria-label='remove device on map'
                    color='secondary'
                    size='small'
                    disabled={!isChangePointButtonEnabled()}
                    onClick={removeDeviceFromMap}
                  >
                    <Delete />
                  </IconButton>
                )
                :
                (
                  <IconButton
                    aria-label='set device from map'
                    color='secondary'
                    size='small'
                    disabled={!isChangePointButtonEnabled()}
                    onClick={setDeviceOnMap}
                  >
                    <MyLocation />
                  </IconButton>
                )
            }
          </Grid>
        </Grid>
        {
          isDevicePortable && (
            <Grid container>
              <Grid
                item
                xs={12}
              >
                <FormControlLabel
                  disabled={true}
                  control={
                    <Checkbox
                      checked={true}
                      value={true}
                      onChange={() => {}}
                      name='all'
                      color='default'
                    />
                  }
                  label='Unrestricted Usage Area'
                />
              </Grid>
            </Grid>
          )
        }
      </HorizontalPanel>
    </HorizontalPanel>
  );
}
