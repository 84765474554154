import React from 'react';

const RequesterLogo = () => (
  <>
    <path fillRule='evenodd' clipRule='evenodd'
          d='M22 12.7355C19.8642 12.7355 18.1267 14.473 18.1267 16.6088C18.1267 18.3783 19.3199 19.8733 20.9436 20.3345C21.3888 20.4609 22.5711 20.4722 23.0563 20.3345C24.6801 19.8734 25.8733 18.3783 25.8733 16.6088C25.8733 14.473 24.1357 12.7355 22 12.7355ZM20.2394 16.6088C20.2394 17.5796 21.0292 18.3694 22 18.3694C22.9708 18.3694 23.7606 17.5796 23.7606 16.6088C23.7606 15.638 22.9708 14.8482 22 14.8482C21.0292 14.8482 20.2394 15.638 20.2394 16.6088Z'
          fill='white' />
    <path
      d='M10.739 6.08108C11.1515 5.66861 11.1515 4.99973 10.739 4.58718C10.3265 4.17471 9.65763 4.17471 9.24509 4.58718C4.12244 9.70978 2.73633 16.6868 5.06908 22.8129L7.37238 22.8129C4.93219 17.2608 6.05439 10.7658 10.739 6.08108Z'
      fill='white' />
    <path
      d='M13.926 9.26808C14.3386 8.85561 14.3386 8.18673 13.926 7.77419C13.5136 7.36172 12.8447 7.36172 12.4321 7.77419C8.36891 11.8374 7.5913 17.961 10.0993 22.8129L12.5446 22.8129C9.79095 18.6331 10.2514 12.9427 13.926 9.26808Z'
      fill='white' />
    <path
      d='M17.113 10.9612C16.7005 10.5487 16.0317 10.5487 15.6191 10.9612C12.3441 14.2361 12.3436 19.5375 15.6191 22.8129H17.113C17.5255 22.4004 17.5255 21.7315 17.113 21.319C14.6637 18.8697 14.6632 14.9048 17.113 12.4551C17.5255 12.0426 17.5255 11.3737 17.113 10.9612Z'
      fill='white' />
    <path
      d='M28.3808 10.9613C27.9683 10.5488 27.2994 10.5488 26.8869 10.9613C26.4744 11.3737 26.4744 12.0426 26.8869 12.4551C29.3307 14.8989 29.3307 18.8752 26.8869 21.319C26.4744 21.7315 26.4744 22.4004 26.8869 22.8129H28.3808C31.6484 19.5455 31.6484 14.2287 28.3808 10.9613Z'
      fill='white' />
    <path
      d='M31.5678 7.77419C31.1553 7.36172 30.4864 7.36172 30.0739 7.77419C29.6613 8.18666 29.6613 8.85554 30.0739 9.26808C33.7485 12.9427 34.209 18.6331 31.4553 22.8129L33.9006 22.8129C36.4086 17.961 35.631 11.8374 31.5678 7.77419Z'
      fill='white' />
    <path
      d='M34.7548 4.58718C34.3423 4.17471 33.6735 4.17471 33.2609 4.58718C32.8484 4.99966 32.8484 5.66854 33.2609 6.08108C37.9456 10.7658 39.0678 17.2608 36.6276 22.8129H38.9315C41.267 16.6795 39.8689 9.70121 34.7548 4.58718Z'
      fill='white' />
    <path
      d='M12.4427 32.7548H6.67221V36.6833H13.4445V39.111H3.66675V24.4443H13.4245V26.8921H6.67221V30.3876H12.4427V32.7548Z'
      fill='#80C5E4' />
    <path d='M23.1139 33.1174H17.3586V39.111H14.3611V24.4443H23.8333V26.8921H17.3586V30.6797H23.1139V33.1174Z'
          fill='#80C5E4' />
    <path
      d='M28.7374 24.4443L32.5417 35.0817L36.3256 24.4443H40.3333V39.111H37.2716V35.1018L37.5768 28.1815L33.5792 39.111H31.4838L27.4964 28.1916L27.8016 35.1018V39.111H24.75V24.4443H28.7374Z'
      fill='#80C5E4' />
  </>
);

export default RequesterLogo;