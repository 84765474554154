import WorkflowModel from "../../../models/WorkflowModel";
import CountryModel from "../../../models/CountryModel";
import { workflowTypeMap } from "./workflowTypeMap";
import { WORKFLOW_TYPE } from "./WORKFLOW_TYPE";
import LocationModel from "../../../models/LocationModel";
import UnsavedWorkflowModel from "../../../models/UnsavedWorkflowModel";

export const extractWorkflowData = (
  workflow: WorkflowModel | undefined | null,
  allCountryList: CountryModel[] | [],
  allLocationList: LocationModel[] | [],
): UnsavedWorkflowModel => {
  const workflowType = workflow?.type === undefined ? WORKFLOW_TYPE.BUILDING_LEVEL : workflow?.type;

  const result: UnsavedWorkflowModel = {
    id: workflow?.id,
    name: workflow?.name || '',
    type: workflowType,
    countries: [],
    locations: [],
    buildings: [],
    technologies: [],
    frequencyManagers: [],
  };

  switch (workflowType) {
    case WORKFLOW_TYPE.COUNTRY_LEVEL:
      const countryList = (workflow?.countries || []).map((country) => country.id);
      result.countries = countryList;
      break;
    case WORKFLOW_TYPE.LOCATION_LEVEL:
      const firstLocation = (workflow?.locations || [])[0];
      const locationCountry = allCountryList.find((c) => c.id === firstLocation?.countryId);

      if (!firstLocation || !locationCountry) {
        result.countries = [];
        result.locations = [];
        result.buildings = [];
      } else {
        result.countries = [locationCountry.id];
        result.locations = (workflow?.locations || []).map((location) => location.id);
      }
      break;
    default:
      // WORKFLOW_TYPE.BUILDING_LEVEL
      const firstBuilding = (workflow?.buildings || [])[0];
      const buildingLocation = allLocationList.find((l) => l.id === firstBuilding?.locationId);
      const buildingCountry = allCountryList.find((c) => c.id === buildingLocation?.countryId);

      if (!firstBuilding || !buildingLocation || !buildingCountry) {
        result.countries = [];
        result.locations = [];
        result.buildings = [];
      } else {
        result.countries = [buildingCountry.id];
        result.locations = [buildingLocation.id];
        result.buildings = (workflow?.buildings || []).map((building) => building.id);
      }
      break;
  }

  return result;
}

export const extractWorkflowViewModel = (
  workflow: WorkflowModel | undefined | null,
  allCountryList: CountryModel[] | [],
  allLocationList: LocationModel[] | [],
) => {
  const workflowView = extractWorkflowData(workflow, allCountryList, allLocationList);

  const viewModel = {
    id: workflowView.id,
    name: workflowView.name,
    type: workflowTypeMap[workflowView.type],
    countries: workflowView.countries.map((countryId) => {
      const foundCountry = allCountryList.find((country) => country.id === countryId);
      return foundCountry?.name;
    }).join(', '),
    locations: workflowView.locations.map((locationId) => {
      const foundLocation = allLocationList.find((location) => location.id === locationId);
      return foundLocation?.name;
    }).join(', '),
    buildings: (workflow?.buildings || []).map((building) => building.name).join(', '),
    technologies: (workflow?.technologies || []).map((tech) => tech.name).join(', '),
  };

  if (!viewModel.buildings) {
    viewModel.buildings = '*';
  }

  if (!viewModel.locations) {
    viewModel.locations = '*';
  }

  if (!viewModel.countries) {
    viewModel.countries = '*';
  }

  if (!viewModel.technologies) {
    viewModel.technologies = '-';
  }

  return viewModel;
}