import * as yup from "yup";
import { extractNumber, extractUnits } from "../../../../helpers/converters/units";
import UnitsInput from "../../../../components/TextFields/UnitsInput";

interface channelValidationSchemaProps {
  maxEIRP: string;
}

const validationSchema = ({ maxEIRP }: channelValidationSchemaProps) => yup.object({
  centralFrequency: yup.string()
    .required('Central frequency required')
    .test('unitTest', 'Please set frequency units', (value: string) => UnitsInput.UNIT_TEMPLATE.FREQUENCY.includes(extractUnits(value))),
  firstChannelNumber: yup.number()
    .required('First channel number required')
    .integer('Channel number should be an integer')
    .moreThan(-1, 'Channel number should be not negative number'),
  amount: yup.number()
    .required('Amount of channels is not specified')
    .integer('Amount of channels should be an integer number')
    .moreThan(0, 'Amount of channel should be a positive number'),
  stepFrequency: yup.string()
    .required('Channel frequency step is required')
    .when('centralFrequency', (centralFrequency, schema) => schema.test({
      test: (stepFrequency) => extractUnits(centralFrequency) === extractUnits(stepFrequency),
      message: 'The same units should be used for frequency',
    })),
  stepNumber: yup.number()
    .required('Channel number step is required')
    .integer('Channel step should be an integer number')
    .moreThan(0, 'Channel step should be a positive number'),
  EIRP: yup.string()
    .required('EIRP is required')
    .test('maxEIPRCheck', `Should not be greater than maxEIRP from technology: ${maxEIRP}`, (value: string) => {
      const currentEIRP = extractNumber(value);
      const technologyMaxEIRP = extractNumber(maxEIRP);

      return currentEIRP <= technologyMaxEIRP;
    }),
  outdoor: yup.boolean()
});

export default validationSchema;