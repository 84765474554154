import { FloorMapPage } from './floor-map/FloorMapPage';
import SettingsPage from '../pages/SettingsPage';
import BuildingPage from "../pages/BuildingPage";
import LocationPage from "../pages/LocationPage";
import SearchPage from "../pages/SearchPage";
import RequestsPage from "../pages/Old/RequestsPage";
import PAGES from "../pages";
import { MapPage } from "../pages/MapPage";
import SingleDevicePage from "../pages/SingleDevicePage";
import DeviceMapPage from "../pages/DeviceMapPage";
import FrequencyAnalysisPage from "../pages/FrequencyAnalysisPage";

type RouteModel = {
  path: string;
  exact?: boolean;
  name: string;
  component: () => JSX.Element | null;
};

export enum AppRoutesEnum {
  Requests = '/requests',
  Locations = '/locations',
  Settings = '/settings',
  Search = '/search',
  Map = '/map',
  Device = '/device'
}

export const ROUTES: RouteModel[] = [
  {
    path: AppRoutesEnum.Requests,
    name: 'navigation_requests',
    component: RequestsPage,
  },
  {
    path: AppRoutesEnum.Settings,
    name: 'navigation_settings',
    component: SettingsPage,
  },
  {
    path: AppRoutesEnum.Locations,
    exact: true,
    name: 'navigation_locations',
    component: LocationPage,
  },
  {
    path: `${AppRoutesEnum.Locations}/buildings`,
    exact: true,
    name: 'navigation_buildings',
    component: BuildingPage,
  },
  {
    path: `${AppRoutesEnum.Locations}/buildings/upload-map`,
    exact: true,
    name: 'navigation_buildings',
    component: FloorMapPage,
  },
  {
    path: `${AppRoutesEnum.Locations}/buildings/floors/devices/map`,
    exact: true,
    name: 'navigation_devices_map',
    component: DeviceMapPage,
  },
  {
    path: PAGES.MAP_PAGE,
    exact: true,
    name: 'Map',
    component: MapPage,
  },
  {
    path: `${AppRoutesEnum.Locations}/buildings/floors/frequency-analysis`,
    exact: true,
    name: 'navigation_freq_analysis',
    component: FrequencyAnalysisPage,
  },
  {
    path: `${AppRoutesEnum.Search}`,
    exact: true,
    name: 'navigation_search',
    component: SearchPage,
  },
  {
    path: `${AppRoutesEnum.Device}`,
    exact: true,
    name: `Device page`,
    component: SingleDevicePage,
  }
];
