import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash/fp';
import { RootState } from '../../app/state';
import { workflowAdapter, workflowSlice } from './workflowState';

const selectRoot = (state: RootState) => state;
export const workflowState = (state: RootState) => state[workflowSlice.name];

const workflowAdapterSelectors = workflowAdapter.getSelectors(workflowState);
export const selectAllWorkflowList = workflowAdapterSelectors.selectAll;
export const selectWorkflowById = workflowAdapterSelectors.selectById;

export const getWorkflowStatus = createSelector(
  workflowState,
  get('workflowStatus'),
);

export const selectCurrentWorkflowId = createSelector(
  workflowState,
  get('selectedWorkflowId'),
);

export const selectCurrentWorkflow = createSelector(
  selectRoot,
  selectCurrentWorkflowId,
  (root, workflowId) => workflowId ? selectWorkflowById(root, workflowId) : null
);