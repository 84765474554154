import { createSelector } from '@reduxjs/toolkit';
import { freqAnalysisSlice } from './slice';
import { RootState } from "../../app/state";

export const selectFreqAnalysisState = (state: RootState) => state[freqAnalysisSlice.name];

export const selectChannelsForD3 = createSelector(
  selectFreqAnalysisState,
  (freqAnalysisState) => freqAnalysisState.channelsForD3
);

export const selectCanvasWidthInHz = createSelector(
  selectFreqAnalysisState,
  (freqAnalysisState) => freqAnalysisState.canvasWidthInHz
);

export const selectRangeWithUnits = createSelector(
  selectFreqAnalysisState,
  (freqAnalysisState) => freqAnalysisState.rangeWithUnits
);