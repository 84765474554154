import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActivationUsers,
  selectActiveUsers,
  selectInactiveUsers,
  selectNewUsers,
  selectUserList,
} from '../../../store/userListStore/userListSelectors';
import { USER_STATUS } from '../../../store/userListStore/userStatus';
import { TableList } from '../../../app/components/TableList';
import { UserListRequest } from '../../../requests/UserListRequest';
import { UserRoleListRequest } from '../../../requests/UserRoleListRequest';
import { IconButton } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Toolbar } from '../../../app/components';
import { UserModel } from '../../../models/UserModel';
import { UserRoleModel } from '../../../models/UserRoleModel';
import { startEditUser } from '../../../store/userActionStore/userActionState';
import { AppDispatch } from '../../../app/state';
import { getEditUser } from '../../../store/userActionStore/userActionSelector';

interface UserTableProps {
  filter: USER_STATUS,
}

const UserTable = (props: UserTableProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const allUsers = useSelector(selectUserList) || [];
  const newUsers = useSelector(selectNewUsers) || [];
  const activationUsers = useSelector(selectActivationUsers) || [];
  const activeUsers = useSelector(selectActiveUsers) || [];
  const inactiveUsers = useSelector(selectInactiveUsers) || [];
  const editUser = useSelector(getEditUser);
  let userList: UserModel[];

  const { filter } = props;
  switch (filter) {
    case USER_STATUS.NEW:
      userList = newUsers;
      break;
    case USER_STATUS.ACTIVATION:
      userList = activationUsers;
      break;
    case USER_STATUS.ACTIVE:
      userList = activeUsers;
      break;
    case USER_STATUS.INACTIVE:
      userList = inactiveUsers;
      break;
    default:
      userList = allUsers;
      break;
  }

  useEffect(() => {
    const foundUser = userList.find((user) => user.id === editUser?.id);

    if (!foundUser) {
      // const firstUser = userList[0];
      dispatch(startEditUser({ user: null }));
    }
  }, [filter])

  const getUserRolesFromUser = (user: UserModel) => {
    const roleArray = (user.userRoles || []).map((role: UserRoleModel) => role.type);
    roleArray.sort();
    return roleArray.join(', ');
  };

  const modifiedUserList = userList.map((user: UserModel) => {
    return {
      ...user,
      userRolesString: getUserRolesFromUser(user),
    };
  });

  // TODO: add translations
  const columns = [
    { flex: 1, field: 'userName', headerName: 'User name' },
    { flex: 1, field: 'email', headerName: 'Email' },
    { flex: 1, field: 'profile.fullName', headerName: 'Name' },
    { flex: 1, field: 'userRolesString', headerName: 'Roles' },
    { flex: 1, field: 'status', headerName: 'Status' },
  ];

  const findUserById = (id: number) => allUsers.find((user: UserModel) => user.id === id);

  const startEditUserHandler = useCallback(
    (id) => {
      dispatch(startEditUser({ user: findUserById(id) }));
    },
    [allUsers],
  );

  const createANewUser = () => {
    dispatch(startEditUser({ user: { id: 0 } }));
  };

  return (
    <UserListRequest>
      <UserRoleListRequest>
        <>
          <Toolbar title='User management'>
            <IconButton
              aria-label='add user'
              color='secondary'
              size='small'
              onClick={createANewUser}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Toolbar>
          <TableList
            selectedId={editUser?.id}
            columns={columns}
            rows={modifiedUserList}
            selectRow={startEditUserHandler} />
        </>
      </UserRoleListRequest>
    </UserListRequest>
  );
};

export default UserTable;