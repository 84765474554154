import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../app/api";
import CountryModel from "../../models/CountryModel";
import { serializeError } from "../../app/shared";

const SLICE_NAME = 'country';

export const requestAllCountries = createAsyncThunk(
  `${SLICE_NAME}/all`,
  async (_, { rejectWithValue }) => {
    try {
      const countryList: CountryModel[] = await request.get('/country');
      return countryList;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const addCountry = createAsyncThunk(
  `${SLICE_NAME}/add`,
  async (body: { name: string; regionId: number }, { rejectWithValue }) => {
    try {
      const country: CountryModel = await request.post('/country', body);
      return country;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const updateCountry = createAsyncThunk(
  `${SLICE_NAME}/update`,
  async ({ id, name, regionId }: CountryModel, { rejectWithValue }) => {
    try {
      const country: CountryModel = await request.put(`/country/${id}`, { name, regionId });
      return country;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const removeCountry = createAsyncThunk(
  `${SLICE_NAME}/delete`,
  async (id: number, { rejectWithValue }) => {
    try {
      await request.delete(`/country/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);