import { RootState } from "../../app/state";
import { createSelector } from "@reduxjs/toolkit";

export const selectFilterSlice = (state: RootState) => state.filterState;

export const selectInfluenceAreaEnabled = createSelector(
  selectFilterSlice,
  (state) => state.influenceAreaEnabled
);

export const selectSmartFilterDeviceId = createSelector(
  selectFilterSlice,
  (state) => state.smartFilterDeviceId
);

export const selectFilterPanelVisibility = createSelector(
  selectFilterSlice,
  (state) => state.filterPanelIsShown
);

export const selectCurrentManualFilters = createSelector(
  selectFilterSlice,
  (state) => state.manualFilters
);

export const selectFilterMapId = createSelector(
  selectFilterSlice,
  (state) => state.filtersAppliedForMapId
);