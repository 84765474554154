import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

type ModalProps = {
  isOpen: boolean;
  text?: string;
  title?: string;
  closeModal: () => void;
  confirmButtonHandler?: (text: string) => void;
  declineButtonHandler?: () => void;
  confirmButtonText?: string;
  declineButtonText?: string;
  label?: string;
};

// todo: rewrite dialogs to a general view with extensibility
export const GenericModalWithText = ({
  isOpen,
  closeModal,
  text,
  title,
  confirmButtonHandler,
  declineButtonHandler,
  confirmButtonText,
  declineButtonText,
  label,
}: ModalProps) => {
  const [dialogText, setDialogText] = useState('');

  const onConfirmButtonHandler = () => {
    closeModal();
    if (!confirmButtonHandler) {
      return;
    }
    confirmButtonHandler(dialogText);
  }

  const onDeleteButtonHandler = () => {
    closeModal();
    if (!declineButtonHandler) {
      return;
    }
    declineButtonHandler();
  }

  const onChange = (e) => {
    setDialogText(e.target.value);
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{title}</Box>
          <Box>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
        <Box marginTop="20px">
          <TextField
            label={label}
            fullWidth
            variant='outlined'
            name='dialogText'
            value={dialogText}
            onChange={onChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box marginRight="20px">
          {confirmButtonText && (
            <Button onClick={onConfirmButtonHandler} color="secondary">
              {confirmButtonText}
            </Button>
          )}
          {declineButtonText && (
            <Button onClick={onDeleteButtonHandler} color="primary" autoFocus>
              {declineButtonText}
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};
