import React from 'react';

const FMLogo = () => (
  <>
    <circle cx='22' cy='22' r='7' fill='white' />
    <circle cx='22' cy='6' r='3' fill='white' fillOpacity='0.5' />
    <circle cx='38' cy='22' r='3' fill='white' fillOpacity='0.5' />
    <circle cx='22' cy='38' r='3' fill='white' fillOpacity='0.5' />
    <circle cx='6' cy='22' r='3' fill='white' fillOpacity='0.5' />
    <path
      d='M14.3423 13.0606C14.8613 13.2495 15.1289 13.8233 14.94 14.3423C14.7511 14.8613 14.1772 15.1289 13.6583 14.94C13.1393 14.7511 12.8717 14.1772 13.0606 13.6583C13.2495 13.1393 13.8233 12.8717 14.3423 13.0606Z'
      fill='white' />
    <path
      d='M30.3423 13.0606C30.8613 13.2495 31.1289 13.8233 30.94 14.3423C30.7511 14.8613 30.1772 15.1289 29.6583 14.94C29.1393 14.7511 28.8717 14.1772 29.0606 13.6583C29.2495 13.1393 29.8233 12.8717 30.3423 13.0606Z'
      fill='white' />
    <path
      d='M30.3423 29.0606C30.8613 29.2495 31.1289 29.8233 30.94 30.3423C30.7511 30.8613 30.1772 31.1289 29.6583 30.94C29.1393 30.7511 28.8717 30.1772 29.0606 29.6583C29.2495 29.1393 29.8233 28.8717 30.3423 29.0606Z'
      fill='white' />
    <path
      d='M14.3423 29.0606C14.8613 29.2495 15.1289 29.8233 14.94 30.3423C14.7511 30.8613 14.1772 31.1289 13.6583 30.94C13.1393 30.7511 12.8717 30.1772 13.0606 29.6583C13.2495 29.1393 13.8233 28.8717 14.3423 29.0606Z'
      fill='white' />
  </>
);

export default FMLogo;
