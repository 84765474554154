import {
  IconButton,
  TextField,
  Divider,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import { useFormik } from "formik";
import { omitBy, isNil, isEmpty } from "lodash/fp";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { RegionModel } from "models/RegionModel";
import { ModalType } from "app/shared/modal-context/constants";
import { useShowModal } from "app/shared/modal-context/modal.context";
import { useSnackbar } from "app/shared/snackbar-context/snackbar.context";
import { addRegion, updateRegion, removeRegion } from "../../../store/regionStore/regionRequests";
import {
  selectCurrentRegion,
  selectCurrentRegionId,
} from "../../../store/regionStore/regionSelectors";
import { useHistory } from "react-router-dom";
import { useTypedDispatch } from "../../../app/state";
import { pagePathGenerator } from "../../../routes/pagePathGenerator";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import classes from './index.module.scss';
import Grid from "@material-ui/core/Grid";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Region Name is required")
    .max(30, "Region name should be less then 30 symbols"),
});

export const RegionForm = () => {
  const history = useHistory();
  const dispatch = useTypedDispatch();
  const { showModal } = useShowModal();
  const { openSnackbar } = useSnackbar();
  const currentRegion = useSelector(selectCurrentRegion);
  const currentRegionId = useSelector(selectCurrentRegionId);
  const isCreateRegion = currentRegionId === -1;

  const regionForm = useFormik({
    initialValues: omitBy(isNil, {
      ...(currentRegion || {}),
    }) as any,
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const navigateToRegionId = (regionId: number | null) => {
    const url = pagePathGenerator.REGIONS_AND_COUNTRY({
      regionId,
    });

    history.replace(url);
  }

  const onSaveRegion = async () => {
    const valid = await regionForm.validateForm();
    if (!isEmpty(valid)) {
      return;
    }
    if (isCreateRegion) {
      dispatch(addRegion(regionForm.values as RegionModel))
        .then((action) => {
          const region = action.payload as RegionModel;
          navigateToRegionId(region.id);
        })
    } else {
      dispatch(updateRegion(regionForm.values as RegionModel));
    }
  }

  const onDeleteRegion = () => {
    if (!isEmpty(currentRegion?.countries)) {
      openSnackbar({
        title: "Cannot delete the region",
        message: "Region contains countries. Remove countries first",
        severity: "info",
      });
      return;
    }

    showModal(ModalType.MODAL_GENERIC_CONFIRM, {
      title: "Delete Region",
      text: "You want to delete this Region. Are you sure?",
      confirmButtonHandler: onContinueDeleteCountry,
      confirmButtonText: "Delete",
    });
  }

  const onContinueDeleteCountry = () => {
    if (!isCreateRegion) {
      dispatch(removeRegion(regionForm.values.id))
        .then((action) => {
          if (action.type === removeRegion.rejected.type) {
            openSnackbar({
              title: "Cannot delete region",
              message: "Failed to delete region",
              severity: "info",
            });
          } else {
            navigateToRegionId(null);
          }
        })
    }
  }
  const onCancelChanges = () => {
    regionForm.resetForm(regionForm.initialValues);
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={isCreateRegion ? 'Add new region' : 'Region details'}>
          <IconButton
            disabled={!regionForm.dirty}
            aria-label="save region changes"
            onClick={onSaveRegion}
            color="secondary"
            size="small"
          >
            <SaveIcon />
          </IconButton>
          <IconButton
            disabled={isCreateRegion}
            aria-label="Delete region"
            onClick={onDeleteRegion}
            color="secondary"
            size="small"
          >
            <Delete />
          </IconButton>
          <IconButton
            disabled={!regionForm.dirty}
            aria-label="cancel region changes"
            onClick={onCancelChanges}
            color="secondary"
            size="small"
          >
            <CancelIcon />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <Divider />
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} className={classes.padding}>
        <Grid item>
          <TextField
            label="Region"
            autoFocus={true}
            variant="outlined"
            id="country-title"
            name="name"
            fullWidth
            onChange={regionForm.handleChange}
            value={regionForm.values.name || ""}
            helperText={regionForm.errors.name}
            error={Boolean(regionForm.errors.name)}
          />
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  );
};
