import { useTypedDispatch } from "../app/state";
import { useSelector } from "react-redux";
import {
  selectCurrentManualFilters, selectFilterMapId, selectFilterPanelVisibility,
  selectSmartFilterDeviceId
} from "../store/filterStore/filterSelectors";
import { selectDeviceById } from "../store/deviceStore/deviceSelectors";
import {
  ManualFilterType,
  setFiltersMapId,
  setManualFilters,
  setSmartFilterDeviceId
} from "../store/filterStore/filterState";
import DeviceModel from "../models/DeviceModel";
import { convertToHz } from "../helpers/converters/frequency";

interface Channel {
  centralFrequency: number,
  bandwidth: number,
}

function areChannelsOverlap(channel1: Channel, channel2: Channel) {
  // distance between 2 central frequencies is less than half of two bandwidthes
  return Math.abs(channel1.centralFrequency - channel2.centralFrequency) < ((channel1.bandwidth + channel2.bandwidth) / 2)
}

function filterByChannel(deviceList: DeviceModel[], selectedChannelList: Channel[]) {
  return deviceList.filter((device) => {
    const interfaceList = device.configuration.wirelessInterfaces || [];

    return interfaceList.some((wirelessInterface) => {
      const bandwidth = wirelessInterface.technologyConfiguration?.bandwidth || '';
      const bandwidthInHz = convertToHz(bandwidth);

      return (wirelessInterface.channels || []).some((channel) => {
        return selectedChannelList.some((smartSelectedChannel) => areChannelsOverlap(smartSelectedChannel, {
          bandwidth: bandwidthInHz,
          centralFrequency: channel.centralFrequencyHz,
        }))
      })
    })
  });
}

function filterByWirelessSystemList(deviceList: DeviceModel[], wirelessSystemIdList: number[]) {
  return deviceList.filter((device) => {
    return device?.configuration?.wirelessSystemId && wirelessSystemIdList.includes(device?.configuration?.wirelessSystemId);
  })
}

function filterByTechnologyConfigurationList(deviceList: DeviceModel[], technologyConfigurationIdList: number[]) {
  return deviceList.filter((device) => {
    return (device.configuration?.wirelessInterfaces || [])
      .some((wirelessInterface) => technologyConfigurationIdList.includes(wirelessInterface.technologyConfigurationId))
  })
}

interface GeneralFilterSet {
  manualFilters?: ManualFilterType | null,
  smartFilterDeviceId?: number | null,
  mapId: number | null,
  force?: boolean,
}

const useFilter = () => {
  const dispatch = useTypedDispatch();
  const filterIsAppliedForMapId = useSelector(selectFilterMapId);
  const smartFilterDeviceId = useSelector(selectSmartFilterDeviceId);
  const smartFilterDevice = useSelector(selectDeviceById(smartFilterDeviceId));
  const isFilterPanelVisible = useSelector(selectFilterPanelVisibility);
  const manualFilters = useSelector(selectCurrentManualFilters);
  const manualFiltersAreChosen = manualFilters &&
    (!!manualFilters.frequencyFrom || !!manualFilters.frequencyTo || manualFilters.technologyConfigurationIdList.length > 0 || manualFilters.wirelessSystemIdList.length > 0)
  const isSmartFilterEnabled = !!smartFilterDeviceId;
  const isAnyFilterChosen = isSmartFilterEnabled || manualFiltersAreChosen;

  const updateSmartFilterDeviceId = (deviceId: number | null) => {
    if (deviceId) {
      dispatch(setManualFilters(null));
    }
    dispatch(setSmartFilterDeviceId(deviceId));
  }

  const clearSmartFilter = () => updateSmartFilterDeviceId(null);

  const filterDeviceList = (deviceList: DeviceModel[]) => {
    if (!isSmartFilterEnabled && !manualFiltersAreChosen) {
      return deviceList;
    }

    const selectedChannelList: Channel[] = [];

    if (isSmartFilterEnabled) {
      const interfaceList = smartFilterDevice?.configuration?.wirelessInterfaces || [];
      interfaceList.forEach((wirelessInterface) => {
        const bandwidth = wirelessInterface.technologyConfiguration?.bandwidth || '';
        const bandwidthInHz = convertToHz(bandwidth);

        (wirelessInterface?.channels || []).forEach((channel) => {
          selectedChannelList.push({
            bandwidth: bandwidthInHz,
            centralFrequency: channel.centralFrequencyHz,
          })
        })
      });
    } else {
      const minValue = convertToHz(manualFilters?.frequencyFrom || '0 Hz');
      const maxValue = convertToHz(manualFilters?.frequencyTo || '1000 THz');

      const channel: Channel = {
        centralFrequency: Math.floor((maxValue + minValue) / 2),
        bandwidth: maxValue - minValue,
      };

      selectedChannelList.push(channel);
    }

    let filteredDeviceList = filterByChannel(deviceList, selectedChannelList);
    if (manualFilters?.wirelessSystemIdList && manualFilters.wirelessSystemIdList.length > 0) {
      filteredDeviceList = filterByWirelessSystemList(filteredDeviceList, manualFilters.wirelessSystemIdList);
    }

    if (manualFilters?.technologyConfigurationIdList && manualFilters.technologyConfigurationIdList.length > 0) {
      filteredDeviceList = filterByTechnologyConfigurationList(filteredDeviceList, manualFilters.technologyConfigurationIdList);
    }

    return filteredDeviceList;
  }

  const initializeFiltersForPage = (props: GeneralFilterSet) => {
    const { manualFilters, smartFilterDeviceId, force = false, mapId } = props;

    if (filterIsAppliedForMapId === mapId && isAnyFilterChosen && !force) {
      return;
    }

    dispatch(setFiltersMapId(mapId));

    if (manualFilters) {
      dispatch(setSmartFilterDeviceId(null));
      dispatch(setManualFilters(manualFilters));

      return;
    }

    if (smartFilterDeviceId) {
      dispatch(setManualFilters(null));
      dispatch(setSmartFilterDeviceId(smartFilterDeviceId));

      return;
    }

    // no filters initialized for a different mapId
    dispatch(setManualFilters(null));
    dispatch(setSmartFilterDeviceId(null));
  }

  return {
    actions: {
      updateSmartFilterDeviceId,
      clearSmartFilter,
      filterDeviceList,
      initializeFiltersForPage,
    },
    state: {
      smartFilterDeviceId,
      smartFilterDevice,
      isSmartFilterEnabled,
      isFilterPanelVisible,
      manualFiltersAreChosen,
      manualFilters,
      isAnyFilterChosen,
    }
  }
}

export default useFilter;