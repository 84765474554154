import { Alert, AlertTitle, Color } from "@material-ui/lab";
import {
  SnackbarKey,
  useSnackbar,
} from "app/shared/snackbar-context/snackbar.context";
import { forwardRef, useEffect, useRef } from "react";

type SnackbarAlertProps = {
  title?: string;
  snackKey: SnackbarKey;
  message: string;
  severity: Color;
};

export const SnackbarAlert = forwardRef(
  (props: SnackbarAlertProps, ref: any) => {
    const { closeSnackbar } = useSnackbar();

    const timerAutoHide = useRef();

    useEffect(() => {
      clearTimeout(timerAutoHide.current);
      timerAutoHide.current = setTimeout(() => {
        closeSnackbar(props.snackKey);
      }, 7000) as any;

      return () => {
        clearTimeout(timerAutoHide.current);
      };
    }, [props.snackKey]);

    return (
      <Alert
        ref={ref}
        severity={props.severity}
        onClose={closeSnackbar.bind(null, props.snackKey)}
      >
        <AlertTitle>{props.title}</AlertTitle>
        {props.message}
      </Alert>
    );
  }
);
