import { createTheme } from '@material-ui/core';

export const theme = createTheme({
  mixins: {
    toolbar: {
      position: 'static',
      minHeight: '48px',
      height: '48px',
      paddingRight: '4px',
      justifyContent: 'space-between',
      backgroundColor: '#F8F8F8',
      gutters: {},
    },
  },
  typography: {
    button: {
      textTransform: 'unset',
    },
    h6: {
      fontSize: '18px',
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        backgroundColor: '#fff',
        "&$selected": {
          backgroundColor: "rgba(0,125,255,0.08)",
          "&:hover": {
            backgroundColor: "rgba(0,125,255,0.08)",
          }
        },
        "&$hover": {
          backgroundColor: "rgba(0,125,255,0.08)",
        }
      },
    },
    MuiTypography: {
      h4: {
        fontSize: '18px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: '#586e8d',
      }
    },
    MuiTab: {
      root: {
        minWidth: '60px',
        '@media (min-width:600px)': {
          minWidth: '40px',
        },
      },
      textColorInherit: {
        opacity: 1,
      },
    },
    MuiIconButton: {
      sizeSmall: {
        padding: '5px',
      },
    },
    MuiAppBar: {
      positionRelative: {
        height: '64px',
      },
    },
    MuiOutlinedInput: {
      root: {
        height: '46px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#0000008a',
      },
      input: {
        padding: '15px 14px 14px 14px',
        height: '20px',
      },
      multiline: {
        padding: '8px 0 8px 8px',
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '16px 24px',
      }
    },
    MuiInputBase: {
      input: {
        height: '38px',
        paddingTop: '10px',
        color: '#000000',
      },
      inputAdornedEnd: {
        height: '20px',
      },
      multiline: {
        minHeight: '80px',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '16px',
        lineHeight: '12px',
      },
    },
    MuiChip: {
      root: {
        backgroundColor: '#E0E0E0',
      },
    },
    MuiFilledInput: {
      input: {
        paddingTop: '12px',
        paddingBottom: '5px',
      },
    },
    MuiBreadcrumbs: {
      root: {
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingRight: '24px',
        paddingLeft: '16px',
        minHeight: '40px',
        backgroundColor: '#FFFFFF',
      },
      separator: { margin: ' 0px 15px' },
    },
    MuiListItem: {
      button: {
        minHeight: '24px',
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      root: {
        '&$selected': {
          background:
            'linear-gradient(0deg, rgba(0, 125, 255, 0.08), rgba(0, 125, 255, 0.08)), #FFFFFF',
        },
      },
    },
    MuiListItemText: {
      root: {
        fontSize: '14px',
        margin: 0,
      },
    },
    MuiButtonBase: {
      root: {
        minHeight: '24px',
      },
    },
    MuiButton: {
      root: {
        margin: '8px',
      },
      sizeLarge: {
        margin: '4px auto 4px 0',
        paddingLeft: '4px',
        color: '#4D4F52',
      },
    },
    MuiTableCell: {
      root: {
        height: '32px',
      },
      sizeSmall: {
        minHeight: '32px',
        height: '32px',
      },
      head: {
        backgroundColor: '#fff',
        padding: '6px 16px 5px 16px',
        lineHeight: '23px',
        height: '36px',
      },
    },
    MuiMenuItem: {
      root: {
        height: '32px',
      },
    },
    MuiPaper: {
      elevation2: {
        minWidth: '392px',
        padding: '32px 32px 24px 32px',
        borderRadius: '24px',
      },
    },
  },
  palette: {
    primary: {
      main: '#008BCA',
      dark: '#0057B2',
      light: '#3397FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#586E8D',
      dark: '#B23842',
      light: '#FF737F',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#4CAF50',
      dark: '#3B873E',
      light: '#7BC67E',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#2196F3',
      dark: '#0B79D0',
      light: '#64B6F7',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FF9800',
      dark: '#C77700',
      light: '#FFB547',
      contrastText: 'rgba(0,0,0,0.87)',
    },
    error: {
      main: '#B90028',
      dark: '#E31B0C',
      light: '#F88078',
      contrastText: '#FFFFFF',
    },
  },
});
