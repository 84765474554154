import { RootState } from "../../app/state";
import { wirelessSystemAdapter } from "./wirelessSystemState";
import { createSelector } from "@reduxjs/toolkit";

const selectRoot = (state: RootState) => state;
export const selectWirelessSystemSlice = (state: RootState) => state.wirelessSystemList;

const wirelessSystemAdapterSelectors = wirelessSystemAdapter.getSelectors(selectWirelessSystemSlice);

export const selectAllWirelessSystems = wirelessSystemAdapterSelectors.selectAll;
export const selectWirelessSystemById = (id) => createSelector(
  selectRoot,
  (root) => wirelessSystemAdapterSelectors.selectById(root, id),
)

export const selectCurrentWirelessSystemId = createSelector(
  selectWirelessSystemSlice,
  (state) => state.selectedWirelessSystemId || 1
);

export const selectCurrentWirelessSystem = createSelector(
  selectRoot,
  selectCurrentWirelessSystemId,
  (root, wirelessSystemId) => (wirelessSystemId && wirelessSystemAdapterSelectors.selectById(root, wirelessSystemId)) || null
);

export const selectWirelessSystemsByIdList = (wirelessSystemIdList: (number | undefined)[]) => createSelector(
  selectAllWirelessSystems,
  (wirelessSystemsList) => wirelessSystemsList.filter((wirelessSystem) => wirelessSystemIdList.includes(wirelessSystem.id))
);

export const selectAllWirelessSystemLoadingState = createSelector(
  selectWirelessSystemSlice,
  (state) => state.loadAllStatus
);