import { Grid } from "@material-ui/core";

import classes from './index.module.scss';

const ToolbarDivider = () => {
  return (
    <Grid
      item
      className={classes.divider}
    >
      <Grid item className={classes.mask} />
    </Grid>
  )
}

export default ToolbarDivider;