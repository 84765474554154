import { AppDispatch } from "app/state/store";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LoginForm } from "./login-form/LoginForm";
import ModalPageLayout from "../../layouts/ModalPageLayout/ModalPageLayout";
import { loginWithPassword } from "../../store/userStore/userRequests";
import {
  isUserLoggedIn,
  selectLoginStatus,
} from "../../store/userStore/userSelectors";
import PAGES from "../../pages";

import styles from "./Login.module.scss";

export const Login = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const status = useSelector(selectLoginStatus);
  const loginStatus = useSelector(isUserLoggedIn);
  useEffect(() => {
    if (loginStatus) {
      history.push("/locations");
      return;
    } else {
      history.replace(PAGES.LOGIN_PAGE);
    }
  }, [loginStatus]);

  const loginCallback = (values: { login: string; password: string }) => {
    dispatch(loginWithPassword(values));
  }

  return (
    <ModalPageLayout classes={{
      container: styles.container,
    }}>
      <LoginForm
        status={Boolean(status?.error)}
        login={loginCallback}
      />
    </ModalPageLayout>
  );
};
