import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyle = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    display: "flex",
    gap: "8px",
    maxHeight: "100%",
    maxWidth: "100%",
    position: "fixed",
    flexDirection: "column",
    zIndex: theme.zIndex.snackbar,
    height: "auto",
    width: "auto",
    minWidth: 288,
    left: "16px",
    bottom: "16px",
  },
}));

interface SnackbarContainerProps {
  children: JSX.Element | JSX.Element[];
}

export const SnackbarContainer: React.FC<SnackbarContainerProps> = React.memo(
  (props) => {
    const style = useStyle();
    return <div {...props} className={style.root} />;
  }
);
