import React, { useEffect } from 'react';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { ListItemText, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from './SelectAndAction.module.scss';

interface AutocompleteComponent {
  currentValue?: any;
  listFields: Array<{ title: string | undefined; valueOption: number }>;
  label: string;
  onCreateNew?: (e: any) => void;
  onChange: (e: any) => void;
  labelForAdd?: string;
  className?: any;
  isAddNewAvailable: boolean;
  disabled?: boolean;
}

// todo: revise this component, rewrite if needed
// todo: rewrite types

export default function SelectAndAction({
  listFields,
  label,
  onCreateNew,
  onChange,
  currentValue,
  labelForAdd,
  className,
  isAddNewAvailable,
  disabled
}: AutocompleteComponent) {
  const { t } = useTranslation();
  const filter = createFilterOptions();
  const findSelected = () => listFields.find((option) => option?.valueOption === currentValue)
  const selectedValue = findSelected();

  const [inputValue, setInputValue] = React.useState<string>(selectedValue?.title || '');

  useEffect(() => {
    setInputValue(selectedValue?.title || '')
  }, [selectedValue]);

  return selectedValue ? (
    <Autocomplete
      classes={{
        root: styles.root,
        option: styles.option,
      }}
      className={className}
      disableClearable={true}
      value={selectedValue}
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
      disabled={disabled}
      onChange={(event: any, newValue: { title: string, valueOption: number }): void => {
        if (newValue) {
          onChange(newValue.valueOption);
        }
      }}
      filterOptions={(listFields, params: any): any => {
        const filtered = filter(listFields, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '' && !filtered.length && isAddNewAvailable && labelForAdd) {
          filtered.push({
            title: `${params.inputValue} ${t(labelForAdd)}`,
            valueOption: params.inputValue,
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        return option?.title || ''
      }}
      id={label}
      options={listFields}
      renderOption={(option) => {
        if (option.title && labelForAdd && onCreateNew && option.title.includes(t(labelForAdd))) {
          return (
            <ListItemText
              className={styles.addElement}
              onClick={() => {
                onCreateNew(option.valueOption);
              }}
            >
              {option.title}
            </ListItemText>
          );
        }
        return option.title || '';
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant='outlined'
          inputProps={{
            ...params.inputProps,
            className: styles.input
          }}
          onBlur={() => setInputValue(selectedValue?.title || '')}
        />
      )}
    />
  ) : null;
}
