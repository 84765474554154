import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SearchFormValues } from "../../containers/SearchPage/SearchDialog/SearchDialogForm";
import { doSearch } from "../../store/deviceStore/deviceRequests";
import { useTypedDispatch } from "../../app/state";
import DeviceGeneralContainer from "../../containers/DevicesPage/DeviceGeneralContainer";
import SearchLeftPanel from "../../containers/SearchPage/SearchLeftPanel";
import { selectSearchDeviceIdList } from "../../store/deviceStore/deviceSelectors";
import { ThreeColumnLayout } from "../../layouts/ThreeColumnLayout/ThreeColumnLayout";
import NoItems from "../../components/NoItems";
import DeviceCurrentIdsSetter from "../../containers/DevicesPage/DeviceCurrentIdsSetter";
import { setCurrentDeviceId } from "../../store/deviceStore/deviceState";
import useFilter from "../../hooks/filter.hook";

const SearchPage = () => {
  const dispatch = useTypedDispatch();
  const { actions } = useFilter();
  actions.initializeFiltersForPage({ mapId: null });
  const deviceIdList = useSelector(selectSearchDeviceIdList);
  const [searchValues, setSearchValues] = useState({} as SearchFormValues);
  const hasResult = deviceIdList.length > 0;

  const onSearch = (props?: SearchFormValues) => {
    if (props) {
      setSearchValues(props);
    }
    dispatch(setCurrentDeviceId(null));
    dispatch(doSearch(props || searchValues));
  }

  useEffect(() => {
    dispatch(setCurrentDeviceId(null));
  }, [])

  const leftPanel = (
    <SearchLeftPanel
      key={1}
      onSearch={onSearch}
      searchValues={searchValues}
    />
  );

  return hasResult ? (
    <>
      <DeviceGeneralContainer
        leftPanel={leftPanel}
        onUpdate={onSearch}
        onLastDeviceDeleted={() => {}}
      />
      <DeviceCurrentIdsSetter />
    </>
  ) : (
    <ThreeColumnLayout
      leftSideBar={leftPanel}
      centerMainPanel={<NoItems title='Your search did not match any devices' />}
    />
  );
}

export default SearchPage;