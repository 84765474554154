import DOMPurify from "dompurify";

export async function getImageSize(image: string): Promise<[number, number] | undefined>  {
  const chunks = image.split('.');
  const ext = chunks[chunks.length - 1];

  if (ext === 'svg') {
    return getSVGImageSize(image);
  }
  return getNonSVGImageSize(image);
}

export async function getNonSVGImageSize(image: string): Promise<[number, number] | undefined> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = function() {
      resolve([img.width, img.height])
    }
    img.src = image;
  })
}

export async function getSVGImageSize(image: string): Promise<[number, number] | undefined> {
  return new Promise (function (resolve) {
    fetch(image).then((res) => {
      return res.text();
    }).then((data) => {
      const container = document.createElement('div');
      container.className = 'temp_image';
      container.style.position = 'absolute';
      container.style.right = '0px';
      container.style.width = '100%';
      document.body.appendChild(container);
      container.innerHTML = DOMPurify.sanitize(data);
      const svg = container.querySelector('svg');
      const bb = svg?.getBBox();
      document.body.removeChild(container);
      if (bb) {
        resolve([bb.width, bb.height]);
      } else {
        resolve(void(0));
      }
    }).catch((e) => {
      // TODO: handle error here
    });
  });
}