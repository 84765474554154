import { Grid, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import TransitEnterexitIcon from '@material-ui/icons/TransitEnterexit';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { Toolbar } from 'app/components';
import { FileUploader } from 'app/components/ui/file-upload-button/FileUploadButton';
import { useTranslation } from 'react-i18next';

import { CheckPermission } from '../../requests/CheckPermission';
import { PERMISSIONS } from '../../store/userStore/permissions.enum';
import { ThreeColumnLayout } from '../../layouts/ThreeColumnLayout/ThreeColumnLayout';
import BackButton from "../../components/BackButton";
import { useSelector } from "react-redux";
import { selectCurrentlyEditPoint } from "../../store/mapPointsStore/mapPointSelectors";
import FloorMapActionPanel from "../../containers/FloorMapActionPanel";
import { requestFloorById } from "../../store/floorStore/floorRequests";
import { getQueryObject } from "../../pages";
import useRoutes from "../../hooks/routeHooks";
import { GeneralRequest } from "../../requests/GeneralRequest";
import PageUpdater from "../../containers/PageUpdater";
import { setCurrentFloorId } from "../../store/floorStore/floorState";
import React, { useEffect } from "react";
import { useTypedDispatch } from "../state";
import { clearMapPointState } from "../../store/mapPointsStore/mapPointsState";
import { ModalType } from "../shared/modal-context/constants";
import { useShowModal } from "../shared/modal-context/modal.context";
import MapScaleContainer from "../../containers/Map/MapScaleContainer";
import { selectCurrentFloor } from "../../store/floorStore/floorSelectors";
import { deleteFloorMapFile, uploadFloorMapFile } from "../../store/mapStore/mapRequests";
import { useSnackbar } from "../shared/snackbar-context/snackbar.context";

const MainPanel = () => {
  const { showModal } = useShowModal();
  const { openSnackbar } = useSnackbar();
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
  const currentFloor = useSelector(selectCurrentFloor);
  const fileUploadInput = React.useRef<HTMLInputElement>(null);
  const isMapPointEdit = typeof useSelector(selectCurrentlyEditPoint) !== 'undefined';
  const isMapUploaded = currentFloor?.mapId;

  useEffect(() => {
    dispatch(clearMapPointState())
  }, []);

  const onContinueReplaceMap = () => {
    fileUploadInput?.current?.click();
  };

  const onMapUpload = (file: File) => {
    if (!currentFloor) {
      return;
    }

    dispatch(uploadFloorMapFile({ file, floorId: currentFloor.id }));
  }

  const handleClick = () => {
    if (isMapUploaded) {
      showModal(ModalType.MODAL_GENERIC_CONFIRM, {
        title: 'Replace map',
        text: 'Are you sure you want to replace current map file?',
        confirmButtonHandler: onContinueReplaceMap,
        confirmButtonText: 'Replace',
      });

      return true;
    }
  };

  const onContinueDeleteFloorMap = () => {
    if (!currentFloor) {
      return;
    }
    const floorId = currentFloor.id;
    dispatch(deleteFloorMapFile({ floorId }))
      .then((action) => {
        // @ts-ignore
        if (action?.error) {
          openSnackbar({
            title: "Cannot delete floor map",
            message: "This floor has devices",
            severity: "error",
          });
        } else {
          dispatch(clearMapPointState());
        }
      });
  }

  const onDeleteClick = () => {
    showModal(ModalType.MODAL_GENERIC_CONFIRM, {
      title: "Delete Floor map",
      text: "Do you really want to delete floor map?",
      confirmButtonHandler: onContinueDeleteFloorMap,
      confirmButtonText: "Delete",
    });
  }

  return (
    <Grid container item xs direction='column'>
      <Toolbar title={t('map_configuration.page_title')}>
        <>
          <CheckPermission permissionName={PERMISSIONS.FLOOR_MAP_ACTIONS} noPermissionComponent={null}>
            <>
              <FileUploader
                handleClick={handleClick}
                handleFile={onMapUpload}
                accept='.svg,.jpg,.png,.jpeg'
                label={isMapUploaded ? 'Replace map' : 'Upload map'}
                icon={isMapUploaded ? <AddToPhotosIcon /> : <AddPhotoAlternateIcon />}
                inputRef={fileUploadInput}
              />
              <IconButton
                disabled={!isMapUploaded || isMapPointEdit}
                aria-label='remove map'
                color='secondary'
                size='small'
                onClick={onDeleteClick}
              >
                <Delete />
              </IconButton>
            </>
          </CheckPermission>
          <BackButton
            disabled={isMapPointEdit}
            aria-label={'close window'}
            // route={backPath}
            backButtonIcon={<TransitEnterexitIcon />}
          />
        </>
      </Toolbar>
      <MapScaleContainer map={currentFloor?.map} />
    </Grid>
  );
};

export const FloorMapPage = () => {
  const urlProps = getQueryObject();
  const { actions } = useRoutes();

  if (urlProps.floorId) {
    return (
      <>
        <PageUpdater watchItemList={[
          {
            title: 'floorId',
            action: setCurrentFloorId,
          },
        ]} />
        <GeneralRequest requestAction={requestFloorById} requestArguments={[urlProps.floorId]}>
          <ThreeColumnLayout centerMainPanel={<MainPanel />} rightSideBar={<FloorMapActionPanel />} />
        </GeneralRequest>
      </>
    )
  } else {
    actions.redirectWrongId();
    return null;
  }
};
