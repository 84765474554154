import { Redirect, Route, RouteProps } from "react-router-dom";

type ConditionalRouteProps = {
  condition: () => boolean;
  redirectTo: string;
} & RouteProps;

const ConditionalRoute = ({ condition, redirectTo, children, ...routeProps }: ConditionalRouteProps) => {
  return (
    condition() ? (
      <Route {...routeProps}>
        {children}
      </Route>
    ) : (
      <Redirect to={redirectTo} />
    )
  )
}

export default ConditionalRoute;