import ModalPageLayout from '../../layouts/ModalPageLayout/ModalPageLayout';
import PAGES, { getQueryObject } from '../index';
import { Redirect } from 'react-router-dom';
import CreatePasswordForm from '../../containers/CreatePasswordPage/CreatePasswordForm';
import classes from './index.module.scss';

const CreatePasswordPage = () => {
  const query = getQueryObject();
  const token = query.token as string;

  if (!token) {
    return <Redirect to={PAGES.HOME} />;
  }

  return (
    <ModalPageLayout classes={{
      paper: classes.modal,
    }}>
      <CreatePasswordForm
        userToken={token} />
    </ModalPageLayout>
  );
};

export default CreatePasswordPage;