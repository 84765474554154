import * as yup from "yup";

// todo: add translations here
export const getDeviceFormValidation = () => yup.object().shape({
  name: yup.string().max(50).required('Device name is required'),
  affectedUnits: yup.string().required('Affected Units is required'),
  type: yup.number().required(),
  height: yup.string(),
  axis: yup.string(),
  room: yup.string(),
  description: yup.string().max(2000),
  modelName: yup.string().max(100),
  manufacturer: yup.string().max(100),
  operator: yup.string().max(50),
  operatorInfo: yup.string().max(100),
  implementationStart: yup.date().nullable(true),
  implementationEnd: yup
    .date()
    .when('implementationStart', {
      is: (implementationStart) => {
        return !!implementationStart ? true : false;
      },
      then: yup
        .date()
        .required('End Date is required')
        .min(
          yup.ref('implementationStart'),
          "End date can't be before Start date"
        ),
    })
    .nullable(),
  implementationNotificationDays: yup.number().nullable(true),
  expirationDate: yup.date().nullable(true),
});