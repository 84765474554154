import { Grid } from '@material-ui/core';
import { Panel } from './Panel';
import styles from './ThreeColumnLayout.module.scss';

interface Props {
  rightSideBar?: JSX.Element | null;
  leftSideBar?: JSX.Element | null;
  centerMainPanel?: JSX.Element | null;
  hideRightSideBar?: boolean;
}

export const ThreeColumnLayout = ({
  rightSideBar,
  leftSideBar,
  centerMainPanel,
  hideRightSideBar,
}: Props) => {
  return (
    <Grid
      container
      direction='row'
      wrap='nowrap'
      className={styles.gridRoot}
    >
      <Grid
        item
        container
        direction='row'
        wrap='nowrap'
      >
        {
          leftSideBar && (
            <Panel
              className={styles.leftPanel}
            >
              {leftSideBar}
            </Panel>
          )
        }
        <Panel
          className={styles.centralPanel}
        >
          {centerMainPanel}
        </Panel>
        {rightSideBar && !hideRightSideBar && (
          <Panel
            xs={5}
            className={styles.rightPanel}
          >
            {rightSideBar}
          </Panel>
        )}
      </Grid>
    </Grid>
  );
};
