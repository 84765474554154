import { Paper as MaterialPaper } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

interface Props extends WithStyles<typeof styles> {
  children?: React.ReactNode;
  className?: string;
}

const styles = {
  root: {
    "border-radius": "24px",
  },
};

function PaperClass(props: Props) {
  const { classes, children, className, ...other } = props;

  return (
    <MaterialPaper className={clsx(classes.root, className)} {...other}>
      {children}
    </MaterialPaper>
  );
}

export const Paper = withStyles(styles)(PaperClass);
