export function extractUnits(s?: string) {
  const str = typeof s === "string" ? s : '';
  return str.replace(/[-,.\s\d]/ig, '');
}

export function extractNumber(s?: string) {
  const str = typeof s === "string" ? s : '';
  const resultList = [parseFloat(str), parseFloat(str.replace('.', ',')), parseFloat(str.replace(',', '.'))];

  return Math.max.apply(Math, resultList);
}