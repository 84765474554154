import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { CheckPermission } from "../../../requests/CheckPermission";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import useLocationRoute from "../locationRoute.hook";
import { selectFloorsByBuildingId } from "../../../store/floorStore/floorSelectors";
import { pagePathGenerator } from "../../../routes/pagePathGenerator";
import MenuList from "../../../components/MenuList";

const LocationFloorList = () => {
  const { state } = useLocationRoute();
  const history = useHistory();
  const { currentLocationId, currentBuildingId } = state;
  let floorList = useSelector(selectFloorsByBuildingId(currentBuildingId)) || [];
  const buildingsCreateMode = !currentBuildingId || currentBuildingId === -1;
  floorList = buildingsCreateMode ? [] : floorList;

  const onNewFloorCreate = () => {
    navigateToFloor(-1);
  };

  const navigateToFloor = (floorId: number) => {
    if (!buildingsCreateMode && currentLocationId && currentBuildingId) {
      const url = pagePathGenerator.BUILDING_PAGE({
        locationId: currentLocationId,
        buildingId: currentBuildingId,
        floorId,
      });

      history.push(url);
    }
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Floors'>
          <CheckPermission permissionName={PERMISSIONS.FLOOR_ACTIONS}>
            <IconButton
              component='span'
              disabled={buildingsCreateMode}
              color='secondary'
              size='small'
              onClick={onNewFloorCreate}
            >
              <AddCircleOutline />
            </IconButton>
          </CheckPermission>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <MenuList
          items={floorList}
          selectItem={navigateToFloor}
        />
      </HorizontalPanel>
    </HorizontalPanel>
  );
}

export default LocationFloorList;