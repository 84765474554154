export default function FxIcon() {
  return (
    <svg
      width='18'
      height='16'
      viewBox='0 0 18 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.85496 16C2.70967 16 2.58617 15.9568 2.48446 15.8703C2.39729 15.7694 2.3537 15.6468 2.3537 15.5027V6.48649H0.501252C0.355961 6.48649 0.232465 6.44324 0.130761 6.35676C0.0435871 6.25586 0 6.13333 0 5.98919V5.25405C0 5.10991 0.0435871 4.99459 0.130761 4.90811C0.232465 4.80721 0.355961 4.75676 0.501252 4.75676H2.3537V3.67567C2.3537 1.22522 3.6032 0 6.1022 0H7.40981C7.5551 0 7.67133 0.0504502 7.75851 0.151351C7.86021 0.237837 7.91106 0.353152 7.91106 0.497297V1.23243C7.91106 1.37658 7.86021 1.4991 7.75851 1.6C7.67133 1.68649 7.5551 1.72973 7.40981 1.72973H6.14578C5.49198 1.72973 5.03431 1.9027 4.77279 2.24865C4.51127 2.58018 4.3805 3.09189 4.3805 3.78378V4.75676H7.19187C7.33716 4.75676 7.4534 4.80721 7.54057 4.90811C7.64227 4.99459 7.69313 5.10991 7.69313 5.25405V5.98919C7.69313 6.13333 7.64227 6.25586 7.54057 6.35676C7.4534 6.44324 7.33716 6.48649 7.19187 6.48649H4.3805V15.5027C4.3805 15.6468 4.32965 15.7694 4.22795 15.8703C4.14078 15.9568 4.02454 16 3.87925 16H2.85496Z'
        fill='#586E8D'
      />
      <path
        d='M8.97384 16C8.86487 16 8.76801 15.964 8.68326 15.8919C8.61061 15.8078 8.57429 15.7117 8.57429 15.6036C8.57429 15.4955 8.61666 15.3934 8.70142 15.2973L11.7888 11.1892L8.91935 7.33333C8.84671 7.23724 8.81038 7.13514 8.81038 7.02703C8.81038 6.91892 8.84671 6.82883 8.91935 6.75676C9.00411 6.67267 9.10097 6.63063 9.20993 6.63063H10.79C10.911 6.63063 11.0018 6.66066 11.0624 6.72072C11.135 6.76877 11.2077 6.84084 11.2803 6.93694L13.3325 9.63964L15.3848 6.93694C15.3969 6.92493 15.4453 6.87087 15.5301 6.77477C15.6027 6.67868 15.7177 6.63063 15.8751 6.63063H17.3825C17.4915 6.63063 17.5823 6.67267 17.6549 6.75676C17.7397 6.82883 17.7821 6.91291 17.7821 7.00901C17.7821 7.12913 17.7457 7.23724 17.6731 7.33333L14.7673 11.2252L17.8729 15.2973C17.9576 15.3934 18 15.4955 18 15.6036C18 15.7117 17.9576 15.8078 17.8729 15.8919C17.8002 15.964 17.7094 16 17.6005 16H15.9478C15.7541 16 15.5906 15.9039 15.4574 15.7117L13.2781 12.8468L11.0624 15.7117C10.9292 15.9039 10.7657 16 10.572 16H8.97384Z'
        fill='#586E8D'
      />
    </svg>
  );
}
