import ChannelModel from "../../models/ChannelModel";
import channelColumns from "./ChannelColumns";
import StyledDataGrid from "components/StyledDataGrid";
import HorizontalPanel from "../HorizontalPanel";

import classes from './index.module.scss';

type ChannelsTableProps = {
  availableChannels: ChannelModel[],
  selectedChannelIdList?: number[],
  onSelect: (selectedChannels: number[]) => any,
}

const ChannelsTable = ({ availableChannels, selectedChannelIdList = [], onSelect }: ChannelsTableProps) => {

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE} className={classes.wrapper}>
      <StyledDataGrid
        columns={channelColumns}
        checkboxSelection
        disableColumnSelector
        rows={availableChannels}
        onSelectionModelChange={onSelect}
        selectionModel={selectedChannelIdList}
        density="compact"
      />
    </HorizontalPanel>
  )
}

export default ChannelsTable;