import { useState } from "react";
import cn from "classnames";
import { getPointOffset } from "../renderMapPoint";
import AddPropsToChildren from "../../../components/AddPropsToChildren";
import { ICONS, ICONS_DIMENSIONS } from "../constants";
import { filterDevicesWithoutMapPoint } from "../zoomMapHelpers";

import classes from './index.module.scss';

const DEVICE_POINT_TYPE = 'device';

const _deviceIconOffset = getPointOffset(DEVICE_POINT_TYPE);

const DeviceRenderer = ({ children, deviceList, currentDeviceId, onClick = (id: number) => {}, ...props }) => {
  const [scale, setScale] = useState(1);
  const devicesWithPoints = deviceList.filter(filterDevicesWithoutMapPoint);

  const onZoom = (newScale) => {
    setScale(newScale);
  }

  const extraProps = {
    onZoomHandlers: [onZoom],
    mapChildren: (
      <>
        {props.mapChildren || null}
        {
          devicesWithPoints.map((device) => {
            const deviceIconDimensions = ICONS_DIMENSIONS[DEVICE_POINT_TYPE];
            const point = device.configuration.mapPoint.mapPoint;

            const x = (point.x - _deviceIconOffset.width / scale);
            const y = (point.y - _deviceIconOffset.height / scale);

            return (
              <image
                key={device.id}
                xlinkHref={ICONS[DEVICE_POINT_TYPE]}
                width={deviceIconDimensions.width / scale}
                height={deviceIconDimensions.height / scale}
                x={x}
                y={y}
                onClick={() => onClick(device.id)}
                className={cn(
                  classes.device,
                  {
                    [classes.deviceNotSelected]: device.id !== currentDeviceId,
                    [classes.deviceIconSelected]: device.id === currentDeviceId,
                  }
                )}
              />
            )
          })
        }
      </>
    )
  }

  return (
    <AddPropsToChildren
      extraProps={extraProps}
      {...props}
    >
      {children}
    </AddPropsToChildren>
  )
}

export default DeviceRenderer;