import { Color } from "@material-ui/lab";
import { SnackbarAlert } from "app/components/ui/alert/SnackbarAlert";
import { createContext, FC, useCallback, useContext, useState } from "react";
import { SnackbarContainer } from "./snackbar-container";

export type SnackbarKey = string | number;
export type SnackbarMessage = string | React.ReactNode;
export interface Snack {
  key: SnackbarKey;
  title: string;
  message: string;
  severity: Color;
}
interface State {
  snacks: Snack[];
}
export interface ProviderContext {
  state: any;
  setState: any;
}

// @ts-ignore
export const SnackbarContext = createContext<ProviderContext>();

export const SnackbarProvider: FC = ({ children }: any) => {
  const [state, setState] = useState<State>({
    snacks: [],
  });

  const snackbars = (
    <SnackbarContainer>
      {state.snacks.map((snack) => (
        <SnackbarAlert
          key={snack.key}
          snackKey={snack.key}
          title={snack.title}
          message={snack.message}
          severity={snack.severity}
        />
      ))}
    </SnackbarContainer>
  );

  return (
    <SnackbarContext.Provider value={{ state, setState }}>
      {children}
      {snackbars}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const { setState } = useContext<any>(SnackbarContext);

  const openSnackbar = useCallback(
    (
      {
        title,
        message,
        severity,
      }: {
        message: Snack["message"];
        title: Snack["title"];
        severity: Snack["severity"];
      },
      key: Snack["key"] = new Date().getTime() + Math.random()
    ): SnackbarKey => {
      const snack: Snack = {
        key,
        title,
        message,
        severity,
      };

      setState(
        (state: State): State => ({
          ...state,
          snacks: [...state.snacks, snack],
        })
      );

      return key;
    },
    [setState]
  );

  const closeSnackbar = useCallback(
    (key: SnackbarKey): void => {
      setState(
        (state: State): State => ({
          ...state,
          snacks: state.snacks.filter((item) => item.key !== key),
        })
      );
    },
    [setState]
  );

  return {
    closeSnackbar,
    openSnackbar,
  };
};
