import { useSelector } from "react-redux";
import { FormControl, IconButton, MenuItem, Select, } from '@material-ui/core';
import cn from 'classnames';
import { CheckPermission } from "../../../requests/CheckPermission";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import { DeviceStatus } from "../../../models/enums/DeviceStatus.enum";
import { useShowModal } from "../../../app/shared/modal-context/modal.context";
import { ModalType } from "../../../app/shared/modal-context/constants";
import { useTypedDispatch } from "../../../app/state";
import { selectCurrentDevice } from "../../../store/deviceStore/deviceSelectors";
import { selectCurrentUser } from "../../../store/userStore/userSelectors";
import { approveDevice, rejectDevice } from "../../../store/deviceStore/deviceRequests";

import { ReactComponent as WorkflowIcon } from "./assets/workflow.svg";

import classes from './index.module.scss';
import useDevicesPage from "../devicePageHooks";

type DeviceWorkflowProps = {
  onUpdate: () => any,
  disabled: boolean,
}

const DeviceWorkflow = (props: DeviceWorkflowProps) => {
  const { showModal } = useShowModal();
  const { actions } = useDevicesPage();
  const dispatch = useTypedDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const currentDevice = useSelector(selectCurrentDevice);
  const configuration = currentDevice?.configuration;
  const { status, assigneeId } = configuration || { status: '', assigneeId: 0 };

  const disabled = props.disabled || !status || status === DeviceStatus.NEW || assigneeId !== currentUser.id;
  const approveDisabled = disabled || status !== DeviceStatus.REQUESTED;
  const rejectDisabled = approveDisabled;

  const onApproveHandler = () => {
    if (!currentDevice) {
      return;
    }
    showModal(ModalType.MODAL_CONFIRM_WITH_TEXT, {
      title: 'Approve Device request',
      text: `You are going to approve the device request. Do you want to proceed?`,
      label: 'Reason',
      confirmButtonHandler: (reason) => {
        dispatch(
          approveDevice({
            id: currentDevice.id,
            reason,
          })
        )
          .then((action) => {
            actions.handleWorkflowErrors(action, 'Approve failed');
            props.onUpdate()
          })
      },
      confirmButtonText: 'Approve',
    });
  }

  const onRejectHandler = () => {
    if (!currentDevice) {
      return;
    }
    showModal(ModalType.MODAL_CONFIRM_WITH_TEXT, {
      title: 'Reject Device request',
      text: `You are going to reject the device request. Do you want to proceed?`,
      label: 'Reason',
      confirmButtonHandler: (reason) => {
        dispatch(
          rejectDevice({
            id: currentDevice.id,
            reason,
          })
        )
          .then((action) => {
            actions.handleWorkflowErrors(action, 'Reject failed');
            props.onUpdate()
          })
      },
      confirmButtonText: 'Reject',
    });
  }

  const menu = (
    <FormControl>
      <IconButton
        disabled={disabled}
        aria-label='open workflow popup'
        color='secondary'
        size='small'
      >
        <WorkflowIcon
          className={cn({
            [classes.disabled]: disabled,
            [classes.icon]: !disabled,
          })}
        />
      </IconButton>
      <Select
        disabled={disabled}
        className={classes.select}
        value={''}
      >
        <MenuItem
          value={0}
          disabled={approveDisabled}
          onClick={onApproveHandler}
        >
          Approve
        </MenuItem>
        <MenuItem
          value={1}
          disabled={rejectDisabled}
          onClick={onRejectHandler}
        >
          Reject
        </MenuItem>
        {/*<MenuItem>Activate</MenuItem>*/}
        {/*<MenuItem>Retire</MenuItem>*/}
      </Select>
    </FormControl>
  );

  return (
    <CheckPermission permissionName={PERMISSIONS.DEVICE_WORKFLOW_UPDATE}>
      {menu}
    </CheckPermission>
  )
}

export default DeviceWorkflow;