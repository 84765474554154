import { Grid, TextField } from "@material-ui/core";
import { Dropdown } from "app/components/Dropdown";
import { BuildingModel } from "app/types";
import { FormikErrors } from "formik";

import styles from "./index.module.scss";

type BuildingFormPropsModel = {
  locations: any[];
  locationDisabled?: boolean;
  values: Partial<BuildingModel>;
  errors: FormikErrors<BuildingModel>;
  handleChange: (e: React.ChangeEvent<any>) => any;
  enableForm?: boolean;
};

export const Index = ({
  locations,
  locationDisabled,
  values,
  errors,
  handleChange,
  enableForm = true,
}: BuildingFormPropsModel) => {
  return (
    <Grid
      container
      direction='row'
      spacing={2}
      className={styles.boxRoot}
    >
      <Grid item xs={4}>
        <Dropdown
          label="Location"
          values={locations}
          disabled={enableForm || locationDisabled}
          selectedValue={values.locationId}
          valueChange={handleChange}
          error={errors.locationId}
          name="locationId"
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Title"
          variant="outlined"
          name="name"
          required
          value={values.name || ""}
          onChange={handleChange}
          helperText={errors.name}
          error={Boolean(errors.name)}
          disabled={enableForm}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Address"
          variant="outlined"
          name="address"
          value={values.address || ""}
          onChange={handleChange}
          disabled={enableForm}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
