import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { Dropdown } from "../../../app/components/Dropdown";
import { selectAllRegions, selectCurrentRegion } from "../../../store/regionStore/regionSelectors";
import useLocationRoute, { LocationProps } from "../locationRoute.hook";
import { useEffect } from "react";

const RegionFilter = () => {
  const { state, actions } = useLocationRoute()
  const { currentRegionId } = state;
  const { updateLocationUrl, updateSelectedValues } = actions;
  const regionList = useSelector(selectAllRegions) || [];
  const currentRegion = useSelector(selectCurrentRegion);

  useEffect(() => {
    if (currentRegionId && !currentRegion) {
      updateSelectedValues({ regionId: null, shouldReplace: true });
      updateLocationUrl({ regionId: null, shouldReplace: true });
    }
  }, [currentRegionId, currentRegion])

  const onChange = (e) => {
    const regionId = e.target.value || null;
    const paramsToChange: LocationProps = {
      regionId,
    };

    updateLocationUrl(paramsToChange);
  }

  return (
    <Grid item>
      <Dropdown
        useDefaultItem
        selectedValue={currentRegionId || 0}
        values={regionList}
        label='Region'
        name='region-filter'
        fullWidth
        valueChange={onChange}
      />
    </Grid>
  );
}

export default RegionFilter;