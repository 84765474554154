import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/state";
import { selectCurrentDevice } from "../../store/deviceStore/deviceSelectors";
import { loadDeviceHistory } from "../../store/deviceConfigurationStore/deviceConfigurationRequests";
import { clearDeviceHistory } from "store/deviceConfigurationStore/deviceConfigurationState";
import { selectHistoryLoadingState } from "../../store/deviceConfigurationStore/deviceConfigurationSelectors";
import Loading from "../../components/Loading/Loading";
import { status } from "../../app/shared";

const RequestDeviceHistory = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const currentDevice = useSelector(selectCurrentDevice);
  const currentDeviceId = currentDevice?.id;
  const loading = useSelector(selectHistoryLoadingState);

  useEffect(() => {
    if (currentDeviceId) {
      dispatch(loadDeviceHistory(currentDeviceId));
    } else {
      dispatch(clearDeviceHistory());
    }
  }, [currentDevice]);

  if (loading  === status.loading || loading === status.default) {
    return <Loading />;
  }

  return children;
}

export default RequestDeviceHistory;