import { useSelector } from "react-redux";
import { isTrialVersionExpired } from "../store/settingsStore/settingsSelectors";
import ConditionalRoute from "./ConditionalRoute";
import PAGES from "../pages";

const TrialRoute = ({ children, ...routeProps }) => {
  const isTrialExpired = useSelector(isTrialVersionExpired);

  return (
    <ConditionalRoute
      {...routeProps}
      condition={() => !isTrialExpired}
      redirectTo={PAGES.TRIAL_EXPIRED}
    >
      {children}
    </ConditionalRoute>
  )
}

export default TrialRoute;