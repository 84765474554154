import { useEffect } from "react";
import { useSelector } from "react-redux";
import Grid, { GridJustification } from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import {
  setFilterPanelVisibility,
  setInfluenceAreaEnabled,
  setSmartFilterDeviceId
} from "../../../store/filterStore/filterState";
import { useTypedDispatch } from "../../../app/state";
import { selectInfluenceAreaEnabled } from "../../../store/filterStore/filterSelectors";
import useFilter from "../../../hooks/filter.hook";
import Tooltip from "@material-ui/core/Tooltip";

import classes from './index.module.scss';

enum FILTER_CHIP_NAMES {
  'INFLUENCE_AREA',
  'MANUAL_FILTERS',
  'SMART_FILTERS'
}

type FilterChipProps = {
  justifyContent?: GridJustification,
  positionAbsolute?: boolean,
  chips?: FILTER_CHIP_NAMES[],
}

const FilterChipContainer = ({
  justifyContent = 'flex-start',
  positionAbsolute = true,
  chips =
    [FILTER_CHIP_NAMES.INFLUENCE_AREA, FILTER_CHIP_NAMES.MANUAL_FILTERS, FILTER_CHIP_NAMES.SMART_FILTERS]
}: FilterChipProps) => {
  const dispatch = useTypedDispatch();
  const { actions, state } = useFilter();
  const { isSmartFilterEnabled, smartFilterDevice, manualFiltersAreChosen, isFilterPanelVisible } = state;
  const influenceAreaEnabled = useSelector(selectInfluenceAreaEnabled);
  const smartFilterTitle = smartFilterDevice?.name || '';
  const chipTitle = !isSmartFilterEnabled
    ? 'Smart filter'
    : smartFilterTitle.length > 50
      ? smartFilterTitle.slice(0, 50) + '...'
      : smartFilterTitle;
  const wirelessInterfaces = smartFilterDevice?.configuration?.wirelessInterfaces || [];
  const hasNoChannels = wirelessInterfaces.every((wirelessInterface) => (wirelessInterface.channels || []).length === 0);

  // on first load if the selected device for smart filters in empty (no wireless interfaces or channels)
  useEffect(() => {
    if (wirelessInterfaces.length === 0 || hasNoChannels) {
      actions.updateSmartFilterDeviceId(null)
    }
  }, []);

  const onInfluenceFilterClick = () => {
    dispatch(setInfluenceAreaEnabled(!influenceAreaEnabled));
  }

  const onManualFilterClick = () => {
    dispatch(setFilterPanelVisibility(!isFilterPanelVisible));
  }

  const chipElements = [
    // influence area
    (
      <Chip
        label="Influence area"
        color={influenceAreaEnabled ? 'primary' : 'default'}
        onClick={onInfluenceFilterClick}
      />
    ),
    // manual filters
    (
      <Chip
        label="Manual filters"
        color={!!manualFiltersAreChosen ? 'primary' : 'default'}
        onClick={onManualFilterClick}
      />
    ),
    // smart filters
    (
      <Tooltip title={smartFilterTitle.length > 50 ? smartFilterTitle : ""}>
        <Chip
          label={chipTitle}
          color={isSmartFilterEnabled ? 'primary' : 'default'}
          disabled={!!manualFiltersAreChosen}
          onClick={actions.clearSmartFilter}
        />
      </Tooltip>
    )
  ]

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent={justifyContent}
      className={positionAbsolute ? classes.absoluteFilters : ''}
    >
      {
        chips.map((chipIndex, index) => <Grid item key={index}>{chipElements[chipIndex]}</Grid>)
      }
    </Grid>
  );
}

FilterChipContainer.FILTER_CHIP_NAMES = FILTER_CHIP_NAMES;

export default FilterChipContainer;
