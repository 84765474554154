import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../app/api";
import { serializeError } from "../../app/shared";
import { SettingsModel } from "./SettingsModel";

export const getGlobalSettings = createAsyncThunk(
  'globalSettings/get-settings',
  async (_, { rejectWithValue }) => {
    try {
      const response: { settings: SettingsModel } = await request.get('/settings');
      return response.settings;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const editGlobalSettings = createAsyncThunk(
  'globalSettings/edit-settings',
  async (data: SettingsModel, { rejectWithValue }) => {
    try {
      const response: { settings: SettingsModel } = await request.put('/settings', data);
      return response.settings;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);