import { Fragment } from "react";
import { ListItem } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { groupBy, entries, map, flow } from "lodash/fp";
import { useCallback, useMemo } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    letter: {
      paddingLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTypography-root": {
        fontSize: "14px",
      },
    },
  })
);

type AlphabeticListItem = {
  id: number;
  name: string;
};

type AlphabeticListProps = {
  items: AlphabeticListItem[];
  selectItem: (id: number) => void;
  selectedItem?: number | null;
};

export const AlphabeticList = ({
  items,
  selectedItem,
  selectItem,
}: AlphabeticListProps) => {
  const classes = useStyles();
  const onSelectLocation = useCallback(
    (id: number) => {
      if (selectedItem !== id) {
        selectItem(id);
      }
    },
    [selectItem, selectedItem]
  );

  const mappedItems = useMemo(
    () => groupBy(({ name }) => name.charAt(0), items),
    [items]
  );

  return (
    <List component="nav" className={classes.list}>
      {flow(
        entries,
        map(([letter, items]: [string, AlphabeticListItem[]]) => (
          <Fragment key={items[0].id}>
            {letter && (
              <ListItemText className={classes.letter} key={letter}>
                <Typography key={`${letter}-item`} variant="subtitle2">
                  {letter}
                </Typography>
              </ListItemText>
            )}
            {items.map((item: AlphabeticListItem) => (
              <ListItem
                button
                key={item.id}
                selected={selectedItem === item.id}
                onClick={() => onSelectLocation(item.id)}
              >
                <ListItemText key={`${item.id}-itemText`} primary={item.name} />
              </ListItem>
            ))}
          </Fragment>
        ))
      )(mappedItems)}
    </List>
  );
};
