import { RootState } from "../../app/state";
import { deviceConfigurationAdapter } from "./deviceConfigurationState";
import { createSelector } from "@reduxjs/toolkit";
import { get } from "lodash/fp";

const selectRoot = (state: RootState) => state;
export const selectDeviceConfigurationSlice = (state: RootState) => state.deviceConfigurationList;

const deviceConfigurationAdapterSelectors = deviceConfigurationAdapter.getSelectors(selectDeviceConfigurationSlice);

export const selectAllConfigurations = deviceConfigurationAdapterSelectors.selectAll;
export const selectConfigurationById = deviceConfigurationAdapterSelectors.selectById;

export const selectCurrentConfigurationId = createSelector(
  selectDeviceConfigurationSlice,
  get('selectedConfigurationId')
);

export const selectCurrentConfiguration = createSelector(
  selectRoot,
  selectCurrentConfigurationId,
  (root, configurationId) => (configurationId && selectConfigurationById(root, configurationId)) || null
);

export const selectHistoryLoadingState = createSelector(
  selectDeviceConfigurationSlice,
  get('loading')
);