import HorizontalPanel from "../../../components/HorizontalPanel";
import BuildingForm from "../BuildingForm";
import useLocationRoute from "../locationRoute.hook";
import { Toolbar } from "../../../app/components";
import LocationFloorList from "../LocationFloorList";
import WirelessSystemList from "../../Shared/WirelessSystemList";

const LocationRightPanel = () => {
  const { state } = useLocationRoute();
  const { currentLocationId } = state;
  const isLocationEditMode = currentLocationId === -1;

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        {
          !isLocationEditMode ? (
            <BuildingForm />
          ) : (
            <Toolbar title='Building details' />
          )
        }
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <LocationFloorList />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <WirelessSystemList />
      </HorizontalPanel>
    </HorizontalPanel>
  );
}

export default LocationRightPanel;