import React from "react";
import {
  IconButton,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AddCircleOutline } from "@material-ui/icons";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { selectAllRegions, selectCurrentRegionId } from "../../../store/regionStore/regionSelectors";
import {
  selectAllCountries,
  selectCurrentCountryId
} from "../../../store/countryStore/countrySelectors";
import MenuList from "../../../components/MenuList";
import PageUpdater from "../../PageUpdater";
import { setCurrentRegionId } from "../../../store/regionStore/regionState";
import { setCurrentCountryId } from "../../../store/countryStore/countryState";
import { pagePathGenerator } from "../../../routes/pagePathGenerator";

import classes from './index.module.scss';

export const LocationConfiguration = () => {
  const history = useHistory();
  const regionList = useSelector(selectAllRegions);
  const currentRegionId = useSelector(selectCurrentRegionId);
  const countryList = useSelector(selectAllCountries);
  const currentCountryId = useSelector(selectCurrentCountryId);
  const isRegionSelected = currentRegionId;
  const isRegionCreateMode = currentRegionId === -1;

  const filteredCountryList = countryList.filter((country) => {
    if (!isRegionSelected) {
      return country;
    }

    return country.regionId === currentRegionId;
  })

  const onSelectRegion = (regionId: number) => {
    const url = pagePathGenerator.REGIONS_AND_COUNTRY({
      regionId: currentRegionId === regionId ? null : regionId,
      countryId: null,
    });

    history.replace(url);
  }

  const onCreateRegion = () => {
    onSelectRegion(-1);
  }

  const onSelectCountry = (countryId: number) => {
    const clickedCountry = countryList.find((country) => country.id === countryId);
    const url = pagePathGenerator.REGIONS_AND_COUNTRY({
      regionId: clickedCountry?.regionId || currentRegionId,
      countryId: countryId,
    });

    history.replace(url);
  };

  const onCreateCountry = () => {
    onSelectCountry(-1);
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE} className={classes.locationWholeContainer}>
      <PageUpdater watchItemList={[
        {
          title: 'regionId',
          action: setCurrentRegionId,
        },
        {
          title: 'countryId',
          action: setCurrentCountryId,
        },
      ]} />
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL} className={classes.regionPanel} >
        <Toolbar title="Region">
          <IconButton
            aria-label="add region"
            onClick={onCreateRegion}
            color="secondary"
            size="small"
          >
            <AddCircleOutline />
          </IconButton>
        </Toolbar>
        <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} addScroll>
          <MenuList
            selectItem={onSelectRegion}
            items={regionList}
            selectedItemId={currentRegionId}
          />
        </HorizontalPanel>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL} className={classes.countryPanel}>
        <Toolbar title="Country">
          <IconButton
            aria-label="add country"
            disabled={!isRegionSelected || isRegionCreateMode}
            onClick={onCreateCountry}
            color="secondary"
            size="small"
          >
            <AddCircleOutline />
          </IconButton>
        </Toolbar>
        <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} addScroll>
          <MenuList
            selectItem={onSelectCountry}
            items={filteredCountryList}
            selectedItemId={currentCountryId}
          />
        </HorizontalPanel>
      </HorizontalPanel>
    </HorizontalPanel>
  );
};
