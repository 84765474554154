import { ReactElement } from "react";
import cn from 'classnames';
import { Paper, List, ListItem, ListItemIcon, ListItemText, } from "@material-ui/core";

import styles from './index.module.scss';
import Tooltip from "@material-ui/core/Tooltip";

export type MenuListItem = {
  id: number;
  name?: string;
  secondary?: string;
  icon?: ReactElement;
}

export type MenuListProps = {
  items?: MenuListItem[];
  size?: 'small' | 'big'
  selectedItemId?: number | null;
  selectItem: (id: number) => void;
  classes?: {
    paper?: string,
    menuItem?: string,
  }
}

const MenuList = (props: MenuListProps) => {
  const { items = [], selectedItemId, selectItem = () => {}, classes, size = 'small' } = props;

  return (
    <Paper elevation={0} className={cn(styles.paper, classes?.paper)}>
      <List component='nav' className={styles.menuList}>
        {
          items.map((menuItem) => (
              <ListItem
                className={cn(
                  styles.menuItem,
                  classes?.menuItem,
                  {
                    [styles.bigMenuItem]: size === 'big',
                    [styles.smallMenuItem]: size === 'small',
                  },
                )}
                key={menuItem.id}
                button
                selected={selectedItemId === menuItem.id}
                onClick={() => {
                  selectItem(menuItem.id);
                }}
              >
                {
                  menuItem.icon && (
                    <ListItemIcon>
                      {
                        menuItem.icon
                      }
                    </ListItemIcon>
                  )
                }
                <Tooltip title={(menuItem.name || '') + ' ' + (menuItem.secondary || '')} enterDelay={1000}>
                  <ListItemText
                    primaryTypographyProps={{
                      className: styles.menuItemText,
                    }}
                    secondaryTypographyProps={{
                      className: styles.menuItemText,
                    }}
                    primary={menuItem.name}
                    secondary={menuItem.secondary}
                    classes={
                      {
                        root: styles.menuItemRoot,
                      }
                    }
                  />
                </Tooltip>
              </ListItem>
            )
          )
        }
      </List>
    </Paper>
  )
}

export default MenuList;