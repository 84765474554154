import { useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import { selectCurrentLocation } from "../../../store/locationStore/locationSelectors";
import { selectCurrentBuilding } from "../../../store/buildingStore/buildingsSelectors";
import { selectCurrentFloor } from "../../../store/floorStore/floorSelectors";

import classes from './index.module.scss';
import { selectCurrentCountry } from "../../../store/countryStore/countrySelectors";

interface MapBreadcrumbsProps {
  country?: boolean,
  location?: boolean,
  building?: boolean,
  floor?: boolean,
}

const MapBreadcrumbs = ({ country = false, location = true, building = false, floor = false }: MapBreadcrumbsProps) => {
  const currentCountry = useSelector(selectCurrentCountry);
  const currentLocation = useSelector(selectCurrentLocation);
  const currentBuilding = useSelector(selectCurrentBuilding);
  const currentFloor = useSelector(selectCurrentFloor);

  let lineArray: string[] = [];

  if (country && currentCountry) {
    lineArray.push(currentCountry.name);
  }

  if (location && currentLocation) {
    lineArray.push(currentLocation.name);
  }

  if (building && currentBuilding) {
    lineArray.push(currentBuilding.name);
  }

  if (floor && currentFloor) {
    lineArray.push(currentFloor.name);
  }
  const line = lineArray.join(' / ');

  return line ? (
    <>
      <Tooltip title={line}>
        <div className={classes.map_breadcrumbs}>
          {line}
        </div>
      </Tooltip>
    </>
  ) : null;

}

export default MapBreadcrumbs;