import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash/fp";
import { Grid, IconButton, TextField } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Delete from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import { useTypedDispatch } from "../../../app/state";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { selectAllRegions } from "../../../store/regionStore/regionSelectors";
import { selectAllCountries } from "../../../store/countryStore/countrySelectors";
import { selectCurrentLocation } from "../../../store/locationStore/locationSelectors";
import { Dropdown } from "../../../app/components/Dropdown";
import { CheckPermission } from "../../../requests/CheckPermission";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import useLocationRoute from "../locationRoute.hook";
import { createLocation, deleteLocation, updateLocation } from "../../../store/locationStore/locationRequests";
import { useShowModal } from "../../../app/shared/modal-context/modal.context";
import { useSnackbar } from "../../../app/shared/snackbar-context/snackbar.context";
import { selectBuildingsByLocationId } from "../../../store/buildingStore/buildingsSelectors";
import { ModalType } from "../../../app/shared/modal-context/constants";
import { LocationModel } from "../../../app/types";

import classes from './index.module.scss';

const validationSchema = yup.object({
  regionId: yup.number(),
  countryId: yup
    .number()
    .test(
      'Is positive?',
      'Country is required',
      (value = 0) => value > 0
    ),
  name: yup
    .string()
    .max(50, 'Location name should not be longer then 50 symbols')
    .required('Location name is required'),
});

const LocationEditForm = () => {
  const dispatch = useTypedDispatch();
  const { showModal } = useShowModal();
  const { openSnackbar } = useSnackbar();
  const { state, actions } = useLocationRoute();
  const { currentCountryId, currentRegionId } = state;
  const regionList = useSelector(selectAllRegions) || [];
  const countryList = useSelector(selectAllCountries);
  const { currentLocationId } = state;
  const currentLocation = useSelector(selectCurrentLocation) || {
    name: '',
    countryId: 0,
    regionId: 0,
  };
  const selectedBuildings = useSelector(selectBuildingsByLocationId(currentLocationId));
  const isCreateMode = currentLocationId === -1;

  const locationForm = useFormik({
    initialValues: {
      name: currentLocation.name,
      countryId: currentLocation.countryId,
      regionId: currentLocation.regionId,
    },
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleCountryChange = (e) => {
    const countryId = parseInt(e.target.value);
    const country = countryList.find((country) => country.id === countryId);
    const regionId = country?.regionId || 0;

    locationForm.setFieldValue('regionId', regionId);
    locationForm.setFieldValue('countryId', countryId);
  };

  const onSave = async () => {
    const valid = await locationForm.validateForm();
    if (!isEmpty(valid)) {
      return;
    }
    if (isCreateMode) {
      dispatch(createLocation(locationForm.values))
        .then((action) => {
          const location = action.payload as LocationModel;
          const props = {
            locationId: location.id,
            countryId: currentCountryId ? location.countryId : null,
            regionId: currentRegionId ? location.regionId : null,
            shouldReplace: true,
          };

          actions.updateLocationUrl(props);
        })
    } else {
      dispatch(updateLocation({
        id: currentLocationId || undefined,
        ...locationForm.values
      }));
    }
  }

  const onDelete = () => {
    if (selectedBuildings.length > 0) {
      openSnackbar({
        title: "Cannot delete location",
        message: "This location contains buildings. Remove buildings first",
        severity: "error",
      });

      return;
    }
    if (currentLocationId) {
      showModal(ModalType.MODAL_GENERIC_CONFIRM, {
        title: "Delete Location",
        text: "You want to delete this location. Are you sure?",
        confirmButtonHandler: () => {
          dispatch(deleteLocation(currentLocationId))
            .then((action) => {
              if ((action as { error: any }).error) {
                openSnackbar({
                  title: 'Cannot delete location',
                  message: 'This location is already in use. It has devices',
                  severity: 'error',
                });
              } else {
                actions.updateLocationUrl({ locationId: null, buildingId: null, shouldReplace: true });
              }
            })
        },
        confirmButtonText: "Delete",
      });
    }
  }

  const onCancel = () => {
    locationForm.resetForm(locationForm.initialValues as any);
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={isCreateMode ? 'Create a location' : 'Location details'}>
          <CheckPermission permissionName={PERMISSIONS.LOCATION_ACTIONS} noPermissionComponent={null}>
            <IconButton
              disabled={!locationForm.dirty && !isCreateMode}
              aria-label='save location changes'
              color='secondary'
              size='small'
              onClick={onSave}
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              disabled={isCreateMode}
              aria-label='Delete location'
              color='secondary'
              size='small'
              onClick={onDelete}
            >
              <Delete />
            </IconButton>
            <IconButton
              disabled={!locationForm.dirty}
              aria-label='cancel location changes'
              color='secondary'
              size='small'
              onClick={onCancel}
            >
              <CancelIcon />
            </IconButton>
          </CheckPermission>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <Grid
          container
          direction='row'
          spacing={2}
          className={classes.form}
        >
          <Grid item xs={4}>
            <Dropdown
              label='Region'
              values={regionList}
              disabled
              selectedValue={locationForm.values.regionId === 0 ? '' : locationForm.values.regionId}
              fullWidth
              name='regionId'
              required
            />
          </Grid>
          <Grid item xs={4}>
            <Dropdown
              label='Country'
              values={countryList}
              selectedValue={locationForm.values.countryId === 0 ? '' : locationForm.values.countryId}
              valueChange={handleCountryChange}
              name='countryId'
              fullWidth
              error={locationForm.errors.countryId as string}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label='Name'
              variant='outlined'
              name='name'
              fullWidth
              required
              value={locationForm.values.name}
              onChange={locationForm.handleChange}
              helperText={locationForm.errors.name}
              error={Boolean(locationForm.errors.name)}
            />
          </Grid>
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default LocationEditForm;