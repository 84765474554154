import { createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import DeviceConfigurationModel from "../../models/DeviceConfigurationModel";
import { loadDeviceHistory } from "./deviceConfigurationRequests";
import { LoadingStatus, status } from "../../app/shared";

export const deviceConfigurationAdapter = createEntityAdapter<DeviceConfigurationModel>({
  selectId: (configuration) => configuration.id,
  sortComparer: (a, b) => (b.id - a.id),
});

type DeviceHistoryState = {
  selectedConfigurationId: number | null,
  loading: LoadingStatus,
} & EntityState<DeviceConfigurationModel>

const initialState: DeviceHistoryState = {
  selectedConfigurationId: null,
  loading: status.default,
  ...deviceConfigurationAdapter.getInitialState(),
}

export const deviceConfigurationSlice = createSlice({
  name: 'deviceConfigurationList',
  initialState,
  reducers: {
    setCurrentConfigurationId: (state, action) => {
      state.selectedConfigurationId = action.payload;
    },
    clearDeviceHistory: (state) => {
      deviceConfigurationAdapter.removeAll(state);
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(loadDeviceHistory.pending, (state) => {
        state.loading = status.loading;
      })
      .addCase(loadDeviceHistory.rejected, (state, action) => {
        state.loading = status.error(action.error);
      })
      .addCase(loadDeviceHistory.fulfilled, (state, action) => {
        state.loading = status.loaded;
        const info = action.payload;
        const history = info?.history || [];
        deviceConfigurationAdapter.setAll(state, history);
      })
});

export const { setCurrentConfigurationId, clearDeviceHistory } = deviceConfigurationSlice.actions;