import PAGES, { createURLPath } from "../pages";

export const pagePathGenerator = {
  HOME: () => PAGES.HOME,
  LOGIN_PAGE: () => PAGES.LOGIN_PAGE,
  USER_CREATE_PASSWORD_PAGE: () => PAGES.USER_CREATE_PASSWORD_PAGE,
  SETTINGS_PAGE: () => PAGES.SETTINGS_PAGE,
  REGIONS_AND_COUNTRY: (props: { regionId?: number | null, countryId?: number | null }) => {
    const { regionId, countryId } = props;
    return createURLPath({
      path: PAGES.REGIONS_AND_COUNTRY,
      queryObject: {
        regionId: regionId ? regionId : null,
        countryId: countryId ? countryId : null,
      }
    })
  },
  USER_MANAGEMENT: (filter?: 'all' | 'new' | 'activation' | 'active' | 'inactive') => {
    const path = {
      all: PAGES.USER_MANAGEMENT_ALL,
      'new': PAGES.USER_MANAGEMENT_NEW,
      activation: PAGES.USER_MANAGEMENT_ACTIVATION,
      active: PAGES.USER_MANAGEMENT_ACTIVE,
      inactive: PAGES.USER_MANAGEMENT_INACTIVE,
    }

    return filter ? path[filter] : PAGES.USER_MANAGEMENT;
  },
  WORKFLOW_MANAGEMENT: () => PAGES.WORKFLOW_MANAGEMENT,
  IMPORT: () => PAGES.IMPORT,
  GLOBAL_SETTINGS: () => PAGES.GLOBAL_SETTINGS,
  TECHNOLOGIES: () => PAGES.TECHNOLOGIES,
  REQUESTS: () => PAGES.REQUESTS,
  BUILDING_PAGE: (props: { locationId?: number | null, buildingId?: number | null, floorId?: number | null }) => createURLPath({
    path: PAGES.BUILDINGS_PAGE,
    queryObject: props,
  }),
  LOCATION_PAGE: (props: { locationId?: number | null, buildingId?: number | null, regionId?: number | null, countryId?: number | null }) => createURLPath({
    path: PAGES.LOCATIONS_PAGE,
    queryObject: props,
  }),
  FLOOR_MAP: (props: { floorId?: number | null }) => createURLPath({
    path: PAGES.UPLOAD_MAP,
    queryObject: props,
  }),
  MAP_PAGE: (props: { floorId?: number | null, locationId?: number | null }) => createURLPath({
    path: PAGES.MAP_PAGE,
    queryObject: props,
  }),
  FREQUENCY_ANALYSIS: (props: { mapId?: number | null }) => createURLPath({
    path: PAGES.FREQUENCY_ANALYSIS,
    queryObject: props,
  }),
  DEVICES_MAP: (props: { mapId?: number | null; deviceId?: number | null }) => createURLPath({
    path: PAGES.DEVICES_MAP,
    queryObject: props,
  }),
  SINGLE_DEVICE_PAGE: (props: { mapId?: number | null, deviceId?: number | null }) => createURLPath({
    path: PAGES.SINGLE_DEVICE_PAGE,
    queryObject: props,
  }),
}
