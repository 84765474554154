import ChildrenElement from "../../models/HelperModels/ChildrenElement";
import HorizontalPanel from "../HorizontalPanel";

interface TabPanelProps {
  active: boolean,
  children?: ChildrenElement,
}

const TabPanel = ({ active, children }: TabPanelProps) => (
  active ? (
    <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
      {
        children
      }
    </HorizontalPanel>
  ) : null
);

export default TabPanel;