import { useSelector } from "react-redux";
import {
  selectCurrentWirelessInterface
} from "../../../store/deviceStore/deviceSelectors";
import HorizontalPanel from "../../../components/HorizontalPanel";
import WirelessInterfaceList from "../WirelessInterfaceList";
import WirelessInterfaceForm from "../WirelessInterfaceForm";
import {
  requestAllTechnologyConfigurations
} from "../../../store/technologyConfigurationStore/technologyConfigurationRequests";
import { GeneralRequest } from "../../../requests/GeneralRequest";
import { selectCurrentBuildingId } from "../../../store/buildingStore/buildingsSelectors";

interface WirelessFormAggregatorProps {
  formDisabled?: boolean;
  onUpdate: () => any;
}

const WirelessFormAggregator = (props: WirelessFormAggregatorProps) => {
  const currentBuildingId = useSelector(selectCurrentBuildingId);
  const currentWirelessInterface = useSelector(selectCurrentWirelessInterface);

  return (
    <GeneralRequest
      requestAction={requestAllTechnologyConfigurations}
      requestArguments={[{ buildingId: currentBuildingId }]}
      loadingElement={null}
    >
      <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
        <WirelessInterfaceList
          {...props}
        />
        {
          !!currentWirelessInterface && (
            <>
              <WirelessInterfaceForm
                {...props}
              />
            </>
          )
        }
      </HorizontalPanel>
    </GeneralRequest>
  )
}

export default WirelessFormAggregator;