import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, IconButton } from "@material-ui/core";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import TransitEnterexitIcon from "@material-ui/icons/TransitEnterexit";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { Delete } from "@material-ui/icons";
import { useTypedDispatch } from "../../../app/state";
import { selectCurrentLocation } from "../../../store/locationStore/locationSelectors";
import { selectCurrentlyEditPoint } from "../../../store/mapPointsStore/mapPointSelectors";
import { getMapUrl } from "../../../helpers/mapHelpers";
import { useShowModal } from "../../../app/shared/modal-context/modal.context";
import { clearMapPointState } from "../../../store/mapPointsStore/mapPointsState";
import { ModalType } from "../../../app/shared/modal-context/constants";
import { Toolbar } from "../../../app/components";
import { CheckPermission } from "../../../requests/CheckPermission";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import { FileUploader } from "../../../app/components/ui/file-upload-button/FileUploadButton";
import BackButton from "../../../components/BackButton";
import { useSnackbar } from "../../../app/shared/snackbar-context/snackbar.context";
import MapScaleContainer from "../../Map/MapScaleContainer";
import { deleteLocationMap, uploadLocationMapFile } from "../../../store/mapStore/mapRequests";

export const LocationMapCentralPanel = () => {
  const dispatch = useTypedDispatch();
  const { showModal } = useShowModal();
  const { openSnackbar } = useSnackbar();
  const currentLocation = useSelector(selectCurrentLocation);
  const fileUploadInput = React.useRef<HTMLInputElement>(null);
  const isMapPointEdit = typeof useSelector(selectCurrentlyEditPoint) !== 'undefined';
  const mapFileName = currentLocation?.map?.url;
  const mapUrl = mapFileName ? getMapUrl(mapFileName) : '';
  const isMapUploaded = !!mapUrl;

  useEffect(() => {
    dispatch(clearMapPointState());
  }, []);

  const onContinueReplaceMap = () => {
    fileUploadInput?.current?.click();
  };

  const handleClick = () => {
    if (isMapUploaded) {
      showModal(ModalType.MODAL_GENERIC_CONFIRM, {
        title: 'Replace map',
        text: 'Are you sure you want to replace current map file?',
        confirmButtonHandler: onContinueReplaceMap,
        confirmButtonText: 'Replace',
      });

      return true;
    }
  };

  const handleUpload = (file: File) => {
    if (!currentLocation) {
      return;
    }

    dispatch(uploadLocationMapFile({
        locationId: currentLocation.id,
        file,
      })
    )
  }

  const onContinueDeleteFloorMap = () => {
    if (!currentLocation) {
      return;
    }

    const locationId = currentLocation.id;

    dispatch(deleteLocationMap({ locationId }))
      .then((action) => {
        // @ts-ignore
        if (action?.error) {
          openSnackbar({
            title: "Cannot delete location map",
            message: "This location has devices",
            severity: "error",
          });
        } else {
          dispatch(clearMapPointState());
        }
      });
  }

  const onDeleteMap = () => {
    showModal(ModalType.MODAL_GENERIC_CONFIRM, {
      title: "Delete location map",
      text: "Do you really want to delete this location map?",
      confirmButtonHandler: onContinueDeleteFloorMap,
      confirmButtonText: "Delete",
    });
  }

  return (
    <Grid container item xs direction='column'>
      <Toolbar title={'Location map'}>
        <>
          <CheckPermission permissionName={PERMISSIONS.LOCATION_ACTIONS} noPermissionComponent={null}>
            <>
              <FileUploader
                handleClick={handleClick}
                handleFile={handleUpload}
                accept='.svg,.jpg,.png,.jpeg'
                label={isMapUploaded ? 'Replace map' : 'Upload map'}
                icon={isMapUploaded ? <AddToPhotosIcon /> : <AddPhotoAlternateIcon />}
                inputRef={fileUploadInput}
              />
              <IconButton
                disabled={!isMapUploaded || isMapPointEdit}
                aria-label='remove map'
                color='secondary'
                size='small'
                onClick={onDeleteMap}
              >
                <Delete />
              </IconButton>
            </>
          </CheckPermission>
          <BackButton
            disabled={isMapPointEdit}
            aria-label={'close window'}
            // route={backPath}
            backButtonIcon={<TransitEnterexitIcon />}
          />
        </>
      </Toolbar>
      <MapScaleContainer map={currentLocation?.map} />
    </Grid>
  );
}