import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { useDimensions } from "../../../hooks/dimensions.hook";
import { selectCurrentlyEditPoint } from "../../../store/mapPointsStore/mapPointSelectors";
import { getMapUrl } from "../../../helpers/mapHelpers";
import NoMapContainer from "../NoMapContainer";
import PointsRenderer from "../PointsRenderer";
import ZoomMap from "../../../components/Map/ZoomMap";
import MapModel from "../../../models/MapModel";

import classes from './index.module.scss';

interface MapScaleProps {
  map?: MapModel,
}

const MapScaleContainer = ({ map }: MapScaleProps) => {
  const container = useDimensions();
  const isMapPointEdit = typeof useSelector(selectCurrentlyEditPoint) !== 'undefined';

  const mapFileName = map?.url
  const mapUrl = mapFileName ? getMapUrl(mapFileName) : '';
  const isMapUploaded = !!mapUrl;

  return (
    <Grid
      container
      item
      xs
      className={classes.gridContainer}
      direction='column'
      innerRef={container.elementRef}
    >
      {
        !isMapUploaded && (
          <NoMapContainer title='No map has been uploaded' />
        )
      }
      {
        isMapUploaded && (
          <PointsRenderer
            mapModel={map}
          >
            <ZoomMap
              dimensions={container.dimensions}
              mapUrl={mapUrl}
              cursor={isMapPointEdit ? 'crosshair' : 'default'}
            />
          </PointsRenderer>
        )
      }
    </Grid>
  )
}

export default MapScaleContainer;