import { RootState } from "../../app/state";
import { countryAdapter } from "./countryState";
import { createSelector } from "@reduxjs/toolkit";

const selectRoot = (state: RootState) => state;
export const selectCountrySlice = (state: RootState) => state.countryList;

const countryAdapterSelectors = countryAdapter.getSelectors(selectCountrySlice);

export const selectAllCountries = countryAdapterSelectors.selectAll;

export const selectCountryById = countryAdapterSelectors.selectById;

export const selectAllCountriesSorted = createSelector(
  selectAllCountries,
  (countryList) => countryList.sort((a, b) => a.name.localeCompare(b.name))
);

export const selectCurrentCountryId = createSelector(
  selectCountrySlice,
  (state) => state.selectedCountryId
);

export const selectCurrentCountry = createSelector(
  selectRoot,
  selectCurrentCountryId,
  (state, countryId) => (countryId && selectCountryById(state, countryId)) || null
);

export const selectCountriesByRegionId = (regionId?: number | null) => createSelector(
  selectAllCountries,
  (countryList) => regionId ? countryList.filter((country) => country.regionId === regionId) : countryList
)

export const selectAllCountryLoading = createSelector(
  selectCountrySlice,
  (state) => state.loadAllStatus,
)