import { LoadingStatus, status } from '../../app/shared';
import { createSlice, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { getAllUserList, getAllUserRoles, requestWorkflowList, requestRequestersList } from './userListRequests';
import { UserModel } from '../../models/UserModel';
import { UserRoleModel } from '../../models/UserRoleModel';
import { requestManagersList } from "./userListRequests";

export const userAdapter = createEntityAdapter<UserModel>({
  selectId: (user) => user.id
});

type UserListState = {
  userList: EntityState<UserModel>,
  rolesList: UserRoleModel[],
  managersList: UserModel[],
  requestersList: UserModel[],
  workflowList: UserModel[],
  userListStatus: LoadingStatus,
  requesterListStatus: LoadingStatus,
  rolesStatus: LoadingStatus,
  managersStatus: LoadingStatus,
  workflowListStatus: LoadingStatus,
}

const initialState: UserListState = {
  userList: userAdapter.getInitialState(),
  rolesList: [],
  managersList: [],
  requestersList: [],
  workflowList: [],
  userListStatus: status.default,
  requesterListStatus: status.default,
  rolesStatus: status.default,
  managersStatus: status.default,
  workflowListStatus: status.default,
};

export const userListSlice = createSlice({
    name: 'userList',
    initialState,
    reducers: {
      updateSingleUser: (state, action) => {
        userAdapter.upsertOne(state.userList, action.payload);
      },
      resetUserList: (state: UserListState) => Object.assign(state, initialState),
    },
    extraReducers: (builder) =>
      builder
        .addCase(getAllUserList.pending, (state) => {
          state.userListStatus = status.loading;
        })
        .addCase(getAllUserList.rejected, (state, action) => {
          state.userListStatus = status.error(action.error as Error);
        })
        .addCase(getAllUserList.fulfilled, (state, action) => {
          state.userListStatus = status.loaded;
          userAdapter.setAll(state.userList, action?.payload?.users)
        })
        .addCase(requestManagersList.pending, (state) => {
          state.managersStatus = status.loading;
        })
        .addCase(requestManagersList.fulfilled, (state, action) => {
          state.managersStatus = status.loaded;
          state.managersList = action?.payload?.users;
        })
        .addCase(getAllUserRoles.pending, (state) => {
          state.rolesStatus = status.loading;
        })
        .addCase(getAllUserRoles.rejected, (state, action) => {
          state.rolesStatus = status.error(action.error as Error);
        })
        .addCase(getAllUserRoles.fulfilled, (state, action) => {
          state.rolesStatus = status.loaded;
          state.rolesList = action?.payload?.roles;
        })
        .addCase(requestRequestersList.pending, (state) => {
          state.requesterListStatus = status.loading;
        })
        .addCase(requestRequestersList.rejected, (state, action) => {
          state.requesterListStatus = status.error(action.error);
        })
        .addCase(requestRequestersList.fulfilled, (state, action) => {
          state.requesterListStatus = status.loaded;
          state.requestersList = action?.payload?.requesters;
        })
        .addCase(requestWorkflowList.pending, (state) => {
          state.workflowListStatus = status.loading;
          state.workflowList = [];
        })
        .addCase(requestWorkflowList.rejected, (state, action) => {
          state.workflowListStatus = status.error(action.error as Error);
        })
        .addCase(requestWorkflowList.fulfilled, (state, action) => {
          state.workflowListStatus = status.loaded;
          state.workflowList = action?.payload?.users;
        })
  }
);

export const { resetUserList, updateSingleUser } = userListSlice.actions;