import * as yup from 'yup';
import { convertToMilliWatt } from "../../../../helpers/converters/power";

export const channelsValidations = yup.object().shape({
  channelNumber: yup.string().required('Channel number is required').max(100),
  EIRP: yup.string().required().test(
    'isCorrect',
    'Invalid value',
    (value) => {
      const valueInMilliW = convertToMilliWatt(value);

      return valueInMilliW > 0;
    }),
  downlinkCF: yup.string().max(15).required().matches(/^\d*\.?\d+ +(MHz|GHz|kHz)$/, 'Invalid value'),
});
