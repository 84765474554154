import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

interface BreadcrumbsItem {
  link?: string;
  title: string;
  tooltip?: string;
}

interface StyledBreadcrumbsProps {
  items?: BreadcrumbsItem[];
}

const StyledBreadcrumbs = (props: StyledBreadcrumbsProps) => {
  const history = useHistory();
  const { items = [] } = props;

  const onClick = (e, link) => {
    e.preventDefault();
    history.push(link);
  }

  return (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      {
        items.map((breadcrumb, index) => (
            breadcrumb.link ? (
              <Tooltip title={breadcrumb.tooltip || breadcrumb.title} key={index}>
                <Link
                  href={breadcrumb.link}
                  color="inherit"
                  onClick={(e) => onClick(e, breadcrumb.link)}
                >
                  {breadcrumb.title}
                </Link>
              </Tooltip>
            ) : (
              <Typography color="textPrimary" key={index}>{breadcrumb.title}</Typography>
            )
          )
        )
      }
    </Breadcrumbs>
  )
}

export default StyledBreadcrumbs;