import { createEntityAdapter, createSlice, EntityState, isAnyOf } from "@reduxjs/toolkit";
import { LoadingStatus, status } from "../../app/shared";
import {
  addWirelessInterfaceToDevice,
  approveDevice,
  assignDevice,
  createNewDevice,
  deleteDevice, deleteDevicePoint,
  deleteWirelessInterface,
  doSearch,
  rejectDevice,
  requestAllDevicesByQuery,
  requestDeviceById,
  requestMyRequests,
  submitDevice,
  updateDevice,
  updateWirelessInterface,
  upsertDevicePoint,
} from "./deviceRequests";
import DeviceModel from "../../models/DeviceModel";

export const deviceAdapter = createEntityAdapter<DeviceModel>({
  selectId: (device) => device.id,
  sortComparer: (a, b) => (a.name || '').localeCompare(b.name || ''),
});

type DeviceState = {
  selectedDeviceId: number | null,
  // todo: extract if needed
  selectedWirelessInterfaceId: number | null,
  searchDeviceIdList: number[],
  myRequestsDeviceIdList: number[],
  myWorkflowDeviceIdList: number[],
  loadAllStatus: LoadingStatus,
  // todo: divide into separate loadings
  otherLoadings: LoadingStatus,
} & EntityState<DeviceModel>

const initialState: DeviceState = {
  selectedDeviceId: null,
  selectedWirelessInterfaceId: null,
  searchDeviceIdList: [],
  myRequestsDeviceIdList: [],
  myWorkflowDeviceIdList: [],
  loadAllStatus: status.default,
  otherLoadings: status.default,
  ...deviceAdapter.getInitialState(),
};

export const deviceSlice = createSlice({
  name: 'deviceList',
  initialState,
  reducers: {
    setCurrentDeviceId: (state, action) => {
      state.selectedDeviceId = action.payload;
      state.selectedWirelessInterfaceId = null;
    },
    setCurrentWirelessInterfaceId: (state, action) => {
      state.selectedWirelessInterfaceId = action.payload;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(requestAllDevicesByQuery.pending, (state) => {
        state.loadAllStatus = status.loading;
      })
      .addCase(requestAllDevicesByQuery.rejected, (state, action) => {
        state.loadAllStatus = status.error(action.error);
      })
      .addCase(requestAllDevicesByQuery.fulfilled, (state, action) => {
        deviceAdapter.upsertMany(state, action.payload);
        state.loadAllStatus = status.loaded;
      })
      .addCase(requestDeviceById.pending, (state) => {
        state.otherLoadings = status.loading;
      })
      .addCase(requestDeviceById.rejected, (state, action) => {
        state.otherLoadings = status.error(action.error);
      })
      .addCase(requestDeviceById.fulfilled, (state, action) => {
        deviceAdapter.upsertOne(state, action.payload);
        state.otherLoadings = status.loaded;
      })
      .addCase(createNewDevice.fulfilled, (state, action) => {
        deviceAdapter.addOne(state, action.payload);
      })
      .addCase(deleteDevice.fulfilled, (state, action) => {
        if (action.payload) {
          deviceAdapter.removeOne(state, action.payload);
        }
      })
      .addCase(doSearch.pending, (state) => {
        state.loadAllStatus = status.loading;
      })
      .addCase(doSearch.rejected, (state, action) => {
        state.loadAllStatus = status.error(action.error);
      })
      .addCase(doSearch.fulfilled, (state, action) => {
        state.searchDeviceIdList = (action.payload || []).map((device) => device.id);
        deviceAdapter.upsertMany(state, action.payload);
      })
      .addCase(requestMyRequests.fulfilled, (state, action) => {
        const { myDevicesList, myWorkflowList } = action.payload || [];
        const deviceList = [...myDevicesList, ...myWorkflowList];
        state.myRequestsDeviceIdList = myDevicesList.map((device) => device.id);
        state.myWorkflowDeviceIdList = myWorkflowList.map((device) => device.id);

        deviceAdapter.upsertMany(state, deviceList);
      })
      .addCase(upsertDevicePoint.fulfilled, (state, action) => {
        if (action.payload) {
          deviceAdapter.upsertOne(state, action.payload);
        }
      })
      .addCase(deleteDevicePoint.fulfilled, (state, action) => {
        if (action.payload) {
          deviceAdapter.upsertOne(state, action.payload);
        }
      })
      .addMatcher(isAnyOf(submitDevice.fulfilled, approveDevice.fulfilled, rejectDevice.fulfilled),
        (state, action) => {
          if (action.payload?.device) {
            deviceAdapter.upsertOne(state, action.payload.device);
          }
        })
      .addMatcher(isAnyOf(
        addWirelessInterfaceToDevice.fulfilled, assignDevice.fulfilled, updateDevice.fulfilled,
        deleteWirelessInterface.fulfilled, updateWirelessInterface.fulfilled
      ), (state, action) => {
        deviceAdapter.upsertOne(state, action.payload);
      })
});

export const { setCurrentDeviceId, setCurrentWirelessInterfaceId } = deviceSlice.actions;