import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isEmpty, mapValues } from "lodash/fp";
import { IconButton } from "@material-ui/core";
import { Cancel, Delete, PublishRounded, Save } from "@material-ui/icons";
import HorizontalPanel from "../../../components/HorizontalPanel";
import BreadcrumbsContainer from "../../Shared/BreadcrumbsContainer";
import {
  selectCurrentDevice,
  selectCurrentDeviceId,
  selectDevicesByMapId,
} from "../../../store/deviceStore/deviceSelectors";
import { selectCurrentConfiguration } from "../../../store/deviceConfigurationStore/deviceConfigurationSelectors";
import { Toolbar } from "../../../app/components";
import DeviceForm, { DeviceFormProps } from "../../../components/DeviceForm";
import { DeviceStatus } from "../../../models/enums/DeviceStatus.enum";
import DeviceWorkflow from "../DeviceWorkflow";
import { selectCurrentUser } from "../../../store/userStore/userSelectors";
import { CheckPermission } from "../../../requests/CheckPermission";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import { useTypedDispatch } from "../../../app/state";
import { createNewDevice, deleteDevice, submitDevice, updateDevice } from "../../../store/deviceStore/deviceRequests";
import useDevicesPage from "../devicePageHooks";
import DeviceModel from "../../../models/DeviceModel";
import MapPreview from "../../Shared/MapPreview";
import { useShowModal } from "../../../app/shared/modal-context/modal.context";
import { ModalType } from "../../../app/shared/modal-context/constants";
import { setCurrentDeviceId } from "../../../store/deviceStore/deviceState";
import ToolbarDivider from "../../../components/ToolbarDivider";
import ApplySmartButton from "../ApplySmartButton";

type DeviceCentralPanelProps = {
  showBreadCrumbs?: boolean,
  showSmartFilterButton?: boolean,
  formDisabled?: boolean,
  formProps: DeviceFormProps,
  onLastDeviceDeleted?: () => void,
}

const DeviceCentralPanel = ({
  showBreadCrumbs = false,
  showSmartFilterButton = false,
  formProps,
  formDisabled,
  onLastDeviceDeleted
}: DeviceCentralPanelProps) => {
  const dispatch = useTypedDispatch();
  const { showModal } = useShowModal();
  const { actions, state } = useDevicesPage();
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const currentUser = useSelector(selectCurrentUser);
  const currentDevice = useSelector(selectCurrentDevice);
  const { currentMapId, isLocationDevice } = state;
  const selectedConfiguration = useSelector(selectCurrentConfiguration);
  const currentPlaceDevices = useSelector(selectDevicesByMapId(currentMapId));
  const currentConfiguration = selectedConfiguration || currentDevice?.configuration;
  const isHistorySelected = !!selectedConfiguration?.parentDeviceConfiguration;
  const isDraft = typeof currentConfiguration?.isDraft === 'undefined' ? true : currentConfiguration?.isDraft;
  const isCreateMode = currentDeviceId === -1;

  const toolBarTitle = isCreateMode
    ? 'Create device'
    : isDraft
      ? 'Device Details (Draft)'
      : 'Device Details';

  const { deviceFormik, onUpdate } = formProps;

  const onSaveButtonClick = async () => {
    const valid = await deviceFormik.validateForm();
    if (!isEmpty(valid)) {
      return;
    }

    if (!currentMapId) {
      return;
    }

    const { name, id, ...configuration } = deviceFormik.values;

    const devicePayload = {
      id: id === -1 ? undefined : id,
      name,
      mapId: currentMapId,
      configuration: mapValues((value) => value === '' ? null : value, configuration),
    }

    if (isCreateMode) {
      dispatch(createNewDevice(devicePayload))
        .then((action) => {
          const newDevice = action.payload as DeviceModel;
          onUpdate();
          actions.onDeviceNavigate(newDevice.id);
        });

      return;
    }

    dispatch(updateDevice(devicePayload))
      .then(() => {
        onUpdate();
      })
  }

  const onCancelChanges = () => {
    deviceFormik.resetForm(deviceFormik.initialValues as any);
  }

  const onSubmitDevice = () => {
    dispatch(submitDevice(currentDeviceId))
      .then((action) => {
        actions.handleWorkflowErrors(action, 'Submit failed');
        onUpdate();
      })
  }

  const onDeleteDevice = () => {
    dispatch(deleteDevice(currentDeviceId))
      .then(() => {
        // full deletion of device
        if (
          // deletion by admin
          (!isDraft && currentConfiguration?.status !== DeviceStatus.NEW) ||
          // deletion new device
          currentConfiguration?.status === DeviceStatus.NEW
        ) {
          dispatch(setCurrentDeviceId(null));
          // if the last device in the list
          if (currentPlaceDevices.length === 1) {
            const action = onLastDeviceDeleted || (() => actions.onDeviceGoBack(true));
            action();
          }
        }

        onUpdate();
      })
  }

  const onDeviceDelete = () => {
    const text = isDraft ?
      `Do you want to delete draft on ${currentDevice?.name}?` :
      `Do you want to delete ${currentDevice?.name}?`;

    showModal(
      ModalType.MODAL_GENERIC_CONFIRM, {
        title: 'Delete Device',
        text,
        confirmButtonHandler: onDeleteDevice,
        confirmButtonText: 'Delete',
      }
    )
  }

  const isDeleteAvailable = useMemo(() => {
    const status = currentConfiguration?.status;
    const requesterId = currentConfiguration?.requesterId;
    const assigneeId = currentConfiguration?.assigneeId;
    const isDraft = currentConfiguration?.isDraft;

    if (deviceFormik.dirty) {
      return false;
    }

    if (isDraft === true) {
      switch (status) {
        case DeviceStatus.NEW:
        case DeviceStatus.ACTIVE:
        case DeviceStatus.REJECTED:
          return requesterId === currentUser?.id;
        case DeviceStatus.REQUESTED:
          return assigneeId === currentUser?.id;
        case DeviceStatus.APPROVED:
        case DeviceStatus.RETIRED:
          return false;
        default:
          return false;
      }
    }

    return !!isDraft;
  }, [deviceFormik, currentConfiguration, currentUser]);

  const isSubmitAvailable = useMemo(() => {
    const status = currentConfiguration?.status as string;
    return ![DeviceStatus.REQUESTED as string, DeviceStatus.APPROVED as string].includes(status) && isDeleteAvailable;
  }, [deviceFormik, currentConfiguration, currentUser]);

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      {
        showBreadCrumbs && (
          <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
            <BreadcrumbsContainer items={
              isLocationDevice ?
                ['locations', 'devices'] :
                ['locations', 'buildings', 'devices']
            } />
          </HorizontalPanel>
        )
      }
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={toolBarTitle}>
          {
            showSmartFilterButton ? (
              <>
                <ApplySmartButton />
                <ToolbarDivider />
              </>
            ) : null
          }
          <IconButton
            disabled={formDisabled || !isSubmitAvailable}
            aria-label='submit device changes'
            color='secondary'
            size='small'
            onClick={onSubmitDevice}
          >
            <PublishRounded />
          </IconButton>
          <DeviceWorkflow
            onUpdate={onUpdate}
            disabled={formDisabled || deviceFormik.dirty}
          />
          <ToolbarDivider />
          <IconButton
            disabled={formDisabled || !deviceFormik.dirty}
            aria-label='save device changes'
            color='secondary'
            size='small'
            onClick={onSaveButtonClick}
          >
            <Save />
          </IconButton>
          <CheckPermission
            permissionName={PERMISSIONS.DEVICE_DELETE}
            noPermissionComponent={(
              <IconButton
                disabled={formDisabled || !isDeleteAvailable}
                aria-label='Delete device'
                color='secondary'
                size='small'
                onClick={onDeviceDelete}
              >
                <Delete />
              </IconButton>
            )}
          >
            <IconButton
              disabled={isHistorySelected || deviceFormik.dirty}
              aria-label='Delete device'
              color='secondary'
              size='small'
              onClick={onDeviceDelete}
            >
              <Delete />
            </IconButton>
          </CheckPermission>
          <IconButton
            disabled={formDisabled || !deviceFormik.dirty}
            aria-label='cancel device changes'
            color='secondary'
            size='small'
            onClick={onCancelChanges}
          >
            <Cancel />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <DeviceForm {...formProps} formDisabled={formDisabled} />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
        <MapPreview showCurrentDevice />
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default DeviceCentralPanel;