import cn from 'classnames';
import MenuList, { MenuListProps } from "../MenuList";
import MenuImage from "../MenuImage";

const MenuListWithImage = (props: MenuListProps) => {
  const { classes, ...restProps } = props;
  const newClasses = { ...classes, paper: cn(classes?.paper, MenuImage.className) };
  const itemsLength = restProps?.items?.length || 0

  return <MenuList {...restProps} classes={itemsLength > 5 ? classes : newClasses} />
}

export default MenuListWithImage;
