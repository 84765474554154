import { Toolbar } from 'app/components';
import { Grid, IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import Delete from '@material-ui/icons/Delete';
import { TextField } from '@material-ui/core';
import { Dropdown } from 'app/components/Dropdown';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useFormik } from 'formik';
import { channelsValidations } from './validations';
import { ModalType } from 'app/shared/modal-context/constants';
import { useShowModal } from 'app/shared/modal-context/modal.context';
import {
  useTypedDispatch,
} from 'app/state';
import _, { isEmpty } from 'lodash';
import { useSnackbar } from "../../../../app/shared/snackbar-context/snackbar.context";
import UnitsInput from "../../../../components/TextFields/UnitsInput";
import {
  selectCurrentChannel,
  selectCurrentChannelId,
  selectCurrentTechnologyConfiguration,
  selectCurrentTechnologyConfigurationId
} from "../../../../store/technologyConfigurationStore/technologyConfigurationSelectors";
import { setCurrentChannelId } from "../../../../store/technologyConfigurationStore/technologyConfigurationState";
import HorizontalPanel from "../../../../components/HorizontalPanel";
import {
  createTechnologyConfigurationChannel,
  deleteTechnologyConfigurationChannel,
  updateTechnologyConfigurationChannel
} from "../../../../store/technologyConfigurationStore/technologyConfigurationRequests";
import TechnologyConfigurationModel from "../../../../models/TechnologyConfigurationModel";

import styles from './ChannelDetails.module.scss';

export default function ChannelDetails() {
  const { openSnackbar } = useSnackbar();
  const dispatch = useTypedDispatch();
  const { showModal } = useShowModal();

  const channelId = useSelector(selectCurrentChannelId);
  const channel = useSelector(selectCurrentChannel);
  const technologyConfigurationId = useSelector(selectCurrentTechnologyConfigurationId);
  const technologyConfiguration = useSelector(selectCurrentTechnologyConfiguration);
  const channelList = technologyConfiguration?.channels || [];
  const isCreateMode = channelId === -1;

  const channelFormik = useFormik({
    initialValues: {
      id: channel?.id || '',
      channelNumber: channel?.channelNumber || '',
      downlinkCF: channel?.downlinkCF || '',
      EIRP: channel?.EIRP || '',
      outdoor: channel?.outdoor || 0,
      isBestPractice: channel?.isBestPractice || 0
    },
    onSubmit: () => {
      console.log('fire');
    },
    validationSchema: channelsValidations,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false
  })

  const saveChannel = useCallback(
    (values) => {
      if (isCreateMode) {
        values.technologyConfigurationId = technologyConfigurationId;
        dispatch(
          createTechnologyConfigurationChannel(
            {
              channels: [
                _.omitBy(values, (value) => !value && value !== 0)
              ]
            }
          )
        ).then((action) => {
          const updatedConfiguration = (action as { payload: TechnologyConfigurationModel }).payload;
          const newChannels = updatedConfiguration?.channels || [];

          const newlyCreatedChannel = newChannels.find((channel) => !channelList.find((existingChannel) => existingChannel.id === channel.id))
          newlyCreatedChannel && dispatch(setCurrentChannelId(newlyCreatedChannel.id));
        })
      } else {
        dispatch(
          updateTechnologyConfigurationChannel(
            _.omitBy(values, (value) => !value && value !== 0)
          )
        ).then((data) => {
          // @ts-ignore
          if (data?.error) {
            openSnackbar({
              title: "Cannot edit channel",
              message: "This channel is already in use",
              severity: "error",
            });
            return;
          }
        })
      }
    },
    [dispatch, channel, channelFormik]
  );

  const onDeleteChannel = () => {
    showModal(ModalType.MODAL_GENERIC_CONFIRM, {
      title: 'Delete Channel',
      text: 'You want to delete this Channel. Are you sure?',
      confirmButtonHandler: deleteChannel,
      confirmButtonText: 'Delete',
    });
  }

  const deleteChannel = useCallback(() => {
    dispatch(deleteTechnologyConfigurationChannel(channel?.id)).then((action) => {
      // @ts-ignore
      if (action?.error) {
        openSnackbar({
          title: "Cannot delete channel",
          message: "This channel is already in use",
          severity: "error",
        });
      } else {
        dispatch(setCurrentChannelId(null));
      }
    })
  }, [channel])

  const onSaveChannel = useCallback(async () => {
    const valid = await channelFormik.validateForm();
    if (isEmpty(valid)) {
      saveChannel(channelFormik.values);
    }
  }, [channelFormik]);

  const onCancelChanges = useCallback(() => {
    channelFormik.resetForm(channelFormik.initialValues as any);
  }, [channelFormik]);

  return (
    <>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={
          channel?.id ?
            'Channel Details' :
            'New Channel'
        }>
          <IconButton
            disabled={!channelFormik.dirty}
            aria-label='save changes'
            color='secondary'
            size='small'
            onClick={onSaveChannel}
          >
            <SaveIcon />
          </IconButton>
          <IconButton
            disabled={channelFormik.dirty}
            aria-label='deactivate'
            color='secondary'
            size='small'
            onClick={onDeleteChannel}
          >
            <Delete />
          </IconButton>
          <IconButton
            disabled={!channelFormik.dirty}
            aria-label='clear'
            color='secondary'
            size='small'
            onClick={onCancelChanges}
          >
            <Cancel />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL} className={styles.wrapper} addScroll>
        <Grid container className={styles.gridContainer}>
          <Grid item className={styles.gridItem}>
            <TextField
              className={styles.fields}
              disabled={false}
              label='Channel Nr'
              variant='outlined'
              name='channelNumber'
              value={channelFormik.values.channelNumber}
              onChange={channelFormik.handleChange}
              helperText={channelFormik.errors.channelNumber}
              error={Boolean(channelFormik.errors.channelNumber)}
            />
          </Grid>
          <Grid item className={styles.gridItem}>
            <UnitsInput
              className={styles.fields}
              unitList={UnitsInput.UNIT_TEMPLATE.FREQUENCY}
              disabled={false}
              label='Downlink CF'
              variant='outlined'
              name='downlinkCF'
              value={channelFormik.values.downlinkCF}
              onChange={channelFormik.handleChange}
              helperText={channelFormik.errors.downlinkCF}
              error={Boolean(channelFormik.errors.downlinkCF)}
            />
          </Grid>
          <Grid item className={styles.gridItem}>
            <UnitsInput
              className={styles.fields}
              unitList={UnitsInput.UNIT_TEMPLATE.EXTENDED_POWER}
              disabled={false}
              label='EIRP'
              variant='outlined'
              name='EIRP'
              value={channelFormik.values.EIRP}
              onChange={channelFormik.handleChange}
              helperText={channelFormik.errors.EIRP}
              error={Boolean(channelFormik.errors.EIRP)}
            />
          </Grid>
          <Grid item className={styles.gridItem}>
            <Dropdown
              name='outdoor'
              className={styles.fields}
              values={[
                {
                  id: 0,
                  name: 'No',
                },
                {
                  id: 1,
                  name: 'Yes',
                },
              ]}
              label='Outdoor'
              valueChange={channelFormik.handleChange}
              selectedValue={
                channelFormik.values?.outdoor ? 1 : 0
              }
            />
          </Grid>
          <Grid item className={styles.gridItem}>
            <Dropdown
              name='isBestPractice'
              className={styles.fields}
              values={[
                {
                  id: 0,
                  name: 'No',
                },
                {
                  id: 1,
                  name: 'Yes',
                },
              ]}
              label='Best Practices'
              valueChange={channelFormik.handleChange}
              selectedValue={
                channelFormik.values?.isBestPractice ? 1 : 0
              }
            />
          </Grid>
        </Grid>
      </HorizontalPanel>
    </>
  );
}
