import { GeneralRequest } from "../../../requests/GeneralRequest";
import { getAllWirelessSystems } from "../../../store/wirelessSystemStore/wirelessSystemRequests";
import { requestLocationByQuery } from "../../../store/locationStore/locationRequests";
import { getAllRegions } from "../../../store/regionStore/regionRequests";

const FetchDeviceRequirementsWrapper = ({ children }) => {
  return (
    <GeneralRequest requestAction={getAllRegions}>
      <GeneralRequest requestAction={getAllWirelessSystems}>
        <GeneralRequest requestAction={requestLocationByQuery}>
          {children}
        </GeneralRequest>
      </GeneralRequest>
    </GeneralRequest>
  )
}

export default FetchDeviceRequirementsWrapper;