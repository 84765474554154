import HorizontalPanel from "../../../components/HorizontalPanel";
import EditFloorPanel from "../EditFloorPanel";
import WirelessSystemList from "../../Shared/WirelessSystemList";

const BuildingRightPanel = () => {
  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <EditFloorPanel />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <WirelessSystemList />
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default BuildingRightPanel;