import { first } from "lodash/fp";
import {
  OutlinedInput,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { useRef, ReactElement } from "react";
// import styles from './index.module.scss';

type FileUploadProps = {
  disabled?: boolean;
  value?: string | null;
  placeholder?: string;
  fullWidth?: boolean;
  handleFile: (file: File) => void;
  accept: string;
  multiple?: boolean;
  size?: number;
  icon?: ReactElement | null;
  buttonAlt?: string;
  hideInput?: boolean;
};

const FileUpload = ({
  disabled = false,
  handleFile,
  accept,
  value = '',
  placeholder = '',
  fullWidth = false,
  multiple = false,
  size = 10000000,
  icon = null,
  buttonAlt = '',
  hideInput = false,
}: FileUploadProps) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const handleButtonClick = () => {
    fileInput?.current?.click();
  };

  const handleChange = (event) => {
    const fileUploaded: File | undefined = first(event?.target?.files);
    if (!fileUploaded) {
      return;
    }
    handleFile(fileUploaded);
  };

  return (
    <>
      <OutlinedInput
        disabled={disabled}
        value={value || ''}
        placeholder={placeholder}
        fullWidth={fullWidth}
        onClick={handleButtonClick}
        style={hideInput ? { display: 'none' } : {}}
        endAdornment={icon ? (
          <InputAdornment position='end'>
            <IconButton
              disabled={disabled}
              aria-label={buttonAlt}
              onClick={handleButtonClick}
              edge="end"
            >
              {icon}
            </IconButton>
          </InputAdornment>
        ) : null}
      />
      <input
        ref={fileInput}
        disabled={disabled}
        accept={accept}
        multiple={multiple}
        size={size}
        type='file'
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  )

};

export default FileUpload;