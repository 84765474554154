import React, { useMemo } from "react";
import { isEmpty } from "lodash/fp";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Grid, IconButton, TextField } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { selectGlobalSettings } from "../../../store/settingsStore/settingsSelectors";
import { editGlobalSettings } from "../../../store/settingsStore/settingsRequests";
import { AppDispatch } from "../../../app/state";
import { Toolbar } from "../../../app/components";

import classes from './index.module.scss';

const GlobalSettingsForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const settings = useSelector(selectGlobalSettings);

  const validationSchema = useMemo(
    () =>
      yup.object({
        powerLossCoefficient: yup.number().typeError('Should be a number').required('Required'),
        distance: yup.number().typeError('Should be a number').required('Required'),
        interferenceLevel: yup.number().typeError('Should be a number').required('Required'),
      }),
    [],
  );

  const settingsFormik = useFormik({
    initialValues: {
      powerLossCoefficient: settings?.powerLossCoefficient,
      distance: settings?.distance,
      interferenceLevel: settings?.interferenceLevel,
    },
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onSave = async () => {
    const errors = await settingsFormik.validateForm();
    if (isEmpty(errors)) {
      dispatch(editGlobalSettings(settingsFormik.values));
    }
  };

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Application Settings'>
          <IconButton
            disabled={!settingsFormik.dirty}
            aria-label='save changes'
            color='secondary'
            size='small'
            onClick={onSave}
          >
            <SaveIcon />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel
        size={HorizontalPanel.SIZES.REST_SPACE}
        className={classes.formPanel}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              label='Power loss coefficient'
              variant='outlined'
              name='powerLossCoefficient'
              required
              fullWidth
              value={settingsFormik.values.powerLossCoefficient}
              onChange={settingsFormik.handleChange}
              helperText={settingsFormik.errors.powerLossCoefficient}
              error={Boolean(settingsFormik.errors.powerLossCoefficient)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label='Distance, m'
              variant='outlined'
              name='distance'
              required
              fullWidth
              value={settingsFormik.values.distance}
              onChange={settingsFormik.handleChange}
              helperText={settingsFormik.errors.distance}
              error={Boolean(settingsFormik.errors.distance)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label='Interference level, dBm'
              variant='outlined'
              name='interferenceLevel'
              required
              fullWidth
              value={settingsFormik.values.interferenceLevel}
              onChange={settingsFormik.handleChange}
              helperText={settingsFormik.errors.interferenceLevel}
              error={Boolean(settingsFormik.errors.interferenceLevel)}
            />
          </Grid>
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default GlobalSettingsForm;