import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus, status } from '../../app/shared';
import { UserModel } from '../../models/UserModel';
import { UserRoleModel } from '../../models/UserRoleModel';
import { createAUser, deactivateUser, getTemporaryUserPassword } from './userActionRequests';

type UserActionState = {
  editUser: UserModel | null;
  editUserTokenMap: {
    [key: number]: string,
  },
  editUserStatus: LoadingStatus;
}

const initialState: UserActionState = {
  editUser: null,
  editUserTokenMap: {},
  editUserStatus: status.default,
};

export const userActionSlice = createSlice({
  name: 'userAction',
  initialState,
  reducers: {
    startEditUser: (state: UserActionState, action) => {
      state.editUser = action?.payload.user;
      // clear previous errors
      state.editUserStatus = status.default;
    },
    setRoles: (state: UserActionState, action) => {
      if (state.editUser) {
        state.editUser.userRoles = action?.payload.roles as UserRoleModel[];
      }
    },
    deleteRole: (state: UserActionState, action) => {
      if (state.editUser) {
        state.editUser.userRoles =
          (state.editUser.userRoles || []).filter((role) => role.type !== action?.payload.type);
      }
    },
    clearEditUserError: (state) => {
      if (!state.editUserStatus.loading) {
        state.editUserStatus = status.default;
      }
    },
    clearEditUser: (state) => {
      state.editUser = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createAUser.pending, (state) => {
        state.editUserStatus = status.loading;
      })
      .addCase(createAUser.rejected, (state, action) => {
        state.editUserStatus = status.error(action.payload as Error);
      })
      .addCase(getTemporaryUserPassword.fulfilled, (state, action) => {
        const { userId, token, user } = action.payload;
        if (userId && token) {
          state.editUserTokenMap[userId] = token;
        }
        if (user && state.editUser) {
          state.editUser.status = user.status;
        }
      })
      .addCase(deactivateUser.fulfilled, (state, action) => {
        const user = action.payload?.user;

        if (user && state.editUser) {
          state.editUser.status = user.status;
        }
      }),
});

export const { startEditUser, clearEditUser, setRoles, deleteRole, clearEditUserError } = userActionSlice.actions;