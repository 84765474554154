import { createAsyncThunk } from "@reduxjs/toolkit";
import { WirelessSystemModel } from "../../app/types";
import { request } from "../../app/api";
import { serializeError } from "../../app/shared";

const SLICE_NAME = "wirelessSystemList";

export const getAllWirelessSystems = createAsyncThunk(
  `${SLICE_NAME}/get-all`,
  async (_, { rejectWithValue }) => {
    try {
      const wirelessSystemList: WirelessSystemModel[] = await request.get("/wireless-system/all");
      return wirelessSystemList;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const createNewWirelessSystem = createAsyncThunk(
  `${SLICE_NAME}/create`,
  async (name: string, { rejectWithValue }) => {
    try {
      if (!name) {
        throw new Error('No name set');
      }
      const data: { wirelessSystem: WirelessSystemModel, error?: string } = await request.post('/wireless-system', { name });
      return data;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const editWirelessSystem = createAsyncThunk(
  `${SLICE_NAME}/edit`,
  async (data: { id: number, name: string }, { rejectWithValue }) => {
    try {
      const response: { wirelessSystem: WirelessSystemModel, error?: string } = await request.put('/wireless-system', data);
      return response;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const deleteWirelessSystem = createAsyncThunk(
  `${SLICE_NAME}/delete`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response: { error?: string } = await request.delete(`/wireless-system/${id}`);
      return { ...response, id };
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
)