import { Redirect } from "react-router-dom";
import Pages, { getQueryObject, isEmptyId } from "../index";
import PageUpdater from "../../containers/PageUpdater";
import { setCurrentFloorId } from "../../store/floorStore/floorState";
import { setCurrentLocationId } from "../../store/locationStore/locationState";
import { ThreeColumnLayout } from "../../layouts/ThreeColumnLayout/ThreeColumnLayout";
import { LocationMapCentralPanel } from "../../containers/LocationMap/LocationMapCentralPanel";
import { LocationMapRightPanel } from "../../containers/LocationMap/LocationMapRightPanel";
import { requestLocationByQuery } from "../../store/locationStore/locationRequests";
import { GeneralRequest } from "../../requests/GeneralRequest";

enum MAP_TYPE {
  LOCATION_MAP,
  FLOOR_MAP,
}

export const MapPage = () => {
  const urlProps = getQueryObject();
  const floorId = parseInt(urlProps.floorId as string, 10);
  const locationId = parseInt(urlProps.locationId as string, 10);

  if (isEmptyId(floorId) && isEmptyId(locationId)) {
    return <Redirect to={Pages.LOCATIONS_PAGE} />
  }

  const mapType = !isEmptyId(locationId) ? MAP_TYPE.LOCATION_MAP : MAP_TYPE.FLOOR_MAP;

  return (
    <>
      <PageUpdater watchItemList={[
        {
          title: 'floorId',
          action: setCurrentFloorId,
        },
        {
          title: 'locationId',
          action: setCurrentLocationId,
        }
      ]} />
      <GeneralRequest requestAction={requestLocationByQuery}>
        <ThreeColumnLayout
          centerMainPanel={mapType === MAP_TYPE.LOCATION_MAP ? <LocationMapCentralPanel /> : null}
          rightSideBar={mapType === MAP_TYPE.LOCATION_MAP ? <LocationMapRightPanel /> : null}
        />
      </GeneralRequest>
    </>
  );
}