import HorizontalPanel from "../../../components/HorizontalPanel";
import RegionAndLocationFilters from "../RegionAndLocationFilters";
import LocationsList from "../LocationsList";

const LocationLeftPanel = () => {
  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <RegionAndLocationFilters />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} addScroll>
        <LocationsList />
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default LocationLeftPanel;