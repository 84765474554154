import { getQueryObject } from "../index";
import useRoutes from "../../hooks/routeHooks";
import PageUpdater from "../../containers/PageUpdater";
import { setCurrentDeviceId } from "../../store/deviceStore/deviceState";
import { setCurrentMapId } from "../../store/mapStore/mapState";
import { setCurrentLocationIdByMapId } from "../../store/locationStore/locationState";
import { setCurrentFloorByMapId } from "../../store/floorStore/floorState";
import { GeneralRequest } from "../../requests/GeneralRequest";
import { requestAllDevicesByQuery } from "../../store/deviceStore/deviceRequests";
import FetchDeviceRequirementsWrapper from "../../containers/DevicesPage/FetchDeviceRequirementsWrapper";
import { ThreeColumnLayout } from "../../layouts/ThreeColumnLayout/ThreeColumnLayout";
import AnalysisCentralPanel from "../../containers/FrequencyAnalysisPage/AnalysisCentralPanel";
import AnalysisRightPanel from "../../containers/FrequencyAnalysisPage/AnalysisRightPanel";
import {
  requestAllTechnologyConfigurations
} from "../../store/technologyConfigurationStore/technologyConfigurationRequests";

const FrequencyAnalysisPage = () => {
  const urlProps = getQueryObject();
  const { actions } = useRoutes();
  const mapId = parseInt(urlProps.mapId as string);

  if (isNaN(mapId)) {
    actions.redirectWrongId();
    return null;
  }

  return (
    <>
      <PageUpdater
        watchItemList={[
          {
            title: 'deviceId',
            action: setCurrentDeviceId,
          },
          {
            title: 'mapId',
            action: setCurrentMapId,
          },
          {
            title: 'mapId',
            action: setCurrentLocationIdByMapId,
          },
          {
            title: 'mapId',
            action: setCurrentFloorByMapId,
          },
        ]}
      />
      <GeneralRequest
        requestAction={requestAllDevicesByQuery}
        requestArguments={[{ mapId }]}
      >
        <FetchDeviceRequirementsWrapper>
          <GeneralRequest requestAction={requestAllTechnologyConfigurations}>
            <ThreeColumnLayout
              centerMainPanel={<AnalysisCentralPanel />}
              rightSideBar={<AnalysisRightPanel />}
            />
          </GeneralRequest>
        </FetchDeviceRequirementsWrapper>
      </GeneralRequest>
    </>
  )
}

export default FrequencyAnalysisPage;