import {
  createStyles,
  makeStyles,
  Theme,
  Toolbar as MaterialToolbar,
  Typography,
} from "@material-ui/core";
import { FC } from "react";

interface ToolbarProps {
  title: string;
  classes?: string;
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#ECEEF0", // TODO: use color from palette
      minHeight: "48px",
      color: theme.palette.secondary.main,
      paddingRight: "10px",
      width: '100%',
    },
    title: {
      flex: "1 1 100%",
    },
  })
);

export const Toolbar: FC<ToolbarProps> = ({ title, children, classes }) => {
  const styles = useToolbarStyles();

  return (
    <MaterialToolbar classes={{ root: classes }} className={styles.root}>
      <Typography className={styles.title} variant="h6" component="div">
        {title}
      </Typography>
      {children}
    </MaterialToolbar>
  );
};
