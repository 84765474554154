import { createSlice } from "@reduxjs/toolkit";

export enum MapPointIdEnum {
  'first',
  'second',
  'third'
}

type PointToSave = { x?: number, y?: number, longitude?: string, latitude?: string, removed: boolean }

type MapPointsState = {
  selectedMapPointId?: MapPointIdEnum;
  pointsToSave: PointToSave[];
}

const initialState: MapPointsState = {
  selectedMapPointId: undefined,
  pointsToSave: [{ removed: false }, { removed: false }, { removed: false }],
}

export const mapPointsState = createSlice({
  name: 'mapPointsSlice',
  initialState,
  reducers: {
    setSelectedMapPointId: (state, action) => {
      state.selectedMapPointId = action.payload as MapPointIdEnum;
    },
    createMapPoint: (state, action) => {
      if (typeof state.selectedMapPointId !== 'undefined') {
        const currentPoint = state.pointsToSave[state.selectedMapPointId];
        state.pointsToSave[state.selectedMapPointId] = {
          ...currentPoint,
          ...action.payload,
          removed: false,
        }

        state.selectedMapPointId = undefined;
      }
    },
    deletePointByIndex: (state, action) => {
      const currentPoint = state.pointsToSave[action.payload as number];
      currentPoint.removed = true;
      delete currentPoint.x;
      delete currentPoint.y;
    },
    clearMapPointState: (state) => {
      state = { ...initialState };
      return state;
    }
  }
});

export const { setSelectedMapPointId, createMapPoint, clearMapPointState, deletePointByIndex } = mapPointsState.actions;