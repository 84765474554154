import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash/fp";
import { Grid, IconButton, TextField } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { Delete } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import { CheckPermission } from "../../../requests/CheckPermission";
import { selectCurrentBuilding } from "../../../store/buildingStore/buildingsSelectors";
import useLocationRoute, { LocationProps } from "../locationRoute.hook";
import { selectFloorsByBuildingId } from "../../../store/floorStore/floorSelectors";
import { useShowModal } from "../../../app/shared/modal-context/modal.context";
import { useSnackbar } from "../../../app/shared/snackbar-context/snackbar.context";
import { ModalType } from "../../../app/shared/modal-context/constants";
import { useTypedDispatch } from "../../../app/state";
import { createBuilding, deleteBuilding, updateBuilding } from "../../../store/buildingStore/buildingsRequests";
import { Dropdown } from "../../../app/components/Dropdown";
import { selectAllLocations } from "../../../store/locationStore/locationSelectors";
import { BuildingModel } from "../../../app/types";

import classes from './index.module.scss';

const validationSchema = yup.object({
  locationId: yup.number().required('Location is required'),
  name: yup
    .string()
    .max(255, 'Too long')
    .required('Title is required'),
  address: yup.string().max(255, 'Too long')
});

const BuildingForm = () => {
  const dispatch = useTypedDispatch();
  const { showModal } = useShowModal();
  const { openSnackbar } = useSnackbar();
  const { state, actions } = useLocationRoute();
  const { currentBuildingId, currentLocationId = 0, currentRegionId, currentCountryId } = state;
  const formDisabled = currentLocationId === -1 || currentBuildingId === null;
  const isCreateBuilding = !formDisabled && (currentBuildingId === -1);
  const locationList = useSelector(selectAllLocations);
  const currentBuilding = useSelector(selectCurrentBuilding) || {
    name: '',
    address: '',
  };
  const currentFloorList = useSelector(selectFloorsByBuildingId(currentBuildingId));

  const buildingForm = useFormik({
    initialValues: {
      name: currentBuilding.name,
      address: currentBuilding.address || '',
      locationId: currentLocationId || 0,
    },
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onBuildingUpdate = (action) => {
    const buildingModel = action.payload as BuildingModel;

    if (!(currentRegionId || currentCountryId)) {
      actions.updateLocationUrl({
        buildingId: buildingModel.id,
        locationId: buildingModel.locationId,
        shouldReplace: true
      });
      return;
    }

    const paramsToUpdate: LocationProps = {
      buildingId: buildingModel.id,
      countryId: null,
      regionId: null,
      locationId: buildingModel.locationId,
      shouldReplace: true,
    };

    const currentLocation = locationList.find((location) => location.id === buildingModel.locationId);

    if (currentCountryId) {
      paramsToUpdate.countryId = currentLocation?.countryId;
    }

    if (currentRegionId) {
      paramsToUpdate.regionId = currentLocation?.regionId;
    }

    paramsToUpdate.locationId = buildingModel.locationId;

    actions.updateLocationUrl(paramsToUpdate);
  };

  const onSave = async () => {
    const valid = await buildingForm.validateForm();
    if (!isEmpty(valid)) {
      return;
    }

    isCreateBuilding ?
      dispatch(createBuilding(buildingForm.values)).then(onBuildingUpdate) :
      currentBuildingId && dispatch(updateBuilding({
        ...buildingForm.values,
        id: currentBuildingId,
      })).then(onBuildingUpdate);
  };

  const onContinueDeleteBuilding = () => {
    if (currentBuildingId) {
      dispatch(deleteBuilding(currentBuildingId))
        .then(() => {
          actions.updateLocationUrl({ buildingId: null, shouldReplace: true });
        })
    }
  }

  const onDelete = () => {
    if (currentFloorList.length > 0) {
      openSnackbar({
        title: "Cannot delete building",
        message: "This building contains floors. Remove floors first",
        severity: "error",
      });
      return;
    }

    showModal(ModalType.MODAL_GENERIC_CONFIRM, {
      title: "Delete Building",
      text: "You want to delete this building. Are you sure?",
      confirmButtonHandler: onContinueDeleteBuilding,
      confirmButtonText: "Delete",
    });
  };

  const onReset = () => {
    buildingForm.resetForm(buildingForm.initialValues as any);
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={isCreateBuilding ? 'Create a building' : 'Building details'}>
          <CheckPermission permissionName={PERMISSIONS.BUILDING_ACTIONS} noPermissionComponent={null}>
            <IconButton
              disabled={!buildingForm.dirty || formDisabled}
              aria-label='save building changes'
              color='secondary'
              size='small'
              onClick={onSave}
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              disabled={isCreateBuilding || formDisabled}
              aria-label='delete building'
              color='secondary'
              size='small'
              onClick={onDelete}
            >
              <Delete />
            </IconButton>
            <IconButton
              disabled={!buildingForm.dirty || formDisabled}
              aria-label='cancel building changes'
              color='secondary'
              size='small'
              onClick={onReset}
            >
              <CancelIcon />
            </IconButton>
          </CheckPermission>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <Grid
          container
          spacing={2}
          className={classes.form}
        >
          <Grid item xs={12}>
            <Dropdown
              label='Location'
              values={locationList}
              disabled={formDisabled}
              selectedValue={buildingForm.values.locationId}
              valueChange={buildingForm.handleChange}
              fullWidth
              error={buildingForm.touched && buildingForm.errors.locationId}
              name='locationId'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Title'
              variant='outlined'
              name='name'
              required
              disabled={formDisabled}
              fullWidth
              value={buildingForm.values.name}
              onChange={buildingForm.handleChange}
              helperText={buildingForm.errors.name}
              error={Boolean(buildingForm.errors.name)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Address'
              variant='outlined'
              name='address'
              disabled={formDisabled}
              fullWidth
              value={buildingForm.values.address}
              onChange={buildingForm.handleChange}
              helperText={buildingForm.errors.address}
              error={Boolean(buildingForm.errors.address)}
            />
          </Grid>
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default BuildingForm;