import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '../../app/api';
import { clearEditUser } from './userActionState';
import { getAllUserList } from '../userListStore/userListRequests';
import { serializeError } from '../../app/shared';
import { updateSingleUser } from "../userListStore/userListState";

export const createAUser = createAsyncThunk(
  `/user/create`,
  async (
    values: object,
    { rejectWithValue, dispatch },
  ) => {
    try {
      await request.post(
        `/user/create`,
        values,
      );
      dispatch(clearEditUser());
      dispatch(getAllUserList());
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const getTemporaryUserPassword = createAsyncThunk(
  `/user/token/create`,
  async (
    { userId }: { userId: number },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await request.post(
        `/user/token/create`,
        { userId },
      );
      const { token, user, status } = response as any;
      if (user) {
        dispatch(updateSingleUser(user))
      }

      if (token) {
        return { token, userId, user, status };
      }
      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const updateUser = createAsyncThunk(
  `/user/update`,
  async (
    values: object,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await request.post(
        `/user/update`,
        values,
      );
      const { user } = response as any;
      if (user) {
        dispatch(getAllUserList());
        return { user };
      }
      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const deleteUser = createAsyncThunk(
  `/user/delete`,
  async (
    { id }: { id: number },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await request.post(
        `/user/delete`,
        { id },
      );
      dispatch(getAllUserList());
      dispatch(clearEditUser());
    } catch (error) {
      rejectWithValue(serializeError(error));
    }
  },
);

export const deactivateUser = createAsyncThunk(
  `/user/deactivate`,
  async (
    { userId }: { userId: number },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await request.post(
        `/user/deactivate`,
        { userId },
      );
      const { user } = response as any;
      if (user) {
        dispatch(updateSingleUser(user));

        return { user };
      }
    } catch (error) {
      rejectWithValue(serializeError(error));
    }
  },
);