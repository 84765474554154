import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash/fp';
import { RootState } from '../../app/state';
import { userActionSlice } from './userActionState';
import { USER_STATUS } from '../userListStore/userStatus';

export const selectUserActionState = (state: RootState) => state[userActionSlice.name];

export const getEditUser = createSelector(
  selectUserActionState,
  get('editUser'),
);

export const isUserEditMode = createSelector(
  getEditUser,
  (editUser) => !!editUser,
);

export const isNewUnsavedUser = createSelector(
  getEditUser,
  (user) => user?.id === 0,
);

export const isEditUserNew = createSelector(
  getEditUser,
  (user) => ((user?.id === 0) || user?.status === USER_STATUS.NEW),
);

export const getEditUserRoles = createSelector(
  getEditUser,
  (user) => {
    // skip super admin
    const userRoleList = (user?.userRoles || []).filter((userRole) => userRole.id !== 4);
    return userRoleList.map((role) => {
      return {
        id: role.id,
        value: role.type,
      }
    })
  }
);

export const getEditUserRoleIds = createSelector(
  getEditUser,
  (user) => (user?.userRoles || []).map((role) => role.id),
);

export const getEditStatus = createSelector(
  selectUserActionState,
  get('editUserStatus'),
);
export const getTemporaryPasswordMap = createSelector(
  selectUserActionState,
  get('editUserTokenMap'),
);