import * as yup from "yup";
import { extractNumber, extractUnits } from "../../../helpers/converters/units";

const getValidation = () => {
  return yup.object({
    frequencyFrom: yup.string()
      .matches(/^\d*\.?\d+ +(nHz|Hz|MHz|GHz|kHz|µHz|THz)$/, 'Invalid value'),
    frequencyTo: yup.string()
      .matches(/^\d*\.?\d+ +(nHz|Hz|MHz|GHz|kHz|µHz|THz)$/, 'Invalid value')
      .when('frequencyFrom', (frequencyFrom, schema) => {
        return schema.test({
          test: (frequencyTo) => {
            const frFromUnits = extractUnits(frequencyFrom);
            const frToUnits = extractUnits(frequencyTo);
            return frFromUnits === frToUnits;
          },
          message: 'Units not match'
        })
      })
      .when('frequencyFrom', (frequencyFrom, schema) => {
        return schema.test({
          test: (frequencyTo) => {
            const frFromRaw = extractNumber(frequencyFrom);
            const frToRaw = extractNumber(frequencyTo);
            return frToRaw > frFromRaw;
          },
          message: 'Invalid range'
        })
      }),
  });
}

export default getValidation;