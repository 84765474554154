import React from "react";
import { useFormik } from "formik";
import { isEmpty } from "lodash/fp";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { GenericModal } from "../../../../app/components/ui/generic-modal/GenericModal";
import UnitsInput from "../../../../components/TextFields/UnitsInput";
import TechnologyConfigurationModel from "../../../../models/TechnologyConfigurationModel";
import validationSchemaBuilder from './channelsValidationSchema';
import {
  createTechnologyConfigurationChannel,
} from "../../../../store/technologyConfigurationStore/technologyConfigurationRequests";
import { useTypedDispatch } from "../../../../app/state";
import { extractNumber, extractUnits } from "../../../../helpers/converters/units";
import { setCurrentChannelId } from "../../../../store/technologyConfigurationStore/technologyConfigurationState";

interface ChannelsFormulaProps {
  isOpen: boolean,
  technologyConfiguration: TechnologyConfigurationModel | null,
  onClose: () => any,
  onCreate: () => any,
}

const ChannelsFormulaModal = ({ isOpen, technologyConfiguration, onClose, onCreate }: ChannelsFormulaProps) => {
  const dispatch = useTypedDispatch();
  const channelList = technologyConfiguration?.channels || [];
  const maxEIRP = technologyConfiguration?.maxEIRP || '';
  const initialValues = {
    centralFrequency: '',
    firstChannelNumber: 0,
    amount: 1,
    // bandwidth: '',
    stepFrequency: '',
    stepNumber: 1,
    EIRP: maxEIRP,
    outdoor: false,
  };

  const channelsFormik = useFormik({
    initialValues,
    onSubmit: () => {},
    validationSchema: validationSchemaBuilder({ maxEIRP }),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false
  });

  const onSubmit = async () => {
    const valid = await channelsFormik.validateForm();
    if (isEmpty(valid)) {
      if (!technologyConfiguration) {
        return;
      }

      const {
        centralFrequency,
        firstChannelNumber,
        amount,
        stepFrequency,
        stepNumber,
        EIRP,
        outdoor,
      } = channelsFormik.values;
      const units = extractUnits(centralFrequency);
      const centralFrequencyAmount = extractNumber(centralFrequency);
      const stepAmount = extractNumber(stepFrequency);

      const channelData = new Array(amount).fill('').map((_, index) => {
        return {
          technologyConfigurationId: technologyConfiguration.id,
          channelNumber: firstChannelNumber + index * stepNumber,
          downlinkCF: `${centralFrequencyAmount + index * stepAmount} ${units}`,
          EIRP,
          outdoor,
        }
      });

      dispatch(
        createTechnologyConfigurationChannel(
          {
            channels: channelData,
          }
        )
      ).then((action) => {
        const updatedConfiguration = (action as { payload: TechnologyConfigurationModel }).payload;
        const newChannels = updatedConfiguration?.channels || [];

        const newlyCreatedChannel = newChannels.find((channel) => !channelList.find((existingChannel) => existingChannel.id === channel.id))
        newlyCreatedChannel && dispatch(setCurrentChannelId(newlyCreatedChannel.id));
      })

      channelsFormik.resetForm({
        values: channelsFormik.initialValues,
      });
      onCreate();
    }
  }

  return (
    <GenericModal
      isOpen={isOpen}
      title="Create channels"
      confirmButtonText="Create"
      closeModal={onClose}
      closeOnConfirm={false}
      confirmButtonHandler={onSubmit}
    >
      <Grid
        container
        direction='column'
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <TextField
            required
            name='amount'
            fullWidth
            type='number'
            inputProps={{
              min: 1,
            }}
            variant='outlined'
            label='Amount of channels'
            value={channelsFormik.values.amount}
            onChange={channelsFormik.handleChange}
            helperText={channelsFormik.errors.amount}
            error={Boolean(channelsFormik.errors.amount)}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <UnitsInput
            required
            name='centralFrequency'
            label='Central Frequency of the first channel'
            unitList={UnitsInput.UNIT_TEMPLATE.FREQUENCY}
            value={channelsFormik.values.centralFrequency}
            onChange={channelsFormik.handleChange}
            helperText={channelsFormik.errors.centralFrequency}
            error={Boolean(channelsFormik.errors.centralFrequency)}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <UnitsInput
            required
            name='stepFrequency'
            label='Channel step/separation'
            unitList={UnitsInput.UNIT_TEMPLATE.FREQUENCY}
            value={channelsFormik.values.stepFrequency}
            onChange={channelsFormik.handleChange}
            helperText={channelsFormik.errors.stepFrequency}
            error={Boolean(channelsFormik.errors.stepFrequency)}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            required
            name='firstChannelNumber'
            fullWidth
            type='number'
            inputProps={{
              min: 0,
            }}
            variant='outlined'
            label='Channel number of the first channel'
            value={channelsFormik.values.firstChannelNumber}
            onChange={channelsFormik.handleChange}
            helperText={channelsFormik.errors.firstChannelNumber}
            error={Boolean(channelsFormik.errors.firstChannelNumber)}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            required
            name='stepNumber'
            fullWidth
            type='number'
            inputProps={{
              min: 1,
            }}
            variant='outlined'
            label='Channel number increment per step'
            value={channelsFormik.values.stepNumber}
            onChange={channelsFormik.handleChange}
            helperText={channelsFormik.errors.stepNumber}
            error={Boolean(channelsFormik.errors.stepNumber)}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <UnitsInput
            required
            name='EIRP'
            label='EIRP'
            unitList={UnitsInput.UNIT_TEMPLATE.EXTENDED_POWER}
            value={channelsFormik.values.EIRP}
            onChange={channelsFormik.handleChange}
            helperText={channelsFormik.errors.EIRP}
            error={Boolean(channelsFormik.errors.EIRP)}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={channelsFormik.values?.outdoor}
                value={channelsFormik.values?.outdoor}
                onChange={channelsFormik.handleChange}
                name='outdoor'
                color='default'
              />
            }
            label='Outdoor'
          />
        </Grid>
      </Grid>
    </GenericModal>
  );
}

export default ChannelsFormulaModal;