import HorizontalPanel from "../../../components/HorizontalPanel";
import { useSelector } from "react-redux";
import {
  selectCurrentDeviceId,
  selectMyRequestDeviceList,
  selectMyWorkflowDeviceList
} from "../../../store/deviceStore/deviceSelectors";
import { Toolbar } from "../../../app/components";
import React from "react";
import { CollapsableListItem } from "../../../components/CollapsableListItem/CollapsableListItem";
import { Sms, Star } from "@material-ui/icons";
import DeviceIcon from "../../../components/DeviceIcon/DeviceIcon";
import DeviceModel from "../../../models/DeviceModel";

import classes from './index.module.scss';

interface MyRequestsMenuPanelProps {
  onDeviceSelected: (id: number | null) => void,
}

const MyRequestsMenuPanel = (props: MyRequestsMenuPanelProps) => {
  const { onDeviceSelected } = props;
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const myRequestDeviceList = useSelector(selectMyRequestDeviceList);
  const myWorkflowDeviceList = useSelector(selectMyWorkflowDeviceList);

  const hasMyRequests = myRequestDeviceList.length > 0;
  const hasMyWorkflow = myWorkflowDeviceList.length > 0;
  const noItems = !hasMyRequests && !hasMyWorkflow;

  const getDeviceMenuItem = (device?: DeviceModel | null) => {
    return {
      id: device?.id || 1,
      name: device?.name || '',
      icon: <DeviceIcon deviceConfiguration={device?.configuration} />
    }
  }

  const myRequests = myRequestDeviceList.map(getDeviceMenuItem);

  const myWorkflows = myWorkflowDeviceList.map(getDeviceMenuItem);

  if (noItems) {
    return null;
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title="Requests" />
      </HorizontalPanel>
      {
        hasMyRequests && (
          <HorizontalPanel size={HorizontalPanel.SIZES.SMALL} className={classes.menuContainer}>
            <CollapsableListItem
              icon={<Sms />}
              listTitle={'My Requests'}
              selectItem={onDeviceSelected}
              selectedItemId={currentDeviceId}
              items={myRequests}
            />
          </HorizontalPanel>
        )
      }
      {
        hasMyWorkflow && (
          <HorizontalPanel size={HorizontalPanel.SIZES.SMALL} className={classes.menuContainer}>
            <CollapsableListItem
              icon={<Star />}
              listTitle={'Workflow Requests'}
              selectItem={onDeviceSelected}
              selectedItemId={currentDeviceId}
              items={myWorkflows}
            />
          </HorizontalPanel>
        )
      }
    </HorizontalPanel>
  )
}

export default MyRequestsMenuPanel;