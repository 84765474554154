import React, { MutableRefObject } from 'react';
import * as yup from "yup";
import { FormikErrors } from "formik";
import { map } from "lodash/fp";
import { Grid, TextField } from "@material-ui/core";
import { FloorModel } from "app/types";
import { extractNumber, extractUnits } from "../../helpers/converters/units";
import UnitsInput from "../TextFields/UnitsInput";

type FloorFormProps = {
  values: Partial<FloorModel>;
  errors: FormikErrors<FloorModel>;
  handleChange: (e: React.ChangeEvent) => any;
  disabled?: boolean;
  horizontalView?: boolean;
  inputRef: MutableRefObject<HTMLDivElement | undefined>;
};

export const floorValidationSchema = (t, allFloors: FloorModel[], currentFloorId?: number | null) => {
  const getOtherFloors = () => allFloors.filter((floor) => floor.id !== currentFloorId);
  const validateFloorName = (value?: string) => !map('name')(getOtherFloors()).includes(value);
  const validateFloorNumber = (value?: number) => !map('floor')(getOtherFloors()).includes(value);

  return yup.object({
    name: yup
      .string()
      .required(t('error_message_floor_name_required'))
      .max(30, t('error_message_floor_name_length'))
      .test('unique-name', 'Floor name should be unique', validateFloorName),
    height: yup
      .string()
      .required('Height field is required')
      .test('more-than-one', 'Height value should be positive', (value?: string) => extractNumber(value) > 0)
      .test('unitsTexting', 'Height should be in meters or centimeter', (value?: string) => ['m', 'sm'].includes(extractUnits(value))),
    floor: yup
      .number()
      .required()
      .test(
        'is-unique',
        t('error_message_floor_number_unique'),
        validateFloorNumber,
      ),
  });
}

export const FloorForm = ({
  values,
  handleChange,
  errors,
  disabled,
  inputRef,
  horizontalView = false,
}: FloorFormProps) => {
  return (
    <Grid
      container
      spacing={2}
      direction={horizontalView ? 'row' : 'column'}
      wrap='nowrap'
    >
      <Grid item xs={horizontalView ? 3 : 12}>
        <TextField
          disabled={disabled}
          fullWidth
          label="Floor name"
          variant="outlined"
          inputRef={inputRef}
          name="name"
          required
          value={values.name ?? ""}
          onChange={handleChange}
          helperText={errors.name}
          error={Boolean(errors.name)}
        />
      </Grid>
      <Grid item xs={horizontalView ? 3 : 12}>
        <TextField
          disabled={disabled}
          fullWidth
          label="Description"
          variant="outlined"
          name="description"
          value={values.description ?? ""}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={horizontalView ? 3 : 12}>
        <UnitsInput
          unitList={UnitsInput.UNIT_TEMPLATE.DISTANCE}
          disabled={disabled}
          fullWidth
          label="Height"
          variant="outlined"
          required
          name="height"
          defaultUnit='m'
          value={values.height ?? ""}
          onChange={handleChange}
          error={Boolean(errors.height)}
          helperText={errors.height}
        />
      </Grid>
      <Grid item xs={horizontalView ? 3 : 12}>
        <TextField
          disabled={disabled}
          fullWidth
          label="Floor number"
          variant="outlined"
          name="floor"
          value={values.floor ?? ""}
          type="number"
          required
          onChange={handleChange}
          error={Boolean(errors.floor)}
          helperText={errors.floor}
        />
      </Grid>
    </Grid>
  );
};
