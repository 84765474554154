import { ReactElement, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Grid, IconButton } from "@material-ui/core";
import ChildrenElement from "../../models/HelperModels/ChildrenElement";

interface BackButtonProps {
  backButtonIcon?: ReactElement[] | ReactElement | null;
  children?: ChildrenElement;
  disabled?: boolean;
  areaLabel?: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  size?: 'small' | 'medium';
  route?: string;
}

const BackButton = ({
  backButtonIcon,
  route,
  children,
  disabled = false,
  areaLabel = 'Go back',
  color = 'secondary',
  size = 'small',
}: BackButtonProps) => {
  const history = useHistory();
  const onClick = useCallback(() => {
    if (disabled) {
      return;
    }
    if (route) {
      history.push(route);
    } else {
      history.goBack();
    }
  }, [route, disabled]);

  // don't show back button if history is empty
  if (!route && history.length === 1) {
    return null;
  }

  return children ? (
      <Grid container onClick={onClick}>
        {
          children
        }
      </Grid>
    ) :
    (
      <IconButton
        aria-label={areaLabel}
        color={color}
        size={size}
        disabled={disabled}
        onClick={onClick}
      >
        {
          backButtonIcon
        }
      </IconButton>
    );
}

export default BackButton;